const COL_WIDTH = 100
const getColumns = (columns) => {
  return [
    {
      columnId: "year_of_study",
      width: 120
    },
    {
      columnId: "date_return_filed",
      width: 160
    },
    {
      columnId: "credits_from_study",
      width: 160
    },
    {
      columnId: "income_tax_allocation",
      width: 160
    },
    {
      columnId: "payroll_allocation",
      width: 160
    },
    {
      columnId: "claim_type",
      width: 160
    },
    ...columns,
    {
      columnId: "remainingCol",
      width: 120
    },
  ]
}

const FIXED_COLUMNS = [
  "year_of_study",
  "date_return_filed",
  "credits_from_study",
  "income_tax_allocation",
  "payroll_allocation",
  "claim_type",
  "remainingCol"
]

export { getColumns, FIXED_COLUMNS }
import BillingHome from "./Home"

const prefixBillingRoute = '/billing'

const BillingRoutes = [
    {
        path: `${prefixBillingRoute}`,
        component: BillingHome,
    },
]

export { BillingRoutes }
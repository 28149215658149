import React from 'react'
import { withRouter, Link } from 'react-router-dom'

import { Button, CircularProgress, Container, Grid } from '@material-ui/core'

import { withFormik, Form, Field } from 'formik'
import * as Yup from 'yup'
import ENUM from '../../config/Enums'

import UseStyles from './ForgotPasswordStyle'
import ForgotPasswordAPI from './ForgotPasswordAPI'

import TitleGroupAuth from '@src/components/common/TitleGroupAuth'
import TextFieldV2 from '@src/components/common/TextFieldV2'

const useStyles = UseStyles
const forgotPasswordAPI = new ForgotPasswordAPI()

function ForgotPasswordSide(props) {
  const classes = useStyles()
  const { touched, errors, values } = props

  const isDisable = () =>
    Object.keys(errors).some((item) => errors[item]) ||
    Object.keys(values).some((item) => values[item].trim().length === 0)

  return (
    <>
      <Container component='main' maxWidth='xs' className={classes.main}>
        <div className={classes.paper}>
          <TitleGroupAuth
            title={'Change Password'}
            description={`Please enter the email address you'd like your password reset information sent to.`}
          />

          <Form className={classes.form} noValidate>
            <Field
              name='email'
              render={({ field }) => (
                <TextFieldV2
                  variant='filled'
                  margin='normal'
                  fullWidth
                  className='mb-0'
                  textKey='email'
                  label='E-mail'
                  showCheckIcon
                  autoComplete='email'
                  {...{ touched, errors, values }}
                  {...field}
                />
              )}
            />

            <div className={classes.wrapperButton}>
              <Button
                type='submit'
                fullWidth
                variant='contained'
                color='default'
                disabled={isDisable() || props.isSubmitting}
                className={`${classes.submit} text-transform-reset`}
              >
                Send password change link
                </Button>
              {props.isSubmitting && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>

            <div className={classes.boxLink}>
              <Link
                className={classes.secondLink}
                to='/auth/forgot-email'
                variant='body2'
              >
                Forgot email
                </Link>

              <Link
                className={classes.secondLink}
                to='/auth/signin'
                variant='button'
                color='textPrimary'
              >
                Return
                </Link>
            </div>
          </Form>
        </div>
      </Container>
    </>
  )
}

const FormikForm = withFormik({
  mapPropsToValues() {
    // Init form field
    return {
      email: ''
    }
  },
  validationSchema: Yup.object().shape({
    // Validate form field
    email: Yup.string().required('Email is required').email('Email is invalid')
  }),
  handleSubmit: async (values, { props, setMessage, setOpenMessage }) => {
    try {
      const responses = await forgotPasswordAPI.forgotPassword(values.email)

      const type = ENUM.NotificationType.SUCCESS
      const m = responses.message

      props.storeNotification.setContent(type, m)
      props.storeNotification.setIsOpen(true)

      // bring values.email and redirect to confirm password component
      props.history.push('/auth/forgot-password/confirm?email=' + values.email)
    } catch (err) {
      const type = ENUM.NotificationType.ERROR
      const m = err.message

      props.storeNotification.setContent(type, m)
      props.storeNotification.setIsOpen(true)
    }
  }
})(ForgotPasswordSide)

export default withRouter(FormikForm)

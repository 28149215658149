import Constants from '@src/config/Constants'
import { toJS } from 'mobx'

class AuthentricateUtil {
  currentUser = null
  static isAuthenticated() {
    const userInfo = window.localStorage.getItem(Constants.PT_UK)
    if (userInfo) {
      this.currentUser = JSON.parse(userInfo)
      if (
        this.currentUser.access_token &&
        this.currentUser._id
      )
        return true
    }
    this.currentUser = null
    return false
  }
  static isAccessRoute(roles = []) {
    if (!this.currentUser) return false
    if (roles.includes(parseInt(this.currentUser.type))) {
      return true
    }
    return false
  }
}

export default AuthentricateUtil

const COL_WIDTH = 100
const getColumns = (columns) => {
  return [
    {
      columnId: "name",
      width: 320
    },
    {
      columnId: "available_amount",
      width: 160
    },
    {
      columnId: "claim_amount",
      width: 160
    },
    {
      columnId: "refund_received_amount",
      width: 160
    },
    {
      columnId: "remain_amount",
      width: 160
    },
    {
      columnId: "total_unclaimed",
      width: 160
    },
  ]
}


export { getColumns }
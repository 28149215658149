import React, { useState, useEffect } from 'react'
import { withStyles, Button, Typography, makeStyles, ButtonGroup } from '@material-ui/core'
import { Link } from 'react-router-dom'

import { IconLeftArrow } from '@src/components/PCustom/SVGIcons'
import Constants from '@src/config/Constants'
const colorS = Constants.COLORS

const PButton = withStyles(() => ({
    root: {
        borderRadius: '6px',
        boxShadow: 'none',
        height: 40,
        padding: '12px 15px',
        fontSize: '14px',
        lineHeight: '16px',
        fontWeight: 400,
        background: '#f8f8f8',
        '&:hover': {
            background: colorS.sGrey20,
        }
    },
    sizeLarge: {
        padding: '16px 32px',
        height: 48,
    },
    iconSizeLarge: {
        '& > *:first-child': {
            fontSize: 20
        }
    },
    textPrimary: {
        background: '#F7F7FA',
        padding: '12px 24px',
        color: colorS.sPurple,
        '&:hover': {
            color: colorS.sPurple,
        }
    },
    textSecondary: {
        background: colorS.sWhite,
        padding: '12px 24px',
        color: colorS.sPurple,
        '&:hover': {
            color: colorS.sPurple,
            background: colorS.sWhite,
        }
    },
    outlinedPrimary: {
        border: `1px solid ${colorS.sPurple}`,
        color: colorS.sPurple,
        background: colorS.sWhite,
        '&:hover': {
            color: colorS.sPurple,
            border: `1px solid ${colorS.sPurple}`,
            background: '#3f51b50a',
        }
    },
    outlinedSecondary: {
        border: `1px solid #d0d0d0`,
        color: '#6E6D7A',
        background: colorS.sWhite,
        '&:hover': {
            color: '#6E6D7A',
            border: `1px solid #6E6D7A`,
            background: '#3f51b50a',
        }
    },
    contained: {
        '&:active': {
            boxShadow: 'none'
        }
    },
    containedPrimary: {
        color: colorS.sWhite,
        background: colorS.sPurple,
        '&:hover': {
            background: colorS.sPurpleHover,
        }
    },
    containedSizeLarge: {
        padding: '16px 32px',
        height: 48,
    },
    startIcon: {},
    disabled: {}
}))(Button)

const wizardNavStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        width: 'fit-content'
    },
    link: {
        padding: '14px 20px',
        background: '#F8F8F8',
        borderRadius: 6,
        marginRight: 16
    },
    arrow: {
        width: 24
    },
    text: {
        color: colorS.sGrey
    },
    purpleLink: {
        textDecoration: 'none',
        color: colorS.sPurple,
        marginLeft: -10,
        display: 'flex',
        alignItems: 'center'
    }
}))

const PWizardNavLink = ({ link, className, children }) => {
    const classes = wizardNavStyles()
    return (
        <Link to={link} className={`${className} ${classes.purpleLink}`}><IconLeftArrow style={{ verticalAlign: 'middle' }} />{children}</Link>
    )
}

const PButtonGroup = withStyles(() => ({

}))(ButtonGroup)
export { PButton, PWizardNavLink, PButtonGroup }
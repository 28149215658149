import SummaryHome from "./Home"

const prefixSummaryRoute = '/summary'

const SummaryRoutes = [
    {
        path: `${prefixSummaryRoute}`,
        component: SummaryHome,
    },
]

export { SummaryRoutes }
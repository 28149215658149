import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import AuthenticateUtil from '@src/utils/AuthenticateUtil'

function PrivateRoute ({ component: Component, ...rest }) {
  const isAuthenticated = AuthenticateUtil.isAuthenticated()
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <>
            <Component {...props} />
          </>
        ) : (
          <Redirect
            to={{
              pathname: '/auth/signin',
              state: { from: props.location }
            }}
          />
      )}
    />
  )
}

export default PrivateRoute

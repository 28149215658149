import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Checkbox from '@material-ui/core/Checkbox'

const useStylesReddit = makeStyles((theme) => ({}))

export default React.memo((props) => {
  // const classes = useStylesReddit()

  return (
    <>
      <Checkbox
        name={props.name}
        checked={props.checked}
        disabled={props.disabled}
        value={props.value}
        onChange={props.onChange}
        icon={
          <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <rect width='24' height='24' rx='4' fill='#F0F0F0' />
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M17.7071 7.29289C18.0976 7.68342 18.0976 8.31658 17.7071 8.70711L10.7071 15.7071C10.3166 16.0976 9.68342 16.0976 9.29289 15.7071L6.29289 12.7071C5.90237 12.3166 5.90237 11.6834 6.29289 11.2929C6.68342 10.9024 7.31658 10.9024 7.70711 11.2929L10 13.5858L16.2929 7.29289C16.6834 6.90237 17.3166 6.90237 17.7071 7.29289Z'
              fill='white'
            />
          </svg>
        }
        checkedIcon={
          <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <rect width='24' height='24' rx='4' fill='#7092FE' />
            <path
              d='M5 13.6667L9.375 17L19 7'
              stroke='white'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        }
        color='primary'
      />
    </>
  )
})

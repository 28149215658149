import { makeStyles } from '@material-ui/core/styles'
import { amber, green } from '@material-ui/core/colors'

const styles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none'
  },
  success: {
    width: '900px',
    padding: 0,
    boxShadow: 'none',
    backgroundColor: theme.palette.common.sWhite,
    '& $message': {
      color: theme.palette.common.sGreen
    }
  },
  error: {
    width: '900px',
    padding: 0,
    boxShadow: 'none',
    backgroundColor: theme.palette.common.sWhite,
    '& $message': {
      color: theme.palette.common.sRedAccent
    }
  },
  info: {
    backgroundColor: theme.palette.common.sWhite
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  },
  wrapTextMessage: {
    display: 'flex',
    maxWidth: '790px',
    flexDirection: 'column',
    alignItems: 'flex-start'
  }
}))

export default styles

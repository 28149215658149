import Enums from '../config/Enums'
import * as _ from 'lodash'
import moment from 'moment'

class UtilityUtil {
  /**
   * Get total loading item
   */
  static getTotalLoadingItem = (total, defaultShow = 10) => {
    if (total === 0) {
      return 4
    } else {
      if (total > defaultShow) {
        return defaultShow
      } else {
        return total
      }
    }
  }

  /**
   * Get price level
   */
  static getPriceLevel = (cost, avgCost) => {
    if (cost && avgCost) {
      const fivePercentCost = 0.05 * avgCost

      if (cost >= avgCost * 2) {
        // is highest price
        return Enums.PriceLevelCalculate.HIGHEST_PRICE
      } else if (cost <= avgCost / 2) {
        // is lowest price
        return Enums.PriceLevelCalculate.LOWEST_PRICE
      } else if (
        cost >= avgCost - fivePercentCost &&
        cost <= avgCost + fivePercentCost
      ) {
        // is best price
        return Enums.PriceLevelCalculate.BEST_PRICE
      } else if (cost > avgCost + fivePercentCost) {
        return Enums.PriceLevelCalculate.HIGHT_PRICE
      } else if (cost < avgCost - fivePercentCost) {
        return Enums.PriceLevelCalculate.LOW_PRICE
      } else {
        // by default -> is best price
        return Enums.PriceLevelCalculate.BEST_PRICE
      }
    } else {
      return Enums.PriceLevelCalculate.BEST_PRICE
    }
  }

  /**
   * Parse join date from date time to string
   * @param {String} date join date
   */
  static parseJoinDate = (date) => {
    if (!date) {
      return ''
    }

    const y = moment().diff(date, 'years')
    if (y > 0) {
      return y === 1 ? '1 year' : `${y} years`
    } else {
      const m = moment().diff(date, 'months')
      if (m > 0) {
        return m === 1 ? '1 month' : `${m} months`
      } else {
        const w = moment().diff(date, 'weeks')
        if (w > 0) {
          return w === 1 ? '1 week' : `${w} weeks`
        } else {
          const d = moment().diff(date, 'days')
          if (d > 0) {
            return d + ' days'
          } else {
            return '1 day'
          }
        }
      }
    }
  }

  /**
   * Parse date from date time to string
   * @param {String} date join date
   */
  static showDefaultDate = (date) => {
    if (!date) {
      return ''
    }

    const currentYear = moment().year()
    const dY = moment(date).year()
    if (currentYear !== dY) {
      return moment(date).format('YYYY MMM DD, h:mm a')
    } else {
      return moment(date).format('MMM DD, h:mm a')
    }
  }

  /**
   * Parse online date from date time to string
   * @param {String} date join date
   */
  static parseOnlineDate = (date) => {
    if (!date) {
      return ''
    }

    const y = moment().diff(date, 'years')
    if (y > 0) {
      return y === 1 ? '1 year' : `${y} years`
    } else {
      const m = moment().diff(date, 'months')
      if (m > 0) {
        return m === 1 ? '1 month' : `${m} months`
      } else {
        const w = moment().diff(date, 'weeks')
        if (w > 0) {
          return w === 1 ? '1 week' : `${w} weeks`
        } else {
          const d = moment().diff(date, 'days')
          if (d > 0) {
            return d === 1 ? '1 day' : `${d} days`
          } else {
            const h = moment().diff(date, 'hours')
            if (h > 0) {
              return h === 1 ? '1 hour' : `${h} hours`
            } else {
              const min = moment().diff(date, 'minutes')
              if (min > 0) {
                return min === 1 ? '1 minutes' : `${min} minutes`
              } else {
                return 'just now'
              }
            }
          }
        }
      }
    }
  }

  /**
   * Map utility detail to utility name
   * Some utility has multiple company, we are treat each company as utility to easy handle utls configuration
   * But with credentials they are the same, so we need to map detail utility (including company name) to real utility
   *
   * @param {String} utility Utiltiy detail value
   */
  static mapUtility = (utility) => {
    return utility
  }

  /**
   * Format currency
   * @param {*} num amount of the money
   */
  static currencyFormat = (num) => {
    return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  /**
   * Format phone number
   * @param {*} phoneNumberString phone number
   */
  static phoneNumberFormat = (phoneNumberString) => {
    let cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      const intlCode = match[1] ? '+1 ' : ''
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
    }
    return null
  }

  /**
   * move item in array from index to index
   * @param {*} arr array data
   * @param {*} fromIndex from index
   * @param {*} toIndex to index
   */
  static moveItemInArray = (arr, fromIndex, toIndex) => {
    const cloneArr = _.cloneDeep(arr)
    var element = cloneArr[fromIndex]
    cloneArr.splice(fromIndex, 1)
    cloneArr.splice(toIndex, 0, element)

    return cloneArr
  }

  /**
   * format data address before send to server
   * @param {*} streetAddress street address
   * @param {*} zipcode zip code
   * @param {*} country country
   * @param {*} state state
   * @param {*} city city
   * @param {*} lat latitude
   * @param {*} lon longtitude
   */
  static formatAddress = (
    streetAddress,
    zipcode,
    country,
    state,
    city,
    lat,
    lon
  ) => {
    const data = {
      street_address: '',
      zipcode: '',
      country: '',
      state: '',
      city: '',
      location: {
        lon: 0,
        lat: 0
      }
    }

    data.street_address = streetAddress
    data.zipcode = zipcode
    data.country = country
    data.state = state
    data.city = city
    data.location.lon = lon
    data.location.lat = lat

    return data
  }

  /**
   * Convert address from object to string like '20240, United States, CA, Inglewood, 1849 c St NW '
   * @param {*} add address
   */
  static getAddressStringFromObject = (add) => {
    let str = ''

    if (!_.isEmpty(add)) {
      // get data from store and parse

      // update place holder
      // ex: 12340 Boggy Creek Road, Orlando, FL, 32824, USA
      str = `${add.street_address}`
      if (add.city) {
        str += `, ${add.city}`
      }
      if (add.state) {
        str += `, ${add.state}`
      }
      if (add.zipcode) {
        str += `, ${add.zipcode}`
      }
      if (add.country) {
        str += `, ${add.country}`
      }
    }

    return str
  }


  /**
   * Convert address from object to string like '20240, United States, CA, Inglewood, 1849 c St NW '
   * @param {*} add address
   */
   static getAddress1StringFromObject = (add) => {
    let str = ''

    if (!_.isEmpty(add)) {
      // get data from store and parse

      // update place holder
      // ex: 12340 Boggy Creek Road, Orlando, FL, 32824, USA
      str = `${add.address1}`
      if (add.city) {
        str += `, ${add.city}`
      }
      if (add.state) {
        str += `, ${add.state}`
      }
      if (add.zipcode) {
        str += `, ${add.zipcode}`
      }
      if (add.country) {
        str += `, ${add.country}`
      }
    }

    return str
  }

  /**
   * Format bytes
   * @param {*} bytes number of bytes
   * @param {*} decimals number of decimals
   */
  static formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
  }

  /**
   * Convert string to slug
   * @param {*} Text text string to convert
   */
  static convertToSlug = (Text) => {
    return Text.toLowerCase()
      .replace(/[^\w ]+/g, '')
      .replace(/ +/g, '-')
  }

  /**
   * validate zipcode
   * @param {*} value zipcode value
   */
  static validateZipCode = (value) => {
    const zipCodePattern = /^\d{5}$|^\d{5}-\d{4}$/
    return zipCodePattern.test(value)
  }

  /**
   * get file extension from file name
   * @param {*} filename file name
   * @return null string if file has not extension
   * @return extension string if file has extension
   */
  static getFileExtension = (filename) => {
    const ext = filename.split('.').pop()
    if (ext === filename) {
      return null
    } else {
      return ext
    }
  }

  /**
   * check extension is image
   * @param {*} ext extension
   */
  static isImage = (ext) => {
    const arrImg = ['APNG', 'BMP', 'GIF', 'ICO', 'JPG', 'JPEG', 'PNG']

    const result = arrImg.find((item) => item === ext.toUpperCase())
    if (result) {
      return true
    } else {
      return false
    }
  }

  /**
   * check extension is video
   * @param {*} ext extension
   */
  static isVideo = (ext) => {
    const arr = ['MP4', 'WEBM', 'OGG']

    const result = arr.find((item) => item === ext.toUpperCase())
    if (result) {
      return true
    } else {
      return false
    }
  }

  /**
   * Get element outer height
   * @param {*} el element
   */
  static getElementOuterHeight = (el) => {
    let height = el.offsetHeight
    const style = window.getComputedStyle(el)

    height +=
      parseInt(style.marginTop) +
      parseInt(style.marginBottom) +
      parseInt(style.paddingBottom) +
      parseInt(style.paddingTop)
    return height
  }

  /**
   * Get element outer width
   * @param {*} el element
   */
  static getElementOuterWidth = (el) => {
    let width = el.offsetWidth
    const style = window.getComputedStyle(el)

    width +=
      parseInt(style.marginLeft) +
      parseInt(style.marginRight) +
      parseInt(style.paddingLeft) +
      parseInt(style.paddingRight)
    return width
  }

  /**
   * Render category info and add imgUrl of this category
   * @param {*} id category's id
   * @param {*} listCats list category data
   */
  static generateCategoryPorchInfo = (id, listCats) => {
    const cat = listCats.find((item) => item.id === id)
    const cloneCat = _.cloneDeep(cat)
    return null

    // if (cloneCat && cloneCat.seoUrlPath) {
    //   const imagesDirectUrl = require.context(
    //     '../assets/img/categories/porch',
    //     true
    //   )
    //   const urlImg = imagesDirectUrl(
    //     './' + `p-category-${cloneCat.seoUrlPath}.svg`
    //   )
    //   const urlImgActive = imagesDirectUrl(
    //     './' + `p-category-${cloneCat.seoUrlPath}-active.svg`
    //   )

    //   cloneCat.name = cat.displayName
    //   cloneCat.imgUrl = urlImg
    //   cloneCat.imgUrlActive = urlImgActive
    //   return cloneCat
    // } else {
    //   return null
    // }
  }

  /**
   * Render category info and add imgUrl of this category
   * @param {*} key category's key
   * @param {*} listCats list category data
   */
  static generateCategoryPorchImage = (key) => {
    return null
    // if (key) {
    //   const imagesDirectUrl = require.context(
    //     '../assets/img/categories/porch',
    //     true
    //   )
    //   const urlImg = imagesDirectUrl('./' + `p-category-${key}.svg`)

    //   return urlImg
    // } else {
    //   return null
    // }
  }

  // TODO: remove this function
  // TODO: and all image in the ../assets/img/categories (not in ../assets/img/categories/porch)
  /**
   * Render category info and add imgUrl of this category
   * @param {*} id category's id
   * @param {*} listCats list category data
   */
  static generateCategoryInfo = (id, listCats) => {
    const cat = listCats.find((item) => item._id === id)
    const cloneCat = _.cloneDeep(cat)

    if (cloneCat && cloneCat.key) {
      const imagesDirectUrl = require.context('../assets/img/categories', true)
      const urlImg = imagesDirectUrl('./' + `p-category-${cloneCat.key}.svg`)
      const urlImgActive = imagesDirectUrl(
        './' + `p-category-${cloneCat.key}-active.svg`
      )

      cloneCat.imgUrl = urlImg
      cloneCat.imgUrlActive = urlImgActive

      return cloneCat
    } else {
      return null
    }
  }

  /**
   * Scroll element to the center of the screen
   * @param {*} el element
   */
  static scrollElemToCenter = (el) => {
    // animate next question in the center of the screen
    const tolerance = 200

    const windowHeight = window.innerHeight
    const elHeight = this.getElementOuterHeight(el)
    if (tolerance + elHeight >= windowHeight) {
      // question height is higher than window
      el.scrollIntoView()
    } else {
      // scroll to center of the screen
      // calculate distance from id element to the top
      const bodyRect = document.body.getBoundingClientRect()
      const elemRect = el.getBoundingClientRect()
      const distanceToTop = elemRect.top - bodyRect.top

      // calculate padding of the body that need
      if (distanceToTop * 2 + elHeight > windowHeight) {
        // need padding bottom on the body

        // calculate center in the screen
        const distanceTopToElem = (windowHeight - elHeight) / 2

        // const paddingValue = distanceToTop * 2 + elHeight - windowHeight
        // console.log('paddingValue: ', paddingValue)

        // set padding to the body
        document.getElementById(
          'root'
        ).style.paddingBottom = `${distanceTopToElem}px`

        // scroll to distance
        window.scrollTo(0, distanceToTop - distanceTopToElem)
      } else {
        // need calculate scrollTo distance
        const scrollDistance = (windowHeight - elHeight) / 2

        // reset padding to the body
        document.getElementById('root').style.paddingBottom = '0px'

        window.scrollTo(0, scrollDistance)
      }
    }
  }

  /**
   * generate image by name
   * @param {*} name name to generate
   * @param {*} size size of the image
   */
  static generateImgByName = (name, size = 40) => {
    const colours = [
      '#1abc9c',
      '#2ecc71',
      '#3498db',
      '#9b59b6',
      '#34495e',
      '#16a085',
      '#27ae60',
      '#2980b9',
      '#8e44ad',
      '#2c3e50',
      '#f1c40f',
      '#e67e22',
      '#e74c3c',
      '#95a5a6',
      '#f39c12',
      '#d35400',
      '#c0392b',
      '#bdc3c7',
      '#7f8c8d'
    ]

    const nameSplit = name.split(' ')

    let initials = ''

    if (name === '') {
      initials = 'A'
    } else {
      if (nameSplit.length > 1) {
        initials =
          nameSplit[0].charAt(0).toUpperCase() +
          nameSplit[1].charAt(0).toUpperCase()
      } else {
        initials = nameSplit[0].charAt(0).toUpperCase()
      }
    }

    const charIndex = initials.charCodeAt(0) - 65
    const colourIndex = charIndex % colours.length

    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    canvas.width = canvas.height = size

    context.fillStyle = colours[colourIndex]
    context.fillRect(0, 0, canvas.width, canvas.height)
    context.font = `${size / 2}px Arial`
    context.textAlign = 'center'
    context.fillStyle = '#FFF'
    context.fillText(initials, canvas.width / 2, canvas.height / 1.5)

    return canvas.toDataURL()
  }
}

export default UtilityUtil

import React, { useState, useEffect, useContext } from 'react'
import { useHistory, useParams, Link } from 'react-router-dom'
import {
    Typography,
    Grid,
    makeStyles,
    Box,
    FormControl,
    Button,
    MenuItem,
    CircularProgress,
    IconButton
} from '@material-ui/core'
import { Delete } from '@material-ui/icons';
import moment from 'moment'
import { CellChange, NumberCell, ReactGrid } from "@silevis/reactgrid";
import "@silevis/reactgrid/styles.css";
import "./assets/styles.css";

import { SummaryValueColumns, SummaryDateColumns } from "./Constants";
import { getColumns, FIXED_COLUMNS } from "./Columns";
import {
    getRows,
    HEADER_ROW_ID,
    BLANK_ROW_ID,
    SUBHEADER_ROW_ID,
    TOTAL_ROW_ID,

    DATE_FEE_RECEIVED_ROW_ID,
    DATE_INVOICE_RECEIVED_ROW_ID,
    TOTAL_FEE_RECEIVED_ROW_ID,
    ATTACH_ROW_ID,
    NOTE_ID
} from "./Rows";
import { calculateOutputVariables } from "./OutputVariables";

import { PButton, PIconText, PSelect } from '@src/components/PCustom'
import { AlertUtil } from '@src/utils'
import TooltipCellTemplate from './TooltipCellTemplate'

import { IconDownArrow } from '@src/components/PCustom/SVGIcons'

import ENUMS from '@src/config/Enums'
import Constants from '@src/config/Constants'
import BillingAPI from '../BillingAPI'
const colorS = Constants.COLORS
const billingAPI = new BillingAPI()

const FIXED_ROWS = [
    HEADER_ROW_ID,
    SUBHEADER_ROW_ID,
    BLANK_ROW_ID,
    TOTAL_ROW_ID,
    DATE_FEE_RECEIVED_ROW_ID,
    DATE_INVOICE_RECEIVED_ROW_ID,
    TOTAL_FEE_RECEIVED_ROW_ID,
    ATTACH_ROW_ID,
    NOTE_ID
]

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'block',
        width: '100%',
        position: 'relative',
        padding: '0',
        margin: 'auto',
        overflow: 'auto',
        '& > .reactgrid': {
            margin: 'auto',
        }
    },
    selector: {
        height: '40px',
        marginRight: '12px',
        '& > div': {
            padding: '5px'
        }
    },
    deleteButton: {
        color: `rgb(220, 0, 78)`,
        border: `1px solid`,
        '&:hover': {
            color: `rgb(220, 0, 78)`,
            border: `1px solid`,
        }
    },
    buttonProgress: {
        // style for button loading
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    wrapperButton: {
        position: 'relative'
    },
    attachments: {
        borderCollapse: 'collapse',
        "& tr": {
            "& td": {
                padding: 5,
            },
            "& th": {
                fontWeight: 500,
                padding: 5
            },

            "& td.currentCol": {
                border: `1px solid #000`,
            }
        }
    },
    uploadInput: {
        display: 'none',
    },
}))

export default ({ storeNotification, onLoading }) => {
    const classes = useStyles()

    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState('');
    const [currentColId, setCurrentColId] = useState('');
    const [processing, setProcessing] = useState(false);

    const [trackLines, setTrackLines] = useState(() => []);
    const [quarters, setQuarters] = useState(() => []);
    const [utilColumns, setUtilColumns] = useState(() => []);

    const [dateFeeReceived, setDateFeeReceived] = useState(() => []);
    const [dateInvoiceReceived, setDateInvoiceReceived] = useState(() => []);
    const [totalFeeReceived, setTotalFeeReceived] = useState(() => []);
    const [attach, setAttach] = useState(() => []);
    const [notes, setNotes] = useState(() => []);

    const columns = getColumns(utilColumns)
    const inputVariables = { trackLines, quarters, dateFeeReceived, dateInvoiceReceived, totalFeeReceived, attach, notes };

    const outputVariables = calculateOutputVariables(inputVariables);
    const companyTrackData = { ...inputVariables, ...outputVariables };

    const rows = getRows(companyTrackData);

    const applyChange = (change) => (rows) => {
        return rows.map((row) => {
            if (row._id === change.rowId) {
                if (SummaryValueColumns.includes(change.columnId)) {
                    row[change.columnId] = change.newCell.value
                } else if (SummaryDateColumns.includes(change.columnId)) {
                    row[change.columnId] = change.newCell.date
                } else if (change.columnId == 'claim_type') {
                    if (change.previousCell.isOpen !== change.newCell.isOpen) {
                        row['claim_type_is_open'] = change.newCell.isOpen
                    }
                    if (change.previousCell.selectedValue !== change.newCell.selectedValue) {
                        row[change.columnId] = change.newCell.selectedValue
                    }
                } else {
                    const tax_values = [...row['tax_values']]
                    tax_values[change.columnId] = change.newCell.value
                    const total_tax = tax_values.reduce((t, c) => t + c, 0)
                    if (total_tax > row['credits_from_study'] - row['income_tax_allocation']) {
                        AlertUtil.showErrorAlert(storeNotification, "Value is not available.")
                    } else {
                        row['tax_values'][change.columnId] = change.newCell.value
                    }

                }
            }
            return row
        })
    }

    useEffect(() => {
        loadData()
        const storedCompany = localStorage.getItem('company')
        if (storedCompany) {
            handleChangeCompany(storedCompany)
        }
    }, [])

    const loadData = async () => {
        try {
            const companyResp = await billingAPI.getCompanies()
            setCompanies(companyResp.data)
        } catch (e) {
            const m = e.message ? e.message : e.toString()
            AlertUtil.showErrorAlert(storeNotification, m)
        }
    }

    const handleChangeCompany = async (e) => {
        if (!e) return
        onLoading(true)
        setSelectedCompany(e)
        localStorage.setItem('company', e)
        try {
            const billingResp = await billingAPI.getBillingData({ company: e })
            setTrackLines(billingResp.data.track_lines || [])
            setQuarters(billingResp.data.quarters || [])
            setUtilColumns(billingResp.data.quarters.map((elem, index) => {
                return {
                    columnId: index,
                    width: 120
                }
            }))
            setDateFeeReceived(billingResp.data.date_fee_received || [])
            setDateInvoiceReceived(billingResp.data.date_invoice_received || [])
            setTotalFeeReceived(billingResp.data.total_fee_received || [])
            setAttach(billingResp.data.attach || [])
            setNotes(billingResp.data.notes || [])

        } catch (e) {
            const m = e.message ? e.message : e.toString()
            AlertUtil.showErrorAlert(storeNotification, m)
        }
        onLoading(false)
    }

    const handleChanges = (changes) => {
        changes.forEach((change) => {
            setTrackLines((trackLine) => applyChange(change)(trackLine));
            if (change.rowId == SUBHEADER_ROW_ID) {
                quarters[change.columnId] = change.newCell.text
                setQuarters(quarters)
            }

            if (change.rowId == DATE_FEE_RECEIVED_ROW_ID) {
                dateFeeReceived[change.columnId] = change.newCell.date
                setDateFeeReceived(dateFeeReceived)
            }

            if (change.rowId == TOTAL_FEE_RECEIVED_ROW_ID) {
                totalFeeReceived[change.columnId] = change.newCell.value
                setTotalFeeReceived(totalFeeReceived)
            }

            if (change.rowId == DATE_INVOICE_RECEIVED_ROW_ID) {
                dateInvoiceReceived[change.columnId] = change.newCell.text
                setDateInvoiceReceived(dateInvoiceReceived)
            }

            if (change.rowId == ATTACH_ROW_ID) {
                attach[change.columnId] = change.newCell.text
                setAttach(attach)
            }
            if (change.rowId.toString().indexOf(NOTE_ID) == 0) {
                const index = parseInt(change.rowId.toString().replace(NOTE_ID, ''))
                notes[index]['values'][change.columnId] = change.newCell.text
                setNotes(notes)
            }
        });
    }

    const handleAddNoteRow = () => {
        if (!selectedCompany) return
        notes.push({
            title: `Note ${notes.length + 1}`,
            values: quarters.map(elem => '')
        })
        setNotes([...notes])
    }

    const handleSave = async () => {
        if (!selectedCompany) return
        setProcessing(true)
        try {
            const dataResp = await billingAPI.storeTrackLines({
                company: selectedCompany,
                quarters,
                dateFeeReceived, dateInvoiceReceived, totalFeeReceived, notes,
            })
            setNotes(dataResp.data.notes || [])
            AlertUtil.showSuccessAlert(storeNotification, "Billing Data is stored")
        } catch (e) {
            const m = e.message ? e.message : e.toString()
            AlertUtil.showErrorAlert(storeNotification, m)
        }
        setProcessing(false)
    }

    const handleLocationChanges = (location) => {
        setCurrentColId(location.columnId)
    }

    const handleUploadFile = async (e, quarter, index) => {
        const formData = new FormData();
        for (let i = 0; i < e.target.files.length; i++) {
            formData.append("file", e.target.files[i]);
        }
        formData.append("quarter", quarter);
        formData.append("company", selectedCompany);


        try {
            const resp = await billingAPI.uploadAttachment(formData)
            attach[index] = resp.data
            setAttach([...attach])
            AlertUtil.showSuccessAlert(storeNotification, "Uploading attchment is done.")
        } catch (ex) {
            const m = e.message ? e.message : e.toString()
            AlertUtil.showErrorAlert(storeNotification, m)
        }
    }

    const handleAttachView = async (id) => {
        if (!id) return
        try {
            window.open(`${Constants.API_URL}/storage/billing_attach?id=${id}`, "_blank");
        } catch (e) {
            const m = e.message ? e.message : e.toString()
            AlertUtil.showErrorAlert(storeNotification, m)
        }
    }

    const handleRemoveFile = async (item, index) => {
        if (!item) return
        if (window.confirm("Are you sure to delete attachment?")) {
            try {
                await billingAPI.deleteAttachment(item._id)
                const rowAttach = attach[index].filter(elem => elem._id != item._id)
                attach[index] = rowAttach
                setAttach([...attach])
                AlertUtil.showSuccessAlert(storeNotification, "Attachment is removed.")
            } catch (e) {
                const m = e.message ? e.message : e.toString()
                AlertUtil.showErrorAlert(storeNotification, m)
            }
        }
    }

    return (
        <div className={`${classes.container} tracker-wrapper`}>
            <Typography variant="h4" className="pl-40">Billing</Typography>
            <Box display="flex" justifyContent="center" className="mb-12" alignItems="center">
                <FormControl variant="filled">
                    <PSelect
                        labelId="company-label"
                        variant="filled"
                        displayEmpty
                        IconComponent={IconDownArrow}
                        value={selectedCompany} onChange={(e) => handleChangeCompany(e.target.value)}
                        className={classes.selector}
                    >
                        {([{ _id: '', name: 'Select a Company' }, ...companies] || []).map((item, index) => (
                            <MenuItem value={item._id} key={index}>{item.name}</MenuItem>
                        ))}
                    </PSelect>
                </FormControl>

                <PButton variant="outlined" color="primary" className="mr-12" onClick={handleAddNoteRow} disabled={!selectedCompany}>Add Note Row</PButton>

                <div className={classes.wrapperButton}>
                    <PButton variant="contained" color="primary" className="mr-12" onClick={handleSave} disabled={!selectedCompany}>Save</PButton>
                    {processing && (
                        <CircularProgress
                            size={24}
                            className={classes.buttonProgress}
                        />
                    )}
                </div>

            </Box>
            <div className={`${classes.container}`}>
                <ReactGrid
                    rows={rows}
                    columns={columns}
                    onCellsChanged={handleChanges}
                    onFocusLocationChanged={handleLocationChanges}
                    stickyTopRows={1}
                    stickyLeftColumns={1}
                    stickyRightColumns={1}
                    // props below are availble for PRO version
                    enableFillHandle
                    enableRangeSelection
                    customCellTemplates={{ tooltip: new TooltipCellTemplate() }}
                />
            </div>
            <div className={`${classes.container} p-40`}>
                <Typography variant="h5">Attachments</Typography>
                <table className={classes.attachments}>
                    <tbody>
                        <tr>
                            <td><b>Quarter</b></td>
                            {quarters.map((item, index) => <td key={index} className={`${index === currentColId ? 'currentCol' : ''}`}>{item}</td>)}
                        </tr>
                        <tr>
                            <td><b>Attachment</b></td>
                            {quarters.map((item, index) => (
                                <td key={index} className={`${index === currentColId ? 'currentCol' : ''}`}>
                                    <ul className="p-0">
                                        {(attach[index] || []).map((elem, attachIndex) => (
                                            <li className="d-flex">
                                                <Link onClick={(e) => handleAttachView(elem._id)} disabled={!elem}>{elem ? elem.name : ''}</Link>
                                                <IconButton
                                                    size="small"
                                                    onClick={(e) => handleRemoveFile(elem, index)}
                                                    color="danger"
                                                    component="span">
                                                    <Delete />
                                                </IconButton>
                                            </li>
                                        ))}
                                    </ul>
                                </td>
                            ))}
                        </tr>
                        <tr>
                            <td><b>Action</b></td>
                            {quarters.map((item, index) =>
                                <td key={index} className={`${index === currentColId ? 'currentCol' : ''}`}>
                                    <input
                                        accept="*"
                                        className={classes.uploadInput}
                                        id={`upload-${index}`}
                                        multiple
                                        type="file"
                                        onChange={(e) => handleUploadFile(e, item, index)}
                                    />
                                    <label htmlFor={`upload-${index}`}>
                                        <PButton variant="contained" color="primary" component="span">
                                            Upload
                                        </PButton>
                                    </label>

                                </td>)}
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

import React from 'react'
import { Box, Typography } from '@material-ui/core'

function TitleGroupAuth({ title, description }) {
  return (
    <>
      <Typography component='h1' variant='h4'>
        <Box fontSize='3rem' fontWeight='400' align='center' className="mb-16">
          {title}
        </Box>
      </Typography>
      <Box mt={1} mb={0} fontSize='1.4rem' align='center'>
        <span dangerouslySetInnerHTML={{ __html: description }} />
      </Box>
    </>
  )
}

export default TitleGroupAuth

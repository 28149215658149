import Base80API from '../../api/Base80API'
import Constants from '../../config/Constants'

class TrackerAPI extends Base80API {
    async getCompanies(params) {
        try {
            const url = `${Constants.API_URL}/company`
            return await this.get(url, params)
        } catch (err) {
            console.log(err)
            throw err
        }
    }

    async getTrackData(params) {
        try {
            const url = `${Constants.API_URL}/track`
            return await this.get(url, params)
        } catch (err) {
            console.log(err)
            throw err
        }
    }

    async addNewSummary(body) {
        try {
            const url = `${Constants.API_URL}/track`
            const response = await this.post(url, body)
            return response
        } catch (err) {
            console.log(err)
            throw err
        }
    }

    async storeTrackLines(body) {
        try {
            const url = `${Constants.API_URL}/track`
            const response = await this.put(url, body)
            return response
        } catch (err) {
            console.log(err)
            throw err
        }
    }

    async deleteTrackLines(summaryId) {
        try {
            const url = `${Constants.API_URL}/track/${summaryId}`
            const response = await this.delete(url, {})
            return response
        } catch (err) {
            console.log(err)
            throw err
        }
    }

    async exportPDF(body) {
        try {
            const url = `${Constants.API_URL}/track/form`
            const response = await this.post(url, body)
            return response
        } catch (err) {
            console.log(err)
            throw err
        }
    }

    async uploadAttachment(body) {
        try {
            const url = `${Constants.API_URL}/upload/attachment`
            const response = await this.post(url, body)
            return response
        } catch (err) {
            console.log(err)
            throw err
        }
    }

    async deleteAttachment(attachId) {
        try {
            const url = `${Constants.API_URL}/track/attachment`
            const response = await this.post(url, { id: attachId })
            return response
        } catch (err) {
            console.log(err)
            throw err
        }
    }

}

export default TrackerAPI

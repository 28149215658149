import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import NumberFormat from 'react-number-format'
import PropTypes from 'prop-types'

export default React.memo((props) => {
  const h = props.height ? props.height : '50px'

  const useStylesReddit = makeStyles((theme) => ({
    // style input only
    inputRoot: {
      height: h,
      color: '#fff',
      fontSize: '2.4rem',
      backgroundColor: 'transparent',
      border: 'none',
      '&$focused': {
        backgroundColor: 'transparent',
        border: 'none'
      },
      '&:hover': {
        backgroundColor: 'transparent',
        border: 'none'
      }
    },
    label: {
      top: '-8px',
      fontSize: '1.85rem',
      color: '#fff',
      '&$labelFocused': {
        color: '#fff'
      }
    },
    focused: {
      backgroundColor: 'transparent',
      border: 'none'
    },
    labelFocused: {
      color: '#fff'
    }
  }))

  const classes = useStylesReddit()

  const [values, setValues] = React.useState({
    // textmask: '(1  )    -    ',
    cardNumber: props.value
  })

  const handleChange = (event) => {
    console.log(event.target.value)

    setValues({
      ...values,
      [event.target.name]: event.target.value
    })

    // props.onChange(event.target.value)
  }

  return (
    <TextField
      variant={props.variant}
      disabled={props.disabled}
      fullWidth={props.fullWidth}
      multiline={props.multiline}
      rows={props.rows}
      InputProps={{
        readOnly: props.readOnly,
        classes: {
          root: classes.inputRoot,
          focused: classes.focused
        },
        endAdornment: props.endAdornment ? props.endAdornment : <></>,
        inputComponent: NumberFormatCustom
      }}
      InputLabelProps={{
        shrink: true,
        classes: {
          root: classes.label,
          focused: classes.labelFocused
        }
      }}
      inputProps={{}}
      // helperText={props.touched.password && props.errors.password}
      // error={props.touched.password && !!props.errors.password}
      placeholder='MM / DD'
      label='Date expired'
      type='text'
      name='cardNumber'
      value={values.cardNumber}
      defaultValue={props.defaultValue}
      id={props.id}
      onChange={handleChange}
      className={props.className}
    />
  )
})

const limit = (val, max) => {
  if (val.length === 1 && val[0] > max[0]) {
    val = '0' + val
  }

  if (val.length === 2) {
    if (Number(val) === 0) {
      val = '01'

      //this can happen when user paste number
    } else if (val > max) {
      val = max
    }
  }

  return val
}

const cardExpiry = (val) => {
  const month = limit(val.substring(0, 2), '12')
  const date = limit(val.substring(2, 4), '31')

  return month + (date.length ? ' / ' + date : '')
}

const NumberFormatCustom = (props) => {
  const { inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        })
      }}
      format={cardExpiry}
      // thousandSeparator
      isNumericString
      // prefix='$'
    />
  )
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
}

import Form941Home from "./Home"

const prefixForm941Route = '/forms'

const Form941Routes = [
    {
        path: `${prefixForm941Route}`,
        component: Form941Home,
    },
]

const initialForm941Y2022 = {
    line1: 0,
    line2: 0,
    line3: 0,
    line5a_col1: 0,
    line5a_col2: 0,

    line5ai_col1: 0,
    line5ai_col2: 0,

    line5aii_col1: 0,
    line5aii_col2: 0,

    line5b_col1: 0,
    line5b_col2: 0,

    line5c_col1: 0,
    line5c_col2: 0,

    line5d_col1: 0,
    line5d_col2: 0,

    line5e: 0,

    line6: 0,
    line7: 0,

    line10: 0,
    line11a: 0,
    line11g: 0,
    line12: 0,
    line11a: 0,
    line13g: 0,
    line14: 0,
    line15: 0,

    line15_chk_0: false,
    line15_chk_1: false,

    line16_chk_0: false,
    line16_chk_1: false,
    line16_chk_2: false,

    line16_month_1: 0,
    line16_month_2: 0,
    line16_month_3: 0,
    line16_month_4: 0,
}

const initialForm941SBY2017 = {
    month_1_1: 0,
    month_1_2: 0,
    month_1_3: 0,
    month_1_4: 0,
    month_1_5: 0,
    month_1_6: 0,
    month_1_7: 0,
    month_1_8: 0,
    month_1_9: 0,
    month_1_10: 0,
    month_1_11: 0,
    month_1_12: 0,
    month_1_13: 0,
    month_1_14: 0,
    month_1_15: 0,
    month_1_16: 0,
    month_1_17: 0,
    month_1_18: 0,
    month_1_19: 0,
    month_1_20: 0,
    month_1_21: 0,
    month_1_22: 0,
    month_1_23: 0,
    month_1_24: 0,
    month_1_25: 0,
    month_1_26: 0,
    month_1_27: 0,
    month_1_28: 0,
    month_1_29: 0,
    month_1_30: 0,
    month_1_31: 0,
    month_1_total: 0,
  
    month_2_1: 0,
    month_2_2: 0,
    month_2_3: 0,
    month_2_4: 0,
    month_2_5: 0,
    month_2_6: 0,
    month_2_7: 0,
    month_2_8: 0,
    month_2_9: 0,
    month_2_10: 0,
    month_2_11: 0,
    month_2_12: 0,
    month_2_13: 0,
    month_2_14: 0,
    month_2_15: 0,
    month_2_16: 0,
    month_2_17: 0,
    month_2_18: 0,
    month_2_19: 0,
    month_2_20: 0,
    month_2_21: 0,
    month_2_22: 0,
    month_2_23: 0,
    month_2_24: 0,
    month_2_25: 0,
    month_2_26: 0,
    month_2_27: 0,
    month_2_28: 0,
    month_2_29: 0,
    month_2_30: 0,
    month_2_31: 0,
    month_2_total: 0,
  
    month_3_1: 0,
    month_3_2: 0,
    month_3_3: 0,
    month_3_4: 0,
    month_3_5: 0,
    month_3_6: 0,
    month_3_7: 0,
    month_3_8: 0,
    month_3_9: 0,
    month_3_10: 0,
    month_3_11: 0,
    month_3_12: 0,
    month_3_13: 0,
    month_3_14: 0,
    month_3_15: 0,
    month_3_16: 0,
    month_3_17: 0,
    month_3_18: 0,
    month_3_19: 0,
    month_3_20: 0,
    month_3_21: 0,
    month_3_22: 0,
    month_3_23: 0,
    month_3_24: 0,
    month_3_25: 0,
    month_3_26: 0,
    month_3_27: 0,
    month_3_28: 0,
    month_3_29: 0,
    month_3_30: 0,
    month_3_31: 0,
    month_3_total: 0,
    
    total: 0    
}
export { Form941Routes, initialForm941Y2022, initialForm941SBY2017 }
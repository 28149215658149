import React from 'react'
import { withRouter } from 'react-router-dom'

import { Link, Box } from '@material-ui/core'

import ENUM from '../../config/Enums'
import UseStyles from './ForgotPasswordResendStyle'
import ForgotPasswordAPI from '../ForgotPassword/ForgotPasswordAPI'

const useStyles = UseStyles
const forgotPasswordAPI = new ForgotPasswordAPI()

function SignUpResendSide(props) {
  const classes = useStyles()
  const email = props.email

  async function resendClick() {
    try {
      const responses = await forgotPasswordAPI.forgotPassword(email)

      const type = ENUM.NotificationType.SUCCESS
      const m = responses.message

      props.storeNotification.setContent(type, m)
      props.storeNotification.setIsOpen(true)
    } catch (err) {
      const type = ENUM.NotificationType.ERROR
      const m = err.message

      props.storeNotification.setContent(type, m)
      props.storeNotification.setIsOpen(true)
    }
  }

  return (
    <Link
      className={classes.linkClick}
      variant='body2'
      href='#'
      onClick={resendClick}
    >
      <Box fontSize='1.4rem' align='center'>
        Resend email
      </Box>
    </Link>
  )
}

export default withRouter(SignUpResendSide)

import React, { useState, useEffect } from 'react'
import { withStyles, makeStyles, Typography, Box } from '@material-ui/core'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import { Skeleton } from '@material-ui/lab'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import IconCheckboxSVG from '@src/assets/img/app/icon-checkbox.svg'

import { PBuildingActionDropdown } from './Common'
import Constants from '@src/config/Constants'
const colorS = Constants.COLORS

const PChipToggleButtonGroup = withStyles(() => ({
    root: {
        borderRadius: 0
    },
    grouped: {
        marginRight: 8,
        '&:last-child': {
            marginRight: '0'
        },
    },
    groupedHorizontal: {
        '&:not(:first-child)': {
            borderLeft: '1px solid transparent',
            marginLeft: 0,
            borderRadius: 24,
        },
        '&:not(:last-child)': {
            borderRadius: 24,
        }
    }
}))(ToggleButtonGroup);

const PChipToggleButton = withStyles(() => ({
    root: {
        borderRadius: 24,
        height: '48px',
        padding: '0 20px',
        textTransform: 'capitalize',
        color: colorS.sBlack,
        fontWeight: '400',
        border: '1px solid transparent',
        boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.05), 0px 12px 16px rgba(0, 0, 0, 0.05)',
        '&$selected, &$selected:hover': {
            background: colorS.sPurple,
            border: `1px solid ${colorS.sPurple} !important`,
            color: colorS.sWhite,
            boxShadow: 'none',
            '& > $label': {
                '& > svg': {
                    color: colorS.sWhite,
                }
            }
        },
        '&:hover': {
            background: colorS.sPurple,
            border: '1px solid ' + colorS.sPurple,
            color: colorS.sWhite,
            boxShadow: 'none',
            '& > $label': {
                '& > svg': {
                    color: colorS.sWhite,
                }
            }
        },
        '&$disabled': {
            color: colorS.sBlack,
            boxShadow: 'none',
            '& > $label': {
                '& > svg': {
                    color: colorS.sGrey,
                }
            }
        }
    },
    selected: {
        '& > $label': {
            '& > svg:first-child': {
                display: 'none',
            },
            '& > svg:last-child': {
                display: 'block'
            }
        }
    },
    disabled: {
        background: colorS.sGrey20,
    },
    label: {
        '& > svg': {
            color: colorS.sPurple,
        },
        '& > svg:last-child': {
            color: colorS.sPurple,
            display: 'none'
        }
    }
}))(ToggleButton);

const PSmallChipToggleButton = withStyles(() => ({
    root: {
        borderRadius: '6px !important',
        height: '30px',
        padding: '0 16px',
        textTransform: 'capitalize',
        color: colorS.sBlack,
        fontWeight: '400',
        fontSize: '12px',
        border: '1px solid transparent',
        boxShadow: 'none',
        background: '#F0F0F0',
        '&$selected, &$selected:hover': {
            background: '#39A4DD',
            border: `1px solid #39A4DD !important`,
            color: colorS.sWhite,
            boxShadow: 'none',
        },
        '&:hover': {
            background: '#39A4DDAA',
            border: `1px solid #39A4DD !important`,
            color: colorS.sWhite,
            boxShadow: 'none',
        },
        '&$disabled': {
            color: colorS.sGrey,
            boxShadow: 'none',
        }
    },
    selected: {},
    disabled: {
        background: colorS.sGrey20,
    },
    label: {}
}))(ToggleButton);

const PLinkToggleButtonGroup = withStyles(() => ({
    root: {
        borderRadius: 0
    },
}))(ToggleButtonGroup)

const PLinkToggleButton = withStyles(() => ({
    root: {
        color: '#6E6D7A',
        background: 'transparent',
        border: '1px solid transparent',
        borderRadius: 8,
        textTransform: 'initial',
        fontWeight: 400,
        '&$selected, &$selected:hover': {
            background: 'transparent',
            border: '1px solid transparent',
            color: colorS.sPurple,
        },
        '&:hover': {
            background: 'transparent',
            border: '1px solid transparent',
            color: colorS.sPurple,
        },
    },
    selected: {}
}))(ToggleButton)

const PBoxToggleButtonGroup = withStyles(() => ({
    root: {
        background: '#F7F7FA',
        borderRadius: 6,
        padding: 3
    },
}))(ToggleButtonGroup)

const PBoxToggleButton = withStyles(() => ({
    root: {
        color: '#6E6D7A',
        background: 'transparent',
        border: '1px solid transparent',
        borderRadius: '6px !important',
        textTransform: 'initial',
        fontWeight: 400,
        padding: '12px 24px',
        '&$selected, &$selected:hover': {
            background: colorS.sWhite,
            border: `1px solid ${colorS.sWhite}`,
            color: colorS.sRedAccent,
            boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.07)',
        },
        '&:hover': {
            background: colorS.sWhite,
            border: `1px solid ${colorS.sWhite}`,
        },
    },
    selected: {}
}))(ToggleButton)


const PBorderToggleButtonGroup = withStyles(() => ({
    root: {
        background: colorS.sWhite,
        borderRadius: 6,
    },
}))(ToggleButtonGroup)

const PBorderToggleButton = withStyles(() => ({
    root: {
        color: '#6E6D7A',
        background: 'transparent',
        border: '1px solid #D6D6D6',
        borderRadius: '6px',
        textTransform: 'initial',
        fontWeight: 400,
        padding: '6px 24px',
        minWidth: 90,
        '&$selected, &$selected:hover': {
            background: colorS.sPurple10,
            color: colorS.sPurple,
            border: `1px solid ${colorS.sPurple}`,
        },
        '&:hover': {
            background: colorS.sWhite,
            border: `1px solid ${colorS.sPurple}`,
        },
    },
    selected: {}
}))(ToggleButton)

const PFillToggleButton = withStyles(() => ({
    root: {
        color: '#6E6D7A',
        background: colorS.sWhite,
        border: '1px solid #D6D6D6',
        borderRadius: '6px',
        textTransform: 'initial',
        fontWeight: 400,
        padding: '6px 24px',
        minWidth: 90,
        '&$selected + &$selected': {
            borderLeft: `1px solid ${colorS.sPurple}`,
        },
        '&$selected, &$selected:hover': {
            background: colorS.sPurple,
            color: colorS.sWhite,
            border: `1px solid ${colorS.sPurple}`,
        },
        '&:hover': {
            background: colorS.sWhite,
            border: `1px solid ${colorS.sPurple}`,
        },
    },
    selected: {}
}))(ToggleButton)

const PImageToggleButtonGroup = withStyles(() => ({
    root: {
        borderRadius: 0
    },
    grouped: {
        marginRight: 24,
        '&:last-child': {
            marginRight: '0'
        },
    },
    groupedHorizontal: {
        '&:not(:first-child)': {
            borderLeft: '1px solid transparent',
            marginLeft: 0,
            borderRadius: 8,
        },
        '&:not(:last-child)': {
            borderRadius: 8,
        }
    }
}))(ToggleButtonGroup);

const PImageToggleButton = withStyles(() => ({
    root: {
        borderRadius: 24,
        height: 'auto',
        padding: '24px 12px',
        color: colorS.sBlack,
        textTransform: 'none',
        fontWeight: '400',
        fontSize: '14px',
        border: '1px solid transparent',
        boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.05), 0px 12px 16px rgba(0, 0, 0, 0.05)',
        display: 'block',
        textAlign: 'center',
        width: '145px',

        '&$selected, &$selected:hover': {
            background: colorS.sPurple,
            border: `1px solid ${colorS.sPurple} !important`,
            color: colorS.sWhite,
            boxShadow: 'none',
            '& > $label': {
                '& > svg': {
                    color: colorS.sWhite,
                }
            }
        },
        '&:hover': {
            background: colorS.sPurple,
            border: '1px solid ' + colorS.sPurple,
            color: colorS.sWhite,
            boxShadow: 'none',
            '& > $label': {
                '& > svg': {
                    color: colorS.sWhite,
                }
            }
        },
        '&$disabled': {
            color: colorS.sBlack,
            boxShadow: 'none',
            '& > $label': {
                '& > svg': {
                    color: colorS.sGrey,
                }
            }
        }
    },
    selected: {
        '& > $label': {
            '& > svg': {
                color: colorS.sWhite,
            },
        }
    },
    disabled: {
        background: colorS.sGrey20,
    },
    label: {
        '& > svg': {
            color: colorS.sBlack,
        }
    }
}))(ToggleButton)

const POutlineToggleButtonGroup = withStyles(() => ({
    root: {
        background: colorS.sWhite,
        borderRadius: 8,
        margin: '0 -8px'
    },
}))(ToggleButtonGroup)

const POutlineToggleButton = withStyles(() => ({
    root: {
        color: colorS.sBlack,
        background: colorS.sWhite,
        border: '1px solid #D6D6D6 !important',
        borderRadius: '8px !important',
        textTransform: 'initial',
        fontWeight: 400,
        padding: '18px 30px',
        minWidth: 105,
        minHeight: 56,
        margin: '0 8px !important',
        '&$selected + &$selected': {
            borderLeft: `1px solid ${colorS.sPurple}`,
        },
        '&$selected, &$selected:hover': {
            background: colorS.sPurple,
            color: colorS.sWhite,
            border: `1px solid ${colorS.sPurple} !important`,
        },
        '&:hover': {
            background: colorS.sWhite,
            border: `1px solid ${colorS.sPurple}`,
        },
        '&$disabled': {
            background: '#F7F7FA',
            border: '1px solid #F7F7FA !important',
            color: colorS.sGrey
        }
    },
    selected: {},
    disabled: {}
}))(ToggleButton)

const PChipIconToggleButton = (props) => {
    return (
        <PChipToggleButton {...props}>
            <AddCircleIcon style={{ fontSize: 20, marginRight: 8 }} />
            <RemoveCircleIcon style={{ fontSize: 20, marginRight: 8 }} />
            {props.children}
        </PChipToggleButton>
    )
}

const iconTogglePaneStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        padding: '24px 36px',
        borderRadius: 8,
        border: '1px solid #F0F0F0',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
            background: colorS.sPurple,
            borderColor: colorS.sPurple,

            '& $name': {
                color: colorS.sWhite
            },
            '& $desc': {
                color: colorS.sWhite
            },
            '& $imageWrap': {
                background: colorS.sWhite
            },
            '& $itemImage': {
                color: colorS.sWhite
            }
        },
        '&$selected': {
            background: colorS.sPurple,
            borderColor: colorS.sPurple,

            '& $name': {
                color: colorS.sWhite
            },
            '& $desc': {
                color: colorS.sWhite
            },
            '& $imageWrap': {
                background: colorS.sWhite
            },
            '& $itemImage': {
                color: colorS.sWhite
            }
        }
    },
    selected: {},
    name: {
        fontSize: '16px',
        fontWeight: 500,
        marginBottom: 8,
        textAlign: 'center',
        wordBreak: 'break-word'
    },
    desc: {
        color: colorS.sGrey,
        textAlign: 'center',
        wordBreak: 'break-word'
    },
    imageWrap: {
        width: 64,
        height: 64,
        background: '#f8f8f8',
        borderRadius: 6,
        padding: 12,
        display: 'flex',
        alignItems: 'center',
        marginBottom: 24,
        '& > img': {
            width: '100%',
        }
    },
    itemImage: {
        width: 84,
        height: 84,
        background: 'transparent',
        borderRadius: 6,
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        marginBottom: 16,
        color: '#343568'
    },
    dropDown: {
        position: 'absolute',
        right: 30,
        top: 5,
        '& > button': {
            padding: 5
        }
    }
}))

const PIconTogglePane = ({ selected = false, onClick, name, desc, normalImg, selectedImg, className }) => {
    const classes = iconTogglePaneStyles()
    return (
        <div
            onClick={onClick}
            className={`${classes.root} ${selected ? classes.selected : ''} ${className}`}
        >
            <div className={classes.imageWrap}>
                {!selected || !selectedImg ? (<img src={normalImg} />) : (<img src={selectedImg} />)}
            </div>
            <Typography component="span" className={classes.name}>{name}</Typography>
            <Typography component="span" className={classes.desc}>{desc}</Typography>
        </div>
    )
}

const PIconToggleExPane = ({
    selected = false,
    onClick,
    name,
    desc,
    normalImg,
    selectedImg,
    className,
    actions,
    onMenuClick,
    dropdownClass,
    itemImage: Comp,
    loading
}) => {
    const classes = iconTogglePaneStyles()
    return (
        <Box position="relative">
            <div
                onClick={onClick}
                className={`${classes.root} ${selected ? classes.selected : ''} ${className}`}
            >
                {normalImg && (
                    <div className={classes.imageWrap}>
                        {!selected || !selectedImg ? (<img src={normalImg} />) : (<img src={selectedImg} />)}
                    </div>
                )}
                {Comp && (
                    <Comp className={classes.itemImage} />
                )}
                <Typography component="span" className={classes.name}>{name}</Typography>
                {!loading ? (
                    <Typography component="span" className={classes.desc}>{desc}</Typography>
                ) : (
                    <Skeleton variant="text" width="60%" className={classes.desc} />
                )}
            </div>
            {selected && (
                <PBuildingActionDropdown
                    selected={true}
                    menuIcon={MoreVertIcon}
                    menuItems={actions}
                    className={`${classes.dropDown} ${dropdownClass}`}
                    onClick={(v) => onMenuClick(v)}
                />
            )}
        </Box>
    )
}

const PIconSelectItem = ({ selected = false, onClick, name, desc, itemImage: Comp, className }) => {
    const classes = iconTogglePaneStyles()
    return (
        <div
            onClick={onClick}
            className={`${classes.root} ${selected ? classes.selected : ''} ${className}`}
        >
            <Comp className={classes.itemImage} />
            <Typography component="span" className={classes.name}>{name}</Typography>
            <Typography component="span" className={classes.desc}>{desc}</Typography>
        </div>
    )
}

const checkTradesmanStyles = makeStyles((theme) => ({
    root: {
        padding: '24px 36px',
        borderRadius: 8,
        border: '1px solid #F0F0F0',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        '&:hover': {
            borderColor: colorS.sPurple,
        },
        '&$checked': {
            borderColor: colorS.sPurple,
        }
    },
    checked: {},
    name: {
        fontSize: '14px',
        fontWeight: 500,
        marginBottom: 8
    },
    star: {
        color: '#FFD426',
        margin: '0 4px',
    },
    itemImage: {
        width: 84,
        height: 84,
        background: 'transparent',
        borderRadius: 6,
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        marginBottom: 16,
        color: '#343568'
    },
    checkBox: {
        position: 'absolute',
        top: 8,
        right: 8
    }
}))

const PCheckTradesmanItem = ({ checked, onClick, name, score, avatar, starIcon: Comp, className }) => {
    const classes = checkTradesmanStyles()
    const [selected, setSelected] = useState(false)

    useEffect(() => {
        setSelected(checked)
    }, [])

    const handleClick = () => {
        setSelected(!selected)
        if (onClick) onClick(!selected)
    }

    return (
        <div
            onClick={handleClick}
            className={`${classes.root} ${selected ? classes.checked : ''} ${className}`}
        >
            {selected && <img src={IconCheckboxSVG} className={classes.checkBox} />}
            <img src={avatar} className={classes.itemImage} />
            <Typography component="span" className={classes.name}>{name}</Typography>
            <Box display="flex">
                {[...Array(score).keys()].map(item => (
                    <Comp key={item} className={classes.star} />
                ))}
            </Box>
        </div>
    )
}

const reportItemStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 0,
        background: 'transparent',
        textAlign: 'center',
        '&$disabled': {
            opacity: 0.2,
        }
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        background: colorS.sWhite,
        padding: '40px 30px',
        borderRadius: 8,
        border: '1px solid #D6D6D6',
        cursor: 'pointer',
        position: 'relative',
        width: '100%',
        '&:hover': {
            borderColor: colorS.sPurple,
        },
    },
    disabled: {
        '& $container': {
            '&:hover': {
                borderColor: '#D6D6D6',
            },
            cursor: 'initial'
        }
    },
    imageWrap: {
        width: 80,
        height: 80,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 24
    },
    itemImage: {
        maxWidth: '100%',
        maxHeight: '100%',
        width: 'auto',
    },
    badgeBox: {
        position: 'absolute',
        top: 10,
        right: 10
    },
    title: {
        color: colorS.sBlack,
    },
    subTitle: {
        color: colorS.sGrey,
    },
    desc: {
        color: colorS.sGrey,
        marginTop: 8
    }
}))

const PReportItem = ({ badge, onClick, title, subTitle, desc, disabled, image, className }) => {
    const classes = reportItemStyles()

    const handleClick = () => {
        if (onClick) onClick()
    }

    return (
        <div className={`${classes.root} ${className ? className : ''} ${disabled ? classes.disabled : ''}`} >
            <div className={classes.container} onClick={handleClick}>
                <img className={classes.badgeBox} src={badge} />
                <div className={classes.imageWrap}>
                    <img src={image} className={classes.itemImage} />
                </div>
                {title && <Typography component="span" className={`${classes.title} ${subTitle ? 'mb-8' : ''}`}>{title}</Typography>}
                {subTitle && <Typography component="span" className={classes.subTitle}>{subTitle}</Typography>}
                {desc && <Typography component="span" className={classes.desc}>{desc}</Typography>}
            </div>
        </div>
    )

}

export {
    PChipToggleButtonGroup,
    PChipToggleButton,
    PSmallChipToggleButton,
    PLinkToggleButtonGroup,
    PLinkToggleButton,
    PBoxToggleButtonGroup,
    PBoxToggleButton,
    PBorderToggleButtonGroup,
    PBorderToggleButton,
    PFillToggleButton,
    POutlineToggleButtonGroup,
    POutlineToggleButton,
    PChipIconToggleButton,
    PIconTogglePane,
    PIconToggleExPane,
    PIconSelectItem,
    PCheckTradesmanItem,
    PReportItem,
    PImageToggleButton,
    PImageToggleButtonGroup
}
import React, { useState, useEffect } from 'react'
import {
    Typography,
    Input,
    Slider,
    Grid,
    makeStyles,
    withStyles,
    InputAdornment
} from '@material-ui/core'
import { IconCircleInfo } from './SVGIcons'
import { PFillTextField } from './Input'
import Constants from '@src/config/Constants'

const colorS = Constants.COLORS

// PInputSliderRange
const BottomRangeSlider = withStyles(() => ({
    root: {
        color: colorS.sPurple,
        marginTop: '-13px',
        width: '100%',
        height: '6px',
    },
    rail: {
        height: '6px',
        borderRadius: '6px',
        opacity: 1,
        background: colorS.sGrey20
    },
    track: {
        height: '6px',
        borderRadius: '6px'
    },
    thumb: {
        width: '20px',
        height: '20px',
        marginTop: '-8px',
        background: colorS.sWhite,
        border: `3px solid ${colorS.sPurple}`,
        '&::after': {
            top: -5,
            left: -5,
            right: -5,
            bottom: -5,
        },
        '&:hover': {
            boxShadow: 'none'
        },
        '&$focusVisible': {
            boxShadow: 'none'
        }
    },
    focusVisible: {}
}))(Slider);

const RangeInput = withStyles(() => ({
    root: {
        background: '#F7F7FA',
        border: '1px solid transparent',
        '&$focused': {
            border: '1px solid transparent',
            background: '#F7F7FA',
        }
    },
    focused: {},
    input: {
        textAlign: 'center',
        fontSize: '14px'
    }
}))(Input)

const inputSliderRangeStyles = makeStyles((theme) => ({
    inputWrap: {
        width: '100%',
        display: 'flex',
        marginBottom: 24,
    },
    minRangeInput: {
        borderRadius: '6px 0px 0px 6px',
        marginRight: '1px'
    },
    maxRangeInput: {
        borderRadius: '0px 6px 6px 0px',
        marginLeft: '1px'
    }
}))

const PInputSliderRange = ({ min = 0, max = 100, step = 10, value, onChange, className }) => {
    const classes = inputSliderRangeStyles()
    const [elemValue, setElemValue] = useState(value)
    const [minRangeValue, setMinRangeValue] = useState(value[0])
    const [maxRangeValue, setMaxRangeValue] = useState(value[1])

    useEffect(() => {
        setElemValue(value)
        setMinRangeValue(value[0])
        setMaxRangeValue(value[1])
    }, [value])

    const handleSliderChange = (e, v) => {
        setElemValue(v)
        setMinRangeValue(v[0])
        setMaxRangeValue(v[1])
        if (onChange) {
            onChange(v)
        }
    }

    const handleRangeChange = (e, index) => {
        let newValue = []
        if (index == 0) {
            setMinRangeValue(e.target.value)
            newValue = [Number(e.target.value), elemValue[1]]
            setElemValue(newValue)
        } else {
            setMaxRangeValue(e.target.value)
            newValue = [elemValue[0], Number(e.target.value)]
            setElemValue(newValue)
        }
        if (onChange) {
            onChange(newValue)
        }
    }

    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            className={className}
        >
            <div className={classes.inputWrap}>
                <RangeInput
                    className={classes.minRangeInput}
                    value={minRangeValue}
                    onChange={(e) => handleRangeChange(e, 0)}
                />
                <RangeInput
                    className={classes.maxRangeInput}
                    value={maxRangeValue}
                    onChange={(e) => handleRangeChange(e, 1)}
                />
            </div>
            <BottomRangeSlider
                className={classes.slider}
                defaultValue={value}
                min={min}
                max={max}
                value={elemValue}
                step={step}
                onChange={handleSliderChange}
            />
        </Grid>
    )
}


/// PInputSlider

const inputSliderStyles = makeStyles((theme) => ({
    inputWrap: {
        width: '100%',
        display: 'flex',
        marginBottom: 24,
    },
    minRangeInput: {
        borderRadius: '6px 0px 0px 6px',
        marginRight: '1px'
    },
    maxRangeInput: {
        borderRadius: '0px 6px 6px 0px',
        marginLeft: '1px'
    }
}))

const BorderInput = withStyles(() => ({
    root: {
        borderRadius: '6px',
        textAlign: 'center',
        width: '100%',
        position: 'relative',
        overflow: 'hidden',
        background: '#fff',
        border: '1px solid #D6D6D6 !important',
        padding: '0 16px',
    },
    underline: { height: 48 },
    focused: {},
    input: {
        fontSize: '14px'
    },
}))(Input);

const PBottomSlider = withStyles(() => ({
    root: {
        color: colorS.sPurple,
        marginTop: '-15px',
        width: 'calc(100% - 32px)',
        height: '2px',
        '&$disabled': {
            color: colorS.sGrey20
        }
    },
    rail: {
        height: '2px',
        borderRadius: '2px',
        opacity: 1,
        background: colorS.sGrey
    },
    track: {
        height: '2px',
        borderRadius: '2px',
    },
    thumb: {
        width: '16px',
        height: '16px',
        marginTop: '-8px',
        background: colorS.sWhite,
        border: `3px solid ${colorS.sPurple}`,
        '&::after': {
            top: -5,
            left: -5,
            right: -5,
            bottom: -5,
        },
        '&:hover': {
            boxShadow: 'none'
        },
        '&$focusVisible': {
            boxShadow: 'none'
        },
        '&$disabled': {
            width: '16px',
            border: '3px solid ' + colorS.sGrey20,
            height: '16px',
            marginTop: '-8px',
            marginLeft: 0,
            background: '#f8f8f8'
        }
    },
    focusVisible: {},
    disabled: {},
    valueLabel: {}
}))(Slider);

const PInputSlider = ({ min = 0, max = 100, value, onChange, className }) => {
    const classes = inputSliderStyles()
    const [elemValue, setElemValue] = useState(value ? value : 0)
    useEffect(() => {
        setElemValue(value ? value : 0)
    }, [value])

    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            className={className}
        >
            <BorderInput
                value={elemValue}
                onChange={(e) => {
                    setElemValue(e.target.value)
                    if (onChange) {
                        onChange(e.target.value)
                    }
                }}
            />
            <PBottomSlider
                className={classes.slider}
                defaultValue={value}
                aria-labelledby="discrete-slider"
                min={min}
                max={max}
                value={elemValue}
                onChange={(e, v) => {
                    setElemValue(v)
                    if (onChange) {
                        onChange(v)
                    }
                }}
            />
        </Grid>
    )
}

const PFillTextFieldSlider = ({ min = 0, max = 100, value, onChange, className, label, endAdornment, disabled }) => {
    const classes = inputSliderStyles()
    const [elemValue, setElemValue] = useState(value ? value : 0)
    useEffect(() => {
        setElemValue(value ? value : 0)
    }, [value])

    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            className={className}
        >
            <PFillTextField label={label} variant="filled" value={elemValue} className="w-100"
                InputProps={{
                    endAdornment: <InputAdornment position="end">{endAdornment}</InputAdornment>,
                }}
                onChange={(e) => {
                    setElemValue(e.target.value)
                    if (onChange) {
                        onChange(e.target.value)
                    }
                }}
                disabled={disabled}
            />

            <PBottomSlider
                className={classes.slider}
                defaultValue={value}
                aria-labelledby="discrete-slider"
                min={min}
                max={max}
                value={elemValue}
                onChange={(e, v) => {
                    setElemValue(v)
                    if (onChange) {
                        onChange(v)
                    }
                }}
                disabled={disabled}
            />
        </Grid>
    )
}

// Circle Slider
const circleSliderStyles = makeStyles((theme) => ({
    rail: {
        background: colorS.sGrey20
    },
    root: {
        margin: 0,
        width: '100%'
    },
    labelWrap: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%'
    }
}))

const PCircleSlider = ({ min = 0, max = 100, value, onChange, disabled, labels = [], className }) => {
    const classes = circleSliderStyles()
    const [elemValue, setElemValue] = useState(value)
    useEffect(() => {
        setElemValue(value)
    }, [value])

    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            className={className}
        >
            {labels.length == 2 ? (
                <div className={classes.labelWrap}>
                    <Typography component="span">{labels[0]}</Typography>
                    <Typography component="span">{labels[1]}</Typography>
                </div>
            ) : (
                <div className={classes.labelWrap}>
                    <Typography component="span">{min}</Typography>
                    <Typography component="span">{max}</Typography>
                </div>
            )}
            <PBottomSlider
                classes={{
                    root: classes.root,
                    rail: classes.rail
                }}
                defaultValue={value}
                min={min}
                max={max}
                value={elemValue}
                disabled={disabled}
                onChange={(e, v) => {
                    setElemValue(v)
                    if (onChange) {
                        onChange(v)
                    }
                }}
            />
        </Grid>
    )
}

const PLabelRangeSlider = withStyles(() => ({
    root: {
        color: colorS.sPurple,
        marginTop: '-13px',
        width: '100%',
        height: '4px',
    },
    rail: {
        height: '4px',
        borderRadius: '4px',
        opacity: 1,
        background: colorS.sGrey20
    },
    track: {
        height: '4px',
        borderRadius: '4px'
    },
    valueLabel: {
        left: 0,
        top: 0,
        transform: 'translate(-16%, -14%) !important',
        '& *': {
            background: 'transparent',
            color: '#000',
        },
    },
    thumb: {
        width: '24px',
        height: '24px',
        marginTop: '-10px',
        background: colorS.sWhite,
        border: `1px solid ${colorS.sPurple}`,
        '&::after': {
            top: -5,
            left: -5,
            right: -5,
            bottom: -5,
        },
        '&:hover': {
            boxShadow: 'none'
        },
        '&$focusVisible': {
            boxShadow: 'none'
        },
        '&$disabled': {
            width: '24px',
            height: '24px',
            marginTop: '-10px',
            marginLeft: '-6px',
            background: colorS.sGrey20,
            border: `1px solid ${colorS.sGrey}`,
        }
    },
    focusVisible: {},
    disabled: {}
}))(Slider);

export { PInputSliderRange, PInputSlider, PCircleSlider, PLabelRangeSlider, PBottomSlider, PFillTextFieldSlider }
// MainIcon

import { v4 as uuidv4 } from 'uuid'

const trackerRoute = '/tracker'
const companyRoute = '/company'
const formsRoute = '/forms'
const billingRoute = '/billing'
const summaryRoute = '/summary'

const TRACKER_PRIMARY_MENU = [
  {
    id: 'summary',
    label: 'Summary',
    desc: null,
    icon: null,
    activatedLink: [],
    link: `${summaryRoute}`
  },
  {
    id: 'tracker',
    label: 'Tracker',
    desc: null,
    icon: null,
    activatedLink: [],
    link: `${trackerRoute}`
  },
  {
    id: 'company',
    label: 'Company',
    desc: null,
    icon: null,
    activatedLink: [],
    link: `${companyRoute}`
  },
  {
    id: 'forms',
    label: 'Forms',
    desc: null,
    icon: null,
    activatedLink: [],
    link: `${formsRoute}`
  },  
  {
    id: 'billing',
    label: 'Billing',
    desc: null,
    icon: null,
    activatedLink: [],
    link: `${billingRoute}`
  },  
]

export { TRACKER_PRIMARY_MENU }

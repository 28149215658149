import React, { useContext } from 'react'
import { Route, useLocation, withRouter, Switch } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import {
  Container,
  Grid,
  Paper,
  CssBaseline,
  Typography
} from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'
import Notification from '@src/components/Notification/Notification'
import NotificationStore from '@src/components/Notification/NotificationStore'
import CurrentPtUserStorage from '@src/components/CurentUser/CurrentPtUserStorage'
import CurrentUserStorage from '@src/components/CurentUser/CurrentUserStorage'

import HeaderAuthentication from '@src/components/Header/HeaderAuthentication'
import { AuthRoutes, AuthRoutesObj, DEFAULT_BACKGROUND } from './constant'

const useStyles = makeStyles((theme) => ({
  '@global': {},
  root: {
    height: '100vh',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  footer: {
    padding: theme.spacing(2),
    marginTop: 'auto'
  },
  image: {},
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    background: 'none'
  }
}))

function MainPublic(props) {
  const storeNotification = useContext(NotificationStore)
  const storeUser = useContext(CurrentPtUserStorage)
  const storeAkUser = useContext(CurrentUserStorage)
  const classes = useStyles()
  const location = useLocation()
  const routeConfig = AuthRoutesObj[location.pathname]
  const { rightConfig = null, bgImage = DEFAULT_BACKGROUND } = routeConfig

  return (
    <>
      <Notification />
      <Grid
        container
        className={classes.root}
      >
        <HeaderAuthentication rightConfig={rightConfig} />
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            component={Paper}
            elevation={0}
            square
            className={classes.wrapper}
          >
            {AuthRoutes.map(({ path, exact = true, component: Comp }) => (
              <Route
                key={path}
                path={path}
                exact={exact}
                render={(props) => (
                  <Comp
                    {...props}
                    storeUser={storeUser}
                    storeAkUser={storeAkUser}
                    storeNotification={storeNotification}
                  />
                )}
              />
            ))}
          </Grid>
          <Grid item xs={false} sm={false} md={6} className={classes.image} />
        </Grid>
      </Grid>
    </>
  )
}

export default observer(withRouter(MainPublic))

import React from 'react'
import { Modal, IconButton, Button, Box } from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  btn: {
    padding: '18px 0',
    minWidth: '56px',
    border: '1px solid #F0F0F0'
  },
  icon: {
    color: theme.palette.common.sBlack
  }
}))

export default (props) => {
  const classes = useStyles()

  return (
    <>
      <Button
        className={classes.btn}
        // onClick={() => props.close()}
        // fullWidth
        variant='outlined'
        color='default'
        {...props}
      >
        <Box component='span' fontSize='1rem' className={classes.icon}>
          <span className='icon-back' />
        </Box>
      </Button>
    </>
  )
}

import BaseAPI from '../../api/BaseAPI'
import Constants from '../../config/Constants'

class SignUpChangeAPI extends BaseAPI {
  /**
   * Change email API
   *
   * @param {Object} dataObject object data include username or emailpassword
   */
  async forgotPassword(email) {
    try {
      const data = {
        email,
        account_type: 0
      }

      const authURL = `${Constants.AUTHEN_SERVER_URL}/authen/forgot-password`
      const response = await this.post(authURL, data)

      return response
    } catch (err) {
      console.log(err)
      throw err
    }
  }
}

export default SignUpChangeAPI

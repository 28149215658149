import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import {
    Typography,
    Checkbox,
    Grid,
    Avatar,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    withStyles
} from '@material-ui/core'

import { formattedNumber } from '@src/utils/ConvertUtils'
import ApartmentIcon from '@material-ui/icons/Apartment'
import ErrorIcon from '@material-ui/icons/Error'

import Constants from '@src/config/Constants'

const colorS = Constants.COLORS

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        width: '100%',
        position: 'relative',
        flexDirection: 'column'
    },
    table: {
        width: '100%',
        marginBottom: '40px',
        minWidth: 650,
    },
    tableHeader: {
        background: colorS.sWhite,
        color: colorS.sGrey,
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '20px',
        padding: '14px 7px',
        cursor: 'pointer',
        textAlign: 'left',
        border: 'none',
        '&:first-child': {
            paddingLeft: 24,
            width: 136
        },
        '&:nth-child(2)': {
            paddingLeft: 0,
            width: 360
        },
        '&:hover': {
            color: colorS.sBlack
        }
    },
    tableRow: {
        cursor: 'pointer',        
        '&:hover': {
            boxShadow: '0px 11px 15px rgb(0 0 0 / 4%), 0px 24px 38px rgb(0 0 0 / 4%), 0px -4px 24px rgb(0 0 0 / 4%)',
            borderRadius: '6px',
            '& $infoCell':{
                display: 'none'
            },
            '& $alarmCell': {
                display: 'table-cell'
            }
        }
    },
    tableCell: {
        borderBottom: `1px solid ${colorS.sGrey20}`,
        padding: '16px 8px',
        textAlign: 'left',
        position: 'relative',
        '&:first-child': {
            paddingLeft: 24,
            borderBottom: 'none',
        },
        '&:nth-child(2)': {
            paddingLeft: 0,
        }
    },
    buildingThumb: {
        width: '136px',
        height: '72px',
        borderRadius: '4px',
        overflow: 'hidden',
        backgroundPosition: '50% 50%',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        flexShrink: '0',
        position: 'relative',
        textAlign: 'center',
        '& > img': {
            height: '72px',
            maxWidth: '136px',
            borderRadius: '4px',
            width: '100%',
        }
    },
    buildingBlankThumb: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: colorS.sGrey
    },
    buildingText: {
        paddingLeft: '16px',
        display: 'block',
        margin: '0',
        width: '100%',
        cursor: 'pointer',
    },
    buildingAddress: {
        color: '#868e92',
        fontSize: '12px',
        lineHeight: '14px',
    },
    alarmIcon: {
        fontSize: '32px',
        position: 'absolute',
        left: '5px',
        top: 'calc(50% - 16px)',
        '&$critial': {
            color: colorS.sRedAccent
        },
        '&$warning': {
            color: '#FF8D02'
        },
    },
    critial: {},
    warning: {},
    alarmCell:{
        display: 'none',
        padding: 24,
        borderTopRightRadius: '6px',
        borderBottomRightRadius: '6px',
        color: colorS.sWhite,
        '&$critial':{
            background: colorS.sRedAccent
        },
        '&$warning': {
            background: '#FF8D02'
        },
    },
    infoCell:{
        display: 'table-cell'
    }
}))

const PBuildingTable = ({ buildings }) => {
    const classes = useStyles()
    const history = useHistory()

    const columns = ['Building', '', 'Area', 'Tenure', 'Type']

    return (
        <Table className={classes.table} aria-label="schedule table">
            <TableHead>
                <TableRow>
                    {columns.map((elem, index) => (
                        <TableCell key={index} className={classes.tableHeader}>{elem}</TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {(buildings.length) ? buildings.map((row, index) => (
                    <TableRow key={index} className={classes.tableRow}>
                        <TableCell className={classes.tableCell}>
                            <Typography component='div'
                                className={`${classes.buildingThumb} ${row.thumb_image ? '' : classes.buildingBlankThumb}`}>
                                {row.thumb_image && (<img src={row.thumb_image} />)}
                                {!row.thumb_image && (<ApartmentIcon style={{ color: 'white', fontSize: 28 }} />)}
                            </Typography>
                            {row.alarm && (<ErrorIcon className={`${classes.alarmIcon} ${row.alarm === 'critical' ? classes.critial : classes.warning}`} />)}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                            <Typography component='div' className={classes.buildingText}>
                                {row.name}<br />
                                <Typography component='span' className={classes.buildingAddress}>
                                    {row.zipcode} {row.address1} {row.city}, {row.state}
                                </Typography>
                            </Typography>
                        </TableCell>
                        <TableCell className={`${classes.tableCell} ${row.alarm && classes.infoCell}`}>{row.area} ft²</TableCell>
                        <TableCell className={`${classes.tableCell} ${row.alarm && classes.infoCell}`}>{row.tenure}</TableCell>
                        <TableCell className={`${classes.tableCell} ${row.alarm && classes.infoCell}`}>{row.type}</TableCell>
                        {row.alarm && (
                            <TableCell 
                                className={`
                                    ${classes.tableCell} 
                                    ${classes.alarmCell} 
                                    ${row.alarm === 'critical' ? classes.critial : classes.warning}
                                `} colSpan="3">
                                <Typography variant="h5">Information missing</Typography>
                                <Typography component="span">Be sure to fill out: Area, State, Ownership</Typography>
                            </TableCell>
                        )}
                    </TableRow>
                )) : (<TableRow className={classes.tableRow}></TableRow>)}
            </TableBody>
        </Table>
    )
};

export { PBuildingTable }
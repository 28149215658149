import { makeStyles } from '@material-ui/core/styles'
import { green } from '@material-ui/core/colors'

const useStyles = makeStyles((theme) => ({
  main: {
    maxWidth: '432px',
    paddingLeft: 0,
    paddingRight: 0
  },
  paper: {
    margin: theme.spacing(8, 0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    minHeight: '5.6rem',
    fontWeight: 400
  },
  socialGroupBtn: {
    margin: theme.spacing(2, 0)
  },
  via: {
    marginTop: theme.spacing(3.2),
    marginBottom: theme.spacing(1.2),
    fontWeight: '400',
    fontSize: '2rem'
  },
  or: {
    width: '100%',
    textAlign: 'center',
    fontSize: '1.8rem',
    fontWeight: 'bold',
    borderBottom: '1px solid #000',
    lineHeight: '0.1em',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1.5),
    '& span': {
      background: theme.palette.background.default,
      padding: '0 10px'
    }
  },
  buttonProgress: {
    // style for button loading
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -7,
    marginLeft: -12
  },
  wrapperButton: {
    position: 'relative'
  },
  secondLink: {
    color: '#7092FE',
    fontSize: '1.4rem',
    textDecoration: 'none'
  },
  inputLabel: {
    fontSize: '1.4rem',
    transform: 'translate(12px, 22px) scale(1)',
    width: '120%' // What happen in here.
  },
  input: {
    backgroundColor: '#ECF1F6'
  },
  checkBox: {
    marginTop: '16px',
    color: '#868E92',
    fontSize: '1.4rem'
  },
  selectCountry: {
    backgroundColor: '#ecf1f5 !important',
    '& > div':{
      border: 'none'
    }
  },
  phoneCode:{
    width: 144,
    marginRight: 24
  }
}))

export default useStyles

import BaseAPI from '@src/api/BaseAPI'
import Constants from '@src/config/Constants'

class AuthenAPI extends BaseAPI {

  /**
   * API to register a new homeowner into system
   * @param {*} dataObject data
   */
  async signin(dataObject) {
    try {
      const data = {
        email: dataObject.username,
        password: dataObject.password
      }

      const authURL = `${Constants.AUTHEN_SERVER_URL}/authen/login`
      const response = await this.post(authURL, data)

      return response
    } catch (err) {
      console.log(err)
      throw err
    }
  }


  /**
   * API to register a new homeowner into system
   * @param {*} dataObject data
   */
  async createAccount(dataObject) {
    try {
      const data = {
        ...dataObject
      }

      const authURL = `${Constants.AUTHEN_SERVER_URL}/authen/register`
      const response = await this.post(authURL, data)

      return response
    } catch (err) {
      console.log(err)
      throw err
    }
  }

  /**
   * API to change password for homeowner
   * @param {*} id Id of homeowner need to update information
   * @param {*} oldPassword old password
   * @param {*} newPassword new password
   */
  async changePassword(id, oldPassword, newPassword) {
    try {
      const data = {
        user_id: id,
        old_password: oldPassword,
        new_password: newPassword
      }

      const authURL = `${Constants.AUTHEN_SERVER_URL}/authen/change-password`
      const response = await this.post(authURL, data)

      return response
    } catch (err) {
      console.log(err)
      throw err
    }
  }
}

export default new AuthenAPI()

import Enums from './Enums'
import {
  red,
  yellow,
  orange,
  grey,
  blue,
  green,
  purple
} from '@material-ui/core/colors'

const constants = {
  // Properties
  AUTHEN_SERVER_URL: process.env.REACT_APP_AUTHEN_SERVER_URL,
  PT_UK: 'currentPtUser',
  API_URL: process.env.REACT_APP_API_URL,

  APP_URL: process.env.REACT_APP_APP_URL,
  // APP_URL: 'https://localhost:3000',

  HTTP_MESSAGE: {
    connection_error: 'Have error connection to server, please retry!'
  },
  UK: 'currentUser',
  ACCESS_TOKEN: 'access_token',
  USER_ID: 'user_id',
  UTILITY_NAME: 'utility_name',
  AUTH_UTILITY: 'auth_utility',
  UTILITY_REDIRECT: 'utility_redirect',
  DATE_FORMAT: 'MM/dd/yyyy',
  GEOCODE_KEY: 'AIzaSyDYi9dMp0X0iVbRMmasPGvWKU7JMc8jVzI',
  MAP_POSITION: {
    lng: -73.9147716,
    lat: 40.6577799
  },
  MAX_IMAGE_SIZE: 20 * 1024 * 1024, // Max 20 MB
  MAX_FILE_UPLOAD_SIZE: 20 * 1024 * 1024, // Max 20 MB
  COLORS: {
    sWhite: '#ffffff',
    sPurple: '#7092FE',
    sPurple10: '#7092FE22',
    sPurpleAccent: '#D0DBFF',
    sPurpleHover: '#8AA6FF',
    sPurpleDisabled: 'rgba(112, 146, 254, 0.33)',
    sBlueAccent: '#F8FBFF',
    sBlueAccent3: '#ECF1F6',
    sRedAccent: '#EC4847',
    sRedAccent3: '#FF8269',
    sGreen: '#00B341',
    sGreenAccent1: '#77C026',
    sBlack: '#2D292A',
    sYellow: '#FCCC38',
    sYellowAccent: '#FDB34D',
    sYellowAccent1: '#FFF0C3',
    sGrey: '#868E92',
    sGreyE8: '#E8E8E8',
    sGrey50: '#A5A5A5',
    sGrey20: '#F0F0F0',
    sGreyDisabled: 'rgb(134, 142, 146, 0.3)',
    sGreyInput: '#ECF1F6',
    sNeutralInput: '#EDF2F6',
    sAdminRegular: '#6E6D7A',
    sNeutralHeader: '#F7F7FA'
  },
  DEFAULT_PROJECT_PAGING_LIMIT: 6,
  DEFAULT_PAGING_LIMIT: 10,
  MESSAGES_LIMIT_CHAT_LIST: 30,
  MESSAGES_LIMIT_HISTORY_MESSAGE: 50,
  TIME_INPUT_DEBOUNCE: 2000
}

export default constants

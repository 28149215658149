import React, { useState, useEffect } from 'react'
import {
    withStyles,
    makeStyles,
    IconButton,
    Typography,
    Collapse
} from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import CloseIcon from '@material-ui/icons/Close'

import Constants from '@src/config/Constants'
const colorS = Constants.COLORS

const PAlert = withStyles(() => ({
    root:{
        padding: '8px 18px',
        borderRadius: 8,
    },
    standardInfo:{
        background: '#F1F5FF',
        color: colorS.sBlack,
        '& $icon':{
            color: colorS.sPurple
        }
    },
    action: {
        alignItems: 'flex-start'
    },
    icon: {}
}))(Alert)

const PAlertTitle = withStyles(() => ({
}))(AlertTitle)

const alertStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        position: 'relative',
        boxSizing: 'border-box',
        textAlign: 'left',
        alignItems: 'center',
        justifyContent: 'flex-start',
        textDecoration: 'none',
    },
}))

const PTransitionAlert = ({ icon: IconComp, open, title, description, type = "info", style, className }) => {
    const classes = alertStyles()
    const [alertOpen, setAlertOpen] = React.useState(open);
    return (
        <div className={`${className ? className : ''} ${classes.container}`} style={style}>
            <Collapse in={alertOpen}>
                <PAlert
                    icon={IconComp && <IconComp fontSize="inherit" />}
                    severity={type}
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setAlertOpen(false);
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                >
                    {title && <AlertTitle>{title}</AlertTitle>}
                    {description && <span>{description}</span>}
                </PAlert>
            </Collapse>
        </div>
    )
}

export { PAlert, PAlertTitle, PTransitionAlert }
import React from 'react'
import { Switch } from '@material-ui/core'

import { makeStyles, useTheme } from '@material-ui/core/styles'

import SwitchActiveIcon from '@src/assets/img/i-done-circle.svg'
import SwitchActiveIconGreen from '@src/assets/img/i-done-circle-green.svg'
import SwitchInActiveIcon from '@src/assets/img/i-inactive-circle.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '52px',
    height: 'auto'
  },
  switchBase: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  icon: {
    width: '16px',
    height: '16px',
    backgroundImage: `url(${SwitchInActiveIcon})`
  },
  checkedIcon: {
    width: '16px',
    height: '16px',
    backgroundImage: `url(${SwitchActiveIcon})`
  },
  checkedIconGreen: {
    width: '16px',
    height: '16px',
    backgroundImage: `url(${SwitchActiveIconGreen})`
  },
  track: {
    width: '24px',
    height: '8px',
    marginLeft: '2px',
    marginTop: '1px',
    backgroundColor: theme.palette.common.sPurpleAccent,
    borderRadius: '20px'
  }
}))

export default ({ color, disabled, checked, onChange, value, className }) => {
  const classes = useStyles()
  const theme = useTheme()

  return (
    <Switch
      color='default'
      checkedIcon={
        <span
          className={
            color === 'green' ? classes.checkedIconGreen : classes.checkedIcon
          }
        />
      }
      icon={
        <span
          className={`${classes.icon} ${
            className && className.icon ? className.icon : ''
          }`}
        />
      }
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        track: `${classes.track} ${
          className && className.track ? className.track : ''
        }`
      }}
      disabled={disabled}
      checked={checked}
      onChange={onChange}
      value={value}
    />
  )
}

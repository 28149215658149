import * as React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Menu from 'material-ui-popup-state/HoverMenu'
import MenuItem from '@material-ui/core/MenuItem'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import {
  usePopupState,
  bindHover,
  bindMenu,
  bindTrigger,
  bindPopover
} from 'material-ui-popup-state/hooks'
import { NavLink, useHistory, useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import { Typography, Box } from '@material-ui/core'

import Constants from '@src/config/Constants'
const colorS = Constants.COLORS

const isActivatedLink = (path, menuList) => {
  let activatedId = null
  for (let i = 0; i < menuList.length; i++) {
    menuList[i].activatedLink.map((link) => {
      if (path === '/') {
        activatedId = 'main'
      } else if (path.includes(link)) {
        activatedId = menuList[i].id
      }
    })
  }
  return activatedId
}
const ParentPopupState = React.createContext(null)
const useStyle = makeStyles((theme) => ({
  appBar: {
    // position: 'sticky',
    // top: 0,

    alignItems: 'center',
    maxHeight: '64px',
    padding: '0 48px',
    // width: '100%',
    width: '100%',
    // minWidth: '1440px',
    minWidth: '100%',
    // width: 'calc(100% - 96px)',
    background: '#fff'

    // borderBottom: `1px solid ${theme.palette.divider}`
  },
  positionSticky: {
    zIndex: 1101
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    flexWrap: 'wrap'
    // borderBottom: `1px solid ${theme.palette.common.sGrey20}`
    // '@media (min-width:1900px)': {
    //   width: '1800px'
    // }
    // background: '#CEDBE4',
  },
  toolbarTitle: {
    cursor: 'pointer',
    display: 'flex',
    marginTop: 'auto',
    marginBottom: 'auto',
    marginRight: '40px',
    height: 'max-content',
    // lineHeight: '2rem',
    color: '#fff',
    fontWeight: 'bold'
  },
  link: {
    margin: theme.spacing(0.5, 0.75),
    fontWeight: 'bold',
    textTransform: 'none'
  },
  root: {
    // style in the stepper
    width: '100%'
  },
  userBox: {},
  wrapListLink: {
    display: 'flex',
    flexGrow: 1,
    flexBasis: 0,
    alignItems: 'center',
    height: '80px',
    maxWidth: '888px',
    '@media (min-width:1801px)': {
      maxWidth: '1344px'
    },
    margin: 'auto',
    width: '100%'
  },
  popoverOpened: {
    color: colorS.sPurple
  },
  popoverActivated: {
    color: '#7092FE !important'
  },
  linkDirection: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    whiteSpace: 'nowrap',
    textDecoration: 'none !important',
    color: '#6E6D7A',
    cursor: 'pointer',
    '& > span': {
      color: '#2D292A'
    },
    '&:hover': {
      color: colorS.sPurple,
      '& > span': {
        color: '#2D292A'
      }
    },
    '&$popoverOpened': {
      color: colorS.sPurple
    }
  },
  linkActive: {
    color: '#7092FE'
  },
  popover: {
    // pointerEvents: 'none'
  },
  menuContentLv1: {
    minWidth: '69.6rem',
    padding: theme.spacing(3),
    background: 'yellow'
  },
  subMenuLv2Root: {
    '&:hover': {
      backgroundColor: '#F7F9FF !important',
      color: '#7092FE',
      '& img': {
        color: '#7092FE'
      }
    }
  },
  paper: {
    boxSizing: 'border-box',
    overflow: 'unset',
    borderRadius: 12,
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.12)',

    '&::after': {
      content: '""',
      position: 'absolute',
      width: '0',
      height: '0',
      marginLeft: '0.5em',
      top: '0',
      left: 'calc(50% - 1em)',
      boxSizing: 'border-box',
      border: '0.5em solid black',
      borderColor: 'transparent transparent white white',
      transformOrigin: '0 0',
      transform: 'rotate(135deg)',
      boxShadow: '-1px 1px 3px 0 rgba(0, 0, 0, 0.05)'

    }
  },
  subCategory: {
    textTransform: 'uppercase',
    color: '#B6BEDA',
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 500,
    marginBottom: 16
  },
  list: {
    padding: 0
  },
  menuWrap: {
    padding: '40px 20px',
    '&$subMenuBox': {
      maxWidth: 696,
      padding: 0
    }
  },
  subMenuWrap: {
    marginTop: -16,
  },
  subMenuContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '40px 20px 20px'
  },
  linkMenuContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '24px 20px',
    background: '#FBFBFD',
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12,
  },
  subMenuBox: {}
}))

const CascadingHoverMenus = (props) => {
  const classes = useStyle()

  const history = useHistory()
  const popupId = `popover-${props.id}`
  const popupState = usePopupState({
    popupId: popupId,
    variant: 'popover',
    deferOpenClose: true
  })
  const path = history.location.pathname
  const isActivated = isActivatedLink(path, props.menuList) == props.id
  if (props.link)
    return (
      <Link
        style={{ background: 'white' }}
        className={`${classes.linkDirection} ${isActivated && classes.linkActive
          }`}
        component={NavLink}
        to={props.link}
      >
        <Typography>{props.label}</Typography>
      </Link>
    )

  return (
    <div id='sub_mega_menu'>
      <Link
        {...bindHover(popupState)}
        style={{ background: 'white' }}
        className={`${classes.linkDirection} ${popupState.isOpen && classes.popoverOpened
          } ${isActivated && classes.linkActive}`}
      >
        <Typography>{props.label}</Typography>
      </Link>

      <ParentPopupState.Provider value={popupState}>
        <Menu
          {...bindMenu(popupState)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          getContentAnchorEl={null}
          classes={{ paper: classes.paper, list: classes.list }}
        >
          <Grid container alignItems="flex-start" className={`${classes.menuWrap} ${props.subMenus ? classes.subMenuBox : ''}`}>
            {props.categories && props.categories.map((category, catIndex) => {
              return (
                <Grid item sm={6} xs={12} key={catIndex} className="pl-20 pr-20">
                  <Typography component="p" className={classes.subCategory}>{category.label || (<>&nbsp;</>)}</Typography>
                  {category.subMenus &&
                    category.subMenus.map((subMenu, index) => {
                      const isHaveSubMenu = (subMenu.subMenus && subMenu.subMenus.length > 0) || false
                      const lengthOfSub = (subMenu.subMenus && subMenu.subMenus.length) || 0
                      return (
                        <Submenu
                          key={index}
                          popupId={`popover-${subMenu}`}
                          title={subMenu.label}
                          desc={subMenu.desc}
                          icon={subMenu.icon}
                          isShowArrow={isHaveSubMenu}
                          onClickItem={() => {
                            if (subMenu.link) {
                              history.push(subMenu.link)
                              popupState.close()
                            }
                            //
                          }}
                        >
                          {isHaveSubMenu && lengthOfSub > 6 && (
                            <Grid
                              container
                              style={{
                                padding: '8px 16px',
                                width: '426px'
                              }}
                            >
                              <Grid item xs={6} key={index}>
                                {subMenu.subMenus.slice(0,6 - lengthOfSub).map((sub, index) => {
                                  return (
                                      <MenuItem
                                        id={sub.id}
                                        onClick={() => {
                                          if (sub.link) {
                                            history.push(sub.link)
                                          }
                                          popupState.close()
                                        }}
                                        style={{
                                          paddingLeft: '8px',
                                          borderRadius: '8px',
                                          alignItems: 'flex-start'
                                        }}
                                        classes={{ root: classes.subMenuLv2Root }}
                                      >
                                        {sub.icon && (
                                          <Box
                                            mr={1}
                                            ml={0}
                                            display='flex'
                                            alignItems='center'
                                          >
                                            <img
                                              src={sub.icon}
                                              alt=''
                                              style={{ width: '20px' }}
                                            />
                                          </Box>
                                        )}
                                        <Typography style={{ fontSize: '14px', color: '#6E6D7A', whiteSpace: 'break-spaces' }}>
                                          {sub.label}
                                        </Typography>
                                      </MenuItem>
                                  )
                                })}
                              </Grid>

                              <Grid item xs={6} key={index}>
                               {subMenu.subMenus.slice(6).map((sub, index) => {
                                  return (
                                    <MenuItem
                                      id={sub.id}
                                      onClick={() => {
                                        if (sub.link) {
                                          history.push(sub.link)
                                        }
                                        popupState.close()
                                      }}
                                      style={{
                                        paddingLeft: '8px',
                                        borderRadius: '8px',
                                        alignItems: 'flex-start'
                                      }}
                                      classes={{ root: classes.subMenuLv2Root }}
                                    >
                                      {sub.icon && (
                                        <Box
                                          mr={1}
                                          ml={0}
                                          display='flex'
                                          alignItems='center'
                                        >
                                          <img
                                            src={sub.icon}
                                            alt=''
                                            style={{ width: '20px' }}
                                          />
                                        </Box>
                                      )}
                                      <Typography style={{ fontSize: '14px', color: '#6E6D7A', whiteSpace: 'break-spaces' }}>
                                        {sub.label}
                                      </Typography>
                                    </MenuItem>
                                  )
                                })}
                              </Grid>
                            </Grid>
                          )}
                          {isHaveSubMenu && lengthOfSub <= 6 && (
                            <Grid
                              container
                              style={{
                                padding: '8px 16px',
                                width: '232px',
                              }}
                            >
                              {subMenu.subMenus.map((sub, index) => {
                                return (
                                  <Grid item xs={12} key={index}>
                                    <MenuItem
                                      id={sub.id}
                                      onClick={() => {
                                        if (sub.link) {
                                          history.push(sub.link)
                                        }
                                        popupState.close()
                                      }}
                                      style={{
                                        paddingLeft: '8px',
                                        borderRadius: '8px',
                                        alignItems: 'flex-start'
                                      }}
                                      classes={{ root: classes.subMenuLv2Root }}
                                    >
                                      {sub.icon && (
                                        <Box
                                          mr={1}
                                          ml={0}
                                          display='flex'
                                          alignItems='center'
                                        >
                                          <img
                                            src={sub.icon}
                                            alt=''
                                            style={{ width: '20px' }}
                                          />
                                        </Box>
                                      )}
                                      <Typography style={{ fontSize: '14px', color: '#6E6D7A', whiteSpace: 'break-spaces' }}>
                                        {sub.label}
                                      </Typography>
                                    </MenuItem>
                                  </Grid>
                                )
                              })}
                            </Grid>
                          )}
                        </Submenu>
                      )
                    })}
                </Grid>
              )
            })}
            {props.subMenus && (
              <div className={classes.subMenuWrap}>
                <div className={classes.subMenuContainer}>
                  {props.subMenus.map((subMenu, index) => {
                    const isHaveSubMenu = (subMenu.subMenus && subMenu.subMenus.length > 0) || false
                    const lengthOfSub = (subMenu.subMenus && subMenu.subMenus.length) || 0

                    return (
                      <Grid item sm={6} xs={12} key={index} className="pl-20 pr-20">
                        <Submenu
                          popupId={`popover-${subMenu}`}
                          title={subMenu.label}
                          desc={subMenu.desc}
                          icon={subMenu.icon}
                          isShowArrow={isHaveSubMenu}
                          onClickItem={() => {
                            if (subMenu.link) {
                              history.push(subMenu.link)
                              popupState.close()
                            }
                          }}
                        >
                          {isHaveSubMenu && lengthOfSub > 6 && (
                            <Grid
                              container
                              style={{
                                padding: '8px 16px',
                                width: '426px'
                              }}
                            >
                              {subMenu.subMenus.map((sub, index) => {
                                return (
                                  <Grid item xs={6} key={index}>
                                    <MenuItem
                                      id={sub.id}
                                      onClick={() => {
                                        if (sub.link) {
                                          history.push(sub.link)
                                        }
                                        popupState.close()
                                      }}
                                      style={{
                                        paddingLeft: '8px',
                                        borderRadius: '8px',
                                        alignItems: 'flex-start'
                                      }}
                                      classes={{ root: classes.subMenuLv2Root }}
                                    >
                                      {sub.icon && (
                                        <Box
                                          mr={1}
                                          ml={0}
                                          display='flex'
                                          alignItems='center'
                                        >
                                          <img
                                            src={sub.icon}
                                            alt=''
                                            style={{ width: '20px' }}
                                          />
                                        </Box>
                                      )}
                                      <Typography style={{ fontSize: '14px', color: '#6E6D7A', whiteSpace: 'break-spaces' }}>
                                        {sub.label}
                                      </Typography>
                                    </MenuItem>
                                  </Grid>
                                )
                              })}
                            </Grid>
                          )}
                          {isHaveSubMenu && lengthOfSub <= 6 && (
                            <Grid
                              container
                              style={{
                                padding: '8px 16px',
                                width: '232px',
                              }}
                            >
                              {subMenu.subMenus.map((sub, index) => {
                                return (
                                  <Grid item xs={12} key={index}>
                                    <MenuItem
                                      id={sub.id}
                                      onClick={() => {
                                        if (sub.link) {
                                          history.push(sub.link)
                                        }
                                        popupState.close()
                                      }}
                                      style={{
                                        paddingLeft: '8px',
                                        borderRadius: '8px',
                                        alignItems: 'flex-start'
                                      }}
                                      classes={{ root: classes.subMenuLv2Root }}
                                    >
                                      {sub.icon && (
                                        <Box
                                          mr={1}
                                          ml={0}
                                          display='flex'
                                          alignItems='center'
                                        >
                                          <img
                                            src={sub.icon}
                                            alt=''
                                            style={{ width: '20px' }}
                                          />
                                        </Box>
                                      )}
                                      <Typography style={{ fontSize: '14px', color: '#6E6D7A', whiteSpace: 'break-spaces' }}>
                                        {sub.label}
                                      </Typography>
                                    </MenuItem>
                                  </Grid>
                                )
                              })}
                            </Grid>
                          )}
                        </Submenu>
                      </Grid>
                    )
                  })}
                </div>
                {props.subLinks &&
                  <div className={classes.linkMenuContainer}>
                    {props.subLinks.map((subLink, index) => {
                      return (
                        <Grid item sm={6} xs={12} key={index} className="pl-20 pr-20">
                          <MenuItem
                            id={subLink.id}
                            onClick={() => {
                              if (subLink.link) {
                                history.push(subLink.link)
                              }
                              popupState.close()
                            }}
                            style={{ paddingLeft: '8px', borderRadius: '8px', }}
                            classes={{ root: classes.subMenuLv2Root }}
                          >
                            {subLink.icon && (
                              <Box
                                mr={1}
                                ml={0}
                                display='flex'
                                alignItems='center'
                              >
                                <img
                                  src={subLink.icon}
                                  alt=''
                                  style={{ width: '20px' }}
                                />
                              </Box>
                            )}
                            <Typography style={{ fontSize: '14px', color: '#6E6D7A', whiteSpace: 'break-spaces' }}>
                              {subLink.label}
                            </Typography>
                          </MenuItem>
                        </Grid>
                      )
                    })}
                  </div>
                }
              </div>
            )}
          </Grid>
        </Menu>
      </ParentPopupState.Provider>
    </div>
  )
}

export default CascadingHoverMenus

const submenuStyles = (theme) => ({
  menu: {
    marginTop: theme.spacing(-1),
    borderRadius: 8,
    boxShadow: `0px 4px 16px rgba(0, 0, 0, 0.12)`
  },
  item: {
    flexGrow: 1
  },
  desc: {
    color: '#868E92'
  },
  moreArrow: {
    marginRight: theme.spacing(-1),
    color: colorS.sGrey,
    fontSize: 18
  },
  root: {
    width: 320
  },
  isOpenSubMenu: {
    backgroundColor: '#F7F9FF !important',
    '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
      color: theme.palette.common.white
    },
    '& svg': {
      color: '#7092FE'
    }
  }
})

const Submenu = withStyles(submenuStyles)(
  // Unfortunately, MUI <Menu> injects refs into its children, which causes a
  // warning in some cases unless we use forwardRef here.
  React.forwardRef(
    (
      {
        classes,
        title,
        link,
        desc,
        popupId,
        icon,
        isShowArrow = true,
        children,
        onClickItem,
        ...props
      },
      ref
    ) => {
      const parentPopupState = React.useContext(ParentPopupState)
      const popupState = usePopupState({
        popupId,
        variant: 'popover',
        parentPopupState,
        deferOpenClose: true
      })
      return (
        <ParentPopupState.Provider value={popupState}>
          <MenuItem
            {...bindHover(popupState)}
            selected={popupState.isOpen}
            ref={ref}
            style={{ borderRadius: '8px', minHeight: '72px', padding: '0 16px', margin: '0 -16px' }}
            onClick={() => {
              onClickItem()
            }}
            classes={{
              root: popupState.isOpen ? classes.isOpenSubMenu : classes.root
            }}
          >
            {icon && (
              <Box mr={2} ml={0} display='flex' alignItems='center'>
                <img src={icon} style={{ width: '32px' }} />
              </Box>
            )}
            <Box className={classes.item}>
              <Typography>{title}</Typography>
              <Typography className={classes.desc}> {desc}</Typography>
            </Box>
            {isShowArrow && <ChevronRight className={classes.moreArrow} />}
          </MenuItem>

          {isShowArrow &&
            <Menu
              {...bindMenu(popupState)}
              classes={{ paper: classes.menu }}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
              getContentAnchorEl={null}
              {...props}
            >
              {children}
            </Menu>
          }

        </ParentPopupState.Provider>
      )
    }
  )
)

import { HTTP, PGEHTTP, SCEHTTP, CONEDHTTP } from '../utils/HTTPCommon'
import Enums from '../config/Enums'
import Constants from '../config/Constants'

import BaseAPI from './BaseAPI'

class UploadS3API extends BaseAPI {
  constructor() {
    super(false)
  }

  async uploadPresignFile(file, data) {
    try {
      console.log('file')
      console.log(file)
      console.log('data')
      console.log(data)
      const formData = new FormData()
      formData.append("Content-Type", file.image.type);
      Object.entries(data.fields).forEach(([k, v]) => {
        formData.append(k, v)
      })
      formData.append('file', file.image) // must be the last one
      const response = await this.post(data.url, formData)
    } catch (err) {
      console.log(err)
      throw err
    }
  }
}

export default UploadS3API

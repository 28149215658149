import React, { useState } from 'react'
import { withRouter, Link } from 'react-router-dom'
import {
  Button,
  Container,
  Typography,
  CircularProgress,
  Grid,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core'

import { withFormik, Form, Field } from 'formik'
import * as Yup from 'yup'

import AuthenAPI from '@src/api/AuthenAPI'

import TitleGroupAuth from '@src/components/common/TitleGroupAuth'
import TextFieldV2 from '@src/components/common/TextFieldV2'

import AlertUtil from '@src/utils/AlertUtil'

import UseStyles from './SignUpStyle'

const useStyles = UseStyles

function SignUpSide(props) {
  const storeUser = props.storeUser
  const classes = useStyles()
  const { touched, errors, values } = props
  const isDisable = () => {
    return Object.keys(errors).some((item) => errors[item]) ||
      Object.keys(values).some((item) => values[item].trim().length === 0)
  }
  return (
    <>
      <Container component='main' className={classes.main}>
        <div className={classes.paper}>
          <TitleGroupAuth
            title={'Sign up Tax Tracker'}
            description={`Get more of the work you want. <br/>Tell us where you work and the work you do.`}
          />

          <Typography variant='subtitle2' className={classes.via}>Registration</Typography>

          {/* TODO: duplicated form */}
          <Form className={classes.form} noValidate>
            <Field
              name='name'
              render={({ field }) => (
                <TextFieldV2
                  variant='filled'
                  margin='normal'
                  fullWidth
                  className='mb-0'
                  textKey='name'
                  label='Full name'
                  autoComplete='name'
                  {...{ touched, errors, values }}
                  {...field}
                />
              )}
            />

            <Field
              name='email'
              render={({ field }) => (
                <TextFieldV2
                  variant='filled'
                  margin='normal'
                  fullWidth
                  className='mb-0'
                  textKey='email'
                  label='E-mail'
                  showCheckIcon
                  autoComplete='email'
                  {...{ touched, errors, values }}
                  {...field}
                />
              )}
            />

            <Field
              name='password'
              render={({ field }) => (
                <TextFieldV2
                  variant='filled'
                  margin='normal'
                  fullWidth
                  className='mb-0'
                  textKey='password'
                  type='password'
                  label='Password'
                  showCheckIcon
                  showSuggestion={
                    errors['password'] &&
                    touched['password'] &&
                    values.password.length > 0
                  }
                  autoComplete='current-password'
                  {...{ touched, errors, values }}
                  {...field}
                />
              )}
            />

            <div className={classes.wrapperButton}>
              <Button
                size='large'
                type='submit'
                fullWidth
                variant='contained'
                color='default'
                disabled={isDisable() || props.isSubmitting}
                className={`${classes.submit} text-transform-reset`}
              >
                Join Tax Tracker
              </Button>
              {props.isSubmitting && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </Form>
        </div>
      </Container>
    </>
  )
}

const FormikForm = withFormik({
  mapPropsToValues() {
    return {
      name: '',
      email: '',
      password: ''
    }
  },
  validationSchema: Yup.object().shape({
    name: Yup.string()
      .required('Full name is required')
      .matches(
        /^(?=.[A-Za-z0-9 ]+$)/,
        'Full name must contain alphabetical, numeric character and space character'
      )
      .min(2, 'Full name must have min 2 characters')
      .max(100, 'Full name have max 100 characters'),
    email: Yup.string().required('Email is required').email('Email is invalid'),
    password: Yup.string()
      .required('Password is required')
      .min(8, 'Password is invalid')
      .max(30, 'Password is invalid'),
  }),
  handleSubmit: async (values, { props, setMessage, setOpenMessage }) => {
    try {
      let response = await AuthenAPI.createAccount(values)
      if (!response || (response && !response.status)) {
        throw 'Something went wrong.'
      } else {
        const {
          data: { _id, email }
        } = response

        // bring values.email and redirect to signup confirm component
        props.history.push(`/auth/signin`)
      }
    } catch (err) {
      const m = err.message
      AlertUtil.showErrorAlert(props.storeNotification, m)
    }
  }
})(SignUpSide)

export default withRouter(FormikForm)

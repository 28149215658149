import React, { useContext, useRef, useEffect, useState } from 'react'

import { makeStyles, useTheme } from '@material-ui/core/styles'

import IconRainbow from '@src/assets/img/i-crescent-rainbow.svg'
import IconArrow from '@src/assets/img/i-estimate-arrow.svg'
import { ContactsOutlined } from '@material-ui/icons'

import ENUMS_CONFIG from '@src/config/Enums'
import { AlertUtil, UtilityUtil } from '@src/utils'

const useStyles = makeStyles((theme) => ({
  right: {
    backgroundImage: `url(${IconRainbow})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain'
  },
  rightMedium: {
    width: '96px',
    height: '48px'
  },
  rightSmall: {
    width: '48px',
    height: '24px'
  },
  arrow: {
    transformOrigin: '48px 48px'
  },
  arrowSmall: {
    transformOrigin: '24px 24px'
  },
  imgArrow: {
    width: '100%'
  }
}))

export default React.memo(({ cost = 0, avgCost = 0, size = 'medium' }) => {
  const theme = useTheme()
  const classes = useStyles()

  const [value, setValue] = useState(0)

  useEffect(() => {
    console.log('cost: ', cost)
    console.log('avgCost: ', avgCost)
    switch (UtilityUtil.getPriceLevel(cost, avgCost)) {
      case ENUMS_CONFIG.PriceLevelCalculate.HIGHEST_PRICE:
        setValue(90)
        break
      case ENUMS_CONFIG.PriceLevelCalculate.LOWEST_PRICE:
        setValue(-90)
        break
      case ENUMS_CONFIG.PriceLevelCalculate.BEST_PRICE:
        setValue(0)

        break
      case ENUMS_CONFIG.PriceLevelCalculate.HIGHT_PRICE:
        const distanceH = avgCost
        const vH = ((cost - distanceH) * 90) / distanceH

        setValue(vH)

        break
      case ENUMS_CONFIG.PriceLevelCalculate.LOW_PRICE:
        const distanceL = avgCost / 2
        const vL = ((cost - distanceL) * 90) / distanceL

        setValue(-(90 - vL))
        break
      default:
        setValue(0)
        break
    }
  }, [cost, avgCost])

  return (
    <>
      {size === 'medium' && (
        <div className={`${classes.right} ${classes.rightMedium}`}>
          <div
            className={classes.arrow}
            style={{ transform: `rotate(${value}deg)` }}
          >
            <img src={IconArrow} className={classes.imgArrow} alt='' />
          </div>
        </div>
      )}

      {size === 'small' && (
        <div className={`${classes.right} ${classes.rightSmall}`}>
          <div
            className={classes.arrowSmall}
            style={{ transform: `rotate(${value}deg)` }}
          >
            <img src={IconArrow} className={classes.imgArrow} alt='' />
          </div>
        </div>
      )}
    </>
  )
})

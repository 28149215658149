import React from 'react'
import Button from '@material-ui/core/Button'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  btn: {
    padding: '10px 16px',
    // minWidth: '56px',
    border: 'none',
    borderRadius: '24px',
    '&:hover': {
      opacity: 0.8
    }
  },
  btnActive: {
    border: '1px solid ' + theme.palette.common.sPurple,
    backgroundColor: theme.palette.common.sWhite,
    '&:hover': {
      opacity: 0.8,
      backgroundColor: theme.palette.common.sWhite
    }
  }
}))

export default (props) => {
  const classes = useStyles()

  return (
    <>
      <Button
        disableRipple={props.disableRipple}
        className={`${classes.btn} ${props.active ? classes.btnActive : ''}`}
        onClick={props.onClick}
        fullWidth={props.fullWidth}
        variant='contained'
        color='default'
      >
        {props.children}
      </Button>
    </>
  )
}

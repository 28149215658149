import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'

export default React.memo((props) => {
  const h = props.height ? props.height : '56px'

  const useStylesReddit = makeStyles((theme) => ({
    // style select only
    inputSelectRoot: {
      height: h,
      paddingLeft: 0,
      paddingRight: 0,
      backgroundColor: theme.palette.common.sWhite,
      border: '1px solid #D6D6D6'
    },
    selectRoot: {
      paddingLeft: 12,
      paddingRight: 12,
      // backgroundColor: theme.palette.common.sWhite,
      backgroundColor: 'transparent',
      border: 'none',
      '&:focus': {
        backgroundColor: 'transparent'
      }
    }
  }))

  const classes = useStylesReddit()

  return (
    <>
      <TextField
        disabled={props.disabled}
        variant='standard'
        select
        fullWidth={props.fullWidth}
        SelectProps={{
          classes: {
            // root: props.value ? classes.selectRootValue : classes.selectRoot,
            select: classes.selectRoot
          },
          displayEmpty: true
        }}
        InputProps={{
          classes: {
            root: `${classes.inputSelectRoot}`
          }
        }}
        inputProps={
          {
            // className: `${props.center ? 'text-center' : ''}`
          }
        }
        // InputProps={{
        //   ...inputP
        // }}
        // InputLabelProps={{
        //   // shrink: true,
        //   className: 'bd-input-label-normal'
        // }}
        // InputLabelProps={{
        //   shrink: true,
        //   className: classes.label
        // }}
        // helperText={props.touched.password && props.errors.password}
        // error={props.touched.password && !!props.errors.password}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
      >
        {props.children}
      </TextField>
    </>
  )
})

import React, { useState, useEffect } from 'react'
import {
    Button,
    Box,
    Typography,
    makeStyles,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TableContainer
} from '@material-ui/core'
import moment from 'moment'

import { PButtonGroup, PButton } from './index'

import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined'
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined'

import Constants from '@src/config/Constants'
const colorS = Constants.COLORS

const scheduleStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        width: 'fit-content'
    },
    button: {
        width: 40,
        height: 40,
        background: colorS.sWhite
    },
    top: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        marginBottom: '24px'
    },
    tableContainer: {
        border: `1px solid ${colorS.sGrey20}`,
        borderRadius: 6,
    },
    table: {
        '& tr:first-child': {
            '& td': {
                borderTop: 'none'
            }
        },
        '& tr:last-child': {
            '& td': {
                borderBottom: 'none'
            }
        },
        '& td': {
            border: `1px solid ${colorS.sGrey20}`,
            '&:first-child': {
                borderLeft: 'none'
            },
            '&:last-child': {
                borderRight: 'none'
            },
        }
    },
    availablePane: {
        background: '#00B341',
        borderRadius: 4,
        width: '100%',
        height: '32px',
    },
    reservedPane: {
        background: colorS.sPurple,
        borderRadius: 4,
        width: '100%',
        height: '32px',
    }
}))

const PScheduleCalendar = ({ className, availableTimes, reservedTimes, startDate, timeTable, onSelect }) => {
    const classes = scheduleStyles()
    const [start, setStart] = useState(moment())
    const [end, setEnd] = useState(moment())
    const [today, setToday] = useState(moment())
    useEffect(() => {
        setStart(today.startOf('week'))
        setEnd(today.endOf('week'))

    }, [])

    const handleTableCell = () => {
        if (onSelect) onSelect()
    }

    return (
        <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
        >
            <div className={classes.top}>
                <Typography variant="h4">{start.format('MMM DD')} - {end.format('MMM DD YYYY')}</Typography>
                <PButtonGroup>
                    <PButton className={classes.button}><ArrowBackIosOutlinedIcon style={{ fontSize: '1.5rem' }} /></PButton>
                    <PButton className={classes.button}><ArrowForwardIosOutlinedIcon style={{ fontSize: '1.5rem' }} /></PButton>
                </PButtonGroup>
            </div>
            <TableContainer classes={{ root: classes.tableContainer }}>
                <Table className={classes.table}>
                    <TableBody>
                        <TableRow>
                            <TableCell></TableCell>
                            {[0, 1, 2, 3, 4, 5, 6].map((item, index) => (
                                <TableCell key={index}>{start.add(1, 'days').format('MMM DD dddd')}</TableCell>
                            ))}
                        </TableRow>
                        {timeTable.map((row, index) => (
                            <TableRow key={index}>
                                {row.map((col, colIndex) => (
                                    <TableCell key={colIndex} className={`${col.label ? '' : 'p-4'}`} onClick={(e) => handleTableCell()}>
                                        {col.label}
                                        {col.available && <div className={classes.availablePane}></div>}
                                        {col.reserved && <div className={classes.reservedPane}></div>}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    )
}

export { PScheduleCalendar }
import Base80API from '../../api/Base80API'
import Constants from '../../config/Constants'

class CompanyAPI extends Base80API {
    async getCompanies(params) {
        try {
            const url = `${Constants.API_URL}/company`
            return await this.get(url, params)
        } catch (err) {
            console.log(err)
            throw err
        }
    }

    async getCompany(id) {
        try {
            const url = `${Constants.API_URL}/company/${id}`
            return await this.get(url)
        } catch (err) {
            console.log(err)
            throw err
        }
    }

    async getForm941XLine16(params) {
        try {
            const url = `${Constants.API_URL}/track/form-941x`
            return await this.get(url, params)
        } catch (err) {
            console.log(err)
            throw err
        }
    }

    async storeForm941XLine16(data) {
        try {
            const url = `${Constants.API_URL}/track/form-941x`
            return await this.post(url, data)
        } catch (err) {
            console.log(err)
            throw err
        }
    }

    async getForm941Fields(params) {
        try {
            const url = `${Constants.API_URL}/track/form-941`
            return await this.get(url, params)
        } catch (err) {
            console.log(err)
            throw err
        }
    }

    async storeForm941Fields(body) {
        try {
            const url = `${Constants.API_URL}/track/form-941`
            return await this.post(url, body)
        } catch (err) {
            console.log(err)
            throw err
        }
    }

    async getForm941_2023Fields(params) {
        try {
            const url = `${Constants.API_URL}/track/form-941-2023`
            return await this.get(url, params)
        } catch (err) {
            console.log(err)
            throw err
        }
    }

    async storeForm941_2023Fields(body) {
        try {
            const url = `${Constants.API_URL}/track/form-941-2023`
            return await this.post(url, body)
        } catch (err) {
            console.log(err)
            throw err
        }
    }

    async getForm941sbFields(params) {
        try {
            const url = `${Constants.API_URL}/track/form-941sb`
            return await this.get(url, params)
        } catch (err) {
            console.log(err)
            throw err
        }
    }

    async storeForm941sbFields(body) {
        try {
            const url = `${Constants.API_URL}/track/form-941sb`
            return await this.post(url, body)
        } catch (err) {
            console.log(err)
            throw err
        }
    }

    async exportPDF(body) {
        try {
            const url = `${Constants.API_URL}/track/form`
            const response = await this.post(url, body)
            return response
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

export default CompanyAPI

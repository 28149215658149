import React, { useState } from 'react'
import { withRouter, Link } from 'react-router-dom'

import {
  Button,
  IconButton,
  InputAdornment,
  CircularProgress,
  Box,
  TextField,
  Typography,
  Container
} from '@material-ui/core'

import { VisibilityOff, Visibility } from '@material-ui/icons'

import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import ENUM from '@src/config/Enums'

import UseStyles from './ResetPasswordStyle'
import ResetPasswordAPI from './ResetPasswordAPI'

import TitleGroupAuth from '@src/components/common/TitleGroupAuth'
import TextFieldV2 from '@src/components/common/TextFieldV2'

const useStyles = UseStyles
const resetPasswordAPI = new ResetPasswordAPI()

const intialState = {
  password: ''
}
const userSchema = Yup.object().shape({
  password: Yup.string()
    .required('Password is required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/,
      'Password must contain atleast 1 small-case letter, 1 Capital letter, 1 digit, 1 special character'
    )
    .min(8, 'Password must have min 8 characters')
    .max(30, 'Password have max 100 characters')
})

function ResetPasswordSide(props) {
  const classes = useStyles()
  const [values, setValues] = React.useState({
    password: '',
    showPassword: false
  })

  const [user, setUser] = useState(intialState)

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value })
  }

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword })
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  return (
    <>
      <Container component='main' maxWidth='xs' className={classes.main}>
        <div className={classes.paper}>
          <TitleGroupAuth
            title={' New password'}
            description={`The password has to be at least 8 characters long, contain an
              upper-case letter and a special symbol.`}
          />

          <Formik
            initialValues={user}
            onSubmit={async (values) => {
              try {
                const query = new URLSearchParams(props.location.search)
                const code = query.get('code')
                const user_id = query.get('user_id')
                const account_type = query.get('account_type')
                const responses = await resetPasswordAPI.resetPassword({
                  code,
                  new_password: values.password,
                  user_id
                })

                const type = ENUM.NotificationType.SUCCESS
                const m = responses.message

                props.storeUser.setUser(responses.data)

                props.storeNotification.setContent(type, m)
                props.storeNotification.setIsOpen(true)

                if (account_type > 0) {
                  props.history.push(
                    `/auth/signin-pro?account_type=${account_type}`
                  )
                } else {
                  props.history.push(`/auth/signin`)
                }

                // if (responses.data.first_login === true) {
                //   props.history.push('/welcome')
                // } else {
                //   props.history.push('/')
                // }
              } catch (err) {
                const type = ENUM.NotificationType.ERROR
                const m = err.message

                props.storeNotification.setContent(type, m)
                props.storeNotification.setIsOpen(true)
              }
            }}
            // onSubmit={(values, actions) => {
            //   actions.setSubmitting(true)
            //   setUser(values)
            //   setTimeout(() => {
            //     actions.setSubmitting(false)
            //   }, 2000)
            // }}
            validationSchema={userSchema}
          >
            {(props) => {
              const isDisable = () =>
                Object.keys(props.errors).some((item) => props.errors[item]) ||
                Object.keys(props.values).some(
                  (item) => props.values[item].trim().length === 0
                )
              return (
                <form className={classes.form} onSubmit={props.handleSubmit}>
                  <Field
                    name='password'
                    render={({ field }) => (
                      <Field
                        name='password'
                        render={({ field }) => (
                          <TextFieldV2
                            variant='filled'
                            margin='normal'
                            fullWidth
                            className='mb-0'
                            textKey='password'
                            type='password'
                            label='Password'
                            showCheckIcon
                            autoComplete='current-password'
                            {...{
                              touched: props.touched,
                              errors: props.errors,
                              values: props.values
                            }}
                            {...field}
                          />
                        )}
                      />
                    )}
                  />

                  <div className={classes.wrapperButton}>
                    <Button
                      type='submit'
                      fullWidth
                      variant='contained'
                      color='default'
                      disabled={isDisable() || props.isSubmitting}
                      className={`${classes.submit} text-transform-reset`}
                    >
                      Change password and log in
                    </Button>
                    {props.isSubmitting && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>

                  <div className={classes.boxLink}>
                    <Link
                      className={classes.secondLink}
                      to='/auth/forgot-email'
                      variant='body2'
                    >
                      Forgot email
                    </Link>

                    <Link
                      className={classes.secondLink}
                      to='/auth/signin'
                      variant='button'
                      color='textPrimary'
                    >
                      Return
                    </Link>
                  </div>
                </form>
              )
            }}
          </Formik>
        </div>
      </Container>
    </>
  )
}

export default withRouter(ResetPasswordSide)

// TextField V1
// const aTextFieldOld = (
//   <TextField
//     variant='outlined'
//     margin='normal'
//     required
//     fullWidth
//     className='mb-0'
//     type={values.showPassword ? 'text' : 'password'}
//     InputProps={{
//       className: 'bd-input-normal',
//       endAdornment: (
//         <InputAdornment position='end'>
//           <IconButton
//             aria-label='toggle password visibility'
//             onClick={handleClickShowPassword}
//             onMouseDown={handleMouseDownPassword}
//             edge='end'
//           >
//             {values.showPassword ? <Visibility /> : <VisibilityOff />}
//           </IconButton>
//         </InputAdornment>
//       )
//     }}
//     InputLabelProps={{
//       className: 'bd-input-label-normal'
//     }}
//     label='Password'
//     helperText={props.touched.password && props.errors.password}
//     error={props.touched.password && !!props.errors.password}
//     autoComplete='current-password'
//     {...field}
//   />
// )

import { createContext } from 'react'
import { decorate, observable, computed, action, toJS } from 'mobx'


import ENUMS from '@src/config/Enums'

const UK = 'currentPtUser'

export class CurrentUserStorage {
    data = {
        _id: '',
        email: '',
        name: '',
        user_type: '',
        access_token: '',
        iot_key: '',
        company: '',
    }

    constructor() {
        const a = !!this.data.id
        if (!a) {
            if (window.localStorage.getItem(UK) !== null) {
                const d = JSON.parse(window.localStorage.getItem(UK))
                this.data._id = d._id
                this.data.email = d.email
                this.data.name = d.name
                this.data.user_type = d.user_type
                this.data.access_token = d.access_token
                this.data.iot_key = d.iot_key
                this.data.company = d.company
            }
        }
    }

    get user() {
        if (window.localStorage.getItem(UK) === null) {
            return toJS(this.data)
        } else {
            const d = window.localStorage.getItem(UK)
            this.setUser(JSON.parse(d))
            return toJS(this.data)
        }
    }

    get hasUser() {
        const a = !!this.data.id
        return a
    }

    get isHomeowner() {
        if (this.data.homeownerId) {
            return true
        } else {
            return false
        }
    }

    get isTradesman() {
        if (this.data.tradesmanId) {
            return true
        } else {
            return false
        }
    }

    /**
     * get user data from localstorage
     */
    getUser = () => {
        const d = window.localStorage.getItem(UK)
        return JSON.parse(d)
    }

    /**
     * set user info
     */
    setUser(d) {
        this.data._id = d._id
        this.data.email = d.email
        this.data.name = d.name
        this.data.user_type = d.user_type
        this.data.access_token = d.access_token
        this.data.iot_key = d.iot_key
        this.data.company = d.company

        window.localStorage.setItem(UK, JSON.stringify(this.data))
    }

    /**
     * remove user
     */
    removeUser = () => {
        this.data._id = ''
        this.data.email = ''
        this.data.name = ''
        this.data.user_type = ''
        this.data.access_token = ''
        this.data.iot_key = ''
        this.data.company = ''

        window.localStorage.removeItem(UK)
        window.localStorage.removeItem('globalData')
    }

    setData(d) {
        const globalData = JSON.parse(window.localStorage.getItem('globalData'))
        window.localStorage.setItem('globalData', JSON.stringify({ ...globalData, ...d }))
    }

    getData(namespace = ''){
        const globalData = JSON.parse(window.localStorage.getItem('globalData'))
        const elems = namespace.split('.')
        let result = globalData
        elems.map((elem, index) => {
            result = result ? result[elem] : null
        })

        return result
    }
}

decorate(CurrentUserStorage, {
    data: observable,
    user: computed,
    hasUser: computed,
    setUser: action,
    removeUser: action
})

export default createContext(new CurrentUserStorage())

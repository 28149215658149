import React from 'react'
// import { Link } from 'react-router-dom'

import {
  Link,
  Breadcrumbs,
  withStyles,
  makeStyles,
  useTheme
} from '@material-ui/core'
import IconBreadcrumb from '@src/assets/img/i-breadcrumb-grey.svg'

export default ({ crumbs }) => {
  // Don't render a single breadcrumb.
  if (crumbs.length <= 1) {
    return null
  }
  return (
    <CrumbsView
      separator={<img src={IconBreadcrumb} />}
      aria-label='breadcrumb'
    >
      {/* <Link color='inherit' href='/' onClick={() => {}} className='small-text'>
        Akeptus
      </Link> */}

      {/* Link back to any previous steps of the breadcrumb. */}
      {crumbs.map(({ name, path }, key) =>
        key + 1 === crumbs.length ? (
          <span key={key} className='small-text'>
            {name}
          </span>
        ) : (
          <Link color='inherit' key={key} href={path} className='small-text'>
            {name}
          </Link>
        )
      )}
    </CrumbsView>
  )
}

const CrumbsView = withStyles((theme) => ({
  li: {
    display: 'flex',
    alignItems: 'center'
  }
}))(Breadcrumbs)

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'

export default React.memo((props) => {
  const h = props.height ? props.height : '56px'

  const useStylesReddit = makeStyles((theme) => ({
    // root: {
    //   border: 'none',
    //   overflow: 'hidden',
    //   borderRadius: 4,
    //   backgroundColor: '#ECF1F6',
    //   border: '1px solid #F0F0F0',
    //   '&:hover': {
    //     backgroundColor: '#fff',
    //     border: '1px solid #7092FE'
    //   },
    //   '&$focused': {
    //     backgroundColor: '#fff',
    //     border: '1px solid #7092FE'
    //   }
    // },
    // focused: {},
    // inputLabel: {
    //   fontSize: '1.4rem',
    //   color: '#868E92',
    //   transform: 'translate(12px, 22px) scale(1)',
    //   width: '120%' // What happen in here.
    // }
    input: {
      fontSize: '1.4rem',
      border: 'none'
    },
    // style input only
    inputRoot: {
      height: h,
      overflow: 'hidden',
      borderRadius: 4,
      backgroundColor: '#ECF1F6',
      border: '1px solid #F0F0F0',
      '&:hover': {
        backgroundColor: '#fff',
        border: '1px solid #7092FE'
      }
    },
    focused: {},
    // disabled: {},
    // style select only
    inputSelectRoot: {
      height: props.height ? props.height : 'auto',
      paddingLeft: 0,
      paddingRight: 0,
      backgroundColor: theme.palette.common.sBlueAccent3,
      border: 'none'
    },
    selectRoot: {
      paddingLeft: 12,
      paddingRight: 12,
      backgroundColor: 'transparent',
      border: 'none',
      '&:focus': {
        backgroundColor: 'transparent'
      }
    }
  }))

  const classes = useStylesReddit()

  return (
    <>
      {!props.select ? (
        <TextField
          variant={props.variant}
          disabled={props.disabled}
          fullWidth={props.fullWidth}
          multiline={props.multiline}
          rows={props.rows}
          InputProps={{
            readOnly: props.readOnly,
            classes: {
              root: classes.inputRoot,
              focused: classes.focused
            },
            endAdornment: props.endAdornment ? props.endAdornment : <></>,
            inputComponent: props.inputComponent
          }}
          inputProps={{
            className: `${classes.input} ${props.center ? 'text-center' : ''}`
          }}
          // InputProps={{
          //   ...inputP
          // }}
          // InputLabelProps={{
          //   // shrink: true,
          //   className: 'bd-input-label-normal'
          // }}
          // InputLabelProps={{
          //   shrink: true,
          //   className: classes.label
          // }}
          // helperText={props.touched.password && props.errors.password}
          // error={props.touched.password && !!props.errors.password}
          placeholder={props.placeholder}
          label={props.label}
          type={props.type}
          name={props.name}
          value={props.value}
          defaultValue={props.defaultValue}
          id={props.id}
          onChange={props.onChange}
        />
      ) : (
        <TextField
          select
          variant={props.variant}
          disabled={props.disabled}
          fullWidth={props.fullWidth}
          multiline={props.multiline}
          id={props.id}
          SelectProps={{
            classes: {
              select: classes.selectRoot
            },
            displayEmpty: props.displayEmpty
          }}
          InputProps={{
            classes: {
              root: classes.inputSelectRoot
            }
          }}
          inputProps={{
            className: `${classes.input} ${props.center ? 'text-center' : ''}`
          }}
          // className='mt-10'
          // SelectProps={{
          //   className: 'bd-select-normal'
          //   // classes: {
          //   //   root: 'bd-select-root-normal',
          //   //   select: 'bd-select-normal'
          //   // }
          // }}
          placeholder={props.placeholder}
          label={props.label}
          name={props.name}
          value={props.value}
          onChange={props.onChange}
        >
          {props.children}
        </TextField>
      )}
    </>
  )
})

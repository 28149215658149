import Geocode from 'react-geocode'
import CONSTANTS from '../config/Constants'

Geocode.setApiKey(CONSTANTS.GEOCODE_KEY)
Geocode.enableDebug()

class GeocodeSelect {
  static handleSelect (formattedAddress, callback) {
    // let address = ''
    // let city = ''
    // let zip = ''
    // let state = ''
    // let country = ''
    // let lat = 0
    // let lon = 0

    // address = `${addressComponent.street_number ? addressComponent.street_number : ''} ${addressComponent.route ? addressComponent.route : ''}`
    // city = (addressComponent.locality ? addressComponent.locality : '')
    // zip = (addressComponent.postal_code ? addressComponent.postal_code : '')
    // state = (addressComponent.administrative_area_level_1 ? addressComponent.administrative_area_level_1 : '')
    // country = (addressComponent.country ? addressComponent.country : '')

    Geocode.fromAddress(formattedAddress).then(
      response => {
        const {
          lat,
          lng
        } = response.results[0].geometry.location
        // const lon = (lng)
        // const lat = (lat)

        callback(null, {
          // address,
          // city,
          // zip,
          // state,
          // country,
          lat: lat,
          lon: lng
        })
      },
      error => {
        callback(error)
        console.error(error)
      }
    )
  }
}

export default GeocodeSelect

import BaseAPI from '../../api/BaseAPI'
import Constants from '../../config/Constants'

class SignUpEmailConfirmation extends BaseAPI {
  /**
   * Sign up confirmation
   *
   * @param {Object} dataObject object data include username or emailpassword
   */
  async active (code) {
    try {
      const data = {
        code
      }

      const authURL = `${Constants.AUTHEN_SERVER_URL}/auth/activation`
      const response = await this.post(authURL, data)

      return response
    } catch (err) {
      console.log(err)
      throw err
    }
  }
}

export default SignUpEmailConfirmation

import React, { useState, useEffect } from 'react'
import { makeStyles, withStyles, Stepper, Step, StepLabel, StepConnector, StepIcon } from '@material-ui/core'

import { IconDownArrow, IconUpArrow } from '@src/components/PCustom/SVGIcons'

import Constants from '@src/config/Constants'
const colorS = Constants.COLORS

const PStepper = withStyles(() => ({
}))(Stepper)

const PStep = withStyles(() => ({
}))(Step)

const PStepLabel = withStyles(() => ({
}))(StepLabel)

const PStepConnector = withStyles({
    alternativeLabel: {
        top: 8,
        left: 'calc(-50% + 8px)',
        right: 'calc(50% + 8px)',
    },
    active: {
        '& $line': { borderColor: colorS.sPurple },
    },
    completed: {
        '& $line': { borderColor: colorS.sPurple },
    },
    line: {
        borderColor: colorS.sGrey20,
        borderTopWidth: 1,
        borderRadius: 1,
    },
})(StepConnector);

const PStepIcon = withStyles(() => ({
    root: {
        width: '16px',
        height: '16px',
        color: 'transparent',
        border: `1px solid ${colorS.sGrey20}`,
        borderRadius: '50%',
        '&$completed': {
            color: colorS.sPurple,
            border: `0px solid ${colorS.sPurple}`,
        },
        '&$active': {
            color: 'transparent',
            border: `3px solid ${colorS.sPurple}`,
            borderRadius: '50%',
        }
    },
    completed: {},
    active: {}
}))(StepIcon)

export { PStepper, PStep, PStepLabel, PStepConnector, PStepIcon }
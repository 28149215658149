import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import NumberFormat from 'react-number-format'
import PropTypes from 'prop-types'

import IconQuestion from '@src/assets/img/i-question-purple.svg'

export default React.memo((props) => {
  const h = props.height ? props.height : '50px'

  const useStylesReddit = makeStyles((theme) => ({
    // style input only
    inputRoot: {
      height: h,
      color: theme.palette.common.sBlack,
      fontSize: '2.4rem',
      backgroundColor: 'transparent',
      border: 'none',
      '&$focused': {
        backgroundColor: 'transparent',
        border: 'none'
      },
      '&:hover': {
        backgroundColor: 'transparent',
        border: 'none'
      }
    },
    label: {
      top: '-8px',
      fontSize: '1.85rem',
      color: theme.palette.common.sBlack,
      '&$labelFocused': {
        color: theme.palette.common.sBlack
      }
    },
    focused: {
      backgroundColor: 'transparent',
      border: 'none'
    },
    labelFocused: {
      color: theme.palette.common.sBlack
    },
    wrap: {
      position: 'relative'
    },
    question: {
      position: 'absolute',
      width: '20px',
      height: '20px',
      left: '75px',
      top: '0'
    }
  }))

  const classes = useStylesReddit()

  const [values, setValues] = React.useState({
    // textmask: '(1  )    -    ',
    cardNumber: props.value
  })

  const handleChange = (event) => {
    console.log(event.target.value)

    setValues({
      ...values,
      [event.target.name]: event.target.value
    })

    props.onChange(event.target.value)
  }

  return (
    <div className={classes.wrap}>
      <TextField
        variant={props.variant}
        disabled={props.disabled}
        fullWidth={props.fullWidth}
        multiline={props.multiline}
        rows={props.rows}
        InputProps={{
          readOnly: props.readOnly,
          classes: {
            root: classes.inputRoot,
            focused: classes.focused
          },
          endAdornment: props.endAdornment ? props.endAdornment : <></>,
          inputComponent: NumberFormatCustom
        }}
        InputLabelProps={{
          shrink: true,
          classes: {
            root: classes.label,
            focused: classes.labelFocused
          }
        }}
        inputProps={{}}
        // helperText={props.touched.password && props.errors.password}
        // error={props.touched.password && !!props.errors.password}
        placeholder='123'
        label='CVV/CVC'
        type='text'
        name='cardNumber'
        value={values.cardNumber}
        defaultValue={props.defaultValue}
        id={props.id}
        onChange={handleChange}
        className={props.className}
      />
      <div className={classes.question}>
        <img src={IconQuestion} alt='' />
      </div>
    </div>
  )
})

const NumberFormatCustom = (props) => {
  const { inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        })
      }}
      format='###'
      // thousandSeparator
      isNumericString
      // prefix='$'
    />
  )
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
}

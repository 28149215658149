import React from 'react'
import { withRouter, useHistory, Link as RouterLink } from 'react-router-dom'

import {
  makeStyles,
  AppBar,
  Toolbar,
  Link,
  Container
} from '@material-ui/core'
import ImgLogo from '@src/assets/img/p-logo.svg'

const useStyles = makeStyles((theme) => ({
  appBar: {
    alignItems: 'center',
    maxHeight: '100px',
    background: 'none'
  },
  toolbar: {
    position: 'relative',
    flexWrap: 'wrap',
    width: '100%',
    minHeight: '100px',
    padding: '40px 50px',
    justifyContent: 'space-between',
    background: '#fff',
    '@media (min-width:1392px)': {
    }
  },
  toolbarTitle: {
    position: 'absolute',
    left: theme.spacing(3),
    top: 0,
    bottom: 0,
    marginTop: 'auto',
    marginBottom: 'auto',
    height: 'max-content',
    color: '#7092FE',
    lineHeight: '2.3rem',
    fontSize: '2rem',
    fontWeight: 'bold'
  },
  link: {
    margin: 0,
    fontWeight: 400,
    textTransform: 'none',
    color: '#7092FE',
    lineHeight: '0'
  },
  logo:{
    height: '20px'
  },
  root: {
    width: '100%'
  },
  nav: {
    position: 'absolute',
    right: theme.spacing(3),
    top: 0,
    bottom: 0,
    marginTop: 'auto',
    marginBottom: 'auto',
    height: 'max-content',
    textAlign: 'right'
  },
  helpCenter: {
    display: 'inline-block',
    marginRight: '24px'
  }
}))

function HeaderAuthentication({ rightConfig = null }) {
  const classes = useStyles()
  const history = useHistory()

  return (
    <AppBar
      position='static'
      color='default'
      elevation={0}
      className={classes.appBar}
    >
      <Toolbar className={classes.toolbar}>
        <Link
          variant='button'
          color='textPrimary'
          to={'/'}
          component={RouterLink}
          className={classes.link}
        >
          {/* <img className={classes.logo} src={ImgLogo} alt='Point80' /> */}
        </Link>

        <nav>
          {rightConfig && (
            <>
              <span className="mr-10">{rightConfig.desc}</span>
              <Link
                variant='button'
                color='textPrimary'
                to={rightConfig.linkPath}
                component={RouterLink}
                className={classes.link}
              >
                {rightConfig.linkTitle}
              </Link>
            </>
          )}
        </nav>
      </Toolbar>
    </AppBar>
  )
}

export default withRouter(HeaderAuthentication)

import 'date-fns'
import React from 'react'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  DatePicker,
  KeyboardTimePicker,
  KeyboardDatePicker
} from '@material-ui/pickers'

import { createMuiTheme, TextField } from '@material-ui/core'
import { ThemeProvider, makeStyles } from '@material-ui/styles'

import Constants from '../../config/Constants'

const colorS = Constants.COLORS

const defaultMaterialTheme = createMuiTheme({
  overrides: {
    MuiFormControl: {
      root: {
        width: '100%'
      }
    },
    MuiInput: {
      root: {
        padding: 16,
        // backgroundColor: colorS.sGrey20,
        borderRadius: '6px',
        '&$focused': {
          border: '1px solid ' + colorS.sGrey20,
          backgroundColor: colorS.sWhite
        },
        '&:hover': {
          border: '1px solid ' + colorS.sGrey20,
          backgroundColor: colorS.sWhite
        },
        // disabled style
        '&$disabled': {
          backgroundColor: colorS.sWhite,
          border: '1px solid ' + colorS.sGrey20
        }
      },
      // '&$underline': {
      underline: {
        height: '56px',
        width: '100%',
        backgroundColor: '#fff',
        border: '1px solid ' + colorS.sGrey20,
        borderRadius: '6px',
        '&:$before': {
          border: 'none',
          transition: 'none'
        },
        '&:$after': {
          border: 'none',
          transition: 'none'
        },
        '&:hover': {
          // style fake .foo class to override this rule
          '&:not(.foo):before': {
            border: 'none'
          },
          '&:before': {
            border: 'none',
            transition: 'none'
          }
        },
        '&$focused': {
          '&:after': {
            border: 'none',
            transition: 'none'
          }
        },
        '&$disabled': {
          '&:before': {
            border: 'none',
            transition: 'none'
          }
          // '&:hover': {
          //   '&:before': {
          //     border: 'none'
          //   }
          // },
        }
      }
    },
    MuiInputBase: {
      input: {
        fontSize: '1.4rem'
      }
    },
    MuiTypography: {
      root: {
        fontSize: '1.2rem'
      },
      body1: {
        fontSize: '1.2rem',
        fontWeight: '500'
      },
      body2: {
        fontSize: '1.2rem'
      },
      caption: {
        fontSize: '1.2rem'
      }
    },
    MuiPickersBasePicker: {
      pickerView: {
        minWidth: 212,
        width: 212,
        justifyContent: 'start',
        // height: 215,
        minHeight: 230
      }
    },
    MuiPickersCalendar: {
      transitionContainer: {
        marginTop: 0,
        minHeight: 70
      }
    },
    MuiPickersCalendarHeader: {
      dayLabel: {
        width: '24px',
        height: '24px',
        margin: 0,
        color: colorS.sGrey
      },
      switchHeader: {
        color: colorS.sBlack
      }
    },
    MuiPickersDay: {
      day: {
        width: '24px',
        height: '24px',
        margin: 0,
        color: colorS.sBlack
      },
      daySelected: {
        backgroundColor: colorS.sRedAccent,
        '&:hover': {
          backgroundColor: colorS.sRedAccent
        }
      },
      dayDisabled: {
        color: colorS.sGrey,
        opacity: 0.4
      },
      current: {
        color: colorS.sRedAccent
      }
    }
    // MuiPickersModal: {
    //   dialogAction: {
    //     color: 'pink'
    //   }
    // }
  }
})

const useStylesReddit = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.sGrey20,
    border: 'none'
  },
  rootValue: {
    backgroundColor: theme.palette.common.sBlueAccent,
    border: 'none'
  },
  inputSelectDate: {
    backgroundColor: '#fff',
    border: '1px solid ' + theme.palette.common.sGrey20,
    borderRadius: '6px'
  }
}))

export default React.memo((props) => {
  const classes = useStylesReddit()

  // The first commit of Material-UI
  const [selectedDate, setSelectedDate] = React.useState(
    new Date('2014-08-18T21:11:54')
  )

  const handleDateChange = (date) => {
    setSelectedDate(date)
  }

  return (
    <ThemeProvider theme={defaultMaterialTheme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          disableToolbar
          variant='inline'
          format='MM.dd.yyyy'
          // label="Date picker inline"
          // id='date-picker-inline'
          value={selectedDate}
          onChange={handleDateChange}
          // onChange={props.onChange}
          disablePast
          // shouldDisableDate={day => comopareCurrentDate(day)}
          inputVariant='standard'
          classes={
            {
              // inputRoot: `m-0 ${classes.inputSelectDate} ${selectedDate ? classes.rootValue : classes.root}`
            }
          }
          // TextFieldComponent={
          //   params => (
          //     <TextField

          //       // fullWidth={props.fullWidth}
          //       // // variant='standard'
          //       // classes={{
          //       //   // root: props.value ? classes.rootValue : classes.root
          //       //   root: `m-0 ${selectedDate ? classes.rootValue : classes.root}`
          //       // }}
          //       // // placeholder='Favorites'
          //       // InputProps={{
          //       //   classes: {
          //       //     root: classes.inputSelectDate
          //       //     // focused: classes.inputSelectDateFocused
          //       //   }
          //       // //   endAdornment: (
          //       // //     <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
          //       // //       <path fill-rule='evenodd' clip-rule='evenodd' d='M3.52001 3.41817C3.16654 3.41817 2.88001 3.71122 2.88001 4.07271V13.2364C2.88001 13.5978 3.16654 13.8909 3.52001 13.8909H12.48C12.8335 13.8909 13.12 13.5978 13.12 13.2364V4.07271C13.12 3.71122 12.8335 3.41817 12.48 3.41817H3.52001ZM1.60001 4.07271C1.60001 2.98823 2.45962 2.10908 3.52001 2.10908H12.48C13.5404 2.10908 14.4 2.98823 14.4 4.07271V13.2364C14.4 14.3208 13.5404 15.2 12.48 15.2H3.52001C2.45962 15.2 1.60001 14.3208 1.60001 13.2364V4.07271Z' fill='#2D292A' />
          //       // //       <path fill-rule='evenodd' clip-rule='evenodd' d='M10.56 0.799988C10.9135 0.799988 11.2 1.09304 11.2 1.45453V4.07271C11.2 4.43421 10.9135 4.72726 10.56 4.72726C10.2065 4.72726 9.92001 4.43421 9.92001 4.07271V1.45453C9.92001 1.09304 10.2065 0.799988 10.56 0.799988Z' fill='#2D292A' />
          //       // //       <path fill-rule='evenodd' clip-rule='evenodd' d='M5.44001 0.799988C5.79347 0.799988 6.08001 1.09304 6.08001 1.45453V4.07271C6.08001 4.43421 5.79347 4.72726 5.44001 4.72726C5.08654 4.72726 4.80001 4.43421 4.80001 4.07271V1.45453C4.80001 1.09304 5.08654 0.799988 5.44001 0.799988Z' fill='#2D292A' />
          //       // //       <path fill-rule='evenodd' clip-rule='evenodd' d='M1.60001 6.6909C1.60001 6.3294 1.88654 6.03635 2.24001 6.03635H13.76C14.1135 6.03635 14.4 6.3294 14.4 6.6909C14.4 7.05239 14.1135 7.34544 13.76 7.34544H2.24001C1.88654 7.34544 1.60001 7.05239 1.60001 6.6909Z' fill='#2D292A' />
          //       // //     </svg>
          //       // //   )
          //       // }}
          //   {...params}
          // />
          // )
          // }
        />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  )
})

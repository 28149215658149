import React, { useEffect, useState } from 'react'
import * as _ from 'lodash'
import { fade, withStyles, makeStyles } from '@material-ui/core/styles'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import IconChecked from '../../assets/img/i-checked-green.svg'

const dateData = [
  {
    value: '0',
    name: '00:00 am'
  },
  {
    value: '0.5',
    name: '00:30 am'
  },
  {
    value: '1',
    name: '01:00 am'
  },
  {
    value: '1.5',
    name: '01:30 am'
  },
  {
    value: '2',
    name: '02:00 am'
  },
  {
    value: '2.5',
    name: '02:30 am'
  },
  {
    value: '3',
    name: '03:00 am'
  },
  {
    value: '3.5',
    name: '03:30 am'
  },
  {
    value: '4',
    name: '04:00 am'
  },
  {
    value: '4.5',
    name: '04:30 am'
  },
  {
    value: '5',
    name: '05:00 am'
  },
  {
    value: '5.5',
    name: '05:30 am'
  },
  {
    value: '6',
    name: '06:00 am'
  },
  {
    value: '6.5',
    name: '06:30 am'
  },
  {
    value: '7',
    name: '07:00 am'
  },
  {
    value: '7.5',
    name: '07:30 am'
  },
  {
    value: '8',
    name: '08:00 am'
  },
  {
    value: '8.5',
    name: '08:30 am'
  },
  {
    value: '9',
    name: '09:00 am'
  },
  {
    value: '9.5',
    name: '09:30 am'
  },
  {
    value: '10',
    name: '10:00 am'
  },
  {
    value: '10.5',
    name: '10:30 am'
  },
  {
    value: '11',
    name: '11:00 am'
  },
  {
    value: '11.5',
    name: '11:30 am'
  },
  {
    value: '12',
    name: '12:00 am'
  },
  {
    value: '12.5',
    name: '12:30 am'
  },
  {
    value: '13',
    name: '01:00 pm'
  },
  {
    value: '13.5',
    name: '01:30 pm'
  },
  {
    value: '14',
    name: '02:00 pm'
  },
  {
    value: '14.5',
    name: '02:30 pm'
  },
  {
    value: '15',
    name: '03:00 pm'
  },
  {
    value: '15.5',
    name: '03:30 pm'
  },
  {
    value: '16',
    name: '04:00 pm'
  },
  {
    value: '16.5',
    name: '04:30 pm'
  },
  {
    value: '17',
    name: '05:00 pm'
  },
  {
    value: '17.5',
    name: '05:30 pm'
  },
  {
    value: '18',
    name: '06:00 pm'
  },
  {
    value: '18.5',
    name: '06:30 pm'
  },
  {
    value: '19',
    name: '07:00 pm'
  },
  {
    value: '19.5',
    name: '07:30 pm'
  },
  {
    value: '20',
    name: '08:00 pm'
  },
  {
    value: '20.5',
    name: '08:30 pm'
  },
  {
    value: '21',
    name: '09:00 pm'
  },
  {
    value: '21.5',
    name: '09:30 pm'
  },
  {
    value: '22',
    name: '10:00 pm'
  },
  {
    value: '22.5',
    name: '10:30 pm'
  },
  {
    value: '23',
    name: '11:00 pm'
  },
  {
    value: '23.5',
    name: '11:30 pm'
  }
]

const useStylesReddit = makeStyles((theme) => ({
  option: {
    padding: '4px 10px',
    fontSize: '1.4rem',
    color: theme.palette.common.sBlack,

    '&[aria-selected="true"]': {
      background: 'none'
    }
    // '&[data-focus='true']': {
    //   backgroundColor: 'red',
    // }
  },
  paper: {
    borderRadius: '4px',
    boxShadow:
      '0px 9px 46px rgba(0, 0, 0, 0.02), 0px 24px 38px rgba(0, 0, 0, 0.04), 0px 11px 15px rgba(0, 0, 0, 0.08)'
  },
  root: {
    backgroundColor: theme.palette.common.sGrey20,
    border: 'none'
  },
  rootValue: {
    minWidth: '12rem',
    backgroundColor: theme.palette.common.sBlueAccent,
    border: 'none'
  }
}))

export default React.memo((props) => {
  const classes = useStylesReddit()
  const [val, setVal] = useState(null)

  useEffect(() => {
    if (!_.isUndefined(props.value)) {
      const d = dateData.find((item) => +item.value === +props.value)
      setVal(d)
    }
  }, [props.value])

  return (
    <Autocomplete
      disabled={props.disabled}
      popupIcon=''
      disableClearable
      classes={{
        option: classes.option,
        paper: classes.paper,
        inputRoot: props.value ? classes.rootValue : classes.root
      }}
      value={val}
      options={dateData}
      getOptionLabel={(option) => option.name}
      renderOption={(option, { selected }) => (
        <>
          <div className='mr-10'>
            {selected ? (
              <img src={IconChecked} />
            ) : (
              <div style={{ width: '20px', height: '20px' }} />
            )}
          </div>
          {option.name}
        </>
      )}
      onChange={props.onChange}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth={props.fullWidth}
          variant={props.variant}
          // placeholder='Favorites'
        />
      )}
    />
  )
})

import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ClearIcon from '@material-ui/icons/Clear'
import CancelIcon from '@material-ui/icons/Cancel'
import InfoIcon from '@material-ui/icons/Info'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import { SnackbarContent, Typography, Box, Button } from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning'
import { useTheme } from '@material-ui/core/styles'

import ENUM from '../../config/Enums'

import styles from './NotificationStyle'

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: CancelIcon,
  info: InfoIcon
}

const NotificationContent = (props) => {
  const classes = styles()
  const theme = useTheme()

  const { className, message, onClose, variant, ...other } = props
  const Icon = variantIcon[variant]

  console.log('variant')
  console.log(variant)
  // ENUM.NotificationType.ERROR
  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby='client-snackbar'
      message={
        <div className='d-flex'>
          <span id='client-snackbar' className={`${classes.message}`}>
            <Icon className={clsx(classes.icon, classes.iconVariant)} />
          </span>
          <div className={classes.wrapTextMessage}>
            <Typography variant='body1' component='div'>
              <Box
                fontSize='1.4rem'
                align='center'
                color={theme.palette.common.sBlack}
              >
                {variant === ENUM.NotificationType.ERROR ? 'Error' : ''}
                {variant === ENUM.NotificationType.SUCCESS ? 'Success' : ''}
              </Box>
            </Typography>
            <Typography variant='body1' component='div'>
              <Box
                fontSize='1.2rem'
                align='center'
                color={theme.palette.common.sGrey}
                className={clsx(classes.iconVariant)}
              >
                {message}
              </Box>
            </Typography>
          </div>
        </div>
      }
      action={[
        <Button
          onClick={onClose}
          // fullWidth
          key='close'
          aria-label='Close'
          variant='contained'
          color='default'
        >
          Close
        </Button>
        // <IconButton key='close' aria-label='Close' color='inherit' onClick={onClose}>
        //   <CloseIcon className={classes.icon} />
        // </IconButton>
      ]}
      {...other}
    />
  )
}

NotificationContent.propTypes = {
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired
}

export default NotificationContent

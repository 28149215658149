import React from 'react'
import { withStyles, Input, TextField } from '@material-ui/core'
import Constants from '@src/config/Constants'
import NumberFormat from 'react-number-format'

const colorS = Constants.COLORS
const PInput = withStyles(() => ({
    root: {
        background: '#FFF',
        border: `1px solid #D6D6D6`,
        '&$focused': {
            border: `1px solid #D6D6D6`
        }
    },
    focused: {},
    underline: {
        height: 48
    }
}))(Input)

const PTextField = withStyles(() => ({
    root: {
        background: '#FFF',
        border: `1px solid #D6D6D6`,
        '&$focused': {
            border: `1px solid #D6D6D6`
        }
    },
    focused: {},
}))(TextField)

const PUnderlineTextField = withStyles(() => ({
    root: {
        background: '#FFF',
        borderBottom: `1px solid #D6D6D6`,
        borderRadius: 0,
        '&$focused': {
            border: 'none',
            borderBottom: `1px solid #D6D6D6`
        },
    },
    focused: {        
        borderBottom: `1px solid #D6D6D6`,
    },
    underline: {
        height: 28,
        padding: '0 3px'
    }
}))(Input)

const PFillTextField = withStyles(() => ({
    root: {
        background: '#FFF',
        '&$focused': {
        }
    },
    focused: {},
}))(TextField)

const PNumberFormat = (props) => {
    const { inputRef, onChange, max, min, ...other } = props;

    const allowValueCap = (inputObj) => {
        const { value } = inputObj
        let result = true
        if (max !== undefined) result = result && (value <= max)
        if (min !== undefined) result = result && (value >= min)
        return result
    };

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            isNumericString
            isAllowed={allowValueCap}
        />
    );
}

export { PInput, PTextField, PNumberFormat, PFillTextField, PUnderlineTextField }
import React, { useState, useEffect, useContext } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  Typography,
  Grid,
  makeStyles,
  Box,
  FormControl,
  CircularProgress,
  MenuItem,
  Input,
  FormControlLabel
} from '@material-ui/core'
import { PButton, PIconText, PFillTextField, PSelect } from '@src/components/PCustom'
import GreenCheckbox from '@src/components/common/GreenCheckbox'
import { IconDownArrow } from '@src/components/PCustom/SVGIcons'

import { AlertUtil } from '@src/utils'
import Constants from '@src/config/Constants'
import Form941API from '../Form941API'
import { initialForm941Y2022 } from '../constant'
const colorS = Constants.COLORS
const form941API = new Form941API()

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    position: 'relative',
    flexDirection: 'column',
    padding: '20px 0 0 0',
    margin: 'auto',
    alignItems: 'center'
  },
  listBox: {
    border: `1px solid #ddd`,
    borderRadius: '4px',
    overflow: 'auto',
    maxHeight: '500px'
  },
  buttonProgress: {
    // style for button loading
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  wrapperButton: {
    position: 'relative'
  },
  selector: {
    height: '40px',
    marginRight: '12px',
    '& > div': {
      padding: '5px'
    }
  },
  textField: {
    padding: 8,
    borderRadius: 4,
    border: `1px solid #aaa`,
    fontFamily: `Ubuntu`,
    fontSize: '14px',
    width: "100%",
    '&[readonly]': {
      background: '#eee'
    }
  },
  table: {
    width: '100%',
    fontSize: '14px',
    marginBottom: 60,
    '& tr td': {
      padding: 4
    }
  }
}))

export default ({ storeNotification, company, quarter, onLoading }) => {
  const classes = useStyles()
  const history = useHistory()

  const [loading, setLoading] = useState(false);
  const [form941Data, setForm941Data] = useState(initialForm941Y2022)

  useEffect(() => {
    handleChangeQuarter()
  }, [company, quarter])

  const handleChangeQuarter = async (e) => {
    if (!company || !quarter) return
    onLoading(true)
    try {
      const companyResp = await form941API.getForm941Fields({ company, quarter })
      setForm941Data(companyResp.data || initialForm941Y2022)
    } catch (e) {
      const m = e.message ? e.message : e.toString()
      AlertUtil.showErrorAlert(storeNotification, m)
    }
    onLoading(false)
  }

  const handleSave = async () => {
    if (!company || !quarter) return
    try {
      await form941API.storeForm941Fields({
        company,
        quarter,
        ...form941Data
      })
      AlertUtil.showSuccessAlert(storeNotification, "Form data is stored successfully.")
    } catch (e) {
      const m = e.message ? e.message : e.toString()
      AlertUtil.showErrorAlert(storeNotification, m)
    }
  }

  const handleViewPDF = async () => {
    if (!company || !quarter) return
    setLoading(true)
    try {
      await form941API.storeForm941Fields({
        company: company,
        quarter: quarter,
        ...form941Data
      })

      const pdfResp = await form941API.exportPDF({
        company: company,
        form_type: '941',
        quarter: quarter
      })

      const pdfURL = pdfResp.data.name || ''
      if (pdfURL) {
        window.open(`${Constants.API_URL}/storage/pdf?path=${pdfURL}`, "_blank");
      }

    } catch (e) {
      const m = e.message ? e.message : e.toString()
      AlertUtil.showErrorAlert(storeNotification, m)
    }
    setLoading(false)
  }

  const handleFormData = (key, value) => {
    form941Data[key] = value

    form941Data['line5a_col2'] = (Number(form941Data['line5a_col1']) || 0) * 0.124
    form941Data['line5ai_col2'] = (Number(form941Data['line5ai_col1']) || 0) * 0.062
    form941Data['line5aii_col2'] = (Number(form941Data['line5aii_col1']) || 0) * 0.062
    form941Data['line5b_col2'] = (Number(form941Data['line5b_col1']) || 0) * 0.124
    form941Data['line5c_col2'] = (Number(form941Data['line5c_col1']) || 0) * 0.029
    form941Data['line5d_col2'] = (Number(form941Data['line5d_col1']) || 0) * 0.009

    form941Data['line5e'] = (Number(form941Data['line5a_col2']) || 0) + (Number(form941Data['line5ai_col2']) || 0) + (Number(form941Data['line5aii_col2']) || 0) + (Number(form941Data['line5b_col2']) || 0) + (Number(form941Data['line5c_col2']) || 0) + (Number(form941Data['line5d_col2']) || 0)

    form941Data['line6'] = (Number(form941Data['line3']) || 0) + (Number(form941Data['line5e']) || 0)
    form941Data['line10'] = (Number(form941Data['line6']) || 0)
    form941Data['line11g'] = (Number(form941Data['line11a']) || 0) + (Number(form941Data['line11b']) || 0)
    form941Data['line12'] = (Number(form941Data['line10']) || 0) - (Number(form941Data['line11g']) || 0)
    form941Data['line13g'] = (Number(form941Data['line13a']) || 0)
    form941Data['line14'] = (Number(form941Data['line12']) || 0) >= (Number(form941Data['line13g']) || 0) ? (Number(form941Data['line12']) || 0) - (Number(form941Data['line13g']) || 0) : 0

    form941Data['line15'] = (Number(form941Data['line13g']) || 0) >= (Number(form941Data['line12']) || 0) ? (Number(form941Data['line13g']) || 0) - (Number(form941Data['line12']) || 0) : 0

    form941Data['line16_month_4'] = (Number(form941Data['line16_month_1']) || 0) + (Number(form941Data['line16_month_2']) || 0) + (Number(form941Data['line16_month_3']) || 0)
    setForm941Data({ ...form941Data })
  }

  return (
    <div>
      <Box display="flex" justifyContent="space-between" alignItems="center" className="mb-24">
        <Typography variant="h4">Form 941 for 2022</Typography>
        <Box display="flex">
          <div className={`${classes.wrapperButton} mr-12`}>
            <PButton variant="outlined" color="primary" className="" onClick={handleSave} disabled={!company || !quarter}>Save</PButton>
          </div>

          <div className={classes.wrapperButton}>
            <PButton variant="contained" color="primary" className="" onClick={handleViewPDF} disabled={!company || !quarter}>View PDF {quarter}</PButton>
            {loading && (
              <CircularProgress
                size={24}
                className={classes.buttonProgress}
              />
            )}
          </div>
        </Box>
      </Box>
      <table className={classes.table}>
        <thead>
          <tr>
            <td><b>Line Name</b></td>
            <td><b>Column 1</b></td>
            <td><b>Column 2</b></td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Line 1</td><td></td><td></td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.line1} onChange={(e) => handleFormData('line1', e.target.value)} />
            </td>
          </tr>
          <tr>
            <td>Line 2</td><td></td><td></td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.line2} onChange={(e) => handleFormData('line2', e.target.value)} />
            </td>
          </tr>
          <tr>
            <td>Line 3</td><td></td><td></td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.line3} onChange={(e) => handleFormData('line3', e.target.value)} />
            </td>
          </tr>

          <tr>
            <td>Line 5a</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.line5a_col1} onChange={(e) => handleFormData('line5a_col1', e.target.value)} />
            </td>
            <td>
              <input type="number" className={classes.textField} readOnly="true" value={form941Data.line5a_col2} />
            </td><td></td>
          </tr>
          <tr>
            <td>{`Line 5a(i)`}</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.line5ai_col1} onChange={(e) => handleFormData('line5ai_col1', e.target.value)} />
            </td>
            <td>
              <input type="number" className={classes.textField} readOnly="true" value={form941Data.line5ai_col2} />
            </td><td></td>
          </tr>
          <tr>
            <td>{`Line 5a(ii)`}</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.line5aii_col1} onChange={(e) => handleFormData('line5aii_col1', e.target.value)} />
            </td>
            <td>
              <input type="number" className={classes.textField} readOnly="true" value={form941Data.line5aii_col2} />
            </td><td></td>
          </tr>
          <tr>
            <td>Line 5b</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.line5b_col1} onChange={(e) => handleFormData('line5b_col1', e.target.value)} />
            </td>
            <td>
              <input type="number" className={classes.textField} readOnly="true" value={form941Data.line5b_col2} />
            </td><td></td>
          </tr>
          <tr>
            <td>Line 5c</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.line5c_col1} onChange={(e) => handleFormData('line5c_col1', e.target.value)} />
            </td>
            <td>
              <input type="number" className={classes.textField} readOnly="true" value={form941Data.line5c_col2} />
            </td><td></td>
          </tr>
          <tr>
            <td>Line 5d</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.line5d_col1} onChange={(e) => handleFormData('line5d_col1', e.target.value)} />
            </td>
            <td>
              <input type="number" className={classes.textField} readOnly="true" value={form941Data.line5d_col2} />
            </td><td></td>
          </tr>
          <tr>
            <td>Line 5e</td>
            <td></td><td></td><td>
              <input type="number" className={classes.textField} readOnly="true" value={form941Data.line5e} />
            </td>
          </tr>

          <tr>
            <td>Line 6</td><td></td><td></td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.line6} onChange={(e) => handleFormData('line6', e.target.value)} readOnly={true} />
            </td>
          </tr>
          <tr>
            <td>Line 7</td><td></td><td></td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.line7} onChange={(e) => handleFormData('line7', e.target.value)} />
            </td>
          </tr>
          <tr>
            <td>Line 10</td><td></td><td></td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.line10} readOnly="true" />
            </td>
          </tr>
          <tr>
            <td>Line 11a</td><td></td><td></td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.line11a} onChange={(e) => handleFormData('line11a', e.target.value)} />
            </td>
          </tr>
          <tr>
            <td>Line 11g</td><td></td><td></td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.line11g} readOnly="true" />
            </td>
          </tr>
          <tr>
            <td>Line 12</td><td></td><td></td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.line12} readOnly="true" />
            </td>
          </tr>
          <tr>
            <td>Line 13a</td><td></td><td></td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.line13a} onChange={(e) => handleFormData('line13a', e.target.value)} />
            </td>
          </tr>
          <tr>
            <td>Line 13g</td><td></td><td></td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.line13g} readOnly="true" />
            </td>
          </tr>
          <tr>
            <td>Line 14</td><td></td><td></td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.line14} readOnly="true" />
            </td>
          </tr>
          <tr>
            <td>Line 15</td>
            <td><input type="number" className={classes.textField} value={form941Data.line15} readOnly="true" /></td>
            <td>
              <FormControlLabel
                className={classes.checkBox}
                control={<GreenCheckbox checked={form941Data.line15_chk_0} onChange={(e) => handleFormData('line15_chk_0', e.target.checked)} />}
                label='Apply to next return'
              />
            </td>
            <td>
              <FormControlLabel
                className={classes.checkBox}
                control={<GreenCheckbox checked={form941Data.line15_chk_1} onChange={(e) => handleFormData('line15_chk_1', e.target.checked)} />}
                label='Send a refund'
              />
            </td>
          </tr>
          <tr>
            <td>Line 16</td>
            <td>
              <FormControlLabel
                className={classes.checkBox}
                control={<GreenCheckbox checked={form941Data.line16_chk_0} onChange={(e) => handleFormData('line16_chk_0', e.target.checked)} />}
                label='Line 12 on this return is less than $2,500...'
              />
            </td><td></td><td></td>
          </tr>
          <tr>
            <td></td>
            <td>
              <FormControlLabel
                className={classes.checkBox}
                control={<GreenCheckbox checked={form941Data.line16_chk_1} onChange={(e) => handleFormData('line16_chk_1', e.target.checked)} />}
                label='You were a monthly schedule depositor...'
              />
            </td><td></td><td></td>
          </tr>
          <tr>
            <td></td><td></td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.line16_month_1} onChange={(e) => handleFormData('line16_month_1', e.target.value)} placeholder="Month 1" />
            </td><td></td>
          </tr>
          <tr>
            <td></td><td></td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.line16_month_2} onChange={(e) => handleFormData('line16_month_2', e.target.value)} placeholder="Month 2" />
            </td><td></td>
          </tr>
          <tr>
            <td></td><td></td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.line16_month_3} onChange={(e) => handleFormData('line16_month_3', e.target.value)} placeholder="Month 3" />
            </td><td></td>
          </tr>
          <tr>
            <td></td><td></td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.line16_month_4} readOnly="true" placeholder="Total Month" />
            </td><td></td>
          </tr>

        </tbody>
      </table>
    </div>
  )
}


import React from 'react'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'

import {
  makeStyles
} from '@material-ui/core/styles'
import DoneIcon from '../../assets/img/i-done-circle.svg'

const useStyles = makeStyles(theme => ({
  btn: {
    padding: '10px 16px',
    minWidth: '56px',
    backgroundColor: theme.palette.common.sGrey20,
    borderRadius: '24px',
    border: 'none',
    '&:hover': {
      opacity: 0.8,
      backgroundColor: theme.palette.common.sGrey20
    }
  },
  btnActive: {
    border: '1px solid ' + theme.palette.common.sPurple,
    backgroundColor: theme.palette.common.sWhite,
    '&:hover': {
      opacity: 0.8,
      backgroundColor: theme.palette.common.sWhite
    }
  },
  text: {
    color: theme.palette.common.sBlack
  },
  switch: {
    position: 'relative',
    display: 'inline-block',
    width: '28px',
    height: '8px',

    '& $input': {
      opacity: 0,
      width: 0,
      height: 0
    }
  },
  slider: {
    position: 'absolute',
    zIndex: 1,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: '#d0dbff',
    borderRadius: '34px',
    transition: '.4s'
  },
  round: {
    position: 'absolute',
    zIndex: 2,
    left: 0,
    bottom: '-4px',
    content: '',
    height: '16px',
    width: '16px',
    boxSizing: 'border-box',
    background: '#FFFFFF',
    border: '1px solid ' + theme.palette.common.sGrey20,
    borderRadius: '50%',
    transition: '.4s'
  },
  input: {
    '&:focus': {
      '&+$round': {
        boxShadow: '0 0 1px #2196F3'
      }
    },
    '&:checked': {
      '&+$round': {
        backgroundColor: '#7092FE',
        backgroundImage: `url(${DoneIcon})`,
        border: 'none',
        transform: 'translateX(12px)'
      }
    }
  }
}))

export default (props) => {
  const classes = useStyles()

  const handleChange = () => {

  }

  return (
    <>

      <Button
        className={`${classes.btn} ${props.active ? classes.btnActive : ''}`}
        onClick={props.onClick}
        variant='outlined'
        color='default'
      >
        <Box component='span' fontSize='1.4rem' className={classes.text}>{props.text}</Box>&nbsp;&nbsp;
        <label className={classes.switch}>
          <input type='checkbox' checked onChange={handleChange} className={classes.input} />
          <span className={classes.round} />
          <span className={classes.slider} />
        </label>
      </Button>

    </>
  )
}

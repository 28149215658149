import Base80API from '../../api/Base80API'
import Constants from '../../config/Constants'

class CompanyAPI extends Base80API {
    async getCompanies(params) {
        try {
            const url = `${Constants.API_URL}/company`
            return await this.get(url, params)
        } catch (err) {
            console.log(err)
            throw err
        }
    }

    async addCompany(body) {
        try {
            const url = `${Constants.API_URL}/company`
            return await this.post(url, body)
        } catch (err) {
            console.log(err)
            throw err
        }
    }

    async updateCompany(companyId, body) {
        try {
            const url = `${Constants.API_URL}/company/${companyId}`
            return await this.put(url, body)
        } catch (err) {
            console.log(err)
            throw err
        }
    }

    async deleteCompany(companyId) {
        try {
            const url = `${Constants.API_URL}/company/${companyId}`
            return await this.delete(url, {})
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

export default CompanyAPI

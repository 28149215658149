import {
  emptyTextCell,
  nonEditable,
  textCell,
  dateCell,
  monthHeaderCell,
  bottomLine,
  numberCell,
  showZero,
  hideZero,
  noSideBorders,
  dropdownCell,
  toolTipCell
} from "./Cells";

import { months } from "./Helpers";

export const HEADER_ROW_ID = "header";
export const SUBHEADER_ROW_ID = "sub_header";
export const BLANK_ROW_ID = "blank";
export const TOTAL_ROW_ID = "total";

export const DATE_FEE_RECEIVED_ROW_ID = "date_fee_received_field";
export const DATE_INVOICE_RECEIVED_ROW_ID = "date_invoice_received_field";
export const TOTAL_FEE_RECEIVED_ROW_ID = "total_fee_received_field";

export const ATTACH_ROW_ID = "attach";
export const NOTE_ID = "note_";
export const NOTE_2_ID = "note_2";
export const DATE_FORM_941X_ROW_ID = "date_form_941x_row_id";

export const CASHBOXBANK_ROW_ID = "cashboxBank";
export const CREDITLINE_ROW_ID = "creditLine";
export const CREDITLINEOVERDRAFT_ROW_ID = "creditLineOverdraft";
export const LIQUIDFUNDS_ROW_ID = "liquidFunds";
export const MONTHSTOTAL_ROW_ID = "monthsTotal";
export const CUMULATIVE_ROW_ID = "cumulative";

const ROW_HEIGHT = 32;
const HEADING_ROW_HEIGHT = 40;

const claimTypes = [
  { label: "Claimed", value: "claimed" },
  { label: "Advanced", value: "advanced" },
]

const sumGroupValues = (values) => {
  if (!values.length) return 0
  return values.reduce(
    (prev, curr) => (parseFloat((prev || 0).toString())) + (parseFloat((curr || 0).toString()))
  );
}

const getHeaderRow = (quarters) => {
  const cells = [
    nonEditable(textCell("Company Name", "justify-content-center", {}, 1)),
    nonEditable(textCell("Total Available", "justify-content-center", {}, 1)),
    nonEditable(textCell("Total Claim", "justify-content-center", {}, 1)),
    nonEditable(textCell("Total Refund Received", "justify-content-center", {}, 1)),
    nonEditable(textCell("Remaining Receivable", "justify-content-center", {}, 1)),
    nonEditable(textCell("Total Unclaimed", "justify-content-center", {}, 1)),
  ]

  return {
    rowId: HEADER_ROW_ID,
    height: ROW_HEIGHT,
    cells
  };
}

const getSummaryRows = (groups) => {
  return [
    ...groups.map(({ _id, name, available_amount, claim_amount, refund_received_amount, remain_amount }, index) => ({
      rowId: _id,
      height: ROW_HEIGHT,
      cells: [
        nonEditable(textCell(name, "", {}, 1)),
        nonEditable(numberCell(available_amount)),
        nonEditable(numberCell(claim_amount)),
        nonEditable(numberCell(refund_received_amount)),
        nonEditable(numberCell(remain_amount)),
        nonEditable(numberCell(available_amount - claim_amount)),
      ]
    })),
  ];
}

export const getRows = (summaryLines) => {
  return [
    getHeaderRow(),
    ...getSummaryRows(summaryLines),
  ];
}

import { HTTP } from '../utils/HTTPCommon'
import Constants from '../config/Constants'

class Base80API {
  constructor(includeToken) {
    if (includeToken === null || includeToken === undefined) {
      this.includeToken = true
    } else {
      this.includeToken = false
    }
    this.HTTP = HTTP
  }

  /**
   * Post data to server api
   *
   * @param {String} url Path of URL string example /users/
   * @param {Object} data Data send to server api
   * @param {Object} headers Headers to include in request
   */
  post(url, data, headers) {
    return this.request('post', url, data, null, headers)
  }

  /**
   * Get data from server api
   *
   * @param {String} url Path of URL string example /users/
   * @param {Object} params Parameters append in the request
   * @param {Object} headers Headers to include in request
   */
  get(url, params, headers) {
    return this.request('get', url, null, params, headers)
  }

  /**
   * Delete data on server api
   *
   * @param {String} url Path of URL string example /users/
   * @param {Object} data Data send to server api
   * @param {Object} params Parameters append in the request
   * @param {Object} headers Headers to include in request
   */
  delete(url, data, params, headers) {
    return this.request('delete', url, data, params, headers)
  }

  /**
   * Put data to server api
   *
   * @param {String} url Path of URL string example /users/
   * @param {Object} data Data send to server api
   * @param {Object} headers Headers to include in request
   */
  put(url, data, headers) {
    return this.request('put', url, data, null, headers)
  }

  /**
   * Patch data to server api
   *
   * @param {String} url Path of URL string example /users/
   * @param {Object} data Data send to server api
   * @param {Object} headers Headers to include in request
   */
  patch(url, data, headers) {
    return this.request('patch', url, data, null, headers)
  }

  /**
   * General method to make request to server api
   *
   * @param {String} method HTTP method
   * @param {String} url Path of URL string example /users/
   * @param {Object} data Data send to server api
   * @param {Object} params Parameters append in the request
   * @param {Object} headers Headers to include in request
   */
  request(method, url, data, params, headers) {
    const promise = new Promise((resolve, reject) => {
      // Get access token from storage
      if (this.includeToken) {
        if (window.localStorage.getItem('currentPtUser') !== null) {
          const u = JSON.parse(window.localStorage.getItem('currentPtUser'))
          const accessToken = u.access_token
          if (accessToken) {
            headers = headers != null ? headers : {}
            headers.Authorization = `Bearer ${accessToken}`
          }
        }
      }

      this.HTTP.request({
        url: url,
        method: method,
        data: data,
        params: params,
        headers: headers
      })
        .then(response => {
          const data = response.data
          resolve(data)
        })
        .catch(error => {
          let errorMessage = Constants.HTTP_MESSAGE.connection_error
          // Error
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            const data = error.response.data
            if (error.response.status == 401){
              window.localStorage.removeItem('currentPtUser')
              window.localStorage.removeItem('currentUser')
              window.location.href="/"
            } else {
              if (data.error) {
                errorMessage = data.error.message
              } else if (data.errors && data.errors.length > 0) {
                errorMessage = data.errors[0].msg || data.errors[0].message
              } else {
                errorMessage = data.message
              }
            }
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the
            // browser and an instance of
            // http.ClientRequest in node.js
            console.log('error.request')
            console.log(error.request)
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error')
            console.log('Error', error.message)
          }

          // if (error.response) {
          //   const data = error.response.data
          //   if (data.error) {
          //     errorMessage = data.error.message
          //   }
          // }
          reject(new Error(errorMessage))
        })
    })
    return promise
  }
}

export default Base80API

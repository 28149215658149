import React from 'react'
import { Route } from 'react-router-dom'
import AuthenticateUtil from '../../utils/AuthenticateUtil'

function PrivateRoute ({ component: Component, ...rest }) {
  const isAuthenticated = AuthenticateUtil.isAuthenticated()
  console.log(`isAuthenticate: ${isAuthenticated}`)
  return (
    <Route
      {...rest}
      render={props => (
        <Component {...props} />
      )}
    />
  )
}

export default PrivateRoute

import React, { useEffect, useState } from 'react'
import { ThemeProvider } from '@material-ui/styles'

import Constants from '@src/config/Constants'

import { Route, Switch, BrowserRouter } from 'react-router-dom'
import PrivateRoute from './components/PrivateRoute/PrivateRoute'
import PublicRoute from './components/PublicRoute/PublicRoute'

import CssBaseline from '@material-ui/core/CssBaseline'

import './UtilClasses.scss'
import './Common.scss'
import './App.scss'
import 'primeicons/primeicons.css'
import BuilddieTheme from './BuilddieTheme'

import MainAuthPublic from './views/Main/MainAuthPublic'

import Tracker from './views/Tracker'
import Company from './views/Company'
import Forms from './views/Forms'
import Billing from './views/Billing'
import Summary from './views/Summary'

const App = () => {
  return (
    <ThemeProvider theme={BuilddieTheme.GlobalTheme}>
      <ThemeProvider theme={BuilddieTheme.Theme}>
        {/* <InitAuthProvider> */}
          <BrowserRouter>
            <div className='app-conatiner'>
              <CssBaseline />
              <Switch>
                <PublicRoute path='/auth' component={MainAuthPublic} />
                <PrivateRoute path='/summary' component={Summary} />
                <PrivateRoute path='/tracker' component={Tracker} />
                <PrivateRoute path='/company' component={Company} />
                <PrivateRoute path='/forms' component={Forms} />
                <PrivateRoute path='/billing' component={Billing} />
                <PrivateRoute path='/' component={Tracker} />
              </Switch>
            </div>
          </BrowserRouter>
        {/* </InitAuthProvider> */}
      </ThemeProvider>
    </ThemeProvider>
  )
}

export default App

import React, { useState, useEffect, useContext } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
    Typography,
    Grid,
    makeStyles,
    Box,
    List,
    ListItem,
    ListItemText,

} from '@material-ui/core'
import { PButton, PIconText, PFillTextField } from '@src/components/PCustom'

import { AlertUtil } from '@src/utils'
import ENUMS from '@src/config/Enums'
import Constants from '@src/config/Constants'
import CompanyAPI from '../CompanyAPI'
const colorS = Constants.COLORS
const companyAPI = new CompanyAPI()

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        width: '100%',
        position: 'relative',
        flexDirection: 'column',
        padding: '20px 0 0 0',
        margin: 'auto',
        alignItems: 'center'
    },
    listBox: {
        border: `1px solid #ddd`,
        borderRadius: '4px',
        overflow: 'auto',
        maxHeight: '500px'
    }
}))

export default ({ storeNotification }) => {
    const classes = useStyles()
    const history = useHistory()

    const [selectedCompany, setSelectedCompany] = useState('')
    const [companies, setCompanies] = useState([]);

    const [name, setName] = useState('')
    const [trade_name, setTradeName] = useState('')
    const [ein, setEIN] = useState('')
    const [email, setEmail] = useState('')
    const [income_tax_filed, setIncomeTaxFiled] = useState('')
    const [address, setAddress] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [zipcode, setZipcode] = useState('')
    const [business_start_date, setBusinessStartSate] = useState('')
    const [first_income_year, setFirstIncomeYear] = useState('')

    useEffect(() => {
        loadData()
    }, [])

    const loadData = async () => {
        try {
            const companyResp = await companyAPI.getCompanies()
            setCompanies(companyResp.data)
        } catch (e) {
            const m = e.message ? e.message : e.toString()
            AlertUtil.showErrorAlert(storeNotification, m)
        }
    }

    const handleCompanyItemClick = (event, id) => {
        setSelectedCompany(id);
        const company = companies.find(elem => elem._id == id)
        setName(company.name || '')
        setEmail(company.email || '')
        setEIN(company.ein || '')
        setIncomeTaxFiled(company.income_tax_filed || '')
        setAddress(company.address || '')
        setCity(company.city || '')
        setState(company.state || '')
        setZipcode(company.zipcode || '')
        setTradeName(company.trade_name || '')
        setBusinessStartSate(company.business_start_date || '')
        setFirstIncomeYear(company.first_income_year || '')
    }

    const handleAddCompany = () => {
        setSelectedCompany('')
        setName('')
        setEmail('')
        setEIN('')
        setAddress('')
        setCity('')
        setState('')
        setZipcode('')
        setTradeName('')
        setBusinessStartSate('')
        setFirstIncomeYear('')
    }

    const handleSaveCompany = async () => {
        try {
            const request = {
                name, email, ein, address, income_tax_filed, city, state, zipcode, trade_name,
                business_start_date, first_income_year
            }

            if (selectedCompany) {
                await companyAPI.updateCompany(selectedCompany, request)
            } else {
                const resp = await companyAPI.addCompany(request)
                setSelectedCompany(resp.data._id)
            }
            await loadData()
            AlertUtil.showSuccessAlert(storeNotification, "Company is stored.")
        } catch (e) {
            const m = e.message ? e.message : e.toString()
            AlertUtil.showErrorAlert(storeNotification, m)
        }
    }

    const handleRemoveCompany = async () => {
        try {
            if (selectedCompany) {
                if (window.confirm("Are you sure to remove company?")) {
                    await companyAPI.deleteCompany(selectedCompany)
                    await loadData()
                    handleAddCompany()
                    AlertUtil.showSuccessAlert(storeNotification, "Company is removed.")
                }
            }
        } catch (e) {
            const m = e.message ? e.message : e.toString()
            AlertUtil.showErrorAlert(storeNotification, m)
        }
    }

    return (
        <div className={classes.container}>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <Typography variant="h4" className="mb-24">Company List</Typography>
                    <div className={classes.listBox}>
                        <List component="nav">
                            {(companies || []).map(item => (
                                <ListItem
                                    button
                                    selected={selectedCompany === item._id}
                                    onClick={(event) => handleCompanyItemClick(event, item._id)}
                                >
                                    <ListItemText primary={item.name} />
                                </ListItem>
                            ))}
                        </List>
                    </div>
                </Grid>
                <Grid item xs={9}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" className="mb-24">
                        <Typography variant="h4">Company Information</Typography>
                        <PButton variant="outlined" color="primary" onClick={handleAddCompany}>+ Add Company</PButton>
                    </Box>
                    <PFillTextField label="Company Name" variant="filled" className="mb-24 w-100"
                        value={name} onChange={(e) => setName(e.target.value)}
                    />
                    <PFillTextField label="Company Trade Name" variant="filled" className="mb-24 w-100"
                        value={trade_name} onChange={(e) => setTradeName(e.target.value)}
                    />
                    <PFillTextField label="Email" variant="filled" className="mb-24 w-100"
                        value={email} onChange={(e) => setEmail(e.target.value)}
                    />
                    <PFillTextField label="EIN" variant="filled" className="mb-24 w-100"
                        value={ein} onChange={(e) => setEIN(e.target.value)} length={9}
                        inputProps={{ maxLength: 9 }}
                    />
                    <PFillTextField label="Income Tax Return Filed" variant="filled" className="mb-24 w-100"
                        value={income_tax_filed} onChange={(e) => setIncomeTaxFiled(e.target.value)}
                    />
                    <Grid container spacing={2} className="mb-24">
                        <Grid item sm={6}>
                            <PFillTextField label="Address" variant="filled" className="w-100"
                                value={address} onChange={(e) => setAddress(e.target.value)}
                            />
                        </Grid>
                        <Grid item sm={6}>
                            <PFillTextField label="City" variant="filled" className="w-100"
                                value={city} onChange={(e) => setCity(e.target.value)}
                            />
                        </Grid>
                        <Grid item sm={6}>
                            <PFillTextField label="State" variant="filled" className="w-100"
                                value={state} onChange={(e) => setState(e.target.value)}
                            />
                        </Grid>
                        <Grid item sm={6}>
                            <PFillTextField label="Zipcode" variant="filled" className="w-100"
                                value={zipcode} onChange={(e) => setZipcode(e.target.value)}
                            />
                        </Grid>
                        <Grid item sm={6}>
                            <PFillTextField label="Business Start Date On Tax Return" variant="filled" className="w-100"
                                value={business_start_date} onChange={(e) => setBusinessStartSate(e.target.value)}
                            />
                        </Grid>
                        <Grid item sm={6}>
                            <PFillTextField label="First Income Year" variant="filled" className="w-100"
                                value={first_income_year} onChange={(e) => setFirstIncomeYear(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Box display="flex" justifyContent="space-between" alignItems="center" className="mb-24">
                        <PButton variant="outlined" color="primary" onClick={handleRemoveCompany}>Remove Company</PButton>
                        <PButton variant="contained" color="primary" onClick={handleSaveCompany}>Save Company</PButton>
                    </Box>
                </Grid>
            </Grid>
        </div>
    )
}

import React, { useState } from 'react'
import Chip from '@material-ui/core/Chip'

import { makeStyles } from '@material-ui/core/styles'

import IconAdd from '@src/assets/img/i-add-circle-blue.svg'
import IconMinus from '@src/assets/img/i-minus-circle-white.svg'

const useStyles = makeStyles((theme) => ({
  clickable: {},
  root: {
    height: '48px',
    padding: '14px 16px',
    backgroundColor: theme.palette.common.sWhite,
    border: '1px solid #D6D6D6',
    borderRadius: '32px',
    '&:hover, &:focus': {
      backgroundColor: theme.palette.common.sWhite
    },
    '&:active': {
      backgroundColor: theme.palette.common.sWhite
    },
    '& $avatar': {
      width: 16,
      height: 16,
      marginLeft: 0
    }
  },
  rootActive: {
    backgroundColor: theme.palette.common.sPurple,
    border: `1px solid ${theme.palette.common.sPurple}`,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.common.sPurple
    },
    '&:active': {
      backgroundColor: theme.palette.common.sPurple
    },
    '& $label': {
      color: theme.palette.common.sWhite
    }
  },
  label: {
    color: theme.palette.common.sBlack
  },
  avatar: {}
}))

export default React.memo(
  ({
    className,
    avatar = false,
    label = '',
    active = false,
    disabled = false,
    onClick
  }) => {
    const classes = useStyles()

    return (
      <Chip
        className={className}
        classes={{
          root: `${classes.root} ${active ? classes.rootActive : ''}`,
          clickable: classes.clickable,
          label: classes.label,
          avatar: classes.avatar
        }}
        size='medium'
        label={label}
        color='default'
        disabled={disabled}
        clickable
        onClick={onClick}
        avatar={
          avatar ? (
            active ? (
              <img src={IconMinus} alt='' />
            ) : (
              <img src={IconAdd} alt='' />
            )
          ) : (
            false
        )
        }
      />
    )
  }
)

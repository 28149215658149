import React from 'react'
import { TextField, Typography, Box } from '@material-ui/core'

import InputAdornment from '@material-ui/core/InputAdornment'
import UseStyles from './styles'
import { green } from '@material-ui/core/colors'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import HelperInfo from '@src/components/common/HelperInfo'

const useStyles = UseStyles

function TextFieldV2(props) {
  const classes = useStyles()
  const {
    touched,
    errors,
    values,
    textKey,
    label,
    showCheckIcon,
    showSuggestion = false
  } = props
  const isError = (key) => (errors[key] && touched[key] ? true : false)
  const isHaveValue = (key) =>
    values[key] && touched[key] && values[key].length > 0 ? true : false
  const getLabelText = (key, defaultText) =>
    isError(key) ? errors[key] : defaultText

  const inputProps = !showSuggestion
    ? {
        className: classes.input,
        endAdornment: showCheckIcon &&
          !isError(textKey) &&
          isHaveValue(textKey) && (
            <InputAdornment
              position='start'
              style={{ marginTop: 0, marginRight: 0 }}
            >
              <CheckCircleIcon style={{ color: green[500] }} />
            </InputAdornment>
          )
      }
    : {
        endAdornment: (
          <HelperInfo infoColor={'#f44336'} opened={showSuggestion}>
            <Typography>
              <Box fontSize='1.4rem'>Rules for password security</Box>
            </Typography>
            <Typography>
              <Box fontSize='1.1rem' mt={1}>
                - Min 8 characters
              </Box>
            </Typography>
            <Typography>
              <Box fontSize='1.1rem' mt={1}>
                - Upper case characters (A-Z)
              </Box>
            </Typography>
            <Typography>
              <Box fontSize='1.1rem' mt={1}>
                - Lower case characters (a-z)
              </Box>
            </Typography>
            <Typography>
              <Box fontSize='1.1rem' mt={1}>
                - Numbers (0-9)
              </Box>
            </Typography>
            <Typography>
              <Box fontSize='1.1rem' mt={1}>
                - One special character
              </Box>
            </Typography>
          </HelperInfo>
        )
      }

  return (
    <TextField
      error={isError(textKey)}
      InputProps={inputProps}
      InputLabelProps={{
        className: classes.inputLabel
      }}
      {...props}
      label={getLabelText(textKey, label)}
    />
  )
}

function TextFieldNonValidate(props) {
  const classes = useStyles()
  return (
    <TextField
      InputProps={{
        className: classes.input,
        classes: {
          focused: classes.focused
        }
      }}
      InputLabelProps={{
        className: classes.inputLabel
      }}
      {...props}
    />
  )
}
export { TextFieldNonValidate }

export default TextFieldV2

import React, { useState, useEffect } from 'react'
import {
    withStyles,
    makeStyles,
    ListItemText,
    ListItemAvatar,
    Avatar,
    ClickAwayListener,
    InputAdornment,
    Typography,
    IconButton,
    Box
} from '@material-ui/core'
import { PButton, PFillToggleButton, PInput, PPurpleCheckbox } from './index'
import ApartmentIcon from '@material-ui/icons/Apartment'
import { IconCaretDown } from './SVGIcons'
import IconDownArrowSVG from '@src/assets/img/app/icon-down-arrow-grey.svg'
import IconMenuDownArrowSVG from '@src/assets/img/app/menu/icon-menu-down-arrow.svg'
import IconSearchSVG from '@src/assets/img/app/reports/icon-search.svg'

import Constants from '@src/config/Constants'
const colorS = Constants.COLORS

const IconDownArrow = (props) => {
    return (<img src={IconDownArrowSVG} {...props} />)
}

const IconSearch = (props) => {
    return (<img src={IconSearchSVG} {...props} />)
}

const badgeAvatarStyels = makeStyles((theme) => ({
    container: {
        position: 'relative',
        width: 48,
        height: 48,
    },
    image: {
        borderRadius: '50%',
        width: '100%',
        height: '100%'
    },
    badge: {
        position: 'absolute',
        bottom: 0,
        right: 0
    },
}))

const PBadgeAvatar = ({ image, status, className, badgeSet }) => {
    const classes = badgeAvatarStyels()
    return (
        <div className={`${className} ${classes.container}`}>
            <img src={image} className={classes.image} />
            {badgeSet[status] && <img src={badgeSet[status]} className={classes.badge} />}
        </div>
    )
}

const PListItemText = withStyles(() => ({
    root: {
    },
    secondary: {
        fontSize: 12,
        color: colorS.sGrey
    },
}))(ListItemText)


const PersonalItemText = withStyles(() => ({
    root: {
        whiteSpace: 'nowrap'
    },
    secondary: {
        fontSize: 14,
        color: colorS.sGrey
    },
}))(ListItemText)

const itemStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        position: 'relative',
        boxSizing: 'border-box',
        textAlign: 'left',
        alignItems: 'center',
        justifyContent: 'flex-start',
        textDecoration: 'none',
        '&$noRounded > div > div': {
            borderRadius: 0
        }
    },
    iconWrap: {
        width: 56,
        height: 56,
        marginRight: 16,
        borderRadius: 8,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    noRounded: {}
}))

const PPersonItem = ({ avatar, className, primary, secondary, rounded = true }) => {
    const classes = itemStyles()
    return (
        <div className={`${className ? className : ''} ${classes.container} ${rounded ? '' : classes.noRounded}`}>
            <ListItemAvatar><Avatar src={avatar} /></ListItemAvatar>
            <PersonalItemText primary={primary} secondary={secondary} />
        </div>
    )
}

const PImageItem = ({ image, className, primary, secondary, onClick }) => {
    const classes = itemStyles()
    return (
        <div className={`${className ? className : ''} ${classes.container}`} onClick={onClick}>
            <img src={image}/>
            <PersonalItemText primary={primary} secondary={secondary} className="ml-24"/>
        </div>
    )
}

const PIconText = ({ icon: Comp, text, style, className, textClass }) => {
    const classes = itemStyles()
    return (
        <div className={`${className || ''} ${classes.container}`} style={style}>
            <Comp className="mr-10" />
            <PersonalItemText primary={text} classes={{ root: textClass }} />
        </div>
    )
}

const StatisticItemText = withStyles(() => ({
    secondary: {
        fontSize: 16,
        color: colorS.sBlack
    },
}))(ListItemText)

const PStatisticsItem = ({ icon, primaryColor, className, label, value }) => {
    const classes = itemStyles()
    return (
        <div className={`${className ? className : ''} ${classes.container}`}>
            <div className={classes.iconWrap} style={{ background: primaryColor }}><img src={icon} /></div>
            <StatisticItemText primary={label} secondary={value} />
        </div>
    )
}

const limitSelectorStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
        display: 'inline-flex',
    },
    optionsBox: {
        position: 'absolute',
        background: colorS.sWhite,
        padding: '16px',
        boxShadow: '0px 11px 15px rgba(0, 0, 0, 0.04), 0px 24px 38px rgba(0, 0, 0, 0.04), 0px 9px 46px rgba(0, 0, 0, 0.02)',
        borderRadius: '8px',
        width: 320,
        top: 32,
        right: 0,
        zIndex: 10
    },
    optionsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        margin: -4
    },
    toggleButton: {
        margin: '4px !important',
        minWidth: 80
    }
}))

const PLimitSelector = ({ className, label, values = [], options = [], description, onChange }) => {
    const classes = limitSelectorStyles()
    const [toggleValues, setToggleValues] = useState([])
    const [valuesText, setValuesText] = useState("-")
    const [open, setOpen] = useState(false)

    useEffect(() => {
        const toggleOptions = options.map(item => {
            return { ...item, selected: values.includes(item.value) }
        })
        setToggleValues(toggleOptions)
        setValuesText(values.join(","))
    }, [])

    const handleSelector = () => {
        setOpen(!open)
    }

    const handleChange = (index) => {
        const oldValues = toggleValues.filter(elem => elem.selected)
        if (oldValues.length < 3 || (oldValues.length == 3 && toggleValues[index].selected)) {
            toggleValues[index].selected = !toggleValues[index].selected
            setToggleValues(toggleValues)
            const newValues = toggleValues.filter(elem => elem.selected).map(item => item.value)
            setValuesText(newValues.join(","))
            if (onChange) onChange(newValues)
        }
    }

    const handleClickAway = () => {
        setOpen(false)
    }

    return (
        <ClickAwayListener
            mouseEvent="onMouseDown"
            touchEvent="onTouchStart"
            onClickAway={handleClickAway}
        >

            <div className={`${className ? className : ''} ${classes.container}`}>
                <div className={classes.selectorWrap}>
                    <Typography component="span">{`${label ? label + " : " : ''}`}</Typography>
                    <PButton
                        disableRipple
                        color="secondary"
                        endIcon={<IconCaretDown />}
                        onClick={handleSelector}
                        style={{ padding: '3px 6px' }}
                    >
                        {valuesText ? valuesText : '-'}
                    </PButton>
                </div>
                {open &&
                    <div className={classes.optionsBox}>
                        <Typography component="span" className="mb-16" style={{ display: 'block' }}>{description}</Typography>
                        <div className={classes.optionsContainer}>
                            {toggleValues.map((item, index) => (
                                <PFillToggleButton
                                    key={index}
                                    value="check"
                                    selected={item.selected}
                                    onChange={(e) => handleChange(index)}
                                    className={classes.toggleButton}
                                >
                                    {item.label}
                                </PFillToggleButton>
                            ))}
                        </div>
                    </div>
                }
            </div>
        </ClickAwayListener>
    )
}

const multiBuildingDropdownStyles = makeStyles((theme) => ({
    container: {
        height: 56,
        minWidth: '380px',
        borderRadius: 8,
        background: colorS.sBlueAccent3,
        fontSize: '14px',
        border: `1px solid ${colorS.sBlueAccent3}`,
        cursor: 'pointer',
        position: 'relative',
        display: 'inline-flex',
        alignItems: 'center',
        padding: 0,
        '&:focus': {
            backgroundColor: 'transparent'
        },
        '& > img': {
            position: 'absolute',
            right: '12px',
            top: 'calc(50% - 3px)',
        },
        '&$opened': {
            background: colorS.sWhite,
            borderColor: colorS.sPurple,
        }
    },
    opened: {},
    placeholder: { color: colorS.sGrey },
    dropdownBox: {
        position: 'absolute',
        background: colorS.sWhite,
        padding: 0,
        boxShadow: '0px 11px 15px rgba(0, 0, 0, 0.04), 0px 24px 38px rgba(0, 0, 0, 0.04), 0px 9px 46px rgba(0, 0, 0, 0.02)',
        border: `1px solid ${colorS.sWhite}`,
        borderRadius: '8px',
        width: '100%',
        top: 64,
        left: 0,
        zIndex: 10
    },
    blueInputRoot: {
        borderColor: colorS.sBlueAccent3,
        background: colorS.sBlueAccent3,
        padding: '16px 18px',
        height: 56,
        width: '100%',
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
    },
    dropdownLabelBox: {
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'inline-flex',
        alignItems: 'center',
        padding: 16,
    },
    dropdownLabel: {
        display: 'block',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        flex: '1 1 auto',
        width: '1%',
        textOverflow: 'ellipsis',
        cursor: 'pointer',
        height: '16px',
        fontSize: '14px',
        lineHeight: '16px'
    },
    buildingRowWrap: {
        padding: '8px 16px 8px 8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        '&:hover': {
            background: '#F7F7FA'
        }
    },
    buildingThumb: {
        width: '70px',
        height: '40px',
        borderRadius: '4px',
        overflow: 'hidden',
        backgroundPosition: '50% 50%',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        flexShrink: '0',
        position: 'relative',
        textAlign: 'center',
        '& > img': {
            height: '40px',
            maxWidth: '70px',
            borderRadius: '4px',
            width: '100%',
        }
    },
    buildingBlankThumb: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: colorS.sGrey
    },
    buildingText: {
        padding: '0 0 0 16px',
        display: 'block',
        margin: '0',
        width: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '220px',
        display: 'block',
    },
    buildingAddress: {
        color: '#868e92',
        fontSize: '12px',
        lineHeight: '14px',
    },
    itemListbox: {
        maxHeight: 300,
        overflow: 'auto'
    },
    simpleContainer: {
        position: 'relative',
        display: 'inline-flex',
    },
    simpleDropdownBox: {
        position: 'absolute',
        background: colorS.sWhite,
        padding: 0,
        boxShadow: '0px 11px 15px rgba(0, 0, 0, 0.04), 0px 24px 38px rgba(0, 0, 0, 0.04), 0px 9px 46px rgba(0, 0, 0, 0.02)',
        border: `1px solid ${colorS.sWhite}`,
        borderRadius: '8px',
        width: '100%',
        minWidth: 380,
        top: 32,
        right: 0,
        zIndex: 10
    },
}))

const BuidingCheckRow = ({ checked, building, onChange }) => {
    const classes = multiBuildingDropdownStyles()
    const [buidingChecked, setBuidingChecked] = useState(false)

    useEffect(() => {
        setBuidingChecked(checked ? true : false)
    }, [])

    const handleChange = (e) => {
        setBuidingChecked(e.target.checked)
        if (onChange) onChange(e.target.checked)
    }

    return (
        <div className={classes.buildingRowWrap}>
            <PPurpleCheckbox className="mr-8" checked={buidingChecked} onChange={handleChange} />
            <Typography component='div'
                className={`${classes.buildingThumb} ${building.thumb_image ? '' : classes.buildingBlankThumb}`}>
                {building.thumb_image && (<img src={building.thumb_image} />)}
                {!building.thumb_image && (<ApartmentIcon style={{ color: 'white', fontSize: 28 }} />)}
            </Typography>
            <Typography component='div' className={classes.buildingText}>
                {building.name}<br />
                <Typography component='span' className={classes.buildingAddress}>
                    {building.zipcode} {building.address1} {building.city}, {building.state}
                </Typography>
            </Typography>
        </div>
    )
}

const PMultiBuildingDropdown = ({ className, selected = [], buildings = [], onChange, onSearch, placeholder }) => {
    const classes = multiBuildingDropdownStyles()
    const [values, setValues] = useState([])
    const [label, setLabel] = useState('')
    const [open, setOpen] = useState(false)

    useEffect(() => {
        const buildingValues = buildings.map(item => {
            return { ...item, selected: selected.includes(item.id) }
        })
        setValues(buildingValues)

        let dropdownLabel = ''
        if (selected.length == 1) {
            const building = buildings.find(item => item.id == selected[0])
            dropdownLabel = building.name
        } else if (selected.length > 1) {
            dropdownLabel = `Selected ${selected.length} buildings`
        }
        setLabel(dropdownLabel)
    }, [])

    const handleClickAway = () => {
        setOpen(false)
    }

    const handleClick = () => {
        setOpen((prev) => !prev)
    }

    const handleChange = (index, building) => {
        values[index].selected = !values[index].selected
        setValues(values)
        const newValues = values.filter(elem => elem.selected).map(item => item.id)
        let dropdownLabel = ''
        if (newValues.length == 1) {
            const building = values.find(item => item.id == newValues[0])
            dropdownLabel = building.name
        } else if (newValues.length > 1) {
            dropdownLabel = `Selected ${newValues.length} buildings`
        }
        setLabel(dropdownLabel)

        if (onChange) onChange(newValues)
    }

    return (
        <ClickAwayListener
            mouseEvent="onMouseDown"
            touchEvent="onTouchStart"
            onClickAway={handleClickAway}
        >
            <div className={`${className ? className : ''} ${classes.container} ${open ? classes.opened : ''}`}>
                <div className={classes.dropdownLabelBox} onClick={handleClick}>
                    <Typography
                        component="span"
                        className={`${classes.dropdownLabel} ${label ? '' : classes.placeholder}`}>
                        {label ? label : placeholder}
                    </Typography>
                    <IconDownArrow />
                </div>
                {open ? (
                    <div className={classes.dropdownBox}>
                        <PInput
                            classes={{ root: classes.blueInputRoot }}
                            startAdornment={<InputAdornment position="start"><IconSearch /></InputAdornment>} />
                        <div className={classes.itemListbox}>
                            {values.map((item, index) => (
                                <BuidingCheckRow key={index} building={item} checked={item.selected} onChange={(checked) => handleChange(index, item)} />
                            ))}
                        </div>
                    </div>
                ) : null}
            </div>
        </ClickAwayListener>
    )
}

const PMultiBuildingSimpleDropdown = ({ className, selected = [], buildings = [], onChange, onSearch, text }) => {
    const classes = multiBuildingDropdownStyles()
    const [values, setValues] = useState([])
    const [label, setLabel] = useState('')
    const [open, setOpen] = useState(false)

    useEffect(() => {
        const buildingValues = buildings.map(item => {
            return { ...item, selected: selected.includes(item.id) }
        })
        setValues(buildingValues)

        let dropdownLabel = ''
        if (selected.length == 1) {
            const building = buildings.find(item => item.id == selected[0])
            dropdownLabel = building.name
        } else if (selected.length > 1) {
            dropdownLabel = `Selected ${selected.length} buildings`
        } else {
            dropdownLabel = `No selected`
        }
        setLabel(dropdownLabel)
    }, [])

    const handleClickAway = () => {
        setOpen(false)
    }

    const handleChange = (index, building) => {
        values[index].selected = !values[index].selected
        setValues(values)
        const newValues = values.filter(elem => elem.selected).map(item => item.id)
        let dropdownLabel = ''
        if (newValues.length == 1) {
            const building = values.find(item => item.id == newValues[0])
            dropdownLabel = building.name
        } else if (newValues.length > 1) {
            dropdownLabel = `Selected ${newValues.length}`
        } else {
            dropdownLabel = `No selected`
        }
        setLabel(dropdownLabel)

        if (onChange) onChange(newValues)
    }

    const handleSelector = () => {
        setOpen(!open)
    }

    return (
        <ClickAwayListener
            mouseEvent="onMouseDown"
            touchEvent="onTouchStart"
            onClickAway={handleClickAway}
        >
            <div className={`${className ? className : ''} ${classes.simpleContainer}`}>
                <div className={classes.selectorWrap}>
                    <Typography component="span">{`${text ? text + " : " : ''}`}</Typography>
                    <PButton
                        disableRipple
                        color="secondary"
                        endIcon={<IconCaretDown />}
                        onClick={handleSelector}
                        style={{ padding: '3px 6px', background: 'transparent' }}
                    >
                        {label}
                    </PButton>
                </div>
                {open ? (
                    <div className={classes.simpleDropdownBox}>
                        <PInput
                            classes={{ root: classes.blueInputRoot }}
                            startAdornment={<InputAdornment position="start"><IconSearch /></InputAdornment>} />
                        <div className={classes.itemListbox}>
                            {values.map((item, index) => (
                                <BuidingCheckRow key={index} building={item} checked={item.selected} onChange={(checked) => handleChange(index, item)} />
                            ))}
                        </div>
                    </div>
                ) : null}
            </div>
        </ClickAwayListener>
    )
}

const actionDropdownStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
        width: 'fit-content'
    },
    actionDropdownBox: {
        position: 'absolute',
        background: colorS.sWhite,
        padding: '16px',
        boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.04), 0px 4px 6px rgba(0, 0, 0, 0.07)',
        borderRadius: '8px',
        top: 32,
        right: 0,
        zIndex: 10
    },
    itemBox: {
        width: '100%',
        display: 'inline-flex',
        alignItems: 'center',
        padding: '16px 0',
        borderBottom: `1px solid #F0F0F0`,
        cursor: 'pointer',
        color: colorS.sGrey,
        '&:first-child': {
            paddingTop: 0,
        },
        '&:last-child': {
            border: 'none',
            paddingBottom: 0,
        },
        '&:hover': {
            color: colorS.sBlack
        }
    },
    failed: {
        color: colorS.sRedAccent
    },
    success: {
        color: colorS.sGreen
    },
    normal: {
        color: colorS.sGrey
    },
    alarmIcon: {
        fontSize: '24px',
        position: 'absolute',
        color: colorS.sRedAccent
    },
    selectedIcon: {
        color: '#fff'
    }
}))

const MenuItemText = withStyles(() => ({
    root: {
        margin: 0,
        whiteSpace: 'nowrap'
    },
    secondary: {
        fontSize: 12,
    },
    multiline: {
        margin: 0
    }
}))(ListItemText)

const PBuildingActionDropdown = ({ className, menuItems = [], menuIcon: IconComp, onClick, alarm = false, selected = false }) => {
    const classes = actionDropdownStyles()
    const [open, setOpen] = useState(false)

    const handleClick = (item) => {
        setOpen(false)
        if (onClick) onClick(item)
    }
    const handleClickAway = () => {
        setOpen(false)
    }

    return (
        <ClickAwayListener
            mouseEvent="onMouseDown"
            touchEvent="onTouchStart"
            onClickAway={handleClickAway}
        >
            <div className={`${className} ${classes.container}`}>
                <IconButton onClick={(e) => setOpen(!open)}>
                    <IconComp className={`${selected && classes.selectedIcon} ${alarm && classes.alarmIcon}`} />
                </IconButton>
                {open ? (
                    <div className={classes.actionDropdownBox}>
                        {menuItems.map((item, index) => (
                            <div className={classes.itemBox} key={index} onClick={(e) => handleClick(item)}>
                                {typeof (item.icon) == 'string' && <img src={item.icon} className="mr-20" />}
                                {typeof (item.icon) != 'string' && <item.icon className="mr-20" />}
                                <MenuItemText
                                    primary={item.label}
                                    secondary={item.desc}
                                    classes={{ secondary: classes[item.status] }}
                                />
                            </div>
                        ))}
                    </div>
                ) : null}
            </div>
        </ClickAwayListener>
    )
}

const PPageCategory = ({ className, icon, text }) => {
    return (
        <Box display="inline-flex" className={className} alignItems="center">
            {icon && <img src={icon} className="mr-16" style={{ width: 24, height: 24 }} />}
            <Typography component="span">{text}</Typography>
            <img src={IconMenuDownArrowSVG} />
        </Box>
    )
}

export {
    PBadgeAvatar,
    PPersonItem,
    PListItemText,
    PIconText,
    PStatisticsItem,
    PLimitSelector,
    PMultiBuildingDropdown,
    PMultiBuildingSimpleDropdown,
    PBuildingActionDropdown,
    PPageCategory,
    PImageItem
}
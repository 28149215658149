import React, { useState, useEffect } from 'react'
import {
    Typography,
    Checkbox,
    Radio,
    Grid,
    Avatar,
    makeStyles,
    Switch,
    withStyles,
    FormControlLabel
} from '@material-ui/core'

import CheckboxSVG from '@src/assets/img/app/icon-checkbox.svg'
import CheckboxBlankSVG from '@src/assets/img/app/icon-check-line.svg'
import IconCheckerSVG from '@src/assets/img/app/icon-simple-checker.svg'
import IconCircleSVG from '@src/assets/img/app/icon-sm-circle.svg'
import IconCheckerIndetermineSVG from '@src/assets/img/app/reports/icon-checkbox-ind.svg'
import RadioCheckedSVG from '@src/assets/img/app/icon-radio-checked.svg'
import RadioUncheckedSVG from '@src/assets/img/app/icon-radio-unchecked.svg'

import Constants from '@src/config/Constants'
const colorS = Constants.COLORS

const PPurpleCheckbox = ((props) => {
    return (
        <Checkbox
            color='default'
            checkedIcon={<img src={CheckboxSVG} />}
            icon={<img src={CheckboxBlankSVG} />}
            indeterminateIcon={<img src={IconCheckerIndetermineSVG} />}
            {...props}
        />
    )
})

const PPurpleRadio = ((props) => {
    return (
        <Radio
            color='default'
            checkedIcon={<img src={RadioCheckedSVG} />}
            icon={<img src={RadioUncheckedSVG} />}
            {...props}
        />
    )
})

const filterCheckboxStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    image: {
        background: colorS.sYellow,
        padding: 8,
        marginRight: 16,
        width: 32,
        height: 32,
        '& img': {
            objectFit: 'initial'
        }
    },
}))

const PFilterCheckBox = ({ icon, checked, onChange, text, className }) => {
    const classes = filterCheckboxStyles()
    const [elemValue, setElemValue] = useState(checked)

    useEffect(() => {
        setElemValue(checked)
    }, [checked])

    const handleChange = (event) => {
        setElemValue(event.target.checked)
        if (onChange) onChange(event.target.checked)
    };

    return (
        <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            className={className}
        >
            <div className={classes.root}>
                {icon && <Avatar src={icon} className={classes.image} />}
                <Typography component="span">{text}</Typography>
            </div>
            <PPurpleCheckbox
                checked={elemValue}
                onChange={handleChange}
                color="primary"
            />
        </Grid>
    )
}

const PurpleSwitch = withStyles((theme) => ({
    root: {
        width: 48,
        height: 24,
        padding: 0,
        display: 'flex',
    },
    switchBase: {
        padding: 4,
        color: colorS.sWhite,
        '&$checked': {
            transform: 'translateX(23px)',
            color: colorS.sWhite,
            '& + $track': {
                opacity: 1,
                backgroundColor: colorS.sPurple,
                borderColor: colorS.sPurple,
            },
        },
        '& + $track': {
            opacity: 1,
            backgroundColor: colorS.sGrey,
            borderColor: colorS.sGrey,
        }
    },
    thumb: {
        width: 16,
        height: 16,
        boxShadow: 'none',
    },
    track: {
        border: `1px solid ${colorS.sPurple}`,
        borderRadius: 24 / 2,
        opacity: 1,
        backgroundColor: colorS.sWhite,
    },
    checked: {},
}))(Switch)


const switchStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        width: 'fit-content'
    },
    checked: {
        position: 'absolute',
        top: '50%',
        left: '9px',
        transform: 'translateY(-50%)',
    },
    unchecked: {
        position: 'absolute',
        top: '50%',
        right: '9px',
        transform: 'translateY(-50%)',
    },
}))

const PSwitch = ({ checked, onChange, className }) => {
    const classes = switchStyles()
    const [elemValue, setElemValue] = useState(checked)

    const handleChange = (event) => {
        setElemValue(event.target.checked)
        if (onChange) onChange(event.target.checked)
    };

    useEffect(() => {
        setElemValue(checked)
    }, [checked])


    return (
        <div className={`${classes.root} ${className}`}>
            <PurpleSwitch
                checked={elemValue}
                onChange={handleChange}
            />
            {elemValue && (
                <img src={IconCheckerSVG} className={classes.checked} />
            )}
            {!elemValue && (
                <img src={IconCircleSVG} className={classes.unchecked} />
            )}
        </div>
    )
}

const outlineCheckboxStyles = makeStyles((theme) => ({
    root: {
        padding: '12px 16px',
        borderRadius: '12px',
        border: `1px solid ${colorS.sBlueAccent3}`,
        background: colorS.sBlueAccent3,
        cursor: 'pointer',
        flexWrap: 'nowrap',
        marginLeft: 0,
        marginRight: 0,
        justifyContent: 'space-between',
        '&$checked': {
            background: '#fff',
            borderColor: colorS.sPurple
        }
    },
    rootRadio: {
        padding: '12px 16px',
        borderRadius: '12px',
        border: `1px solid ${colorS.sBlueAccent3}`,
        background: colorS.sBlueAccent3,
        cursor: 'pointer',
        flexWrap: 'nowrap',
        marginLeft: 0,
        marginRight: 0,
        justifyContent: 'space-between',
        '&$checked': {
            background: '#fff',
            borderColor: colorS.sPurple
        }
    },
    span: {
        paddingRight: '36px',
        display: 'block'
    },
    checked: {}
}))

const POutlineCheckBox = ({ checked, onChange, text, className }) => {
    const classes = outlineCheckboxStyles()
    const [elemValue, setElemValue] = useState(checked)

    useEffect(() => {
        setElemValue(checked)
    }, [checked])

    const handleChange = (event) => {
        setElemValue(event.target.checked)
        if (onChange) onChange(event.target.checked)
    };

    return (
        <FormControlLabel
            value="start"
            control={
                <PPurpleCheckbox checked={elemValue} onChange={handleChange} color="primary" />
            }
            label={<Typography component="span" className={classes.span}>{text}</Typography>}
            labelPlacement="start"
            className={`${classes.root} ${className} ${elemValue ? classes.checked : ''}`}
        />
    )
}

const PLabelCheckBox = ({ checked, onChange, text, className }) => {
    const classes = outlineCheckboxStyles()
    const [elemValue, setElemValue] = useState(checked)

    useEffect(() => {
        setElemValue(checked)
    }, [checked])

    const handleChange = (event) => {
        setElemValue(event.target.checked)
        if (onChange) onChange(event.target.checked)
    };

    return (
        <FormControlLabel
            control={
                <PPurpleCheckbox checked={elemValue} onChange={handleChange} color="primary" />
            }
            label={<Typography component="span">{text}</Typography>}
            labelPlacement="end"
            className={className}
        />
    )
}

const POutlineRadioBox = (props) => {
    const classes = outlineCheckboxStyles()
    return (
        <FormControlLabel
            value="start"
            control={
                <PPurpleRadio className="" {...props} />
            }
            label={<Typography component="span" className={classes.span}>{props.text}</Typography>}
            labelPlacement={props.placement ? props.placement : "start"}
            className={`${classes.root} ${props.className} ${props.checked ? classes.checked : ''}`}
        />
    )
}


const PLabelRadioBox = ({ checked, onChange, text, className }) => {
    const classes = outlineCheckboxStyles()
    const [elemValue, setElemValue] = useState(checked)

    useEffect(() => {
        setElemValue(checked)
    }, [checked])

    const handleChange = (event) => {
        setElemValue(event.target.checked)
        if (onChange) onChange(event.target.checked)
    };

    return (
        <FormControlLabel
            control={
                <PPurpleCheckbox checked={elemValue} onChange={handleChange} color="primary" />
            }
            label={<Typography component="span" className={classes.span}>{text}</Typography>}
            labelPlacement="end"
            className={className}
        />
    )
}

export { PFilterCheckBox, PSwitch, PPurpleRadio, PPurpleCheckbox, POutlineCheckBox, PLabelCheckBox, PLabelRadioBox, POutlineRadioBox }
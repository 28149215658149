import {
  emptyTextCell,
  nonEditable,
  textCell,
  dateCell,
  monthHeaderCell,
  bottomLine,
  numberCell,
  showZero,
  hideZero,
  noSideBorders,
  dropdownCell,
  toolTipCell
} from "./Cells";

import { months } from "./Helpers";

export const HEADER_ROW_ID = "header";
export const SUBHEADER_ROW_ID = "sub_header";
export const BLANK_ROW_ID = "blank";
export const TOTAL_ROW_ID = "total";
export const DATE_FORM_FIELD_ROW_ID = "date_form_field";
export const FORM_USED_ROW_ID = "form_used";
export const TOTAL_REFUND_RECEIVED_ROW_ID = "total_refund_received";
export const REFUND_TYPE_ROW_ID = "refund_type";
export const DATA_RECEIVED_ROW_ID = "data_received";
export const ATTACH_ROW_ID = "attach";
export const NOTE_ID = "note_";
export const NOTE_2_ID = "note_2";
export const DATE_FORM_941X_ROW_ID = "date_form_941x_row_id";


export const CASHBOXBANK_ROW_ID = "cashboxBank";
export const CREDITLINE_ROW_ID = "creditLine";
export const CREDITLINEOVERDRAFT_ROW_ID = "creditLineOverdraft";
export const LIQUIDFUNDS_ROW_ID = "liquidFunds";
export const MONTHSTOTAL_ROW_ID = "monthsTotal";
export const CUMULATIVE_ROW_ID = "cumulative";

const ROW_HEIGHT = 32;
const HEADING_ROW_HEIGHT = 40;

const claimTypes = [
  { label: "Claimed", value: "claimed" },
  { label: "Advanced", value: "advanced" },
]

const sumGroupValues = (values) => {
  if (!values.length) return 0
  return values.reduce(
    (prev, curr) => (parseFloat((prev || 0).toString())) + (parseFloat((curr || 0).toString()))
  );
}

const getHeaderRow = (quarters) => {
  const cells = [
    nonEditable(emptyTextCell),
    nonEditable(textCell("Credits Summary", "justify-content-center", {}, 5)),
    nonEditable(emptyTextCell),
    nonEditable(emptyTextCell),
    nonEditable(emptyTextCell),
    nonEditable(emptyTextCell),
  ]

  for (let index = 0; index < quarters.length; index++) {
    if (index == 0) {
      cells.push(nonEditable(textCell("Utilization", "justify-content-center", {}, quarters.length)))
    } else {
      cells.push(nonEditable(emptyTextCell))
    }
  }

  cells.push(nonEditable({ type: "text", text: "Remaining" }))

  return {
    rowId: HEADER_ROW_ID,
    height: ROW_HEIGHT,
    cells
  };
}

const getSubHeaderRow = (quarters) => {
  const cells = [
    nonEditable(textCell("Year of Study", "justify-content-center", {}, 1)),
    nonEditable(textCell("Date Return Filed", "justify-content-center", {}, 1)),
    nonEditable(textCell("Credits From Study", "justify-content-center", {}, 1)),
    nonEditable(textCell("Income Tax Allocation", "justify-content-center", {}, 1)),
    nonEditable(textCell("Payroll Allocation", "justify-content-center", {}, 1)),
    nonEditable(textCell("Claim Type", "justify-content-center", {}, 1)),
  ]

  for (let index = 0; index < quarters.length; index++) {
    cells.push(textCell(quarters[index].toString(), "justify-content-center", {}, 1))
  }

  cells.push(nonEditable(emptyTextCell))

  return {
    rowId: SUBHEADER_ROW_ID,
    height: ROW_HEIGHT,
    cells
  };
}

const getTaxDataRows = (groups) => {
  return [
    ...groups.map(({ _id, year_of_study, date_return_filed, credits_from_study, income_tax_allocation, claim_type, tax_values, claim_type_is_open }, index) => ({
      rowId: _id,
      height: ROW_HEIGHT,
      cells: [
        dateCell(new Date(year_of_study), "justify-content-center"),
        dateCell(new Date(date_return_filed), "justify-content-center"),
        numberCell(credits_from_study),
        numberCell(income_tax_allocation),
        numberCell(credits_from_study - income_tax_allocation),
        dropdownCell(claimTypes, claim_type, claim_type_is_open),
        ...tax_values.map((_, idx) => numberCell(tax_values[idx])),
        nonEditable(
          hideZero(numberCell(credits_from_study - income_tax_allocation - sumGroupValues(tax_values), "font-bold disabled"))
        )
      ]
    })),
  ];
}

const getTaxTotalRow = (taxTotal, quarters, trackLines) => {
  const cells = [
    nonEditable(emptyTextCell),
    nonEditable(emptyTextCell),
    hideZero(nonEditable(numberCell(taxTotal.credits_from_study, "font-bold"))),
    hideZero(nonEditable(numberCell(taxTotal.income_tax_allocation, "font-bold"))),
    hideZero(nonEditable(numberCell(taxTotal.payroll_allocation, "font-bold"))),
    nonEditable(emptyTextCell),
  ]

  const quarterTotal = quarters.map((item, index) => trackLines.reduce((t, c) => t + parseFloat((c.tax_values[index] || 0).toString()), 0))

  for (let index = 0; index < quarterTotal.length; index++) {
    cells.push(nonEditable(hideZero(numberCell(quarterTotal[index], "font-bold"))))
  }

  cells.push(nonEditable(emptyTextCell))

  return {
    rowId: TOTAL_ROW_ID,
    height: ROW_HEIGHT,
    cells
  };
}

const getDateFormFieldRow = (formFiled) => {
  const cells = [
    nonEditable(emptyTextCell),
    nonEditable(emptyTextCell),
    nonEditable(emptyTextCell),
    nonEditable(emptyTextCell),

    nonEditable(textCell("Date form filed", "", {}, 2)),
    nonEditable(emptyTextCell),
  ]

  for (let index = 0; index < formFiled.length; index++) {
    cells.push(dateCell(formFiled[index] ? new Date(formFiled[index]) : '', "justify-content-center"))
  }

  cells.push(nonEditable(emptyTextCell))

  return {
    rowId: DATE_FORM_FIELD_ROW_ID,
    height: ROW_HEIGHT,
    cells
  };
}

const getFormUsedRow = (formUsed) => {
  const cells = [
    nonEditable(emptyTextCell),
    nonEditable(emptyTextCell),
    nonEditable(emptyTextCell),
    nonEditable(emptyTextCell),

    nonEditable(textCell("Form Used", "", {}, 2)),
    nonEditable(emptyTextCell),
  ]

  for (let index = 0; index < formUsed.length; index++) {
    cells.push(textCell(formUsed[index], "justify-content-center"))
  }

  cells.push(nonEditable(emptyTextCell))

  return {
    rowId: FORM_USED_ROW_ID,
    height: ROW_HEIGHT,
    cells
  };
}

const getBlankRow = (quarters, index) => {
  const cells = [
    nonEditable(emptyTextCell),
    nonEditable(emptyTextCell),
    nonEditable(emptyTextCell),
    nonEditable(emptyTextCell),
    nonEditable(emptyTextCell),
    nonEditable(emptyTextCell),
  ]

  for (let index = 0; index < quarters.length; index++) {
    cells.push(nonEditable(emptyTextCell))
  }

  cells.push(nonEditable(emptyTextCell))

  return {
    rowId: `${BLANK_ROW_ID}-${index}`,
    height: ROW_HEIGHT,
    cells
  };
}

const getTotalRefundRow = (totalRefundReceived) => {
  const cells = [
    nonEditable(emptyTextCell),
    nonEditable(emptyTextCell),
    nonEditable(emptyTextCell),
    nonEditable(emptyTextCell),

    nonEditable(textCell("Total Refund Received", "", {}, 2)),
    nonEditable(emptyTextCell),
  ]

  for (let index = 0; index < totalRefundReceived.length; index++) {
    cells.push(hideZero(numberCell(totalRefundReceived[index])))
  }

  cells.push(nonEditable(emptyTextCell))

  return {
    rowId: TOTAL_REFUND_RECEIVED_ROW_ID,
    height: ROW_HEIGHT,
    cells
  };
}

const getForm941XRow = (form941x) => {
  const cells = [
    nonEditable(emptyTextCell),
    nonEditable(emptyTextCell),
    nonEditable(emptyTextCell),
    nonEditable(emptyTextCell),

    nonEditable(textCell("Form941x Line 16", "", {}, 2)),
    nonEditable(emptyTextCell),
  ]

  for (let index = 0; index < form941x.length; index++) {
    cells.push(hideZero(numberCell(form941x[index])))
  }

  cells.push(nonEditable(emptyTextCell))

  return {
    rowId: DATE_FORM_941X_ROW_ID,
    height: ROW_HEIGHT,
    cells
  };
}

const getRefundTypeRow = (refundTypes) => {
  const cells = [
    nonEditable(emptyTextCell),
    nonEditable(emptyTextCell),
    nonEditable(emptyTextCell),
    nonEditable(emptyTextCell),

    nonEditable(textCell("Refund Type", "", {}, 2)),
    nonEditable(emptyTextCell),
  ]

  for (let index = 0; index < refundTypes.length; index++) {
    cells.push(textCell(refundTypes[index], "justify-content-center"))
  }

  cells.push(nonEditable(emptyTextCell))

  return {
    rowId: REFUND_TYPE_ROW_ID,
    height: ROW_HEIGHT,
    cells
  };
}

const getDateReceivedRow = (dateReceived) => {
  const cells = [
    nonEditable(emptyTextCell),
    nonEditable(emptyTextCell),
    nonEditable(emptyTextCell),
    nonEditable(emptyTextCell),

    nonEditable(textCell("Date Received", "", {}, 2)),
    nonEditable(emptyTextCell),
  ]

  for (let index = 0; index < dateReceived.length; index++) {
    cells.push(dateCell(dateReceived[index] ? new Date(dateReceived[index]) : '', "justify-content-center"))
  }

  cells.push(nonEditable(emptyTextCell))

  return {
    rowId: DATA_RECEIVED_ROW_ID,
    height: ROW_HEIGHT,
    cells
  };
}

const getAttachRow = (attach) => {
  const cells = [
    nonEditable(emptyTextCell),
    nonEditable(emptyTextCell),
    nonEditable(emptyTextCell),
    nonEditable(emptyTextCell),

    nonEditable(textCell("Evidence (Attach)", "", {}, 2)),
    nonEditable(emptyTextCell),
  ]

  for (let index = 0; index < attach.length; index++) {
    cells.push(nonEditable(emptyTextCell))
  }

  cells.push(nonEditable(emptyTextCell))

  return {
    rowId: ATTACH_ROW_ID,
    height: ROW_HEIGHT,
    cells
  };
}

const getNotesRow = (notes) => {
  return notes.map((note, idx) => {
    const cells = [
      nonEditable(emptyTextCell),
      nonEditable(emptyTextCell),
      nonEditable(emptyTextCell),
      nonEditable(emptyTextCell),

      textCell(note.title, "", {}, 2),
      nonEditable(emptyTextCell),
    ]

    for (let index = 0; index < note.values.length; index++) {
      cells.push(toolTipCell(note.values[index], "justify-content-center"))
    }

    cells.push(nonEditable(emptyTextCell))

    return {
      rowId: `${NOTE_ID}${idx}`,
      height: ROW_HEIGHT,
      cells
    };
  })
}

export const getCreditLineRows = (
  cumulativeTotals,
  yearlyInflowOuflowDiff,
  creditLine
) => {
  const yearlyOverdraft =
    -yearlyInflowOuflowDiff - (isNaN(creditLine) ? 0 : creditLine);
  return [
    {
      rowId: CREDITLINE_ROW_ID,
      height: ROW_HEIGHT,
      cells: [
        nonEditable(textCell("Credit line", "padding-left-lg")),
        ...months().map((_, idx) =>
          idx === 0
            ? numberCell(creditLine, "light-green-bg")
            : nonEditable(showZero(numberCell(creditLine, "disabled")))
        ),
        nonEditable(showZero(numberCell(creditLine, "font-bold disabled")))
      ]
    },
    {
      rowId: CREDITLINEOVERDRAFT_ROW_ID,
      height: HEADING_ROW_HEIGHT,
      cells: [
        nonEditable(
          textCell("Credit line overdraft", "align-items-end text-lg font-bold")
        ),
        ...months().map((_, idx) => {
          const overdraft =
            -cumulativeTotals[idx] - (isNaN(creditLine) ? 0 : creditLine);
          return nonEditable(
            numberCell(
              overdraft > 0 ? overdraft : NaN,
              overdraft > 0
                ? "align-items-end disabled text-md text-red font-bold"
                : "disabled"
            )
          );
        }),
        nonEditable(
          numberCell(
            yearlyOverdraft > 0 ? yearlyOverdraft : NaN,
            "align-items-end disabled text-red text-lg font-bold"
          )
        )
      ]
    }
  ];
}

export const getRows = ({ trackLines, quarters, formFiled, formUsed, refundTypes, dateReceived, attach, notes, totalRefundReceived, taxTotal, quarterTotal, form941x }) => {
  return [
    getHeaderRow(quarters),
    getSubHeaderRow(quarters),
    ...getTaxDataRows(trackLines),
    getTaxTotalRow(taxTotal, quarters, trackLines),
    getBlankRow(quarters, 1),
    getDateFormFieldRow(formFiled),
    getFormUsedRow(formUsed),
    getBlankRow(quarters, 2),
    getTotalRefundRow(totalRefundReceived),
    getRefundTypeRow(refundTypes),
    getDateReceivedRow(dateReceived),
    getAttachRow(attach),
    // getForm941XRow(form941x),
    ...getNotesRow(notes),
    getBlankRow(quarters, 3),
  ];
}

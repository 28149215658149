import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  linkClick: {
    margin: theme.spacing(0.5, 0.75),
    fontWeight: 400,
    color: '#7092FE',
    textDecoration: 'none'
  }
}))

export default useStyles

import {
    withStyles,
    makeStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@material-ui/core'

import Constants from '@src/config/Constants'
const colorS = Constants.COLORS

const PDialog = withStyles(() => ({
    paper: {
        borderRadius: 10
    },
    paperWidthSm: {}
}))(Dialog);

const PDialogActions = withStyles(() => ({
    root: {
        padding: '24px'
    }
}))(DialogActions);

const PDialogContent = withStyles(() => ({
    root: {
        padding: '0 24px'
    }
}))(DialogContent);

const PDialogContentText = withStyles(() => ({
}))(DialogContentText);

const PDialogTitle = withStyles(() => ({
}))(DialogTitle);

export { PDialog, PDialogActions, PDialogContent, PDialogContentText, PDialogTitle }
import React, { useEffect, useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import { DialogContent, IconButton, Dialog } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    width: '100vw',
    maxWidth: '100vw',
    minWidth: '100vw',
    minHeight: '100vh',
    margin: 'auto',
    borderRadius: 0
  },
  dialogRoot: {
    width: '100vw',
    maxWidth: '100vw',
    minWidth: '100vw',
    minHeight: '100vh',
    margin: 'auto'
  },
  dialogContentRoot: {
    padding: theme.spacing(3)
  },
  closeModal: {
    position: 'absolute',
    top: '35px',
    right: '50px',
    zIndex: 1,
    padding: 0,
    width: '32px',
    height: '32px',
    background: '#fff',
    // fontSize: '1.6rem',
    // color: theme.palette.common.sGrey,
    // background: theme.palette.common.sBlueAccent3,
    '&:hover': {
      opacity: 1
    }
  }
}))

export default React.memo(
  ({ children, close, open, classParent = { contentRoot: null } }) => {
    const classes = useStyles()

    const [openModal, setOpenModal] = useState(false)

    const handleClose = () => {
      setOpenModal(false)
      close()
    }

    useEffect(() => {
      if (open) {
        setOpenModal(true)
      } else if (open === false) {
        setOpenModal(false)
      }
    }, [open])

    return (
      <>
        <Dialog
          // modal={false}
          classes={{ paper: classes.dialogPaper, root: classes.dialogRoot }}
          modal='false'
          open={openModal}
          onClose={handleClose}
        >
          <IconButton
            aria-label='close'
            onClick={handleClose}
            className={classes.closeModal}
          >
            {/* <span className='icon-close' /> */}
            <svg
              width='40'
              height='40'
              viewBox='0 0 40 40'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M24.7167 15.2832L15.2834 24.7165'
                stroke='#6E6D7A'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M24.7167 24.7165L15.2834 15.2832'
                stroke='#6E6D7A'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M20 35C11.715 35 5 28.285 5 20C5 11.715 11.715 5 20 5C28.285 5 35 11.715 35 20C35 28.285 28.285 35 20 35Z'
                stroke='#6E6D7A'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </IconButton>
          <DialogContent
            classes={{
              root: `${classes.dialogContentRoot} ${classParent.contentRoot}`
            }}
          >
            {children}
          </DialogContent>
        </Dialog>
      </>
    )
  }
)

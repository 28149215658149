import {
    withStyles,
    makeStyles,
    Tabs,
    Tab,
} from '@material-ui/core'

import Constants from '@src/config/Constants'
const colorS = Constants.COLORS

const PTabs = withStyles(() => ({
    root: {
        borderBottom: `1px solid ${colorS.sGrey20}`,
        position: 'relative'
    },
    indicator: {
        backgroundColor: colorS.sPurple,
    },
    scrollButtons: {
        height: '100%',
        position: 'absolute',
        zIndex: 10,
        background: colorS.sWhite,
        '&:last-child': {
            right: 0
        }
    },
    scrollable: {}
}))(Tabs)

const PTab = withStyles(() => ({
    root: {
        color: colorS.sBlack,
        padding: '12px 12px 24px 12px'
    }
}))(Tab)

const PTinyTab = withStyles(() => ({
    root: {
        minWidth: 'fit-content',
        height: 40,
        padding: 0,
        marginRight: 24
    },

}))(Tab)

const PTinyIconTab = withStyles(() => ({
    root: {
        minWidth: 'fit-content',
        height: 40,
        padding: 0,
        marginRight: 8,
        padding: '0 16px',
    },
    textColorPrimary: {
        color: '#6E6D7A',
        '&$selected': {
            color: colorS.sPurple,
        }
    },
    selected: {}

}))(Tab)

const PScrollIconTab = withStyles(() => ({
    root: {
        minWidth: 'fit-content',
        height: 48,
        padding: 0,
        marginRight: 0,
        padding: '0 24px',
    },
    textColorPrimary: {
        color: '#6E6D7A',
        '&$selected': {
            color: colorS.sPurple,
            background: '#FBFBFD'
        }
    },
    selected: {}

}))(Tab)

export { PTabs, PTab, PTinyTab, PTinyIconTab, PScrollIconTab }
import { withStyles, AccordionSummary, AccordionDetails, Accordion } from '@material-ui/core'
const PAccordion = withStyles(() => ({
    root: {
        border: 'none',
        boxShadow: 'none',
        width: '100%',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
}))(Accordion);

const PAccordionDetails = withStyles(() => ({
    root: {
        padding: '0',
    },
}))(AccordionDetails);

const PAccordionSummary = withStyles(() => ({
    root: {
        backgroundColor: '#fff',
        borderBottom: 'none',
        marginBottom: -1,
        minHeight: 16,
        padding: 0,
        '&$expanded': {
            minHeight: 16,
        },
    },
    content: {
        justifyContent: 'space-between',
        margin: '2px 0',
        '&$expanded': {
            margin: '2px 0',
        },
    },
    expanded: {},
    expandIcon:{
        padding: '0 12px'
    }
}))(AccordionSummary);

export { PAccordion, PAccordionDetails, PAccordionSummary }
import React, { useState, useEffect } from 'react'

import InputTextField from './InputTextField'

export default React.memo((props) => {
  // const [address, setAddress] = useState({})
  const [autocomplete, setAutocomplete] = useState(null)
  const AddressComponent = {
    street_number: 'short_name',
    route: 'long_name',
    locality: 'long_name',
    administrative_area_level_1: 'short_name',
    country: 'long_name',
    postal_code: 'short_name'
  }

  const ELEMENT_ID = 'autocomplete' + props.id

  useEffect(() => {
    const complete = new window.google.maps.places.Autocomplete(
      document.getElementById(ELEMENT_ID)
    )
    setAutocomplete(complete)
  }, [])

  useEffect(() => {
    if (autocomplete) {
      autocomplete.addListener('place_changed', handlePlaceSelect)
    }
  }, [autocomplete])

  const handlePlaceSelect = () => {
    const place = autocomplete.getPlace()
    const formattedAddress = place.formatted_address
    const address = place.address_components
    const fullAddress = {}
    // Get each component of the address from the place details,
    // and then fill-in the corresponding field on the form.
    for (let i = 0; i < place.address_components.length; i++) {
      const addressType = place.address_components[i].types[0]
      if (AddressComponent[addressType]) {
        const val = place.address_components[i][AddressComponent[addressType]]
        fullAddress[addressType] = val
      }
    }

    if (props.onSelect) {
      props.onSelect(fullAddress, formattedAddress)
    }
  }

  return (
    <InputTextField
      id={ELEMENT_ID}
      variant={props.variant}
      disabled={props.disabled}
      fullWidth={props.fullWidth}
      endAdornment={props.endAdornment}
      center={props.center}
      // InputProps={{
      //   ...inputP
      // }}
      // InputLabelProps={{
      //   // shrink: true,
      //   className: 'bd-input-label-normal'
      // }}
      // InputLabelProps={{
      //   shrink: true,
      //   className: classes.label
      // }}
      // helperText={props.touched.password && props.errors.password}
      // error={props.touched.password && !!props.errors.password}
      placeholder={props.placeholder}
      label={props.label}
      type={props.type}
      name={props.name}
      value={props.value}
      onChange={props.onChange}
      defaultValue={props.defaultValue}
    />
  )
})

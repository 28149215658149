import React, { useState } from 'react'

import {
  Button,
  Typography,
  Container,
  Popover,
  Grid,
  TextField,
  Box,
  InputAdornment
} from '@material-ui/core'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import InfoIcon from '@material-ui/icons/Info'
function HelperInfo(props) {
  const { infoColor = '' } = props
  // Popover
  // For popover => Read it
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <>
      <Button
        tabindex='-1'
        disableRipple
        aria-describedby={id}
        onClick={handleClick}
        style={{
          background: 'none',
          boxShadow: 'none',
          color: '#A5A5A5',
          minWidth: '25px',
          paddingRight: '0px'
        }}
        size='small'
      >
        {!open ? (
          <InfoIcon style={{ color: infoColor }} />
        ) : (
          <FiberManualRecordIcon style={{ color: '#7092FE' }} />
        )}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left'
        }}
        style={{ marginLeft: '3rem' }}
        {...props}
      >
        <Box style={{ width: '25.6rem' }} p={2}>
          {props.children}
        </Box>
      </Popover>
    </>
  )
}

export default HelperInfo

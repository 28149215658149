import { createContext } from 'react'
import { decorate, observable, computed, action, toJS } from 'mobx'

import ENUMS from '@src/config/Enums'

const UK = 'currentUser'

export class CurrentUserStorage {
  data = {
    _id: '',
    email: '',
    name: '',
    username: '',
    access_token: '',
    phones: [],
    notifications: { sms: false, call: false },
    tradesmanId: '',
    homeownerId: '',
    avatar: '',
    type: ENUMS.LoginType.HOMEOWNER
  }

  constructor() {
    const a = !!this.data._id
    if (!a) {
      if (window.localStorage.getItem(UK) !== null) {
        const d = JSON.parse(window.localStorage.getItem(UK))
        this.data._id = d._id
        this.data.email = d.email
        this.data.name = d.name
        this.data.username = d.username
        this.data.access_token = d.access_token
        this.data.tradesmanId = d.tradesmanId
        this.data.homeownerId = d.homeownerId
        this.data.notifications = d.notifications || { sms: false, call: false }
        this.data.phones = d.phones || []
        this.data.avatar = d.avatar
        this.data.type = d.type
      }
    }
  }

  get user() {
    if (window.localStorage.getItem(UK) === null) {
      return toJS(this.data)
    } else {
      const d = window.localStorage.getItem(UK)
      this.setUser(JSON.parse(d))
      return toJS(this.data)
    }
  }

  get hasUser() {
    const a = !!this.data._id
    return a
  }

  get isHomeowner() {
    if (this.data.homeownerId) {
      return true
    } else {
      return false
    }
  }

  get isTradesman() {
    if (this.data.tradesmanId) {
      return true
    } else {
      return false
    }
  }

  /**
   * get user data from localstorage
   */
  getUser = () => {
    const d = window.localStorage.getItem(UK)
    return JSON.parse(d)
  }

  /**
   * set user info
   */
  setUser(d) {
    this.data._id = d._id
    this.data.email = d.email
    this.data.name = d.name
    this.data.username = d.username
    this.data.access_token = d.access_token
    this.data.tradesmanId = d.tradesmanId
    this.data.homeownerId = d.homeownerId
    this.data.phones = d.phones
    this.data.notifications = d.notifications
    this.data.avatar = d.avatar
    this.data.type = d.type

    if (d.company) {
      this.data.company = d.company
    }

    window.localStorage.setItem(UK, JSON.stringify(this.data))
  }

  setName(name) {
    this.data.name = name
  }

  /**
   * remove user
   */
  removeUser = () => {
    this.data._id = ''
    this.data.email = ''
    this.data.name = ''
    this.data.username = ''
    this.data.access_token = ''

    this.data.tradesmanId = ''
    this.data.homeownerId = ''
    this.data.avatar = ''
    this.data.type = ENUMS.LoginType.HOMEOWNER

    window.localStorage.removeItem(UK)
  }
}

decorate(CurrentUserStorage, {
  data: observable,
  user: computed,
  hasUser: computed,
  setUser: action,
  setName: action,
  removeUser: action
})

export default createContext(new CurrentUserStorage())

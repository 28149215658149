import SignIn from '@src/views/SignIn/SignIn'
import SignUp from '@src/views/SignUp/SignUp'
import SignUpConfirm from '@src/views/SignUpConfirm/SignUpConfirm'
import SignUpResend from '@src/views/SignUpResend/SignUpResend'
import SignUpChange from '@src/views/SignUpChange/SignUpChange'
import SignUpEmailConfirmation from '@src/views/SignUpEmailConfirmation/SignUpEmailConfirmation'
import ForgotPassword from '@src/views/ForgotPassword/ForgotPassword'
import ForgotEmail from '@src/views/ForgotEmail/ForgotEmail'
import ForgotPasswordConfirm from '@src/views/ForgotPasswordConfirm/ForgotPasswordConfirm'
import ForgotPasswordResend from '@src/views/ForgotPasswordResend/ForgotPasswordResend'
import ResetPassword from '@src/views/ResetPassword/ResetPassword'

import Home from '@src/views/Home/Home'
import BgSignup from '@src/assets/img/bg/bg-sign-up.svg'

import { convertArrToObject } from '@src/utils/ConvertUtils'

const DEFAULT_BACKGROUND = BgSignup
const prefixRoute = '/auth'

const haveBasicAccountConfig = {
  desc: 'Have an account?',
  linkTitle: 'Sign in',
  linkPath: `${prefixRoute}/signin` // move to constant
}

const dontHaveBasicAccountConfig = {
  desc: 'Don\'t have Tax Tracker account?',
  linkTitle: 'Join us',
  linkPath: `${prefixRoute}/signup` // move to constant
}

const AuthRoutes = [
  {
    path: `${prefixRoute}`,
    component: Home,
  },
  {
    path: `${prefixRoute}/signin`,
    component: SignIn,
    rightConfig: dontHaveBasicAccountConfig
  },
  {
    path: `${prefixRoute}/signup`,
    component: SignUp,
    rightConfig: haveBasicAccountConfig
  },
  {
    path: `${prefixRoute}/signup/confirm`,
    component: SignUpConfirm,
    rightConfig: haveBasicAccountConfig
  },
  {
    path: `${prefixRoute}/signup/resend`,
    component: SignUpResend,
    rightConfig: haveBasicAccountConfig
  },
  {
    path: `${prefixRoute}/signup/change`,
    component: SignUpChange,
    rightConfig: haveBasicAccountConfig // NOTE: how about pro account
  },
  {
    path: `${prefixRoute}/verify-email`,
    component: SignUpEmailConfirmation,
    rightConfig: haveBasicAccountConfig
  },
  {
    path: `${prefixRoute}/forgot-password`,
    component: ForgotPassword,
    rightConfig: dontHaveBasicAccountConfig
  },
  {
    path: `${prefixRoute}/forgot-password/confirm`,
    component: ForgotPasswordConfirm,
    rightConfig: dontHaveBasicAccountConfig
  },
  {
    path: `${prefixRoute}/forgot-password/resend`,
    component: ForgotPasswordResend,
    rightConfig: dontHaveBasicAccountConfig
  },
  {
    path: `${prefixRoute}/forgot-email`,
    component: ForgotEmail,
    rightConfig: dontHaveBasicAccountConfig
  },
  {
    path: `${prefixRoute}/reset-password`,
    component: ResetPassword,
    rightConfig: dontHaveBasicAccountConfig
  },

]

const AuthRoutesObj = convertArrToObject(AuthRoutes)

export { AuthRoutes, AuthRoutesObj, DEFAULT_BACKGROUND }

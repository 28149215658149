import TrackerHome from "./Home"

const prefixTrackerRoute = '/tracker'

const TrackerRoutes = [
    {
        path: `${prefixTrackerRoute}`,
        component: TrackerHome,
    },
]

export { TrackerRoutes }
import { HTTP, PGEHTTP, SCEHTTP, CONEDHTTP } from '../utils/HTTPCommon'
import Enums from '../config/Enums'
import Constants from '../config/Constants'

import Base80API from './Base80API'

class UploadAPI extends Base80API {
  async postFile (fileName, fileSize, uploadKind) {
    try {
      const url = `${Constants.PT_UPLOAD_URL}`
      const data = {
        file_name: fileName,
        file_size: fileSize,
        upload_kind: uploadKind
      }
      const response = await this.post(url, data)
      return response
    } catch (err) {
      console.log(err)
      throw err
    }
  }

  async getDetailFile (id) {
    try {
      const url = `${Constants.PT_UPLOAD_URL}/${id}`

      const response = await this.get(url)

      return response
    } catch (err) {
      console.log(err)
      throw err
    }
  }

  async deleteFile (id) {
    try {
      const url = `${Constants.PT_UPLOAD_URL}/${id}`

      const response = await this.delete(url)

      return response
    } catch (err) {
      console.log(err)
      throw err
    }
  }
}

export default UploadAPI

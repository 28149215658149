import React, { useState, useEffect, useContext } from 'react'
import { withRouter, Link, useHistory } from 'react-router-dom'

import { makeStyles, Typography, AppBar } from '@material-ui/core'
import ImgLogo from '@src/assets/img/p-logo.svg'
import ProfilePopover from './ProfilePopover'

import { TRACKER_PRIMARY_MENU } from './config'
import ENUMS_CONFIG from '@src/config/Enums'

import {
  TRADESMAN_HEADER_MENU,
  EMPLOYEE_HEADER_MENU
} from './TrasmanEmployeeConfig'

import CascadingHoverMenus from './CascadingHoverMenus'

import { UtilityUtil } from '@src/utils'

const useStyles = makeStyles((theme) => ({
  appBar: {
    height: '64px',
    width: '100%',
    background: '#fff',
    padding: '0 48px',
    margin: 'auto',
    borderBottom: `1px solid ${theme.palette.common.sGrey20}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row'
  },
  toolbarTitle: {
    cursor: 'pointer',
    display: 'inline-flex',
    height: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '& img': {
      height: '20px'
    }
  },
  wrapListLink: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    background: '#fff',
    paddingRight: 44
  },

  link: {
    margin: theme.spacing(0.5, 0.75),
    fontWeight: 'bold',
    textTransform: 'none'
  },
  root: {
    // style in the stepper
    width: '100%'
  },
  userBox: {},
  linkDirection: {
    cursor: 'pointer',
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    paddingRight: theme.spacing(3),
    textDecoration: 'none',
    color: theme.palette.common.sBlack,
    '& > span': {
      color: '#868E92'
    },
    '&:hover': {
      '& > span': {
        color: theme.palette.common.sBlack
      },
      '& $wrapSubMenu': {
        display: 'block'
      }
    },
    '&:last-child': {
      padding: '0'
    },
    '&$linkDirectionDisabled': {
      '&:hover': {
        cursor: 'default',
        '& > span': {
          color: '#868E92'
        }
      }
    }
  },
  linkDirectionDisabled: {},
  wrapSubMenu: {
    display: 'none',
    position: 'absolute',
    zIndex: 9999,
    width: '100%',
    left: 0,
    top: '100%',
    borderBottom: '1px solid #F0F0F0',
    backgroundColor: '#F7F7FA'
  }
}))

export default withRouter(({ storeUser }) => {
  const classes = useStyles()
  const history = useHistory()
  const [avatar, setAvatar] = useState(null)
  const [name, setName] = useState('')

  const handleClickLogo = () => {
    history.push('/')
  }

  const renderTradesmanMenu = () => {
    return (
      <div className={classes.wrapListLink}>
        {TRADESMAN_HEADER_MENU.map((headerMenu, idx) => {
          return (
            <CascadingHoverMenus
              key={headerMenu.id}
              {...headerMenu}
              menuList={TRADESMAN_HEADER_MENU}
            />
          )
        })}
      </div>
    )
  }

  const renderPrimaryMenu = () => {
    return (
      <div className={classes.wrapListLink}>
        {TRACKER_PRIMARY_MENU.map((headerMenu, idx) => {
          return (
            <CascadingHoverMenus
              key={headerMenu.id}
              {...headerMenu}
              menuList={TRACKER_PRIMARY_MENU}
            />
          )
        })}
      </div>
    )
  }

  const renderEmployeeMenu = () => {
    return (
      <div className={classes.wrapListLink}>
        {EMPLOYEE_HEADER_MENU.map((headerMenu, idx) => {
          return (
            <CascadingHoverMenus
              key={headerMenu.id}
              {...headerMenu}
              menuList={EMPLOYEE_HEADER_MENU}
            />
          )
        })}
      </div>
    )
  }

  const renderMenu = () => {
    return renderPrimaryMenu()
  }

  useEffect(() => {
    if (storeUser.user) {
      const n = storeUser.user.name || ''

      if (storeUser.user.avatar) {
        setAvatar(storeUser.user.avatar)
      } else {
        // generate user avatar from name
        setAvatar(UtilityUtil.generateImgByName(n, 40))
      }
      setName(n)
    }
  }, [storeUser.user])

  return (
    <AppBar color='default' elevation={0} className={classes.appBar}>
      <Typography
        variant='h6'
        color='inherit'
        noWrap
        className={classes.toolbarTitle}
        onClick={handleClickLogo}
      >
        {/* <img src={ImgLogo} alt='Point80' /> */}
      </Typography>

      {renderMenu()}

      <ProfilePopover avatar={avatar} name={name} storeUser={storeUser} />
    </AppBar>
  )
})

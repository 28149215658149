import Base80API from '../../api/Base80API'
import Constants from '../../config/Constants'

class BillingAPI extends Base80API {
    async getCompanies(params) {
        try {
            const url = `${Constants.API_URL}/company`
            return await this.get(url, params)
        } catch (err) {
            console.log(err)
            throw err
        }
    }

    async getBillingData(params) {
        try {
            const url = `${Constants.API_URL}/track/billing`
            return await this.get(url, params)
        } catch (err) {
            console.log(err)
            throw err
        }
    }

    async storeTrackLines(body) {
        try {
            const url = `${Constants.API_URL}/track/billing`
            const response = await this.post(url, body)
            return response
        } catch (err) {
            console.log(err)
            throw err
        }
    }

    async uploadAttachment(body) {
        try {
            const url = `${Constants.API_URL}/upload/billing/attachment`
            const response = await this.post(url, body)
            return response
        } catch (err) {
            console.log(err)
            throw err
        }
    }

    async deleteAttachment(attachId) {
        try {
            const url = `${Constants.API_URL}/track/billing/attachment`
            const response = await this.post(url, { id: attachId })
            return response
        } catch (err) {
            console.log(err)
            throw err
        }
    }

}

export default BillingAPI

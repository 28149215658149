import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  inputLabel: {
    fontSize: '1.4rem',
    color: '#868E92',
    transform: 'translate(12px, 22px) scale(1)',
    width: '120%' // What happen in here.
  },
  input: {
    backgroundColor: '#ECF1F6',
    '&.Mui-error': {
      border: '1px solid red'
    },
    '& > fieldset': {
      border: '0'
    },
    '&:hover, &:focus': {
      '& > fieldset': {
        border: '1px solid #F0F0F0 !important'
      },
      backgroundColor: 'white !important'
    },
    // TODO-CUONg: Fix style
    '& > div': {
      border: '0px'
    }
  },

  focused: {
    border: '1px solid #7092FE !important',
    backgroundColor: 'white !important'
  }
}))

export default useStyles

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'

export default React.memo((props) => {
  const h = props.height ? props.height : '50px'

  const useStylesReddit = makeStyles((theme) => ({
    // style input only
    inputRoot: {
      height: h,
      color: '#fff',
      fontSize: '2.4rem',
      backgroundColor: 'transparent',
      border: 'none',
      '&$focused': {
        backgroundColor: 'transparent',
        border: 'none'
      },
      '&:hover': {
        backgroundColor: 'transparent',
        border: 'none'
      }
    },
    label: {
      top: '-8px',
      fontSize: '1.85rem',
      color: '#fff',
      '&$labelFocused': {
        color: '#fff'
      }
    },
    focused: {
      backgroundColor: 'transparent',
      border: 'none'
    },
    labelFocused: {
      color: '#fff'
    }
  }))

  const classes = useStylesReddit()

  return (
    <TextField
      variant={props.variant}
      disabled={props.disabled}
      fullWidth={props.fullWidth}
      multiline={props.multiline}
      rows={props.rows}
      InputProps={{
        readOnly: props.readOnly,
        classes: {
          root: classes.inputRoot,
          focused: classes.focused
        },
        endAdornment: props.endAdornment ? props.endAdornment : <></>
      }}
      InputLabelProps={{
        shrink: true,
        classes: {
          root: classes.label,
          focused: classes.labelFocused
        }
      }}
      inputProps={{
        className: `${props.center ? 'text-center' : ''}`
      }}
      // InputProps={{
      //   ...inputP
      // }}
      // InputLabelProps={{
      //   // shrink: true,
      //   className: 'bd-input-label-normal'
      // }}
      // InputLabelProps={{
      //   shrink: true,
      //   className: classes.label
      // }}
      // helperText={props.touched.password && props.errors.password}
      // error={props.touched.password && !!props.errors.password}
      placeholder={props.placeholder}
      label={props.label}
      type={props.type}
      name={props.name}
      value={props.value}
      defaultValue={props.defaultValue}
      id={props.id}
      onChange={props.onChange}
      className={props.className}
    />
  )
})

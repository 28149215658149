import BaseAPI from '../../api/BaseAPI'
import Constants from '../../config/Constants'

class ResetPasswordAPI extends BaseAPI {
  /**
   * Reset password API
   * @param {*} code code
   * @param {*} password password
   */
  async resetPassword({ code, new_password, user_id }) {
    try {
      const data = {
        code,
        new_password,
        user_id
      }

      const authURL = `${Constants.AUTHEN_SERVER_URL}/authen/reset-password`
      const response = await this.post(authURL, data)

      return response
    } catch (err) {
      console.log(err)
      throw err
    }
  }
}

export default ResetPasswordAPI

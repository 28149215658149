import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Checkbox from '@material-ui/core/Checkbox'

const useStylesReddit = makeStyles((theme) => ({}))

export default React.memo((props) => {
  // const classes = useStylesReddit()

  return (
    <>
      <Checkbox
        name={props.name}
        checked={props.checked}
        disabled={props.disabled}
        value={props.value}
        onChange={props.onChange}
        icon={
          <svg
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <circle cx='10' cy='10' r='9.5' fill='white' stroke='#D6D6D6' />
          </svg>
        }
        checkedIcon={
          <svg
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <circle cx='10' cy='10' r='10' fill='#7092FE' />
            <path
              d='M6.66602 10.8333L8.33268 12.5L13.3327 7.5'
              stroke='white'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        }
        color='primary'
      />
    </>
  )
})

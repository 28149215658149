const convertArrToObject = (routeList) => {
  return routeList.reduce((obj, route) => {
    let key = route.path.slice(0)
    obj[key] = route
    return obj
  }, {})
}

const formattedNumber = (val, digit = 2, blank = '-') => {
  return val == undefined || val == null
    ? blank
    : val.toLocaleString(undefined, { maximumFractionDigits: digit })
}

export { formattedNumber, convertArrToObject }

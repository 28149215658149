import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx'

import * as _ from 'lodash'
import Dropzone from 'react-dropzone'
import UploadAPI from '@src/api/UploadAPI'

import {
    Typography,
    Grid,
    CircularProgress,
    makeStyles,
    useTheme,
    IconButton
} from '@material-ui/core'

import CONSTANTS_CONFIG from '@src/config/Constants'
import Enums from '@src/config/Enums'

import { AlertUtil, UploadFile } from '@src/utils'

import ImgUploadSVG from '@src/assets/img/app/icon-camera.svg'
import IconTrashSVG from '@src/assets/img/app/icon-trash-w.svg'
import IconCameraSVG from '@src/assets/img/app/icon-camera-w.svg'
import IconDelete from '@src/assets/img/i-delete-white.svg'

const useStyles = makeStyles((theme) => ({
    wrap: {
        width: '546px',
        margin: theme.spacing(0, 'auto'),
        minHeight: '200px',
        maxHeight: '300px',
        display: 'flex',
        position: 'relative'
    },
    uploadBox: {
        marginTop: 0,
        borderWidth: '2px',
        width: '100%',
        background: '#F8F8F8',
        padding: 0,
        position: 'relative'
    },
    uploadContentText1: {
        fontSize: '14px',
        color: theme.palette.common.sGrey
    },
    uploadContentText2: {
        fontSize: '14px',
        color: theme.palette.common.sGrey
    },
    preview: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    imageBox: {
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    },
    img:{
        maxHeight: '100%'
    },
    imgWrap: {
        position: 'relative',
        maxWidth: '71px',
        maxHeight: '71px',
        overflow: 'hidden',
        // display: 'flex',
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(1),
        borderRadius: theme.palette.common.radiusValue,
        '&:hover:$after': {
            cursor: 'pointer',
            content: '""',
            position: 'absolute',
            zIndex: 1,
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background:
                'linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))',
            borderRadius: theme.palette.common.radiusValue
        },
        '&:hover:$before': {
            cursor: 'pointer',
            content: '""',
            zIndex: 2,
            top: 0,
            left: 0,
            position: 'absolute',
            width: '100%',
            height: '100%',
            background: `url(${IconDelete}) no-repeat center`,
            borderRadius: theme.palette.common.radiusValue
        }
    },
    loadingBox: {
        position: 'relative',
        maxWidth: '71px',
        maxHeight: '71px',
        overflow: 'hidden',
        // display: 'flex',
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(1),
        borderRadius: theme.palette.common.radiusValue,
        '&:$before': {
            cursor: 'pointer',
            content: '""',
            zIndex: 1,
            top: 0,
            left: 0,
            position: 'absolute',
            width: '100%',
            height: '100%',
            background:
                'linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))',
            borderRadius: theme.palette.common.radiusValue
        }
    },
    imgLoading: {
        position: 'absolute',
        zIndex: 2,
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        width: '100%',
        height: '100%',
        margin: 'auto',
        color: theme.palette.common.sWhite
    },
    removeButton:{
        background: 'rgba(0, 0, 0, 0.35)',
        width: '32px',
        height: '32px',
        padding: '0',
        position: 'absolute',
        right: 16,
        top: 16,
        '&:hover':{
            background: 'rgba(0, 0, 0, 0.65)',
        }
    },
    cameraButton:{
        background: 'rgba(0, 0, 0, 0.35)',
        width: '32px',
        height: '32px',
        padding: '0',
        position: 'absolute',
        right: 14,
        top: 56,
        '&:hover': {
            background: 'rgba(0, 0, 0, 0.65)',
        }        
    }
}))

const acceptedFileTypes = 'image/*' // 'image/x-png, image/png, image/jpg, image/jpeg'
const imageMaxSize = +CONSTANTS_CONFIG.MAX_IMAGE_SIZE // bytes

const acceptedFileTypesArray = acceptedFileTypes.split(',').map((item) => {
    return item.trim()
})
const uploadAPI = new UploadAPI()

export default observer(
    ({ onChange, className, image }) => {
        const classes = useStyles()
        const theme = useTheme()

        let storeFiles = []

        const [srcImage, setSrcImage] = useState('')

        const handleOnDrop = async (acceptedFiles) => {
            const reader = new FileReader()
            reader.addEventListener("load", () => {
                setSrcImage(reader.result)
            });
            reader.readAsDataURL(acceptedFiles[0])

            if (onChange) onChange(acceptedFiles)
        }

        useEffect(() => {
            setSrcImage(image)
        }, [image])

        const handleRemoveImage = (e) => {
            e.preventDefault()
            setSrcImage('')
            if (onChange) onChange(null)
        }

        return (
            <div className={`${classes.wrap} ${className}`}>
                <Dropzone
                    accept={acceptedFileTypes}
                    maxSize={imageMaxSize}
                    onDrop={handleOnDrop}
                >
                    {({ getRootProps, getInputProps }) => (
                        <section
                            className={`cursor-pointer upload-box ${classes.uploadBox}`}
                        >
                            <div {...getRootProps()} className='w-100 h-100'>
                                <input {...getInputProps()} />
                                <div className='text-center flex-column d-flex w-100 h-100 justify-content-center align-items-center'>
                                    {srcImage ? (
                                        <>
                                            <img className={classes.img} src={srcImage} />
                                            <IconButton className={classes.cameraButton}>
                                                <img src={IconCameraSVG} />
                                            </IconButton>
                                        </>
                                    ) : (
                                            <>
                                                <div className='text-center'>
                                                    <img src={ImgUploadSVG} alt='' />
                                                </div>
                                                <p className='text-center mb-0'>
                                                    <span className={classes.uploadContentText1}>
                                                        Drag a photo here or direct manually
                                                    </span>
                                                    <br />
                                                    <span className={classes.uploadContentText2}>
                                                        Only jpg, png or bmp extensions. Maximum size 10MB
                                                    </span>
                                                </p>
                                            </>
                                        )}
                                </div>
                            </div>
                        </section>
                    )}
                </Dropzone>
                {srcImage && (
                    <IconButton className={classes.removeButton} onClick={handleRemoveImage}>
                        <img src={IconTrashSVG} />
                    </IconButton>
                )}
            </div>
        )
    }
)

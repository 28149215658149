import React, { useState, useEffect, useContext } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
    Typography,
    Grid,
    makeStyles,
    Box,
    FormControl,
    MenuItem,
    LinearProgress
} from '@material-ui/core'
import { PButton, PIconText, PFillTextField, PSelect } from '@src/components/PCustom'
import GreenCheckbox from '@src/components/common/GreenCheckbox'
import { IconDownArrow } from '@src/components/PCustom/SVGIcons'

import { AlertUtil } from '@src/utils'
import Constants from '@src/config/Constants'
import Form941Panel from './Form941'
import Form941_2023Panel from './Form941_2023'
import Form941XPanel from './Form941X'
import Form941sbPanel from './Form941sb'
import Form8974Panel from './Form8974'
import Form941API from '../Form941API'
import { initialForm941Y2022 } from '../constant'
const colorS = Constants.COLORS
const form941API = new Form941API()

const FORM_TYPES = ['941_2023', '941', '941x', '941sb', '8974', '8974_2023']

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        width: '100%',
        position: 'relative',
        flexDirection: 'column',
        padding: '20px 0 0 0',
        margin: 'auto',
        alignItems: 'center'
    },
    listBox: {
        border: `1px solid #ddd`,
        borderRadius: '4px',
        overflow: 'auto',
        maxHeight: '500px'
    },
    buttonProgress: {
        // style for button loading
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    wrapperButton: {
        position: 'relative'
    },
    selector: {
        height: '40px',
        marginRight: '12px',
        '& > div': {
            padding: '5px'
        }
    },
    textField: {
        padding: 8,
        borderRadius: 4,
        border: `1px solid #aaa`,
        fontFamily: `Ubuntu`,
        fontSize: '14px',
        width: "100%",
        '&[readonly]': {
            background: '#eee'
        }
    },
    table: {
        width: '100%',
        fontSize: '14px',
        marginBottom: 60,
        '& tr td': {
            padding: 4
        }
    },
    progress: {
        width: '100%',
        marginTop: '-60px',
        marginBottom: '60px'
    }
}))

export default ({ storeNotification }) => {
    const classes = useStyles()
    const history = useHistory()

    const [selectedCompany, setSelectedCompany] = useState('')
    const [companies, setCompanies] = useState([]);
    const [selectedQuarter, setSelectedQuarter] = useState('')
    const [selectedForm, setSelectedForm] = useState('')
    const [quarters, setQuarters] = useState(() => []);
    const [loading, setLoading] = useState(false);


    const [form941Data, setForm941Data] = useState(initialForm941Y2022)

    useEffect(() => {
        loadData()
        const storedCompany = localStorage.getItem('company')
        if (storedCompany) {
            handleChangeCompany(storedCompany)
        }
    }, [])

    const loadData = async () => {
        try {
            const companyResp = await form941API.getCompanies()
            setCompanies(companyResp.data)
        } catch (e) {
            const m = e.message ? e.message : e.toString()
            AlertUtil.showErrorAlert(storeNotification, m)
        }
    }
    const handleChangeCompany = async (e) => {
        setSelectedCompany(e)
        localStorage.setItem('company', e)

        if (!e) {
            setQuarters([])
            return
        }
        try {
            const companyResp = await form941API.getCompany(e)
            setQuarters((companyResp.data.quarters || []).map(item => { return { _id: item, name: item } }))
        } catch (e) {
            const m = e.message ? e.message : e.toString()
            AlertUtil.showErrorAlert(storeNotification, m)
        }
    }

    const handleChangeQuarter = async (e) => {
        setSelectedQuarter(e)
        try {
            const companyResp = await form941API.getForm941Fields({ company: selectedCompany, quarter: e })
            setForm941Data(companyResp.data || initialForm941Y2022)
        } catch (e) {
            const m = e.message ? e.message : e.toString()
            AlertUtil.showErrorAlert(storeNotification, m)
        }
    }

    const handleChangeForm = async (e) => {
        setSelectedForm(e)
    }

    return (
        <div className={classes.container}>
            {loading && <LinearProgress className={classes.progress} />}
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <Box display="flex" flexDirection="column">
                        <Typography variant="h4" className="mb-24">Form & Company</Typography>

                        <FormControl variant="filled" className="mb-24">
                            <PSelect
                                labelId="form-label"
                                variant="filled"
                                displayEmpty
                                IconComponent={IconDownArrow}
                                value={selectedForm} onChange={(e) => handleChangeForm(e.target.value)}
                                className={classes.selector}
                            >
                                <MenuItem value={''}>Select a Form</MenuItem>
                                {FORM_TYPES.map((item, index) => (
                                    <MenuItem value={item} key={index}>{item}</MenuItem>
                                ))}
                            </PSelect>
                        </FormControl>

                        <FormControl variant="filled" className="mb-24">
                            <PSelect
                                labelId="company-label"
                                variant="filled"
                                displayEmpty
                                IconComponent={IconDownArrow}
                                value={selectedCompany} onChange={(e) => handleChangeCompany(e.target.value)}
                                className={classes.selector}
                            >
                                {([{ _id: '', name: 'Select a Company' }, ...companies] || []).map((item, index) => (
                                    <MenuItem value={item._id} key={index}>{item.name}</MenuItem>
                                ))}
                            </PSelect>
                        </FormControl>

                        <FormControl variant="filled" className="mb-24">
                            <PSelect
                                labelId="quarter-label"
                                variant="filled"
                                displayEmpty
                                IconComponent={IconDownArrow}
                                value={selectedQuarter} onChange={(e) => handleChangeQuarter(e.target.value)}
                                className={classes.selector}
                            >
                                {([{ _id: '', name: 'Select a Quarter' }, ...quarters] || []).map((item, index) => (
                                    <MenuItem value={item._id} key={index}>{item.name}</MenuItem>
                                ))}
                            </PSelect>
                        </FormControl>

                    </Box>
                </Grid>
                <Grid item xs={9}>
                    {selectedForm === '941' && (
                        <Form941Panel storeNotification={storeNotification} company={selectedCompany} quarter={selectedQuarter}
                            onLoading={(e) => setLoading(e)} />
                    )}
                    {selectedForm === '941_2023' && (
                        <Form941_2023Panel storeNotification={storeNotification} company={selectedCompany} quarter={selectedQuarter}
                            onLoading={(e) => setLoading(e)} />
                    )}
                    {selectedForm === '941x' && (
                        <Form941XPanel storeNotification={storeNotification} company={selectedCompany} quarter={selectedQuarter}
                            onLoading={(e) => setLoading(e)} />
                    )}
                    {selectedForm === '941sb' && (
                        <Form941sbPanel storeNotification={storeNotification} company={selectedCompany} quarter={selectedQuarter}
                            onLoading={(e) => setLoading(e)} />
                    )}
                    {(selectedForm === '8974' || selectedForm === '8974_2023') && (
                        <Form8974Panel storeNotification={storeNotification} company={selectedCompany} quarter={selectedQuarter}
                            onLoading={(e) => setLoading(e)} formType={selectedForm}/>
                    )}
                </Grid>
            </Grid>
        </div>
    )
}

import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'

function SignInSide (props) {
  let isLogin = false
  // check user login
  if (!props.storeUser.hasUser) {
    isLogin = false
  } else {
    isLogin = true
  }

  if (!isLogin) {
    return <Redirect to='/auth/signin' />
  }
  return (
    <Redirect to='/tracker' />
  )
}

export default SignInSide

import ENUM from '../../config/Enums'

import { createContext } from 'react'
import { decorate, observable, computed } from 'mobx'

export class NotificationStore {
  data = {
    type: ENUM.NotificationType.ERROR,
    message: '',
    isOpen: false
  }

  get type() {
    return this.data.type
  }

  get message() {
    return this.data.message
  }

  get isOpen() {
    return this.data.isOpen
  }

  setIsOpen = (data) => {
    this.data.isOpen = data
  }

  setContent = (type, message) => {
    this.data.type = type
    this.data.message = message
  }
}

decorate(NotificationStore, {
  data: observable,
  type: computed,
  message: computed,
  isOpen: computed,
})

export default createContext(new NotificationStore())

import React from 'react'
import {
  Card,
  CardMedia,
  CardContent
} from '@material-ui/core'

export default React.memo((props) => {
  return (
    <Card className='card ph-item'>
      <CardMedia className='cardMedia'>
        <svg
          width='48'
          height='48'
          viewBox='0 0 48 48'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <rect width='48' height='48' rx='4' fill='#F0F0F0' />
        </svg>
      </CardMedia>
      <div className='cardDetails align-self-center'>
        <CardContent className='cardContent cardContentAnimate'>
          <svg
            width='344'
            height='20'
            viewBox='0 0 344 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <rect width='120' height='8' rx='4' fill='#F0F0F0' />
            <rect x='320' width='24' height='8' rx='4' fill='#F0F0F0' />
            <rect y='12' width='80' height='8' rx='4' fill='#F0F0F0' />
          </svg>
        </CardContent>
      </div>
    </Card>
  )
})

import Constants from '../config/Constants'
import Enums from '../config/Enums'
import AlertUtil from './AlertUtil'
import UploadS3API from '@src/api/UploadS3API'
import UploadAPI from '@src/api/UploadAPI'

const uploadS3API = new UploadS3API()
const uploadAPI = new UploadAPI()

const KEY_LENGTH = 10

class UploadFile {
  storeFiles = []

  constructor ({ storeNotification, uploadKind }) {
    this.storeNotification = storeNotification
    this.uploadKind = uploadKind
  }

  upload = async (acceptedFiles) => {
    const files = acceptedFiles.map((file) => {
      const key = this.randomKey()
      return {
        image: file,
        status: Enums.FileUploadStatus.SELECTED,
        key: key
      }
    })
    this.storeFiles = [...this.storeFiles, ...files]

    // Upload File
    const promises = this.storeFiles.map((file) => {
      return this.handleFile(file)
    })

    await Promise.all(promises)
  }

  randomKey = () => {
    var result = ''
    var characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    var charactersLength = characters.length
    for (var i = 0; i < KEY_LENGTH; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }

  handleFile = async (file) => {
    if (file.status === Enums.FileUploadStatus.SELECTED) {
      const dataCreated = await this.createUpload(file)
      await this.uploadFile(file, dataCreated)
    }
  }

  createUpload = async (file) => {
    try {
      const image = file.image

      const fileName = image.name
      const fileSize = image.size
      file.status = Enums.FileUploadStatus.UPLOADING

      const response = await uploadAPI.postFile(
        fileName,
        fileSize,
        this.uploadKind
      )
      console.log('response.data')
      console.log(response)
      const presign = response.data

      return presign
    } catch (err) {
      console.log('err')
      console.log(err)
      file.status = Enums.FileUploadStatus.ERROR

      if (this.storeNotification) {
        const m = err.message
        AlertUtil.showErrorAlert(this.storeNotification, m)
      }
    }
  }

  uploadFile = async (file, data) => {
    try {
      console.log('data')
      console.log(data)
      await uploadS3API.uploadPresignFile(file, data.presign_url)
      file.status = Enums.FileUploadStatus.SUCCESS
      file._id = data._id
      file.dataUpload = {
        upload_id: data._id,
        bucket: data.bucket,
        key: data.key,
        file_name: data.file_name,
        file_size: data.file_size
      }
    } catch (err) {
      file.status = Enums.FileUploadStatus.ERROR

      if (this.storeNotification) {
        const m = err.message
        AlertUtil.showErrorAlert(this.storeNotification, m)
      }
    }
  }
}

export default UploadFile

import React from 'react'
import { withRouter, Link } from 'react-router-dom'

import { Container, Box } from '@material-ui/core'

import SignUpResend from '../SignUpResend/SignUpResend'
import TitleGroupAuth from '@src/components/common/TitleGroupAuth'

import UseStyles from './SignUpConfirmStyle'

const useStyles = UseStyles

function SignUpConfirm(props) {
  const query = new URLSearchParams(props.location.search)
  const email = query.get('email')
  const _id = query.get('_id')

  const classes = useStyles()

  return (
    <>
      <Container component='main' maxWidth='xs' className={classes.main}>
        <div className={classes.paper}>
          <TitleGroupAuth
            title={'Confirm your e-mail'}
            description={`To activate your account use the link in the email we’ve sent to
              <b>${email}</b>, and you'll be ready to go.`}
          />

          <div className={classes.boxLink}>
            <SignUpResend
              email={email}
              _id={_id}
              storeNotification={props.storeNotification}
            />

            <Link
              className={classes.secondLink}
              to={`/auth/signup/change?email=${email}&_id=${_id}`}
              variant='body2'
            >
              <Box fontSize='1.4rem'>This is not my e-mail</Box>
            </Link>
          </div>
        </div>
      </Container>
    </>
  )
}

export default withRouter(SignUpConfirm)

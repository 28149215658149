import React from 'react'
import { withRouter, Link } from 'react-router-dom'

import { Typography, Container, Box } from '@material-ui/core'

import ForgotPasswordResend from '../ForgotPasswordResend/ForgotPasswordResend'

import TitleGroupAuth from '@src/components/common/TitleGroupAuth'
import UseStyles from './ForgotPasswordConfirmStyle'

const useStyles = UseStyles

function SignUpConfirm(props) {
  const query = new URLSearchParams(props.location.search)
  const email = query.get('email')

  const classes = useStyles()

  return (
    <>
      <Container component='main' maxWidth='xs' className={classes.main}>
        <div className={classes.paper}>
          <TitleGroupAuth
            title={'Password change link sent'}
            description={`To continue, follow the instructions in the email we sent to <b>${email}</b>.`}
          />

          <div className={classes.boxLink}>
            <ForgotPasswordResend
              email={email}
              storeNotification={props.storeNotification}
            />

            <Link
              className={classes.secondLink}
              to='/auth/forgot-password'
              variant='body2'
            >
              Email is not coming?
            </Link>

            <Link
              className={classes.secondLink}
              to='/auth/signin'
              variant='button'
              color='textPrimary'
            >
              Return
            </Link>
          </div>
        </div>
      </Container>
    </>
  )
}

export default withRouter(SignUpConfirm)

import React, { useEffect, useState } from 'react'

import { makeStyles, useTheme } from '@material-ui/core/styles'

import { Avatar } from '@material-ui/core'
import IconUser from '@src/assets/img/p-user.svg'
import { getResizedSrc } from '@src/components/common/LazyImage'

// import CompanyAPI from '../../../components/Company/CompanyAPI'
// import AlertUtil from '../../../utils/AlertUtil'
const colours = [
  '#7092FE',
  '#E188EC',
  '#FF87BF',
  '#FFA18D',
  '#FFCC6A',
  '#F9F871'
]

const useStyles = makeStyles((theme) => ({
  imgCircle: {
    color: theme.palette.common.sWhite
  }
}))

export default React.memo(
  ({ variant = 'circular', src, alt, className, resized = null }) => {
    const classes = useStyles()

    const [name, setName] = useState('')
    // const [source, setSource] = useState(IconUser)

    useEffect(() => {
      if (alt) {
        const arr = alt.split(' ')

        // get first character in the string
        if (arr.length > 1) {
          setName(arr[0].charAt(0) + arr[1].charAt(0))
        } else {
          setName(arr[0].charAt(0))
        }
      }
    }, [alt])

    return (
      <Avatar
        variant={variant}
        src={
          src
            ? getResizedSrc(resized, src, 'x-small')
            : name
            ? getResizedSrc(resized, src, 'x-small')
            : IconUser
        }
        alt={alt}
        className={`${className} ${classes.imgCircle}`}
        style={{
          backgroundColor: name
            ? colours[(name.charCodeAt(0) - 65) % 6]
            : '#ffffff'
        }}
      >
        {name}
      </Avatar>
    )
  }
)

import React, { useContext } from 'react'
import { observer } from 'mobx-react-lite'

import NotificationStore from './NotificationStore'
import NotificationContent from './NotificationContent'
import { makeStyles } from '@material-ui/core/styles'

import { Snackbar } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    top: 0,
    width: '100vw',
    height: 'fit-content',
    padding: '5px 0',
    background: '#FFFFFF',
    boxShadow:
      '0px 9px 46px rgba(0, 0, 0, 0.02), 0px 24px 38px rgba(0, 0, 0, 0.04), 0px 11px 15px rgba(0, 0, 0, 0.04)'
  }
}))

const Notification = () => {
  const store = useContext(NotificationStore)
  const classes = useStyles()

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    store.setIsOpen(false)
  }

  const closeNotification = () => {
    store.setIsOpen(false)
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      open={store.isOpen}
      autoHideDuration={5000}
      onClose={() => closeNotification()}
      classes={{
        root: classes.root
        // anchorOriginTopCenter: classes.anchorOriginTopCenter,
        // anchorOriginTopCenter: classes.anchorOriginTopCenter,
        // anchorOriginTopCenter: classes.anchorOriginTopCenter,
      }}
    >
      <NotificationContent
        variant={store.type}
        message={store.message}
        onClose={handleClose}
      />
    </Snackbar>
  )
}

export default observer(Notification)

import React from 'react'
import * as _ from 'lodash'
import { withRouter, Link } from 'react-router-dom'
import {
  Button,
  CircularProgress,
  FormControlLabel,
  Container
} from '@material-ui/core'

import { withFormik, Form, Field } from 'formik'
import * as Yup from 'yup'

import TitleGroupAuth from '@src/components/common/TitleGroupAuth'
import TextFieldV2 from '@src/components/common/TextFieldV2'
import GreenCheckbox from '@src/components/common/GreenCheckbox'

// import GreenCheckbox from '@src/components/common/'

import ENUMS from '@src/config/Enums'
import Constants from '@src/config/Constants'

import { UtilityUtil, AlertUtil } from '@src/utils'

import AuthenAPI from '@src/api/AuthenAPI'
import UseStyles from './SignInStyle'

const useStyles = UseStyles

const CALLBACK_UTILITY_ACCOUNT = 'UtilityAccountCallBack'
const SETTING_PERSONAL_DATA_URL = '/settings/personal-data'

function SignInSide(props) {
  const classes = useStyles()
  const { touched, errors, values } = props

  // Popover
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const isDisable = () =>
    Object.keys(errors).some((item) => errors[item]) ||
    Object.keys(values).some((item) => values[item].trim().length === 0)

  return (
    <>
      <Container component='main' maxWidth='xs' className={classes.main}>
        <div className={classes.paper}>
          <TitleGroupAuth title={'Sign in to Tax Tracker'} />

          <Form className={classes.form} noValidate>
            <Field
              name='username'
              render={({ field }) => (
                <TextFieldV2
                  variant='filled'
                  margin='normal'
                  fullWidth
                  className='mb-0'
                  textkey='username'
                  label='Email address'
                  showcheckicon='true'
                  autoComplete='username'
                  {...{ touched, errors, values }}
                  {...field}
                />
              )}
            />

            <Field
              name='password'
              render={({ field }) => (
                <TextFieldV2
                  variant='filled'
                  margin='normal'
                  fullWidth
                  className='mb-0'
                  textkey='password'
                  type='password'
                  label='Password'
                  showcheckicon='true'
                  autoComplete='current-password'
                  {...{ touched, errors, values }}
                  {...field}
                />
              )}
            />

            <FormControlLabel
              className={classes.checkBox}
              control={<GreenCheckbox value='remember' />}
              label='Stay signed in'
            />

            <div className={classes.wrapperButton}>
              <Button
                type='submit'
                fullWidth
                variant='contained'
                color='default'
                disabled={isDisable() || props.isSubmitting}
                className={`${classes.submit} text-transform-reset`}
              >
                Sign in into Tax Tracker
              </Button>
              {props.isSubmitting && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </Form>
          <Link
            className={classes.secondLink}
            to='/auth/forgot-password'
            variant='body2'
          >
            Can’t sign in?
          </Link>
        </div>
      </Container>
    </>
  )
}

const FormikForm = withFormik({
  mapPropsToValues() {
    // Init form field
    return {
      username: '',
      password: ''
    }
  },
  validationSchema: Yup.object().shape({
    // Validate form field
    username: Yup.string()
      .required('Email is required')
      .email('Email is invalid'),
    password: Yup.string()
      .required('Password is required')
      // .matches(
      //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/,
      //   'Password must contain atleast 1 small-case letter, 1 Capital letter, 1 digit, 1 special character'
      // )
      .min(8, 'Password must have min 8 characters')
      .max(30, 'Password have max 100 characters')
  }),
  handleSubmit: async (values, { props, setMessage, setOpenMessage }) => {
    try {
      const responses = await AuthenAPI.signin(values)
      const loginData = _.cloneDeep(responses.data)
      if (!responses.status) {
        throw loginData
      }
      console.log('loginData')
      console.log(loginData)
      props.storeUser.setUser(loginData)
      props.history.push('/tracker')
    } catch (err) {
      console.log(err)
      const m = 'Login failed!'
      AlertUtil.showErrorAlert(props.storeNotification, m)
    }
  }
})(SignInSide)

export default withRouter(FormikForm)

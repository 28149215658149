export const emptyTextCell = { type: "text", text: "" };

const numberFormat = new Intl.NumberFormat("us", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});

const dateFormat = new Intl.DateTimeFormat('en-US', { timeZone: "Europe/London" });

export const textCell = (text, className = "", style, colspan) => ({ type: "text", text, className, style, colspan });
export const toolTipCell = (text, className = "", style, colspan) => ({ type: "tooltip", text, className, style, colspan });

export const dropdownCell = (values, selectedValue, isOpen) => ({ type: "dropdown", values, selectedValue, isOpen });

export const numberCell = (value, className = "", style) => ({
  type: "number",
  value,
  className,
  style,
  format: numberFormat,
});

export const dateCell = (date, className = "", style) => ({ type: "date", date, className, style, format: dateFormat });

export const nonEditable = (cell) => ({
  ...cell,
  nonEditable: true
});

export const showZero = (cell) => ({ ...cell, nanToZero: true, hideZero: false });
export const hideZero = (cell) => ({ ...cell, nanToZero: true, hideZero: true });

export const bottomLine = (cell) => ({
  ...cell,
  style: {
    ...cell.style,
    border: {
      border: cell.style ? cell.style.border : null,
      bottom: {
        width: "1px",
        color: "#A6A6A6",
        style: "solid"
      }
    }
  }
});

export const noSideBorders = (cell) => ({
  ...cell,
  style: {
    ...cell.style,
    border: {
      border: cell.style ? cell.style.border : null,
      // ...cell.style?.border,
      left: {
        style: "none"
      },
      right: {
        style: "none"
      }
    }
  }
});

export const monthHeaderCell = (
  month,
  additionalClassNames = "",
  colspan = 1,
) => {
  return nonEditable(
    textCell(month, `text-lg font-bold ${additionalClassNames}`, {
      background: "#107C41",
      color: "white",
      border: {
        bottom: { style: "none" },
        left: { style: "none" },
        right: { style: "none" }
      }
    }, colspan)
  );
}

import React, { useState, useEffect } from 'react'
import {
    TextField,
    makeStyles,
    withStyles,
    ClickAwayListener,
    Typography,
    FormControl,
    Select,
    MenuItem,
    FormHelperText,
    InputLabel,
} from '@material-ui/core'

import {
    IconDownArrow,
    IconUpArrow,
} from '@src/components/PCustom/SVGIcons'

import Constants from '@src/config/Constants'
const colorS = Constants.COLORS

const useStyles = makeStyles((theme) => ({
    inputSelectRoot: {
        height: '56px',
        minWidth: '180px',
        paddingLeft: 0,
        paddingRight: 0,
        backgroundColor: theme.palette.common.sBlueAccent3,
        fontSize: '14px',
        border: `1px solid ${theme.palette.common.sBlueAccent3}`,
        '&$inputSelectFocus': {
            border: `1px solid ${theme.palette.common.sBlueAccent3}`,
        }
    },
    inputSelectFocus: {},
    inputSelectFullWidth: {
        width: '100%'
    },
    selectRoot: {
        padding: '30px 16px 10px',
        backgroundColor: 'transparent',
        border: 'none',
        '&:focus': {
            backgroundColor: 'transparent'
        },
        '& + img': {
            right: '16px',
            top: 'calc(50% - 3px)',
        }
    },
    labelRoot: {
        fontSize: '14px',
        color: colorS.sGrey
    },
    labelFilled: {
        '&$labelShrink': {
            transform: 'translate(16px, 10px) scale(1)'
        }
    },
    labelShrink: {}
}))

const PFillSelect = ({ menuItems = [], onChange, label, className, disabled, value }) => {
    const classes = useStyles()

    const [selectedValue, setSelectedValue] = useState('')
    useEffect(() => {
        setSelectedValue(value)
    }, [value])

    const handleChange = (event) => {
        setSelectedValue(event.target.value)
        if (onChange) onChange(event.target.value)
    }

    return (
        <TextField
            value={selectedValue}
            onChange={handleChange}
            label={label}
            select
            variant='filled'
            InputLabelProps={{
                classes: {
                    root: classes.labelRoot,
                    filled: classes.labelFilled,
                    shrink: classes.labelShrink,
                }
            }}
            SelectProps={{
                classes: {
                    select: classes.selectRoot
                },
                IconComponent: IconDownArrow,
                native: true
            }}
            InputProps={{
                classes: {
                    root: classes.inputSelectRoot,
                    focused: classes.inputSelectFocus
                }
            }}
            className={className}
            disabled={disabled}
        >
            {menuItems.map((item, index) => (
                <option value={item.value} key={index}>{item.label}</option>
            ))}
        </TextField>
    )
}

const useOutlineStyles = makeStyles((theme) => ({
    selectRoot: {
        height: '48px',
        minWidth: '180px',
        padding: 0,
        borderRadius: 6,
        backgroundColor: colorS.sWhite,
        fontSize: '14px',
        border: `1px solid #d6d6d6`,
        cursor: 'pointer',
        position: 'relative',
        display: 'inline-flex',
        '&:focus': {
            backgroundColor: 'transparent'
        },
        '& > img': {
            position: 'absolute',
            right: '12px',
            top: 'calc(50% - 3px)',
        }
    },
    dropdownLabel: {
        display: 'block',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        flex: '1 1 auto',
        width: '1%',
        textOverflow: 'ellipsis',
        cursor: 'pointer',
        height: '16px',
        fontSize: '14px',
        lineHeight: '16px',
        marginRight: '8px'
    },
    dropdown: {
        fontSize: '14px',
        zIndex: '1000',
        transformOrigin: 'center top',
        top: '48px',
        left: '0px',
        minWidth: '100%',
        position: 'absolute',
        boxSizing: 'border-box',
        background: '#ffffff',
        border: '0 none',
        borderRadius: '3px',
        boxShadow: '0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%)',
    },
    dropdownWrap: {
        overflow: 'auto',
        maxHeight: '200px',
        margin: '5px 0',
    },
    dropdownItems: {
        padding: 0,
        margin: '0',
        listStyleType: 'none',
    },
    dropdownItem: {
        margin: '0',
        padding: '0.5rem 1rem',
        background: 'transparent',
        transition: 'box-shadow 0.2s',
        cursor: 'pointer',
        fontWeight: '400',
        whiteSpace: 'nowrap',
        position: 'relative',
        overflow: 'hidden',
        '&:hover': {
            background: colorS.sGrey20
        },
        '&$itemSelected': {
            background: colorS.sPurple,
            color: colorS.sWhite
        }
    },
    itemSelected: {},
    dropdownLabelBox: {
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'inline-flex',
        alignItems: 'center',
        padding: 16,
    }
}))

const POutlineSelect = ({ menuItems = [], onChange, className, disabled, style, value }) => {
    const classes = useOutlineStyles()
    const [selectedItem, setSelectedItem] = useState({})
    const [open, setOpen] = useState(false)

    useEffect(() => {
        if (value) {
            const initItem = menuItems.find(item => item.value == value)
            setSelectedItem(initItem)
        } else {
            if (menuItems.length) {
                setSelectedItem(menuItems[0])
            }
        }
    }, [menuItems, value])

    const handleChange = (item) => {
        setSelectedItem(item)
        setOpen(false)
        if (onChange) onChange(item.value)
    }

    const handleClick = () => {
        setOpen((prev) => !prev)
    }

    const handleClickAway = () => {
        setOpen(false)
    }

    return (
        <ClickAwayListener
            mouseEvent="onMouseDown"
            touchEvent="onTouchStart"
            onClickAway={handleClickAway}
        >
            <div className={`${classes.selectRoot} ${className}`} style={style}>
                <div className={classes.dropdownLabelBox} onClick={handleClick}>
                    <Typography component="span" className={classes.dropdownLabel}>{selectedItem.label}</Typography>
                    {open ? <IconUpArrow /> : <IconDownArrow />}
                </div>
                {open ? (
                    <div className={classes.dropdown}>
                        <div className={classes.dropdownWrap}>
                            <ul className={classes.dropdownItems}>
                                {menuItems.map((item, index) => (
                                    <li
                                        key={index}
                                        onClick={(e) => handleChange(item)}
                                        className={`${classes.dropdownItem} ${item.value == selectedItem.value && classes.itemSelected}`}
                                    >{item.label}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                ) : null}
            </div>
        </ClickAwayListener>
    )
}

const PSelect = withStyles(() => ({
    root: {},
    select:{
        borderWidth: 0,
        '&:focus':{
            background: 'transparent'
        }
    },
    filled: {},
    outlined: {},
    selectMenu: {},
    disabled: {},
    icon: {
        top: `calc(50% - 3px)`,
        color: colorS.sBlack
    },
    iconOpen: {},
    iconFilled: {},
    iconOutlined: {
        right: `16px`
    },
}))(Select)

export { PFillSelect, POutlineSelect, PSelect }
import { makeStyles } from '@material-ui/core/styles'
import { green } from '@material-ui/core/colors'

const useStyles = makeStyles((theme) => ({
  main: {
    maxWidth: '400px',
    paddingLeft: 0,
    paddingRight: 0
  },
  paper: {
    margin: theme.spacing(8, 0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    minHeight: '5.6rem',
    fontWeight: 400,
    width: '48%'
  },
  defaultColor: {
    color: '#7092FE'
  },

  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -7,
    marginLeft: -12
  },
  wrapperButton: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between'
  },
  boxLink: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  secondLink: {
    margin: theme.spacing(0.5, 0.75),
    fontWeight: 400,
    color: '#7092FE',
    textDecoration: 'none'
  },
  disabledClick: {
    margin: theme.spacing(0.5, 0.75),
    fontWeight: 400,
    fontSize: '1.4rem'
  }
}))

export default useStyles

import React, { useState, useEffect, useContext } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  Typography,
  Grid,
  makeStyles,
  Box,
  FormControl,
  CircularProgress,
  MenuItem,
  Input,
  FormControlLabel
} from '@material-ui/core'
import { PButton, PIconText, PFillTextField, PSelect } from '@src/components/PCustom'
import GreenCheckbox from '@src/components/common/GreenCheckbox'
import { IconDownArrow } from '@src/components/PCustom/SVGIcons'

import { AlertUtil } from '@src/utils'
import Constants from '@src/config/Constants'
import Form941API from '../Form941API'
const colorS = Constants.COLORS
const form941API = new Form941API()
const formText = {
  '8974': '8974',
  '8974_2023': '8974 (2023)',
}
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    position: 'relative',
    flexDirection: 'column',
    padding: '20px 0 0 0',
    margin: 'auto',
    alignItems: 'center'
  },
  listBox: {
    border: `1px solid #ddd`,
    borderRadius: '4px',
    overflow: 'auto',
    maxHeight: '500px'
  },
  buttonProgress: {
    // style for button loading
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  wrapperButton: {
    position: 'relative'
  },
  selector: {
    height: '40px',
    marginRight: '12px',
    '& > div': {
      padding: '5px'
    }
  },
  textField: {
    padding: 8,
    borderRadius: 4,
    border: `1px solid #aaa`,
    fontFamily: `Ubuntu`,
    fontSize: '14px',
    width: "100%",
    '&[readonly]': {
      background: '#eee'
    }
  },
  table: {
    width: '100%',
    fontSize: '14px',
    marginBottom: 60,
    '& tr td': {
      padding: 4
    }
  }
}))

export default ({ storeNotification, company, quarter, onLoading, formType }) => {
  const classes = useStyles()
  const history = useHistory()

  const [loading, setLoading] = useState(false);
  const [line16, setLine16] = useState(0)

  useEffect(() => {
    handleChangeQuarter()
  }, [company, quarter])

  const handleChangeQuarter = async (e) => {
    if (!company || !quarter) return
    onLoading(true)
    try {
      const companyResp = await form941API.getForm941XLine16({ company, quarter })
      setLine16((companyResp.data || {}).value || 0)
    } catch (e) {
      const m = e.message ? e.message : e.toString()
      AlertUtil.showErrorAlert(storeNotification, m)
    }
    onLoading(false)
  }

  const handleViewPDF = async () => {
    if (!company || !quarter) return
    setLoading(true)
    try {
      const pdfResp = await form941API.exportPDF({
        company: company,
        form_type: formType,
        quarter: quarter
      })

      const pdfURL = pdfResp.data.name || ''
      if (pdfURL) {
        window.open(`${Constants.API_URL}/storage/pdf?path=${pdfURL}`, "_blank");
      }

    } catch (e) {
      const m = e.message ? e.message : e.toString()
      AlertUtil.showErrorAlert(storeNotification, m)
    }
    setLoading(false)
  }

  return (
    <div>
      <Box display="flex" justifyContent="space-between" alignItems="center" className="mb-24">
        <Typography variant="h4">Form {formText[formType]}</Typography>
        <Box display="flex">
          <div className={classes.wrapperButton}>
            <PButton variant="contained" color="primary" className="" onClick={handleViewPDF} disabled={!company || !quarter}>View PDF {quarter}</PButton>
            {loading && (
              <CircularProgress
                size={24}
                className={classes.buttonProgress}
              />
            )}
          </div>
        </Box>
      </Box>
    </div>
  )
}

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  wrapperButton: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between'
  },
  boxLink: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    minHeight: '5.6rem',
    fontWeight: 400,
    width: '48%'
  },
  secondLink: {
    margin: theme.spacing(0.5, 0.75),
    fontWeight: 400,
    color: '#7092FE',
    textDecoration: 'none'
  },
  disabledClick: {
    margin: theme.spacing(0.5, 0.75),
    fontWeight: 400,
    fontSize: '1.4rem'
  }
}))

export default useStyles

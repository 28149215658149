import React from 'react'
import Button from '@material-ui/core/Button'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  popover: {
    pointerEvents: 'none'
  },
  paper: {
    overflowX: 'unset',
    overflowY: 'unset',
    minWidth: '220px',
    '&::before': {
      content: '""',
      position: 'absolute',
      marginRight: '-0.71em',
      right: '50%',
      width: 10,
      height: 10,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[1],
      clipPath:
        'polygon(-5px -5px, calc(100% + 5px) -5px, calc(100% + 5px) calc(100% + 5px))'
    }
  },
  paperArrowTop: {
    marginTop: '6px',
    boxShadow:
      '0px -9px 46px rgb(0 0 0 / 2%), 0px -24px 38px rgb(0 0 0 / 4%), 0px 11px 15px rgb(0 0 0 / 8%)',
    '&::before': {
      top: '-4px',
      transform: 'translate(-50%, 50%) rotate(-45deg)'
    }
  },
  paperArrowBottom: {
    marginTop: '-6px',
    boxShadow:
      '0px 9px 46px rgb(0 0 0 / 2%), 0px 24px 38px rgb(0 0 0 / 4%), 0px 11px 15px rgb(0 0 0 / 8%)',
    '&::before': {
      bottom: 0,
      transform: 'translate(-50%, 50%) rotate(135deg)'
    }
  }
}))

export default React.memo(({ MenuButton, children }) => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  return (
    <div className={classes.root}>
      <div>
        <div
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup='true'
          onClick={handleToggle}
          className='d-flex'
        >
          {MenuButton}
        </div>
        {/* <Button
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup='true'
          onClick={handleToggle}
        >
          Toggle Menu Grow
        </Button> */}
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          placement='top'
          style={{
            zIndex: 1101
          }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom'
              }}
            >
              <Paper
                className={`${classes.paper} ${
                  placement === 'bottom'
                    ? classes.paperArrowTop
                    : classes.paperArrowBottom
                }`}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    // autoFocusItem={open}
                    id='menu-list-grow'
                    onKeyDown={handleListKeyDown}
                    onClick={handleClose}
                    className='p-0'
                  >
                    {children}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  )
})

import React from 'react'

const STATIC_IMAGE_SIZE = {
  'x-small': { min: 0, max: 80 },
  small: { min: 80, max: 160 },
  medium: { min: 160, max: 320 }
}

const getResizedSrc = (
  resized = null,
  urlFallback,
  type = STATIC_IMAGE_SIZE.xsmall
) => {
  if (!resized) {
    return urlFallback
  }
  let finalSrc = urlFallback
  resized.map((item) => {
    if (item.name === type) {
      finalSrc = item.url
    }
  })
  return finalSrc
}

function isEmptyObj(obj) {
  let objLength = 0
  Object.keys(obj).map((item) => {
    if (item) {
      objLength++
    }
  })
  return objLength === 0
}
function normalizeResizedList(resized) {
  let result = {}
  result = resized.reduce((acc, item) => {
    return { ...acc, [item.name]: { ...item } }
  }, {})
  return result
}

function LazyPicture({ className = '', src = '', alt = '', resized = [] }) {
  let noneResizedFile = (
    <LazyImage className={className} src={src} alt={alt} resized={resized} />
  )
  if (resized.length === 0) {
    return noneResizedFile
  }
  const normalResizedObj = normalizeResizedList(resized)

  if (isEmptyObj(normalResizedObj)) {
    return noneResizedFile
  }

  const VIEWPORT_RANGE_CONFIG = [743, 1127, 1439] // the same as airbnb

  return (
    <picture className={className}>
      <source
        srcset={`${normalResizedObj.small.url} 1x, ${normalResizedObj.medium.url} 2x`}
        media={`(max-width: ${VIEWPORT_RANGE_CONFIG[0]}px)`}
      />
      <source
        srcset={`${normalResizedObj.small.url} 1x, ${normalResizedObj.medium.url} 2x`}
        media={`(min-width: ${
          VIEWPORT_RANGE_CONFIG[0] + 0.1
        }px) and (max-width: ${VIEWPORT_RANGE_CONFIG[1]}px)`}
      />
      <source
        srcset={`${normalResizedObj.medium.url} 1x, ${normalResizedObj.large.url} 2x`}
        media={`(min-width: ${
          VIEWPORT_RANGE_CONFIG[1] + 0.1
        }px) and (max-width: ${VIEWPORT_RANGE_CONFIG[2]}px)`}
      />
      <source
        srcset={`${normalResizedObj.large.url} 1x, ${normalResizedObj['x-large'].url} 2x`}
        media={`(min-width: ${VIEWPORT_RANGE_CONFIG[2] + 0.1}px)`}
      />
      <img
        alt=''
        src={src}
        data-original-uri={normalResizedObj.medium.url || src}
        className={`${className} lazyload blur-up`}
        loading='lazy'
      />
    </picture>
  )
}

function LazyImage({
  className = '',
  src = '',
  alt = '',
  opt = null,
  size = 'x-small',
  resized = [],
  onClick = () => {}
}) {
  let newSrc = getResizedSrc(resized, src, size)

  return (
    <img
      onClick={onClick}
      className={`${className} lazyload blur-up`}
      loading='lazy'
      src={newSrc}
      data-src={newSrc}
      alt={alt}
    />
  )
}

export default LazyImage
export { LazyPicture, getResizedSrc }

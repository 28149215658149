import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'

import { Link, Box, CircularProgress } from '@material-ui/core'

import ENUMS from '../../config/Enums'
import UseStyles from './SignUpResendStyle'
import AlertUtil from '../../utils/AlertUtil'
import AuthenAPI from '@src/api/AuthenAPI'
const useStyles = UseStyles

function SignUpResendSide(props) {
  const classes = useStyles()
  const [sent, setSent] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const query = new URLSearchParams(props.location.search)
  const email = query.get('email')
  const _id = query.get('_id')

  async function resendClick() {
    setIsLoading(true)
    try {
      let responses
      responses = await AuthenAPI.resendEmailCreate({ email, user_id: _id })

      // const m = responses.message ? responses.message : 'Successfully! Please check your email inbox and confirm your signup!'
      const m =
        responses && responses.message ? responses.message : 'Successfully!'
      AlertUtil.showSuccessAlert(props.storeNotification, m)
      setIsLoading(false)
      setSent(true)
    } catch (err) {
      setIsLoading(false)
      const m = err.message
      AlertUtil.showErrorAlert(props.storeNotification, m)
    }
  }
  if (isLoading) {
    return <CircularProgress size={24} className={classes.buttonProgress} />
  }
  if (sent) {
    return <p className={classes.disabledClick}>We have sent e-mail to you</p>
  }

  return (
    <Link
      className={classes.linkClick}
      variant='body2'
      href='#'
      onClick={() => {
        if (!sent) resendClick()
      }}
    >
      {/* TODO: We have sent e-mail to you */}
      <Box fontSize='1.4rem'>Resend e-mail</Box>
    </Link>
  )
}

export default withRouter(SignUpResendSide)

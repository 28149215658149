import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'

import { Button, Container } from '@material-ui/core'

import SignUpEmailConfirmationAPI from './SignUpEmailConfirmationAPI'

import ENUMS from '@src/config/Enums'
import AlertUtil from '@src/utils/AlertUtil'
import UseStyles from './SignUpEmailConfirmationStyle'
import AuthenAPI from '@src/api/AuthenAPI'

import TitleGroupAuth from '@src/components/common/TitleGroupAuth'

const useStyles = UseStyles
const signUpEmailConfirmationAPI = new SignUpEmailConfirmationAPI()

function SignUpEmailConfirmationSide(props) {
  const classes = useStyles()
  const query = new URLSearchParams(props.location.search)
  const code = query.get('code')
  const user_id = query.get('user_id')
  const user_type = query.get('user_type')

  const email = query.get('email')

  const [data, setData] = useState(-1)
  const [error, setError] = useState('')

  const fetchData = async () => {
    try {
      console.log('--fetchData--', { user_id, email, code })
      let responses = await AuthenAPI.verifyEmail({ user_id, email, code })
      const m = 'Activation successful!'
      AlertUtil.showSuccessAlert(props.storeNotification, m)
      setData(user_type)
    } catch (err) {
      setError(err.message)
      const m = 'Activation failed!'
      AlertUtil.showErrorAlert(props.storeNotification, err.message || m)
      setData(-2)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  if (data === -1) {
    return (
      <>
        <Container component='main' maxWidth='sm'>
          <div className={classes.paper}></div>
        </Container>
      </>
    )
  }
  if (data === -2) {
    return (
      <>
        <Container component='main' maxWidth='sm'>
          <div className={classes.paper}>
            <TitleGroupAuth title={`Activation failed!`} description={error} />
            <Button
              type='button'
              fullWidth
              variant='outline'
              color='default'
              onClick={() => {
                props.history.push('/')
              }}
              className={`${classes.submit} ${classes.defaultColor} text-transform-reset`}
            >
              Back to home
            </Button>
          </div>
        </Container>
      </>
    )
  }

  if (data >= 0) {
    return <Redirect to='/auth/signin' />
  }
}

export default SignUpEmailConfirmationSide

import React from 'react'
import {
  Box, Card, CardMedia, CardContent, Typography
} from '@material-ui/core'

import {
  makeStyles, useTheme
} from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
    alignItems: 'center',
    padding: '14px',
    backgroundColor: theme.palette.common.sWhite,
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.07), 0px 0px 12px rgba(0, 0, 0, 0.04)',
    borderRadius: theme.palette.common.radiusValue
  },
  cardDetails: {
    flex: 1
  },
  cardMedia: {
    display: 'flex',
    width: 48,
    height: 48,
    justifyContent: 'center',
    alignItems: 'center'
  },
  cardContent: {
    padding: theme.spacing(0, 0, 0, 1.25),
    paddingBottom: '0!important'
  }
}))

export default (props) => {
  const classes = useStyles()
  const theme = useTheme()

  return (
    <>
      <Card className={`${classes.card}`}>
        <CardMedia className={classes.cardMedia}>
          <img src={props.iconUrl} alt={props.description} />
        </CardMedia>
        <div className={classes.cardDetails}>
          <CardContent className={classes.cardContent}>
            <Typography variant='body1' component='div'>
              <Box component='div' fontSize='2rem' color={theme.palette.common.sBlack}>{props.name}</Box>
              <Box component='div' fontSize='1.2rem' color={theme.palette.common.sBlack}>{props.description}</Box>
            </Typography>
          </CardContent>
        </div>
      </Card>
    </>
  )
}

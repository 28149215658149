import React, { useState, useEffect } from 'react'
import {
    Stage,
    Layer,
    Arc,
    Text,
} from 'react-konva'
import {
    Typography,
    makeStyles
} from '@material-ui/core'

import Constants from '@src/config/Constants'
const colorS = Constants.COLORS

const colorSet = {
    danger: colorS.sRedAccent,
    warning: colorS.sYellow,
    success: colorS.sGreen,
}
const DonutShaped = props => {

    let arcDeg = 360 * props.percent / 100 // for all total tick showed

    return (
        <>
            {/* show bold tick */}
            <Arc
                x={props.center.x}
                y={props.center.y}
                innerRadius={props.arcRadius}
                outerRadius={props.arcRadius}
                angle={360}
                fill='rgb(240, 240, 240)'
                stroke='rgb(240, 240, 240)'
                strokeWidth={props.strokeWidth}
                lineJoin="round"
            />

            <Arc
                x={props.center.x}
                y={props.center.y}
                innerRadius={props.arcRadius}
                outerRadius={props.arcRadius}
                angle={arcDeg}
                fill={props.strokeColor}
                stroke={props.strokeColor}
                strokeWidth={props.strokeWidth}
                rotation={-90}
                lineJoin="round"
            />
        </>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'block',
        position: 'relative'
    },
    centerLabel: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translateX(-50%) translateY(-50%)',
    }
}))

const PArcPercent = props => {
    const classes = useStyles()

    const widthStage = props.width + props.strokeWidth
    const strokeWidth = props.strokeWidth || 4
    const heightStage = props.height + props.strokeWidth
    const size = props.size || 1
    const percentValue = props.percentValue || 0
    const centerCoordinates = {
        x: (widthStage / 2),
        y: (heightStage / 2)
    }

    // init comp
    const unit = props.unit || ''

    const arcRadius = props.width * size / 2 // radius calc from the center to the scroll border
    const [percent, setPercent] = useState(percentValue)
    const [strokeColor, setStrokeColor] = useState('success')

    useEffect(() => {
        setPercent(props.percentValue || 0)
        if (props.percentValue <= 30) {
            setStrokeColor('danger')
        } else if (props.percentValue <= 50) {
            setStrokeColor('warning')
        } else {
            setStrokeColor('success')
        }
    }, [props.percentValue])

    return (
        <div className={classes.container}>
            <Stage width={widthStage} height={heightStage}>
                <Layer className='m-auto'>
                    <DonutShaped
                        size={size}
                        arcRadius={arcRadius - 2}
                        percent={percent}
                        center={centerCoordinates}
                        strokeWidth={strokeWidth}
                        strokeColor={colorSet[strokeColor]}
                    />
                </Layer>
            </Stage>
            <Typography component='span' className={classes.centerLabel}>
                {percent}{unit}
            </Typography>
        </div>
    )
}

export { PArcPercent }

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  main: {
    maxWidth: '432px',
    paddingLeft: 0,
    paddingRight: 0
  },
  paper: {
    margin: theme.spacing(8, 0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  boxLink: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  secondLink: {
    margin: theme.spacing(0.5, 0.75),
    fontWeight: 400,
    color: '#7092FE',
    textDecoration: 'none'
  },
  socialGroup: {
    marginTop: theme.spacing(1.8)
  }
}))

export default useStyles

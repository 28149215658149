import React, { useState, useEffect, useContext } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  Typography,
  Grid,
  makeStyles,
  Box,
  FormControl,
  CircularProgress,
  MenuItem,
  Input,
  FormControlLabel
} from '@material-ui/core'
import { PButton, PIconText, PFillTextField, PSelect } from '@src/components/PCustom'
import GreenCheckbox from '@src/components/common/GreenCheckbox'
import { IconDownArrow } from '@src/components/PCustom/SVGIcons'

import { AlertUtil } from '@src/utils'
import Constants from '@src/config/Constants'
import Form941API from '../Form941API'
import { initialForm941SBY2017 } from '../constant'
const colorS = Constants.COLORS
const form941API = new Form941API()

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    position: 'relative',
    flexDirection: 'column',
    padding: '20px 0 0 0',
    margin: 'auto',
    alignItems: 'center'
  },
  listBox: {
    border: `1px solid #ddd`,
    borderRadius: '4px',
    overflow: 'auto',
    maxHeight: '500px'
  },
  buttonProgress: {
    // style for button loading
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  wrapperButton: {
    position: 'relative'
  },
  selector: {
    height: '40px',
    marginRight: '12px',
    '& > div': {
      padding: '5px'
    }
  },
  textField: {
    padding: 8,
    borderRadius: 4,
    border: `1px solid #aaa`,
    fontFamily: `Ubuntu`,
    fontSize: '14px',
    width: "100%",
    '&[readonly]': {
      background: '#eee'
    }
  },
  table: {
    width: '100%',
    fontSize: '14px',
    marginBottom: 60,
    '& tr td': {
      padding: 4
    }
  }
}))

export default ({ storeNotification, company, quarter, onLoading }) => {
  const classes = useStyles()
  const history = useHistory()

  const [loading, setLoading] = useState(false);
  const [form941Data, setForm941Data] = useState({ ...initialForm941SBY2017 })

  useEffect(() => {
    handleChangeQuarter()
  }, [company, quarter])

  const handleChangeQuarter = async (e) => {
    if (!company || !quarter) return
    onLoading(true)
    try {
      const companyResp = await form941API.getForm941sbFields({ company, quarter })
      setForm941Data(companyResp.data || { ...initialForm941SBY2017 })
    } catch (e) {
      const m = e.message ? e.message : e.toString()
      AlertUtil.showErrorAlert(storeNotification, m)
    }
    onLoading(false)
  }

  const handleSave = async () => {
    if (!company || !quarter) return
    try {
      await form941API.storeForm941sbFields({
        company,
        quarter,
        ...form941Data
      })
      AlertUtil.showSuccessAlert(storeNotification, "Form data is stored successfully.")
    } catch (e) {
      const m = e.message ? e.message : e.toString()
      AlertUtil.showErrorAlert(storeNotification, m)
    }
  }

  const handleViewPDF = async () => {
    if (!company || !quarter) return
    setLoading(true)
    try {
      await form941API.storeForm941sbFields({
        company: company,
        quarter: quarter,
        ...form941Data
      })

      const pdfResp = await form941API.exportPDF({
        company: company,
        form_type: '941sb',
        quarter: quarter
      })

      const pdfURL = pdfResp.data.name || ''
      if (pdfURL) {
        window.open(`${Constants.API_URL}/storage/pdf?path=${pdfURL}`, "_blank");
      }

    } catch (e) {
      const m = e.message ? e.message : e.toString()
      AlertUtil.showErrorAlert(storeNotification, m)
    }
    setLoading(false)
  }

  const handleFormData = (key, value) => {
    form941Data[key] = Number(value)
    const month_props = [
      '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31']

    form941Data['month_1_total'] = month_props.reduce((t, c) => Number(t) + Number(form941Data[`month_1_${c}`] || 0), 0)
    form941Data['month_2_total'] = month_props.reduce((t, c) => Number(t) + Number(form941Data[`month_2_${c}`] || 0), 0)
    form941Data['month_3_total'] = month_props.reduce((t, c) => Number(t) + Number(form941Data[`month_3_${c}`] || 0), 0)
    form941Data['total'] = form941Data['month_1_total'] + form941Data['month_2_total'] + form941Data['month_3_total']

    setForm941Data({ ...form941Data })
  }

  return (
    <div>
      <Box display="flex" justifyContent="space-between" alignItems="center" className="mb-24">
        <Typography variant="h4">Form 940 Schedule</Typography>
        <Box display="flex">
          <div className={`${classes.wrapperButton} mr-12`}>
            <PButton variant="outlined" color="primary" className="" onClick={handleSave} disabled={!company || !quarter}>Save</PButton>
          </div>

          <div className={classes.wrapperButton}>
            <PButton variant="contained" color="primary" className="" onClick={handleViewPDF} disabled={!company || !quarter}>View PDF {quarter}</PButton>
            {loading && (
              <CircularProgress
                size={24}
                className={classes.buttonProgress}
              />
            )}
          </div>
        </Box>
      </Box>

      <table className={classes.table}>
        <tbody>
          <tr><td colSpan={9}><b>Month 1</b></td></tr>
          <tr>
            <td>1</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_1_1} onChange={(e) => handleFormData('month_1_1', e.target.value)} />
            </td>

            <td>9</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_1_9} onChange={(e) => handleFormData('month_1_9', e.target.value)} />
            </td>

            <td>17</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_1_17} onChange={(e) => handleFormData('month_1_17', e.target.value)} />
            </td>

            <td>25</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_1_25} onChange={(e) => handleFormData('month_1_25', e.target.value)} />
            </td>

            <td>
              <input type="number" className={classes.textField} value={form941Data.month_1_total} readOnly="true" />
            </td>
          </tr>

          <tr>
            <td>2</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_1_2} onChange={(e) => handleFormData('month_1_2', e.target.value)} />
            </td>

            <td>10</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_1_10} onChange={(e) => handleFormData('month_1_10', e.target.value)} />
            </td>

            <td>18</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_1_18} onChange={(e) => handleFormData('month_1_18', e.target.value)} />
            </td>

            <td>26</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_1_26} onChange={(e) => handleFormData('month_1_26', e.target.value)} />
            </td>

            <td></td>
          </tr>

          <tr>
            <td>3</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_1_3} onChange={(e) => handleFormData('month_1_3', e.target.value)} />
            </td>

            <td>11</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_1_11} onChange={(e) => handleFormData('month_1_11', e.target.value)} />
            </td>

            <td>19</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_1_19} onChange={(e) => handleFormData('month_1_19', e.target.value)} />
            </td>

            <td>27</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_1_27} onChange={(e) => handleFormData('month_1_27', e.target.value)} />
            </td>

            <td></td>
          </tr>

          <tr>
            <td>4</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_1_4} onChange={(e) => handleFormData('month_1_4', e.target.value)} />
            </td>

            <td>12</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_1_12} onChange={(e) => handleFormData('month_1_12', e.target.value)} />
            </td>

            <td>20</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_1_20} onChange={(e) => handleFormData('month_1_20', e.target.value)} />
            </td>

            <td>28</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_1_28} onChange={(e) => handleFormData('month_1_28', e.target.value)} />
            </td>

            <td></td>
          </tr>

          <tr>
            <td>5</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_1_5} onChange={(e) => handleFormData('month_1_5', e.target.value)} />
            </td>

            <td>13</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_1_13} onChange={(e) => handleFormData('month_1_13', e.target.value)} />
            </td>

            <td>21</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_1_21} onChange={(e) => handleFormData('month_1_21', e.target.value)} />
            </td>

            <td>29</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_1_29} onChange={(e) => handleFormData('month_1_29', e.target.value)} />
            </td>

            <td></td>
          </tr>

          <tr>
            <td>6</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_1_6} onChange={(e) => handleFormData('month_1_6', e.target.value)} />
            </td>

            <td>14</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_1_14} onChange={(e) => handleFormData('month_1_14', e.target.value)} />
            </td>

            <td>22</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_1_22} onChange={(e) => handleFormData('month_1_22', e.target.value)} />
            </td>

            <td>30</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_1_30} onChange={(e) => handleFormData('month_1_30', e.target.value)} />
            </td>

            <td></td>
          </tr>

          <tr>
            <td>7</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_1_7} onChange={(e) => handleFormData('month_1_7', e.target.value)} />
            </td>

            <td>15</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_1_15} onChange={(e) => handleFormData('month_1_15', e.target.value)} />
            </td>

            <td>23</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_1_23} onChange={(e) => handleFormData('month_1_23', e.target.value)} />
            </td>

            <td>31</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_1_31} onChange={(e) => handleFormData('month_1_31', e.target.value)} />
            </td>

            <td></td>
          </tr>

          <tr>
            <td>8</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_1_8} onChange={(e) => handleFormData('month_1_8', e.target.value)} />
            </td>

            <td>16</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_1_16} onChange={(e) => handleFormData('month_1_16', e.target.value)} />
            </td>

            <td>24</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_1_24} onChange={(e) => handleFormData('month_1_24', e.target.value)} />
            </td>

            <td></td>
            <td></td>

            <td></td>
          </tr>

          {/* ******************************** Month 2 ********************************************************** */}
          <tr><td colSpan={9}><b>Month 2</b></td></tr>
          <tr>
            <td>1</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_2_1} onChange={(e) => handleFormData('month_2_1', e.target.value)} />
            </td>

            <td>9</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_2_9} onChange={(e) => handleFormData('month_2_9', e.target.value)} />
            </td>

            <td>17</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_2_17} onChange={(e) => handleFormData('month_2_17', e.target.value)} />
            </td>

            <td>25</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_2_25} onChange={(e) => handleFormData('month_2_25', e.target.value)} />
            </td>

            <td>
              <input type="number" className={classes.textField} value={form941Data.month_2_total} readOnly="true" />
            </td>
          </tr>

          <tr>
            <td>2</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_2_2} onChange={(e) => handleFormData('month_2_2', e.target.value)} />
            </td>

            <td>10</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_2_10} onChange={(e) => handleFormData('month_2_10', e.target.value)} />
            </td>

            <td>18</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_2_18} onChange={(e) => handleFormData('month_2_18', e.target.value)} />
            </td>

            <td>26</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_2_26} onChange={(e) => handleFormData('month_2_26', e.target.value)} />
            </td>

            <td></td>
          </tr>

          <tr>
            <td>3</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_2_3} onChange={(e) => handleFormData('month_2_3', e.target.value)} />
            </td>

            <td>11</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_2_11} onChange={(e) => handleFormData('month_2_11', e.target.value)} />
            </td>

            <td>19</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_2_19} onChange={(e) => handleFormData('month_2_19', e.target.value)} />
            </td>

            <td>27</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_2_27} onChange={(e) => handleFormData('month_2_27', e.target.value)} />
            </td>

            <td></td>
          </tr>

          <tr>
            <td>4</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_2_4} onChange={(e) => handleFormData('month_2_4', e.target.value)} />
            </td>

            <td>12</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_2_12} onChange={(e) => handleFormData('month_2_12', e.target.value)} />
            </td>

            <td>20</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_2_20} onChange={(e) => handleFormData('month_2_20', e.target.value)} />
            </td>

            <td>28</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_2_28} onChange={(e) => handleFormData('month_2_28', e.target.value)} />
            </td>

            <td></td>
          </tr>

          <tr>
            <td>5</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_2_5} onChange={(e) => handleFormData('month_2_5', e.target.value)} />
            </td>

            <td>13</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_2_13} onChange={(e) => handleFormData('month_2_13', e.target.value)} />
            </td>

            <td>21</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_2_21} onChange={(e) => handleFormData('month_2_21', e.target.value)} />
            </td>

            <td>29</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_2_29} onChange={(e) => handleFormData('month_2_29', e.target.value)} />
            </td>

            <td></td>
          </tr>

          <tr>
            <td>6</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_2_6} onChange={(e) => handleFormData('month_2_6', e.target.value)} />
            </td>

            <td>14</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_2_14} onChange={(e) => handleFormData('month_2_14', e.target.value)} />
            </td>

            <td>22</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_2_22} onChange={(e) => handleFormData('month_2_22', e.target.value)} />
            </td>

            <td>30</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_2_30} onChange={(e) => handleFormData('month_2_30', e.target.value)} />
            </td>

            <td></td>
          </tr>

          <tr>
            <td>7</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_2_7} onChange={(e) => handleFormData('month_2_7', e.target.value)} />
            </td>

            <td>15</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_2_15} onChange={(e) => handleFormData('month_2_15', e.target.value)} />
            </td>

            <td>23</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_2_23} onChange={(e) => handleFormData('month_2_23', e.target.value)} />
            </td>

            <td>31</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_2_31} onChange={(e) => handleFormData('month_2_31', e.target.value)} />
            </td>

            <td></td>
          </tr>

          <tr>
            <td>8</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_2_8} onChange={(e) => handleFormData('month_2_8', e.target.value)} />
            </td>

            <td>16</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_2_16} onChange={(e) => handleFormData('month_2_16', e.target.value)} />
            </td>

            <td>24</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_2_24} onChange={(e) => handleFormData('month_2_24', e.target.value)} />
            </td>

            <td></td>
            <td></td>

            <td></td>
          </tr>


          {/* ******************************** Month 3 ********************************************************** */}
          <tr><td colSpan={9}><b>Month 3</b></td></tr>
          <tr>
            <td>1</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_3_1} onChange={(e) => handleFormData('month_3_1', e.target.value)} />
            </td>

            <td>9</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_3_9} onChange={(e) => handleFormData('month_3_9', e.target.value)} />
            </td>

            <td>17</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_3_17} onChange={(e) => handleFormData('month_3_17', e.target.value)} />
            </td>

            <td>25</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_3_25} onChange={(e) => handleFormData('month_3_25', e.target.value)} />
            </td>

            <td>
              <input type="number" className={classes.textField} value={form941Data.month_3_total} readOnly="true" />
            </td>
          </tr>

          <tr>
            <td>2</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_3_2} onChange={(e) => handleFormData('month_3_2', e.target.value)} />
            </td>

            <td>10</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_3_10} onChange={(e) => handleFormData('month_3_10', e.target.value)} />
            </td>

            <td>18</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_3_18} onChange={(e) => handleFormData('month_3_18', e.target.value)} />
            </td>

            <td>26</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_3_26} onChange={(e) => handleFormData('month_3_26', e.target.value)} />
            </td>

            <td></td>
          </tr>

          <tr>
            <td>3</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_3_3} onChange={(e) => handleFormData('month_3_3', e.target.value)} />
            </td>

            <td>11</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_3_11} onChange={(e) => handleFormData('month_3_11', e.target.value)} />
            </td>

            <td>19</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_3_19} onChange={(e) => handleFormData('month_3_19', e.target.value)} />
            </td>

            <td>27</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_3_27} onChange={(e) => handleFormData('month_3_27', e.target.value)} />
            </td>

            <td></td>
          </tr>

          <tr>
            <td>4</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_3_4} onChange={(e) => handleFormData('month_3_4', e.target.value)} />
            </td>

            <td>12</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_3_12} onChange={(e) => handleFormData('month_3_12', e.target.value)} />
            </td>

            <td>20</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_3_20} onChange={(e) => handleFormData('month_3_20', e.target.value)} />
            </td>

            <td>28</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_3_28} onChange={(e) => handleFormData('month_3_28', e.target.value)} />
            </td>

            <td></td>
          </tr>

          <tr>
            <td>5</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_3_5} onChange={(e) => handleFormData('month_3_5', e.target.value)} />
            </td>

            <td>13</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_3_13} onChange={(e) => handleFormData('month_3_13', e.target.value)} />
            </td>

            <td>21</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_3_21} onChange={(e) => handleFormData('month_3_21', e.target.value)} />
            </td>

            <td>29</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_3_29} onChange={(e) => handleFormData('month_3_29', e.target.value)} />
            </td>

            <td></td>
          </tr>

          <tr>
            <td>6</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_3_6} onChange={(e) => handleFormData('month_3_6', e.target.value)} />
            </td>

            <td>14</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_3_14} onChange={(e) => handleFormData('month_3_14', e.target.value)} />
            </td>

            <td>22</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_3_22} onChange={(e) => handleFormData('month_3_22', e.target.value)} />
            </td>

            <td>30</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_3_30} onChange={(e) => handleFormData('month_3_30', e.target.value)} />
            </td>

            <td></td>
          </tr>

          <tr>
            <td>7</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_3_7} onChange={(e) => handleFormData('month_3_7', e.target.value)} />
            </td>

            <td>15</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_3_15} onChange={(e) => handleFormData('month_3_15', e.target.value)} />
            </td>

            <td>23</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_3_23} onChange={(e) => handleFormData('month_3_23', e.target.value)} />
            </td>

            <td>31</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_3_31} onChange={(e) => handleFormData('month_3_31', e.target.value)} />
            </td>

            <td></td>
          </tr>

          <tr>
            <td>8</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_3_8} onChange={(e) => handleFormData('month_3_8', e.target.value)} />
            </td>

            <td>16</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_3_16} onChange={(e) => handleFormData('month_3_16', e.target.value)} />
            </td>

            <td>24</td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.month_3_24} onChange={(e) => handleFormData('month_3_24', e.target.value)} />
            </td>

            <td></td>
            <td></td>

            <td></td>
          </tr>
          <tr>
            <td><b>Total</b></td>
            <td>
              <input type="number" className={classes.textField} value={form941Data.total} readOnly="true" />
            </td>

          </tr>
        </tbody>
      </table>
    </div>
  )
}

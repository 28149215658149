import React from 'react'
import { withRouter, Link, useHistory } from 'react-router-dom'

import SignUpResend from '../SignUpResend/SignUpResend'
import { Button, CircularProgress, Box, Container } from '@material-ui/core'

import { withFormik, Form, Field } from 'formik'
import * as Yup from 'yup'
import ENUMS from '../../config/Enums'

import UseStyles from './SignUpChangeStyle'
import AlertUtil from '../../utils/AlertUtil'
import TitleGroupAuth from '@src/components/common/TitleGroupAuth'
import TextFieldV2 from '@src/components/common/TextFieldV2'

import AuthenAPI from '@src/api/AuthenAPI'
const useStyles = UseStyles

function SignUpChangeSide(props) {
  const query = new URLSearchParams(props.location.search)
  const history = useHistory()
  const email = query.get('email')
  const _id = query.get('_id')
  const { touched, errors, values } = props
  const isDisable = () =>
    Object.keys(errors).some((item) => errors[item]) ||
    values['email'].trim().length === 0
  const classes = useStyles()

  return (
    <>
      <Container component='main' maxWidth='xs' className={classes.main}>
        <div className={classes.paper}>
          <TitleGroupAuth
            title={'Confirm your email'}
            description={` To activate your account use the link in the email we’ve sent to
              ${email}, and you'll be ready to go.`}
          />

          <div className={classes.boxLink}>
            <SignUpResend
              email={email}
              storeNotification={props.storeNotification}
            />

            <p className={classes.disabledClick}> This is not my e-mail</p>
          </div>

          <Form className={classes.form}>
            <form noValidate>
              <Field
                name='email'
                render={({ field }) => (
                  <TextFieldV2
                    variant='filled'
                    margin='normal'
                    fullWidth
                    className='mb-0'
                    textKey='email'
                    label='New e-mail'
                    showCheckIcon
                    autoComplete='email'
                    {...{ touched, errors, values }}
                    {...field}
                  />
                )}
              />

              <div className={classes.wrapperButton}>
                <Button
                  type='button'
                  fullWidth
                  variant='outline'
                  color='default'
                  onClick={() => {
                    history.goBack()
                  }}
                  className={`${classes.submit} ${classes.defaultColor} text-transform-reset`}
                >
                  Cancel
                </Button>

                <Button
                  type='submit'
                  fullWidth
                  variant='contained'
                  color='default'
                  disabled={isDisable() || props.isSubmitting}
                  className={`${classes.submit} text-transform-reset`}
                >
                  Change e-mail
                  {props.isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </div>
            </form>
          </Form>
        </div>
      </Container>
    </>
  )
}

const FormikForm = withFormik({
  mapPropsToValues() {
    // Init form field
    return {
      email: ''
    }
  },
  validationSchema: Yup.object().shape({
    // Validate form field
    email: Yup.string().required('Email is required').email('Email is invalid')
  }),
  handleSubmit: async (values, { props, setMessage, setOpenMessage }) => {
    try {
      const query = new URLSearchParams(props.location.search)
      const old_email = query.get('email')
      const _id = query.get('_id')

      let responses = await AuthenAPI.changeEmailCreate({
        old_email,
        new_email: values.email,
        user_id: _id
      })
      // Redirect
      props.history.push(
        `/auth/signup/confirm?email=${values.email}&_id=${_id}`
      )
      const m = responses.message ? responses.message : 'Successfully!'
      AlertUtil.showSuccessAlert(props.storeNotification, m)
    } catch (err) {
      const m = err.message
      AlertUtil.showErrorAlert(props.storeNotification, m)
    }
  }
})(SignUpChangeSide)

export default withRouter(FormikForm)

import React, { useState, useEffect } from 'react'
import {
    withStyles,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core'

import Constants from '@src/config/Constants'
const colorS = Constants.COLORS

const PTable = withStyles(() => ({
}))(Table)

const PTableBody = withStyles(() => ({
}))(TableBody)

const PTableCell = withStyles(() => ({
    head: {
        background: colorS.sNeutralHeader,
        color: colorS.sGrey,
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
        padding: '14px 7px',
        cursor: 'pointer',
        border: 'none',
        '&:first-child': {
            paddingLeft: '24px',
            borderTopLeftRadius: '6px',
            borderBottomLeftRadius: '6px',
        },
        '&:last-child': {
            borderTopRightRadius: '6px',
            borderBottomRightRadius: '6px',
        },
        '&:hover': {
            color: colorS.sBlack
        },
    },
    body: {
        padding: '14px 7px',
        border: 'none',
        position: 'relative',
        '&:first-child': {
            paddingLeft: '24px',
        }
    }
}))(TableCell)

const PTableHead = withStyles(() => ({
}))(TableHead)

const PTableRow = withStyles(() => ({
    root: {
        '&:nth-of-type(even)': {
            backgroundColor: colorS.sNeutralHeader,
        },
    },    
}))(TableRow)

export { PTable, PTableBody, PTableCell, PTableHead, PTableRow }

/**
 * Define enums used on pge modules
 */

const NotificationType = Object.freeze({
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
  INFO: 'info'
})

const LoginType = Object.freeze({
  HOMEOWNER: 0,
})

export default {
  LoginType,
  NotificationType
}

import React from 'react'

const IconDownArrow = (props) => {
    return (
        <svg {...props} width="10" height="6" viewBox="0 0 10 6" fill="none">
            <path d="M1.7998 1.3999L4.9998 4.5999L8.1998 1.3999" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={{ stroke: 'currentColor' }} />
        </svg>
    )
}

const IconUpArrow = (props) => {
    return (
        <svg {...props} width="10" height="6" viewBox="0 0 10 6" fill="none">
            <path d="M1.7998 4.5999L4.9998 1.3999L8.1998 4.5999" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={{ stroke: 'currentColor' }} />
        </svg>
    )
}

const IconPencil = (props) => {
    return (
        <svg {...props} width="14" height="20" viewBox="0 0 14 20" fill="none">
            <path d="M10.7254 0H3.28349C2.89745 0 2.58582 0.311628 2.58582 0.697674V4.33488L0.743958 11.693C0.692796 11.8977 0.734656 12.1116 0.860238 12.2791L6.44163 19.7209C6.57187 19.8977 6.78117 20 6.99977 20C7.21838 20 7.42768 19.8977 7.55791 19.7209L13.1393 12.2791C13.2649 12.1116 13.3068 11.893 13.2556 11.693L11.423 4.33488V0.697674C11.423 0.311628 11.1114 0 10.7254 0ZM3.98117 1.39535H10.0277V3.72093H3.98117V1.39535ZM7.00442 10.2326C6.61838 10.2326 6.30675 9.92093 6.30675 9.53488C6.30675 9.14884 6.61838 8.83721 7.00442 8.83721C7.39047 8.83721 7.7021 9.14884 7.7021 9.53488C7.7021 9.92093 7.39047 10.2326 7.00442 10.2326ZM11.8277 11.707L7.7021 17.2093V11.4977C8.5114 11.2093 9.09745 10.4419 9.09745 9.53488C9.09745 8.3814 8.15791 7.44186 7.00442 7.44186C5.85094 7.44186 4.9114 8.3814 4.9114 9.53488C4.9114 10.4419 5.49745 11.2093 6.30675 11.4977V17.2093L2.18117 11.707L3.82768 5.11628H10.1812L11.8277 11.707Z" fill="currentColor" />
        </svg>
    )
}

const IconRectangle = (props) => {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none">
            <mask id="path-1-inside-1" fill="white">
                <rect x="3" y="3" width="18" height="18" rx="1.5" />
            </mask>
            <rect x="3" y="3" width="18" height="18" rx="1.5" stroke="currentColor" strokeWidth="3.5" strokeLinejoin="round" mask="url(#path-1-inside-1)" />
        </svg>
    )
}

const IconZone = (props) => {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M14.757 3H5.243C4.463 3 4.18 3.081 3.895 3.234C3.61341 3.38312 3.38312 3.61341 3.234 3.895C3.08 4.18 3 4.463 3 5.243V14.757C3 15.537 3.081 15.82 3.234 16.105C3.386 16.39 3.61 16.614 3.895 16.766C4.18 16.919 4.463 17 5.243 17H14.757C15.537 17 15.82 16.919 16.105 16.766C16.39 16.614 16.614 16.39 16.766 16.105C16.919 15.82 17 15.537 17 14.757V5.243C17 4.463 16.919 4.18 16.766 3.895C16.6169 3.61341 16.3866 3.38312 16.105 3.234C15.82 3.08 15.537 3 14.757 3ZM4.75 14.757V5.243C4.75 5.016 4.758 4.863 4.77 4.77C4.863 4.758 5.016 4.75 5.243 4.75H14.757C14.984 4.75 15.137 4.758 15.23 4.77C15.242 4.863 15.25 5.016 15.25 5.243V14.757C15.25 14.984 15.242 15.137 15.23 15.23C15.137 15.242 14.984 15.25 14.757 15.25H5.243C5.016 15.25 4.863 15.242 4.77 15.23C4.75437 15.0728 4.74769 14.9149 4.75 14.757Z" fill="currentColor" />
            <path opacity="0.25" fillRule="evenodd" clipRule="evenodd" d="M9.243 7H18.757C19.537 7 19.82 7.081 20.105 7.234C20.39 7.386 20.614 7.61 20.766 7.895C20.919 8.18 21 8.463 21 9.243V18.757C21 19.537 20.919 19.82 20.766 20.105C20.6168 20.3866 20.3866 20.6168 20.105 20.766C19.82 20.919 19.537 21 18.757 21H9.243C8.463 21 8.18 20.919 7.895 20.766C7.61341 20.6169 7.38312 20.3866 7.234 20.105C7.08 19.82 7 19.537 7 18.757V9.243C7 8.463 7.081 8.18 7.234 7.895C7.38312 7.61341 7.61341 7.38312 7.895 7.234C8.18 7.08 8.463 7 9.243 7Z" fill="currentColor" />
        </svg>
    )
}

const IconDotRectangle = (props) => {
    return (
        <svg {...props} width="24" height="24" viewBox="-3 -3 24 24" fill="none">
            <mask id="path-1-inside-2" fill="white">
                <rect width="18" height="18" rx="1.5" />
            </mask>
            <rect width="18" height="18" rx="1.5" stroke="currentColor" strokeWidth="3.5" strokeLinejoin="round" strokeDasharray="4 3" mask="url(#path-1-inside-2)" />
        </svg>
    )
}

const IconPlug = (props) => {
    return (
        <svg {...props} width="25" height="24" viewBox="0 0 25 24" fill="none">
            <circle cx="12.5" cy="12" r="9.15" stroke="currentColor" strokeWidth="1.7" />
            <path d="M13.35 19C13.35 18.5306 12.9694 18.15 12.5 18.15C12.0306 18.15 11.65 18.5306 11.65 19H13.35ZM13.35 21V19H11.65V21H13.35Z" fill="currentColor" />
            <path d="M11.65 5C11.65 5.46944 12.0306 5.85 12.5 5.85C12.9694 5.85 13.35 5.46944 13.35 5H11.65ZM13.35 5V3H11.65V5H13.35Z" fill="currentColor" />
            <g clipPath="url(#clipPlug-icon)">
                <circle cx="9.5" cy="12" r="1.5" fill="currentColor" />
                <circle cx="15.5" cy="12" r="1.5" fill="currentColor" />
            </g>
            <defs>
                <clipPath id="clipPlug-icon">
                    <rect width="9" height="3" fill="white" transform="translate(8 10.5)" />
                </clipPath>
            </defs>
        </svg>
    )
}

const IconLayer = (props) => {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M18.7692 12.0001L21.2792 13.4841C22.2262 14.0441 22.2292 14.9541 21.2792 15.5161L12.8622 20.4901C12.3902 20.7701 11.6122 20.7721 11.1362 20.4901L2.71919 15.5161C1.77219 14.9561 1.76919 14.0461 2.71919 13.4841L5.22919 12.0001L2.71919 10.5161C1.77219 9.95607 1.76919 9.04607 2.71919 8.48407L11.1362 3.51007C11.6082 3.23007 12.3862 3.22807 12.8622 3.51007L21.2802 8.48407C22.2282 9.04407 22.2302 9.95407 21.2802 10.5161L18.7702 12.0001H18.7692ZM17.0492 13.0161L19.2172 14.2961C19.4042 14.4061 19.4072 14.5911 19.2172 14.7041L11.9992 18.9701L4.78119 14.7041C4.59419 14.5941 4.59119 14.4091 4.78119 14.2961L6.94919 13.0161L11.1362 15.4901C11.6132 15.7721 12.3902 15.7701 12.8622 15.4901L17.0492 13.0161ZM4.78119 9.29607C4.59119 9.40907 4.59419 9.59307 4.78119 9.70407L11.9992 13.9701L19.2172 9.70407C19.4072 9.59107 19.4042 9.40707 19.2172 9.29607L11.9992 5.03007L4.78119 9.29607Z" fill="currentColor" />
        </svg>
    )
}

const IconCircleChecked = (props) => {
    return (
        <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none">
            <circle cx="8" cy="8" r="8" fill="currentColor" />
            <path d="M5.33203 8.66667L6.66536 10L10.6654 6" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

const IconUndo = (props) => {
    return (
        <svg {...props} width="22" height="13" viewBox="0 0 22 13" fill="none">
            <path d="M16 0.875H7.84999C7.44958 0.875 7.12498 1.1996 7.12498 1.60001C7.12498 2.00042 7.44958 2.32501 7.84999 2.32501H16C18.0036 2.32501 19.625 3.88863 19.625 5.80002C19.625 7.71141 18.0036 9.27503 16 9.27503H4.24999V7.58659C4.24999 7.25617 3.85365 7.08725 3.61529 7.31606L1.1013 9.72948C0.947562 9.87706 0.94756 10.1229 1.1013 10.2705L3.61529 12.684C3.85365 12.9128 4.24999 12.7439 4.24999 12.4134V10.725H16C18.8211 10.725 21.125 8.5205 21.125 5.79999C21.125 3.07948 18.8211 0.875 16 0.875Z" fill="currentColor" stroke="currentColor" strokeWidth="0.25" />
        </svg>
    )
}

const IconRedo = (props) => {
    return (
        <svg {...props} width="22" height="13" viewBox="0 0 22 13" fill="none">
            <path d="M6.00001 12.125H14.15C14.5504 12.125 14.875 11.8004 14.875 11.4C14.875 10.9996 14.5504 10.675 14.15 10.675H6.00005C3.99636 10.675 2.37505 9.11137 2.37505 7.19998C2.37505 5.28859 3.99636 3.72497 6.00005 3.72497H17.75V5.41341C17.75 5.74383 18.1464 5.91275 18.3847 5.68394L20.8987 3.27052C21.0524 3.12294 21.0524 2.87706 20.8987 2.72948L18.3847 0.316035C18.1464 0.0872135 17.75 0.256144 17.75 0.586555V2.27499H6.00001C3.17888 2.27499 0.875 4.4795 0.875 7.20001C0.875 9.92052 3.17888 12.125 6.00001 12.125Z" fill="currentColor" stroke="currentColor" strokeWidth="0.25" />
        </svg>
    )
}

const IconThreeDots = (props) => {
    return (
        <svg {...props} width="18" height="18" viewBox="0 0 18 4" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M4 2C4 2.53043 3.78929 3.03914 3.41421 3.41421C3.03914 3.78929 2.53043 4 2 4C1.46957 4 0.960859 3.78929 0.585786 3.41421C0.210714 3.03914 0 2.53043 0 2C0 1.46957 0.210714 0.96086 0.585786 0.585787C0.960859 0.210714 1.46957 0 2 0C2.53043 0 3.03914 0.210714 3.41421 0.585787C3.78929 0.96086 4 1.46957 4 2ZM11 2C11 2.53043 10.7893 3.03914 10.4142 3.41421C10.0391 3.78929 9.53043 4 9 4C8.46957 4 7.96086 3.78929 7.58579 3.41421C7.21071 3.03914 7 2.53043 7 2C7 1.46957 7.21071 0.96086 7.58579 0.585787C7.96086 0.210714 8.46957 0 9 0C9.53043 0 10.0391 0.210714 10.4142 0.585787C10.7893 0.96086 11 1.46957 11 2ZM16 4C16.5304 4 17.0391 3.78929 17.4142 3.41421C17.7893 3.03914 18 2.53043 18 2C18 1.46957 17.7893 0.96086 17.4142 0.585787C17.0391 0.210714 16.5304 0 16 0C15.4696 0 14.9609 0.210714 14.5858 0.585787C14.2107 0.96086 14 1.46957 14 2C14 2.53043 14.2107 3.03914 14.5858 3.41421C14.9609 3.78929 15.4696 4 16 4Z" fill="currentColor" />
        </svg>
    )
}

const IconPlus = (props) => {
    return (
        <svg {...props} width="12" height="12" viewBox="0 0 12 12" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M6 0C5.73478 0 5.48043 0.105357 5.29289 0.292893C5.10536 0.48043 5 0.734784 5 1V5H1C0.734784 5 0.48043 5.10536 0.292893 5.29289C0.105357 5.48043 0 5.73478 0 6C0 6.26522 0.105357 6.51957 0.292893 6.70711C0.48043 6.89464 0.734784 7 1 7H5V11C5 11.2652 5.10536 11.5196 5.29289 11.7071C5.48043 11.8946 5.73478 12 6 12C6.26522 12 6.51957 11.8946 6.70711 11.7071C6.89464 11.5196 7 11.2652 7 11V7H11C11.2652 7 11.5196 6.89464 11.7071 6.70711C11.8946 6.51957 12 6.26522 12 6C12 5.73478 11.8946 5.48043 11.7071 5.29289C11.5196 5.10536 11.2652 5 11 5H7V1C7 0.734784 6.89464 0.48043 6.70711 0.292893C6.51957 0.105357 6.26522 0 6 0Z" fill="currentColor" />
        </svg>
    )
}

const IconMinus = (props) => {
    return (
        <svg {...props} width="12" height="12" viewBox="0 0 12 2" fill="none">
            <path d="M0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H11C11.2652 0 11.5196 0.105357 11.7071 0.292893C11.8946 0.48043 12 0.734784 12 1C12 1.26522 11.8946 1.51957 11.7071 1.70711C11.5196 1.89464 11.2652 2 11 2H1C0.734784 2 0.48043 1.89464 0.292893 1.70711C0.105357 1.51957 0 1.26522 0 1C0 0.734784 0.105357 0.48043 0.292893 0.292893Z" fill="currentColor" />
        </svg>
    )
}

const IconPlantType = (props) => {
    return (
        <svg {...props} width="82" height="86" viewBox="0 0 82 86" fill="none">
            <g clipPath="url(#clipPlant-type-0)">
                <path d="M76 67.9922H74V23.8074C74.0024 23.278 73.8695 22.7567 73.614 22.293C73.3585 21.8293 72.9888 21.4385 72.54 21.1577L42.61 2.46985C42.1288 2.16382 41.5703 2.00129 41 2.00129C40.4297 2.00129 39.8712 2.16382 39.39 2.46985L9.46003 21.1577C9.01122 21.4385 8.64155 21.8293 8.38605 22.293C8.13056 22.7567 7.99769 23.278 8.00003 23.8074V67.9922H6.00003V23.8074C5.99701 22.9381 6.21583 22.0825 6.6358 21.3214C7.05577 20.5602 7.66299 19.9189 8.40003 19.4579L38.33 0.770046C39.1291 0.265405 40.0549 -0.00244141 41 -0.00244141C41.9452 -0.00244141 42.8709 0.265405 43.67 0.770046L73.6 19.4579C74.3371 19.9189 74.9443 20.5602 75.3643 21.3214C75.7842 22.0825 76.0031 22.9381 76 23.8074V67.9922Z" fill="currentColor" />
                <path d="M70 70.9917H68V40.2453C68.0065 39.8439 67.9074 39.4479 67.7126 39.0969C67.5178 38.7459 67.2341 38.4523 66.89 38.2455L42.24 23.3472C41.8658 23.1214 41.4371 23.0021 41 23.0021C40.563 23.0021 40.1342 23.1214 39.76 23.3472L15.11 38.2955C14.7659 38.5023 14.4822 38.7959 14.2874 39.1469C14.0926 39.4979 13.9935 39.8939 14 40.2952V70.9917H12V40.2453C11.999 39.5083 12.1891 38.7837 12.552 38.1423C12.9148 37.5009 13.4378 36.9645 14.07 36.5857L38.72 21.5874C39.4076 21.1709 40.1961 20.9507 41 20.9507C41.8039 20.9507 42.5925 21.1709 43.28 21.5874L67.93 36.5857C68.5622 36.9645 69.0852 37.5009 69.448 38.1423C69.8109 38.7837 70.0011 39.5083 70 40.2453V70.9917Z" fill="currentColor" />
                <path d="M67 35.9961H66V28.347C66.0032 27.913 65.8933 27.4856 65.6812 27.1069C65.469 26.7282 65.1619 26.4112 64.79 26.1873L42.34 12.3789C41.9362 12.1334 41.4726 12.0036 41 12.0036C40.5274 12.0036 40.0639 12.1334 39.66 12.3789L17.21 26.1873C16.8381 26.4112 16.531 26.7282 16.3189 27.1069C16.1067 27.4856 15.9968 27.913 16 28.347V35.9961H15V28.347C14.9983 27.7436 15.1531 27.15 15.4493 26.6243C15.7455 26.0985 16.173 25.6586 16.69 25.3474L39.13 11.529C39.6917 11.181 40.3393 10.9966 41 10.9966C41.6608 10.9966 42.3084 11.181 42.87 11.529L65.31 25.3374C65.827 25.6486 66.2545 26.0885 66.5507 26.6143C66.8469 27.14 67.0017 27.7336 67 28.337V35.9961Z" fill="currentColor" />
                <path d="M40.9993 85.9902C40.0721 85.9902 39.1589 85.7637 38.3393 85.3303L1.23925 65.7725C0.897098 65.5939 0.605415 65.332 0.391079 65.011C0.176742 64.69 0.0466464 64.3203 0.0127777 63.9358C-0.021091 63.5514 0.042357 63.1645 0.197276 62.811C0.352194 62.4575 0.593601 62.1487 0.899252 61.913L6.12925 57.5935L7.40925 59.1333L2.14925 63.4828C2.10204 63.5124 2.06337 63.5538 2.03706 63.6029C2.01075 63.652 1.99772 63.7071 1.99925 63.7628C2.00633 63.8135 2.02547 63.8617 2.05505 63.9034C2.08463 63.9452 2.12379 63.9793 2.16925 64.0028L39.2693 83.5705C39.8026 83.8516 40.3964 83.9985 40.9993 83.9985C41.6021 83.9985 42.1959 83.8516 42.7293 83.5705L79.8293 63.9928C79.8747 63.9693 79.9139 63.9352 79.9435 63.8934C79.973 63.8517 79.9922 63.8035 79.9993 63.7528C80.0025 63.7029 79.9932 63.653 79.9723 63.6076C79.9513 63.5621 79.9193 63.5227 79.8792 63.4928L74.5892 59.1233L75.8693 57.5835L81.1193 61.923C81.422 62.1594 81.6603 62.4682 81.8124 62.8209C81.9645 63.1736 82.0255 63.5588 81.9896 63.9412C81.9538 64.3236 81.8223 64.6909 81.6073 65.0091C81.3923 65.3274 81.1006 65.5865 80.7593 65.7625L43.6593 85.3403C42.8387 85.7702 41.9256 85.9933 40.9993 85.9902Z" fill="currentColor" />
                <path d="M29 28.9968H28V18.9979L29 17.998V28.9968Z" fill="currentColor" />
                <path d="M31 27.9968L30 28.9967V17.9979L31 16.998V27.9968Z" fill="currentColor" />
                <path d="M54 19.0078H53V29.0067H54V19.0078Z" fill="currentColor" />
                <path d="M52 27.9968L51 26.9969V16.998L52 17.9979V27.9968Z" fill="currentColor" />
                <path d="M78.539 15.999H65.539C65.4064 15.999 65.2792 15.9463 65.1855 15.8526C65.0917 15.7588 65.039 15.6316 65.039 15.499C65.039 15.3665 65.0917 15.2393 65.1855 15.1455C65.2792 15.0518 65.4064 14.9991 65.539 14.9991H78.069V14.8991C78.0966 14.5123 78.0362 14.1243 77.8925 13.7641C77.7488 13.4039 77.5254 13.0809 77.239 12.8194C76.265 12.2203 75.1025 12.0064 73.979 12.2194C73.8614 12.2434 73.7391 12.2242 73.6345 12.1655C73.5298 12.1067 73.4498 12.0123 73.409 11.8995C73.1048 11.1343 72.5717 10.4816 71.8827 10.0307C71.1936 9.5797 70.3821 9.35238 69.559 9.37976C68.5118 9.27637 67.4593 9.51492 66.559 10.0597C66.4899 10.1153 66.4079 10.1528 66.3205 10.1685C66.2332 10.1842 66.1432 10.1777 66.059 10.1497C65.9759 10.1203 65.9017 10.0698 65.8439 10.0032C65.786 9.93663 65.7465 9.85618 65.729 9.76972C65.729 9.57974 64.899 5.11026 60.409 5.11026C59.1548 5.10182 57.9265 5.46733 56.8809 6.16012C55.8354 6.85292 55.02 7.84155 54.539 8.9998C54.4771 9.11826 54.3712 9.20767 54.244 9.24881C54.1168 9.28995 53.9786 9.27953 53.859 9.21978C53.743 9.1581 53.6559 9.05324 53.6166 8.92788C53.5773 8.80251 53.589 8.66671 53.649 8.54986C55.139 5.6202 57.399 4.13037 60.379 4.13037C61.7918 4.13017 63.1637 4.60402 64.2751 5.47602C65.3866 6.34802 66.1732 7.56773 66.509 8.93981C67.4604 8.55248 68.4823 8.36856 69.509 8.39987C70.4647 8.38362 71.4056 8.63784 72.223 9.1332C73.0405 9.62856 73.7012 10.3449 74.129 11.1995C75.4278 11.0163 76.748 11.3267 77.829 12.0694C78.2337 12.4249 78.5515 12.8684 78.7578 13.3659C78.9641 13.8634 79.0535 14.4016 79.019 14.9391V15.539C79.0093 15.6612 78.955 15.7755 78.8666 15.8603C78.7781 15.9451 78.6615 15.9945 78.539 15.999Z" fill="currentColor" />
                <path d="M41 56.9935C38.6131 56.9935 36.3239 56.0454 34.636 54.3578C32.9482 52.6701 32 50.3812 32 47.9946C32 40.8454 40.23 31.1565 40.58 30.7466C40.6287 30.6945 40.6875 30.653 40.7529 30.6246C40.8182 30.5962 40.8887 30.5815 40.96 30.5815C41.0313 30.5815 41.1018 30.5962 41.1671 30.6246C41.2325 30.653 41.2914 30.6945 41.34 30.7466C41.69 31.1565 49.92 40.8454 49.92 47.9946C49.9201 50.3674 48.9829 52.6443 47.3123 54.3297C45.6417 56.015 43.3731 56.9724 41 56.9935ZM41 31.8964C39.37 33.8962 33 42.0952 33 48.0346C32.8883 49.1468 33.0111 50.2701 33.3603 51.332C33.7095 52.3939 34.2774 53.3708 35.0274 54.1998C35.7774 55.0287 36.6928 55.6913 37.7147 56.1448C38.7365 56.5982 39.842 56.8325 40.96 56.8325C42.078 56.8325 43.1835 56.5982 44.2053 56.1448C45.2272 55.6913 46.1426 55.0287 46.8926 54.1998C47.6426 53.3708 48.2105 52.3939 48.5597 51.332C48.9089 50.2701 49.0317 49.1468 48.92 48.0346C49 42.1052 42.64 33.9262 41 31.9064V31.8964Z" fill="currentColor" />
                <path d="M51.75 71.9919H49V70.992H51.75C52.6094 70.9894 53.433 70.6476 54.0417 70.0409C54.6503 69.4342 54.9947 68.6117 55 67.7524V40.2556C55.0013 39.828 54.9182 39.4044 54.7555 39.0089C54.5927 38.6135 54.3536 38.2541 54.0516 37.9513C53.7497 37.6485 53.391 37.4082 52.996 37.2443C52.6011 37.0804 52.1776 36.996 51.75 36.996H45V35.9961H51.75C52.309 35.9961 52.8624 36.1063 53.3787 36.3205C53.895 36.5347 54.364 36.8486 54.7587 37.2443C55.1535 37.6399 55.4664 38.1096 55.6794 38.6263C55.8924 39.143 56.0013 39.6967 56 40.2556V67.7524C55.9947 68.8769 55.5449 69.9537 54.7488 70.7479C53.9526 71.5421 52.8746 71.9893 51.75 71.9919Z" fill="currentColor" />
                <path d="M33 71.9919H30.25C29.1254 71.9893 28.0474 71.5421 27.2512 70.7479C26.4551 69.9537 26.0053 68.8769 26 67.7524V40.2556C25.9987 39.6967 26.1077 39.143 26.3206 38.6263C26.5336 38.1096 26.8465 37.6399 27.2413 37.2443C27.636 36.8486 28.105 36.5347 28.6213 36.3205C29.1376 36.1063 29.6911 35.9961 30.25 35.9961H37V36.996H30.25C29.8224 36.996 29.3989 37.0804 29.004 37.2443C28.609 37.4082 28.2503 37.6485 27.9484 37.9513C27.6465 38.2541 27.4073 38.6135 27.2445 39.0089C27.0818 39.4044 26.9987 39.828 27 40.2556V67.7524C27.0053 68.6117 27.3497 69.4342 27.9583 70.0409C28.567 70.6476 29.3906 70.9894 30.25 70.992H33V71.9919Z" fill="currentColor" />
                <path d="M27 49.9946H13V46.9946H27V49.9946ZM14 48.9946H26V47.9946H14V48.9946Z" fill="currentColor" />
                <path d="M69 49.9946H55V46.9946H69V49.9946ZM56 48.9946H68V47.9946H56V48.9946Z" fill="currentColor" />
                <path d="M46 49.9945H44.67C44.5684 49.9916 44.4704 49.9566 44.39 49.8945C43.6863 49.3439 42.8185 49.0447 41.925 49.0447C41.0315 49.0447 40.1637 49.3439 39.46 49.8945C39.3707 49.9615 39.2616 49.9967 39.15 49.9945H33V48.9945H39C39.8554 48.3711 40.8865 48.0352 41.945 48.0352C43.0035 48.0352 44.0346 48.3711 44.89 48.9945H49V49.9945H46Z" fill="currentColor" />
                <path d="M42.5 52.9941C42.2033 52.9941 41.9133 52.9062 41.6666 52.7413C41.42 52.5765 41.2277 52.3423 41.1142 52.0682C41.0006 51.7941 40.9709 51.4925 41.0288 51.2015C41.0867 50.9105 41.2296 50.6433 41.4393 50.4335C41.6491 50.2237 41.9164 50.0808 42.2074 50.023C42.4983 49.9651 42.7999 49.9948 43.074 50.1083C43.3481 50.2219 43.5824 50.4141 43.7472 50.6608C43.912 50.9075 44 51.1975 44 51.4941C44 51.892 43.842 52.2735 43.5607 52.5548C43.2794 52.8361 42.8978 52.9941 42.5 52.9941ZM42.5 50.9941C42.3674 50.9941 42.2402 51.0468 42.1464 51.1406C42.0527 51.2344 42 51.3615 42 51.4941C42 51.6267 42.0527 51.7539 42.1464 51.8477C42.2402 51.9415 42.3674 51.9941 42.5 51.9941C42.6326 51.9941 42.7598 51.9415 42.8536 51.8477C42.9473 51.7539 43 51.6267 43 51.4941C43 51.3615 42.9473 51.2344 42.8536 51.1406C42.7598 51.0468 42.6326 50.9941 42.5 50.9941Z" fill="currentColor" />
                <path d="M40.5 54.9937H39.5C39.3674 54.9937 39.2402 54.941 39.1464 54.8472C39.0527 54.7534 39 54.6263 39 54.4937C39 54.361 39.0527 54.2339 39.1464 54.1401C39.2402 54.0463 39.3674 53.9937 39.5 53.9937H40.5C40.6326 53.9937 40.7598 54.0463 40.8536 54.1401C40.9473 54.2339 41 54.361 41 54.4937C41 54.6263 40.9473 54.7534 40.8536 54.8472C40.7598 54.941 40.6326 54.9937 40.5 54.9937Z" fill="currentColor" />
                <path d="M38.5 52.9941H36.5C36.3674 52.9941 36.2402 52.9415 36.1464 52.8477C36.0527 52.7539 36 52.6267 36 52.4941C36 52.3615 36.0527 52.2344 36.1464 52.1406C36.2402 52.0468 36.3674 51.9941 36.5 51.9941H38.5C38.6326 51.9941 38.7598 52.0468 38.8536 52.1406C38.9473 52.2344 39 52.3615 39 52.4941C39 52.6267 38.9473 52.7539 38.8536 52.8477C38.7598 52.9415 38.6326 52.9941 38.5 52.9941Z" fill="currentColor" />
                <path d="M41 79.9907C39.3189 79.9907 37.6755 79.4922 36.2777 78.5584C34.8798 77.6245 33.7904 76.2971 33.147 74.7441C32.5037 73.1911 32.3354 71.4823 32.6633 69.8336C32.9913 68.185 33.8009 66.6706 34.9896 65.482C36.1783 64.2934 37.6929 63.4839 39.3417 63.156C40.9906 62.8281 42.6996 62.9964 44.2528 63.6396C45.806 64.2829 47.1335 65.3722 48.0675 66.7699C49.0015 68.1676 49.5 69.8107 49.5 71.4917C49.4974 73.745 48.601 75.9052 47.0075 77.4985C45.414 79.0918 43.2535 79.9881 41 79.9907ZM41 63.9926C39.5166 63.9926 38.0666 64.4324 36.8332 65.2564C35.5999 66.0804 34.6386 67.2516 34.0709 68.6219C33.5033 69.9922 33.3547 71.5 33.6441 72.9547C33.9335 74.4094 34.6478 75.7456 35.6967 76.7944C36.7456 77.8431 38.082 78.5574 39.5368 78.8467C40.9917 79.1361 42.4997 78.9876 43.8701 78.42C45.2406 77.8524 46.4119 76.8912 47.236 75.658C48.0601 74.4248 48.5 72.9749 48.5 71.4917C48.5 69.5028 47.7098 67.5954 46.3033 66.189C44.8968 64.7826 42.9891 63.9926 41 63.9926Z" fill="currentColor" />
                <path d="M40.1083 77.2113C39.9707 77.2113 39.837 77.1656 39.7283 77.0813C39.6122 76.9995 39.5266 76.8815 39.4849 76.7457C39.4433 76.61 39.448 76.4642 39.4983 76.3314L40.2783 72.9918H38.3983C38.2083 72.9867 38.0236 72.9276 37.866 72.8213C37.7084 72.7151 37.5843 72.5661 37.5083 72.3919C37.4394 72.2286 37.4152 72.05 37.438 71.8743C37.4609 71.6986 37.53 71.5322 37.6383 71.392L39.6383 67.0425H42.3783C42.554 67.0427 42.7259 67.0939 42.8732 67.1897C43.0205 67.2855 43.1369 67.4219 43.2083 67.5824C43.2786 67.7448 43.3045 67.9229 43.2834 68.0986C43.2623 68.2742 43.195 68.4411 43.0883 68.5823L42.3583 69.9921H43.8483C43.9901 69.982 44.1314 70.0172 44.2519 70.0927C44.3723 70.1682 44.4656 70.2801 44.5183 70.4121C44.5772 70.5547 44.5925 70.7115 44.5623 70.8628C44.532 71.0141 44.4575 71.153 44.3483 71.262L40.5183 77.0513C40.4053 77.1522 40.2598 77.209 40.1083 77.2113ZM40.2883 67.9924L38.4183 71.9919H41.5083L40.8583 74.7816L43.3783 70.992H40.7583L42.2283 67.9924H40.2883Z" fill="currentColor" />
            </g>
            <defs>
                <clipPath id="clipPlant-type-0">
                    <rect width="82" height="86" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

const IconHVACSystem = (props) => {
    return (
        <svg {...props} width="82" height="86" viewBox="0 0 82 86" fill="none">
            <g clipPath="url(#clip-hvac-system-0)">
                <path d="M76 67.9921H74V23.8072C74.0024 23.2778 73.8695 22.7566 73.614 22.2929C73.3585 21.8292 72.9888 21.4384 72.54 21.1576L42.61 2.46973C42.1288 2.1637 41.5703 2.00116 41 2.00116C40.4297 2.00116 39.8712 2.1637 39.39 2.46973L9.46003 21.1576C9.01122 21.4384 8.64155 21.8292 8.38605 22.2929C8.13056 22.7566 7.99769 23.2778 8.00003 23.8072V67.9921H6.00003V23.8072C5.99701 22.938 6.21583 22.0823 6.6358 21.3212C7.05577 20.5601 7.66299 19.9187 8.40003 19.4577L38.33 0.769924C39.1291 0.265283 40.0549 -0.00256348 41 -0.00256348C41.9452 -0.00256348 42.8709 0.265283 43.67 0.769924L73.6 19.4577C74.3371 19.9187 74.9443 20.5601 75.3643 21.3212C75.7842 22.0823 76.0031 22.938 76 23.8072V67.9921Z" fill="currentColor" />
                <path d="M70 70.9918H68V40.2454C68.0065 39.844 67.9074 39.448 67.7126 39.097C67.5178 38.746 67.2341 38.4524 66.89 38.2456L42.24 23.3473C41.8658 23.1216 41.4371 23.0022 41 23.0022C40.563 23.0022 40.1342 23.1216 39.76 23.3473L15.11 38.2956C14.7659 38.5024 14.4822 38.796 14.2874 39.147C14.0926 39.498 13.9935 39.894 14 40.2954V70.9918H12V40.2454C11.999 39.5085 12.1891 38.7839 12.552 38.1424C12.9148 37.501 13.4378 36.9646 14.07 36.5858L38.72 21.5875C39.4076 21.171 40.1961 20.9508 41 20.9508C41.8039 20.9508 42.5925 21.171 43.28 21.5875L67.93 36.5858C68.5622 36.9646 69.0852 37.501 69.448 38.1424C69.8109 38.7839 70.0011 39.5085 70 40.2454V70.9918Z" fill="currentColor" />
                <path d="M67 35.9959H66V28.3468C66.0032 27.9127 65.8933 27.4853 65.6812 27.1066C65.469 26.7279 65.1619 26.411 64.79 26.187L42.34 12.3786C41.9362 12.1332 41.4726 12.0034 41 12.0034C40.5274 12.0034 40.0639 12.1332 39.66 12.3786L17.21 26.187C16.8381 26.411 16.531 26.7279 16.3189 27.1066C16.1067 27.4853 15.9968 27.9127 16 28.3468V35.9959H15V28.3468C14.9983 27.7433 15.1531 27.1498 15.4493 26.624C15.7455 26.0983 16.173 25.6583 16.69 25.3471L39.13 11.5287C39.6917 11.1807 40.3393 10.9963 41 10.9963C41.6608 10.9963 42.3084 11.1807 42.87 11.5287L65.31 25.3371C65.827 25.6483 66.2545 26.0883 66.5507 26.614C66.8469 27.1398 67.0017 27.7333 67 28.3368V35.9959Z" fill="currentColor" />
                <path d="M40.9993 85.9901C40.0721 85.9901 39.1589 85.7635 38.3393 85.3301L1.23925 65.7724C0.897098 65.5938 0.605415 65.3319 0.391079 65.0109C0.176742 64.6899 0.0466464 64.3202 0.0127777 63.9357C-0.021091 63.5512 0.042357 63.1644 0.197276 62.8109C0.352194 62.4574 0.593601 62.1486 0.899252 61.9129L6.12925 57.5934L7.40925 59.1332L2.14925 63.4827C2.10204 63.5123 2.06337 63.5536 2.03706 63.6027C2.01075 63.6518 1.99772 63.707 1.99925 63.7627C2.00633 63.8133 2.02547 63.8616 2.05505 63.9033C2.08463 63.9451 2.12379 63.9791 2.16925 64.0026L39.2693 83.5704C39.8026 83.8514 40.3964 83.9983 40.9993 83.9983C41.6021 83.9983 42.1959 83.8514 42.7293 83.5704L79.8293 63.9926C79.8747 63.9691 79.9139 63.9351 79.9435 63.8933C79.973 63.8516 79.9922 63.8033 79.9993 63.7527C80.0025 63.7028 79.9932 63.6528 79.9723 63.6074C79.9513 63.562 79.9193 63.5226 79.8792 63.4927L74.5892 59.1232L75.8693 57.5834L81.1193 61.9229C81.422 62.1593 81.6603 62.4681 81.8124 62.8207C81.9645 63.1734 82.0255 63.5587 81.9896 63.9411C81.9538 64.3235 81.8223 64.6907 81.6073 65.009C81.3923 65.3273 81.1006 65.5864 80.7593 65.7624L43.6593 85.3401C42.8387 85.7701 41.9256 85.9932 40.9993 85.9901Z" fill="currentColor" />
                <path d="M29 28.9966H28V18.9978L29 17.9979V28.9966Z" fill="currentColor" />
                <path d="M31 27.9968L30 28.9967V17.9979L31 16.998V27.9968Z" fill="currentColor" />
                <path d="M53 27.9968V17.9979L54 18.9978V28.9966L53 27.9968Z" fill="currentColor" />
                <path d="M52 27.9968H51V16.998L52 17.9979V27.9968Z" fill="currentColor" />
                <path d="M64.5 69.9919H56.5C56.3674 69.9919 56.2402 69.9392 56.1464 69.8454C56.0527 69.7517 56 69.6245 56 69.4919C56 69.3593 56.0527 69.2322 56.1464 69.1384C56.2402 69.0446 56.3674 68.992 56.5 68.992H64V57.7533L57.21 52.9038C57.1559 52.866 57.1099 52.8177 57.0746 52.762C57.0392 52.7062 57.0153 52.644 57.0041 52.5789C56.993 52.5138 56.9948 52.4472 57.0095 52.3828C57.0243 52.3185 57.0516 52.2577 57.09 52.2039C57.1697 52.0985 57.2872 52.028 57.4178 52.0075C57.5484 51.987 57.6818 52.018 57.79 52.0939L64.79 57.0934C64.8559 57.1392 64.9096 57.2006 64.9462 57.2721C64.9828 57.3436 65.0013 57.423 65 57.5033V69.5019C64.9974 69.6328 64.9436 69.7574 64.8501 69.849C64.7566 69.9406 64.6309 69.9919 64.5 69.9919Z" fill="currentColor" />
                <path d="M25.5 69.9919H17.5C17.3674 69.9919 17.2402 69.9392 17.1465 69.8455C17.0527 69.7517 17 69.6245 17 69.492V57.4933C16.9994 57.4082 17.0201 57.3242 17.0603 57.2491C17.1005 57.174 17.1588 57.1102 17.23 57.0634L24.23 52.6939C24.2851 52.6542 24.3477 52.6262 24.414 52.6115C24.4802 52.5968 24.5488 52.5957 24.6155 52.6084C24.6822 52.621 24.7456 52.6471 24.8019 52.6851C24.8582 52.7231 24.9061 52.7721 24.9429 52.8292C24.9796 52.8863 25.0043 52.9503 25.0154 53.0172C25.0266 53.0842 25.024 53.1527 25.0079 53.2186C24.9917 53.2846 24.9623 53.3465 24.9214 53.4007C24.8805 53.4549 24.829 53.5002 24.77 53.5338L18 57.7733V68.992H25.5C25.6326 68.992 25.7598 69.0447 25.8536 69.1384C25.9473 69.2322 26 69.3594 26 69.492C26 69.6245 25.9473 69.7517 25.8536 69.8455C25.7598 69.9392 25.6326 69.9919 25.5 69.9919Z" fill="currentColor" />
                <path d="M43.5 41.9955H42.68C42.2183 42.0384 41.7531 41.9699 41.3233 41.796C40.8936 41.622 40.5118 41.3475 40.21 40.9955L39.94 40.7155C39.7603 40.4765 39.5229 40.287 39.2501 40.1646C38.9773 40.0422 38.678 39.9909 38.38 40.0155H37.5C37.3674 40.0155 37.2402 39.9628 37.1464 39.869C37.0527 39.7752 37 39.6481 37 39.5155C37 39.3828 37.0527 39.2557 37.1464 39.1619C37.2402 39.0681 37.3674 39.0155 37.5 39.0155H38.38C38.8125 38.9861 39.2457 39.0617 39.6427 39.2358C40.0396 39.4099 40.3887 39.6774 40.66 40.0155L40.85 40.1955C41.0582 40.4775 41.3368 40.6998 41.658 40.8402C41.9792 40.9806 42.3316 41.0342 42.68 40.9955H43.5C43.6326 40.9955 43.7598 41.0481 43.8536 41.1419C43.9473 41.2357 44 41.3628 44 41.4955C44 41.5611 43.9871 41.6261 43.9619 41.6868C43.9368 41.7475 43.9 41.8026 43.8536 41.849C43.8071 41.8954 43.752 41.9323 43.6913 41.9574C43.6307 41.9825 43.5657 41.9955 43.5 41.9955Z" fill="currentColor" />
                <path d="M43.5 44.9951H42.68C42.2183 45.038 41.7531 44.9696 41.3233 44.7956C40.8936 44.6216 40.5118 44.3471 40.21 43.9951L39.94 43.7151C39.7603 43.4761 39.5229 43.2866 39.2501 43.1642C38.9773 43.0418 38.678 42.9905 38.38 43.0151H37.5C37.3674 43.0151 37.2402 42.9624 37.1464 42.8686C37.0527 42.7749 37 42.6477 37 42.5151C37 42.3825 37.0527 42.2553 37.1464 42.1615C37.2402 42.0678 37.3674 42.0151 37.5 42.0151H38.38C38.8125 41.9857 39.2457 42.0613 39.6427 42.2354C40.0396 42.4096 40.3887 42.677 40.66 43.0151L40.85 43.1951C41.0582 43.4771 41.3368 43.6995 41.658 43.8399C41.9792 43.9803 42.3316 44.0338 42.68 43.9951H43.5C43.6326 43.9951 43.7598 44.0478 43.8536 44.1415C43.9473 44.2353 44 44.3625 44 44.4951C44 44.5607 43.9871 44.6258 43.9619 44.6864C43.9368 44.7471 43.9 44.8022 43.8536 44.8486C43.8071 44.8951 43.752 44.9319 43.6913 44.957C43.6307 44.9822 43.5657 44.9951 43.5 44.9951Z" fill="currentColor" />
                <path d="M43.5 47.9947H42.68C42.2183 48.0376 41.7531 47.9692 41.3233 47.7952C40.8936 47.6212 40.5118 47.3467 40.21 46.9947L39.94 46.7147C39.7603 46.4758 39.5229 46.2863 39.2501 46.1639C38.9773 46.0414 38.678 45.9901 38.38 46.0147H37.5C37.3674 46.0147 37.2402 45.962 37.1464 45.8683C37.0527 45.7745 37 45.6473 37 45.5147C37 45.3821 37.0527 45.2549 37.1464 45.1612C37.2402 45.0674 37.3674 45.0147 37.5 45.0147H38.38C38.8125 44.9854 39.2457 45.061 39.6427 45.2351C40.0396 45.4092 40.3887 45.6767 40.66 46.0147L40.85 46.1947C41.0582 46.4767 41.3368 46.6991 41.658 46.8395C41.9792 46.9799 42.3316 47.0334 42.68 46.9947H43.5C43.6326 46.9947 43.7598 47.0474 43.8536 47.1412C43.9473 47.2349 44 47.3621 44 47.4947C44 47.5604 43.9871 47.6254 43.9619 47.6861C43.9368 47.7467 43.9 47.8018 43.8536 47.8483C43.8071 47.8947 43.752 47.9315 43.6913 47.9567C43.6307 47.9818 43.5657 47.9947 43.5 47.9947Z" fill="currentColor" />
                <path d="M31.5188 46.9953C28.9866 47.0385 26.4774 46.5098 24.178 45.4484C21.8787 44.387 19.8485 42.8204 18.2388 40.8653C18.1959 40.8158 18.1634 40.7582 18.1431 40.6959C18.1229 40.6336 18.1153 40.5679 18.1209 40.5026C18.1265 40.4374 18.1451 40.3739 18.1757 40.316C18.2062 40.258 18.2481 40.2068 18.2988 40.1653C18.4015 40.0807 18.5333 40.0396 18.666 40.0508C18.7987 40.062 18.9217 40.1246 19.0088 40.2253C20.5197 42.0628 22.4256 43.5358 24.5846 44.5348C26.7436 45.5338 29.1002 46.033 31.4788 45.9953C31.5453 45.9926 31.6116 46.0033 31.674 46.0266C31.7363 46.0499 31.7933 46.0854 31.8418 46.131C31.8902 46.1767 31.929 46.2315 31.956 46.2923C31.983 46.3532 31.9975 46.4188 31.9988 46.4853C32.0014 46.5505 31.9911 46.6155 31.9683 46.6767C31.9455 46.7378 31.9107 46.7937 31.866 46.8412C31.8213 46.8887 31.7676 46.9268 31.7079 46.9533C31.6483 46.9798 31.584 46.994 31.5188 46.9953Z" fill="currentColor" />
                <path d="M22.3594 11.6888C22.2791 11.69 22.1997 11.6716 22.1282 11.6349C22.0567 11.5983 21.9953 11.5447 21.9494 11.4788C19.9251 8.55424 17.2218 6.16409 14.0711 4.5132C10.9205 2.8623 7.41644 1.99989 3.85938 1.99988C3.72677 1.99988 3.59959 1.94721 3.50582 1.85345C3.41205 1.7597 3.35938 1.63253 3.35938 1.49994C3.35938 1.36735 3.41205 1.24019 3.50582 1.14643C3.59959 1.05267 3.72677 1 3.85938 1C7.57766 1.00027 11.2404 1.90192 14.5339 3.62768C17.8273 5.35344 20.6532 7.85186 22.7694 10.9088C22.807 10.9624 22.8335 11.0229 22.8474 11.0869C22.8612 11.1508 22.8621 11.2169 22.85 11.2812C22.8379 11.3455 22.813 11.4068 22.7768 11.4613C22.7406 11.5158 22.6939 11.5626 22.6394 11.5988C22.5581 11.6579 22.4599 11.6895 22.3594 11.6888Z" fill="currentColor" />
                <path d="M16.9994 11.4801C16.934 11.4797 16.8693 11.4662 16.8092 11.4405C16.7491 11.4147 16.6948 11.3772 16.6494 11.3301C14.978 9.63715 12.9863 8.29398 10.7904 7.37887C8.59447 6.46377 6.23833 5.99505 3.85938 6.00007C3.72677 6.00007 3.59959 5.94739 3.50582 5.85362C3.41205 5.75985 3.35938 5.63268 3.35938 5.50007C3.35938 5.36746 3.41205 5.24028 3.50582 5.14651C3.59959 5.05275 3.72677 5.00007 3.85938 5.00007C6.37096 4.99337 8.85865 5.48787 11.1767 6.4546C13.4948 7.42133 15.5967 8.84086 17.3594 10.6301C17.4285 10.7006 17.4752 10.7899 17.4936 10.8869C17.5119 10.9839 17.5012 11.0842 17.4627 11.1751C17.4242 11.266 17.3597 11.3434 17.2772 11.3977C17.1948 11.452 17.0981 11.4806 16.9994 11.4801Z" fill="currentColor" />
                <path d="M12.3894 11.56C12.2907 11.558 12.1942 11.5305 12.1094 11.48C9.6633 9.85959 6.79349 8.99691 3.85938 9C3.72677 9 3.59959 8.94732 3.50582 8.85355C3.41205 8.75979 3.35938 8.63261 3.35938 8.5C3.35938 8.36739 3.41205 8.24022 3.50582 8.14645C3.59959 8.05268 3.72677 8 3.85938 8C6.99112 7.99883 10.0544 8.91676 12.6694 10.64C12.779 10.7133 12.8552 10.827 12.8814 10.9562C12.9076 11.0854 12.8817 11.2198 12.8094 11.33C12.7635 11.3998 12.7013 11.4573 12.628 11.4974C12.5548 11.5375 12.4729 11.559 12.3894 11.56Z" fill="currentColor" />
                <path d="M13.3994 18.0686C13.3168 18.0687 13.2354 18.0487 13.1623 18.0104C13.0891 17.9721 13.0263 17.9166 12.9794 17.8486C11.9724 16.3554 10.6142 15.1322 9.02407 14.2866C7.4339 13.4409 5.66042 12.9987 3.85938 12.9986C3.72677 12.9986 3.59959 12.946 3.50582 12.8522C3.41205 12.7584 3.35938 12.6313 3.35938 12.4986C3.35938 12.366 3.41205 12.2389 3.50582 12.1451C3.59959 12.0513 3.72677 11.9986 3.85938 11.9986C5.83267 11.9902 7.77759 12.4685 9.52188 13.3913C11.2662 14.314 12.756 15.6526 13.8594 17.2886C13.9323 17.4004 13.9584 17.5364 13.9323 17.6672C13.9061 17.7981 13.8297 17.9135 13.7194 17.9886C13.6239 18.0487 13.5118 18.0767 13.3994 18.0686Z" fill="currentColor" />
                <path d="M57.5 69.9918H24.5C24.3674 69.9918 24.2402 69.9392 24.1465 69.8454C24.0527 69.7516 24 69.6245 24 69.4919V38.5855C23.9994 38.4968 24.0234 38.4097 24.0692 38.3338C24.1151 38.2579 24.1812 38.1962 24.26 38.1555L40.76 29.0666C40.8323 29.0224 40.9153 28.999 41 28.999C41.0847 28.999 41.1678 29.0224 41.24 29.0666L57.74 38.0655C57.8196 38.1079 57.886 38.1713 57.9319 38.2489C57.9778 38.3266 58.0014 38.4153 58 38.5055V69.5019C57.9974 69.6327 57.9436 69.7574 57.8501 69.849C57.7566 69.9406 57.6309 69.9919 57.5 69.9918ZM25 68.9919H57V38.7955L41 30.0765L25 38.8854V68.9919Z" fill="currentColor" />
                <path d="M48.5 63.9929C48.2033 63.9929 47.9133 63.9049 47.6666 63.7401C47.42 63.5753 47.2277 63.341 47.1142 63.0669C47.0006 62.7929 46.9709 62.4913 47.0288 62.2003C47.0867 61.9093 47.2296 61.642 47.4393 61.4323C47.6491 61.2225 47.9164 61.0796 48.2074 61.0217C48.4983 60.9639 48.7999 60.9936 49.074 61.1071C49.3481 61.2206 49.5824 61.4129 49.7472 61.6596C49.912 61.9062 50 62.1962 50 62.4929C50 62.8907 49.842 63.2723 49.5607 63.5536C49.2794 63.8349 48.8978 63.9929 48.5 63.9929ZM48.5 61.9929C48.3674 61.9929 48.2402 62.0456 48.1464 62.1394C48.0527 62.2331 48 62.3603 48 62.4929C48 62.6255 48.0527 62.7527 48.1464 62.8465C48.2402 62.9402 48.3674 62.9929 48.5 62.9929C48.6326 62.9929 48.7598 62.9402 48.8536 62.8465C48.9473 62.7527 49 62.6255 49 62.4929C49 62.3603 48.9473 62.2331 48.8536 62.1394C48.7598 62.0456 48.6326 61.9929 48.5 61.9929Z" fill="currentColor" />
                <path d="M48.4992 65.9923C47.7077 65.9917 46.9398 65.7221 46.3217 65.2278C45.7036 64.7334 45.2719 64.0436 45.0975 63.2716C44.9231 62.4996 45.0163 61.6913 45.3619 60.9793C45.7074 60.2672 46.2848 59.6938 46.9992 59.3531V52.5039C46.9992 52.3069 47.038 52.1119 47.1134 51.9299C47.1888 51.7479 47.2992 51.5826 47.4385 51.4433C47.5778 51.304 47.7432 51.1936 47.9252 51.1182C48.1072 51.0428 48.3022 51.004 48.4992 51.004C48.6962 51.004 48.8912 51.0428 49.0732 51.1182C49.2552 51.1936 49.4206 51.304 49.5599 51.4433C49.6991 51.5826 49.8096 51.7479 49.885 51.9299C49.9604 52.1119 49.9992 52.3069 49.9992 52.5039V59.3531C50.7136 59.6938 51.291 60.2672 51.6365 60.9793C51.9821 61.6913 52.0753 62.4996 51.9009 63.2716C51.7265 64.0436 51.2948 64.7334 50.6767 65.2278C50.0585 65.7221 49.2907 65.9917 48.4992 65.9923ZM48.4992 51.9939C48.3666 51.9939 48.2394 52.0466 48.1456 52.1403C48.0519 52.2341 47.9992 52.3613 47.9992 52.4939V59.673C47.9991 59.7758 47.9674 59.876 47.9084 59.9601C47.8494 60.0442 47.7658 60.108 47.6692 60.143C47.1126 60.3388 46.6433 60.7252 46.3442 61.2338C46.0451 61.7424 45.9355 62.3403 46.0349 62.9219C46.1343 63.5034 46.4361 64.0311 46.8871 64.4115C47.3381 64.7919 47.9091 65.0006 48.4992 65.0006C49.0892 65.0006 49.6603 64.7919 50.1113 64.4115C50.5622 64.0311 50.8641 63.5034 50.9635 62.9219C51.0629 62.3403 50.9533 61.7424 50.6542 61.2338C50.3551 60.7252 49.8858 60.3388 49.3292 60.143C49.2326 60.108 49.149 60.0442 49.09 59.9601C49.0309 59.876 48.9992 59.7758 48.9992 59.673V52.5039C49.0005 52.4374 48.9886 52.3713 48.964 52.3095C48.9395 52.2476 48.9029 52.1913 48.8563 52.1439C48.8098 52.0964 48.7542 52.0587 48.6929 52.0329C48.6315 52.0072 48.5657 51.9939 48.4992 51.9939Z" fill="currentColor" />
                <path d="M49.5 55.9937H45.5C45.3674 55.9937 45.2402 55.941 45.1464 55.8472C45.0527 55.7534 45 55.6263 45 55.4937C45 55.361 45.0527 55.2339 45.1464 55.1401C45.2402 55.0463 45.3674 54.9937 45.5 54.9937H49.5C49.6326 54.9937 49.7598 55.0463 49.8536 55.1401C49.9473 55.2339 50 55.361 50 55.4937C50 55.6263 49.9473 55.7534 49.8536 55.8472C49.7598 55.941 49.6326 55.9937 49.5 55.9937Z" fill="currentColor" />
                <path d="M47.5 53.9939H45.5C45.3674 53.9939 45.2402 53.9412 45.1464 53.8475C45.0527 53.7537 45 53.6265 45 53.4939C45 53.3613 45.0527 53.2341 45.1464 53.1403C45.2402 53.0466 45.3674 52.9939 45.5 52.9939H47.5C47.6326 52.9939 47.7598 53.0466 47.8536 53.1403C47.9473 53.2341 48 53.3613 48 53.4939C48 53.6265 47.9473 53.7537 47.8536 53.8475C47.7598 53.9412 47.6326 53.9939 47.5 53.9939Z" fill="currentColor" />
                <path d="M34.9987 57.1736C34.9995 57.4451 34.9451 57.7139 34.8387 57.9636C34.7206 58.2262 34.573 58.4745 34.3987 58.7036C34.2152 58.9597 34.0112 59.2005 33.7887 59.4236C33.5687 59.6636 33.3387 59.8936 33.1187 60.1236L32.6687 60.5736C32.4983 60.7441 32.338 60.9244 32.1887 61.1136C32.0387 61.3036 31.9187 61.4836 31.8087 61.6536C31.7435 61.7632 31.6931 61.8809 31.6587 62.0036H34.9987V63.0036H30.5487C30.5569 62.5818 30.6139 62.1623 30.7187 61.7536C30.8304 61.4454 30.9817 61.1529 31.1687 60.8836C31.3591 60.6147 31.5697 60.3607 31.7987 60.1236L32.4887 59.4236L33.0287 58.8836C33.1925 58.7192 33.343 58.542 33.4787 58.3536C33.608 58.1844 33.7155 57.9997 33.7987 57.8036C33.8715 57.6155 33.9088 57.4154 33.9087 57.2136C33.9156 57.0155 33.8816 56.818 33.8087 56.6336C33.7424 56.4752 33.6311 56.3396 33.4887 56.2436C33.3703 56.1587 33.2385 56.0945 33.0987 56.0536C32.9246 56.0158 32.7467 55.999 32.5687 56.0036C32.3665 56.0005 32.1647 56.024 31.9687 56.0736C31.6692 56.1583 31.3879 56.2973 31.1387 56.4836C31.0587 56.5464 30.9819 56.6131 30.9087 56.6836L30.3887 55.9336C30.484 55.8348 30.5877 55.7445 30.6987 55.6636C30.8483 55.5422 31.009 55.435 31.1787 55.3436C31.3834 55.2424 31.5978 55.162 31.8187 55.1036C32.0624 55.0335 32.3151 54.9998 32.5687 55.0036C33.234 54.9497 33.895 55.1498 34.4187 55.5636C34.6235 55.7759 34.781 56.0291 34.881 56.3065C34.981 56.584 35.0211 56.8795 34.9987 57.1736Z" fill="currentColor" />
                <path d="M36 56.5837C36.42 56.4126 36.8192 56.1946 37.19 55.9337C37.5493 55.71 37.8319 55.3821 38 54.9937H39V62.9937H38V56.4837C37.9019 56.5834 37.7948 56.6737 37.68 56.7537C37.548 56.8505 37.4111 56.9406 37.27 57.0237L36.81 57.2637C36.65 57.3337 36.49 57.4037 36.34 57.4537L36 56.5837Z" fill="currentColor" />
                <path d="M41.5 55.9939C41.2033 55.9939 40.9133 55.9059 40.6666 55.7411C40.42 55.5763 40.2277 55.342 40.1142 55.0679C40.0006 54.7938 39.9709 54.4922 40.0288 54.2013C40.0867 53.9103 40.2296 53.643 40.4393 53.4332C40.6491 53.2235 40.9164 53.0806 41.2074 53.0227C41.4983 52.9648 41.7999 52.9945 42.074 53.1081C42.3481 53.2216 42.5824 53.4139 42.7472 53.6605C42.912 53.9072 43 54.1972 43 54.4939C43 54.8917 42.842 55.2733 42.5607 55.5546C42.2794 55.8359 41.8978 55.9939 41.5 55.9939ZM41.5 53.9939C41.3674 53.9939 41.2402 54.0466 41.1464 54.1403C41.0527 54.2341 41 54.3613 41 54.4939C41 54.6265 41.0527 54.7537 41.1464 54.8474C41.2402 54.9412 41.3674 54.9939 41.5 54.9939C41.6326 54.9939 41.7598 54.9412 41.8536 54.8474C41.9473 54.7537 42 54.6265 42 54.4939C42 54.3613 41.9473 54.2341 41.8536 54.1403C41.7598 54.0466 41.6326 53.9939 41.5 53.9939Z" fill="currentColor" />
                <path d="M28.4999 49.995C28.4343 49.9958 28.3693 49.9829 28.309 49.957C28.2487 49.9312 28.1945 49.893 28.1499 49.845C28.103 49.7985 28.0658 49.7432 28.0404 49.6823C28.015 49.6214 28.002 49.556 28.002 49.49C28.002 49.424 28.015 49.3586 28.0404 49.2977C28.0658 49.2368 28.103 49.1815 28.1499 49.135L30.7899 46.495L28.1499 43.845C28.103 43.7985 28.0658 43.7432 28.0404 43.6823C28.015 43.6214 28.002 43.556 28.002 43.49C28.002 43.424 28.015 43.3586 28.0404 43.2977C28.0658 43.2368 28.103 43.1815 28.1499 43.135C28.2433 43.0434 28.369 42.9921 28.4999 42.9921C28.6307 42.9921 28.7564 43.0434 28.8499 43.135L31.8499 46.135C31.8967 46.1815 31.9339 46.2368 31.9593 46.2977C31.9847 46.3586 31.9978 46.424 31.9978 46.49C31.9978 46.556 31.9847 46.6214 31.9593 46.6823C31.9339 46.7432 31.8967 46.7985 31.8499 46.845L28.8499 49.845C28.8052 49.893 28.751 49.9312 28.6907 49.957C28.6304 49.9829 28.5654 49.9958 28.4999 49.995Z" fill="currentColor" />
                <path d="M51.6797 46.9953C51.5471 46.9953 51.4199 46.9427 51.3261 46.8489C51.2324 46.7551 51.1797 46.628 51.1797 46.4953C51.1797 46.3627 51.2324 46.2356 51.3261 46.1418C51.4199 46.048 51.5471 45.9953 51.6797 45.9953C54.0084 46.0176 56.3128 45.5202 58.4251 44.5393C60.5373 43.5585 62.4041 42.1189 63.8897 40.3253C63.9346 40.2775 63.9888 40.2394 64.049 40.2133C64.1092 40.1873 64.1741 40.1738 64.2397 40.1738C64.3053 40.1738 64.3702 40.1873 64.4304 40.2133C64.4906 40.2394 64.5448 40.2775 64.5897 40.3253C64.6362 40.371 64.6732 40.4254 64.6985 40.4855C64.7237 40.5456 64.7368 40.6102 64.7368 40.6753C64.7368 40.7405 64.7237 40.8051 64.6985 40.8652C64.6732 40.9253 64.6362 40.9797 64.5897 41.0253C63.015 42.9154 61.0398 44.4315 58.8069 45.4641C56.5741 46.4966 54.1396 47.0197 51.6797 46.9953Z" fill="currentColor" />
                <path d="M64.4901 44.9954C64.0201 44.9954 64.0001 44.4854 64.0001 44.4854V40.9954H60.4401C60.3182 40.9764 60.207 40.9147 60.1264 40.8212C60.0458 40.7278 60.001 40.6088 60.0001 40.4854C59.9972 40.363 60.0411 40.2441 60.1229 40.153C60.2046 40.0619 60.3181 40.0055 60.4401 39.9954H64.4401C64.506 39.9897 64.5723 39.998 64.6347 40.0197C64.6971 40.0415 64.7543 40.0761 64.8024 40.1214C64.8505 40.1667 64.8886 40.2216 64.914 40.2826C64.9395 40.3436 64.9518 40.4093 64.9501 40.4754V44.4754C64.9501 44.6106 64.8964 44.7404 64.8008 44.836C64.7051 44.9316 64.5754 44.9854 64.4401 44.9854L64.4901 44.9954Z" fill="currentColor" />
            </g>
            <defs>
                <clipPath id="clip-hvac-system-0">
                    <rect width="82" height="86" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

const IconStar = (props) => {
    return (
        <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M15.1623 6.86141C15.4831 6.56829 15.303 6.0336 14.8702 5.99431L10.2472 5.57469L8.46329 1.15078C8.29495 0.733315 7.70388 0.73347 7.53576 1.15102L5.75122 5.58311L1.132 5.99563C0.698567 6.03434 0.518029 6.56989 0.839569 6.8631L4.36722 10.08L3.31592 14.8236C3.21961 15.2582 3.69648 15.5925 4.0722 15.3538L7.99922 12.8589L11.9281 15.355C12.3035 15.5935 12.7802 15.2598 12.6845 14.8254L11.6392 10.08L15.1623 6.86141Z" fill="currentColor" />
        </svg>
    )
}

const IconPointerArrow = (props) => {
    return (
        <svg {...props} width="18" height="18" viewBox="0 0 18 18">
            <path d="M14.872 8.859L3.646 2.072l-.98-.592.231 1.121 2.683 13 .243 1.178.664-1.003 3.038-4.59 5.22-1.417 1.127-.306-1-.604zM4.108 3.52l9.247 5.59-4.274 1.16-.182.05-.104.156-2.479 3.746L4.108 3.52z" fillRule="nonzero" fillOpacity="1" fill="currentColor" stroke="none"></path>
        </svg>
    )
}

const IconReportDetail = (props) => {
    return (
        <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M15 5.83333V4.16667C15 3.24583 14.2542 2.5 13.3333 2.5H4.16667C3.24583 2.5 2.5 3.24583 2.5 4.16667V8.33333C2.5 9.25417 3.24583 10 4.16667 10H5.83333" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.42578 14.2588H14.9074" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.42578 11.667H14.9074" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.42578 9.07422H14.9074" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M16.204 17.4997H7.13065C6.41398 17.4997 5.83398 16.9197 5.83398 16.203V7.12967C5.83398 6.41301 6.41398 5.83301 7.13065 5.83301H16.2048C16.9207 5.83301 17.5007 6.41301 17.5007 7.12967V16.2038C17.5007 16.9197 16.9207 17.4997 16.204 17.4997Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

const IconReview = (props) => {
    return (
        <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M6.66732 16.25H3.33398" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6.66732 12.083H3.33398" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13.334 3.75H16.6673" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6.45768 2.29199C8.41352 2.29199 9.99935 3.87783 9.99935 5.83366C9.99935 7.78949 8.41352 9.37533 6.45768 9.37533C4.50185 9.37533 2.91602 7.78949 2.91602 5.83366C2.91602 3.87783 4.50185 2.29199 6.45768 2.29199Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.83508 5.14551L6.11341 6.86718L5.08008 5.83384" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13.334 7.91699H16.6673" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14.4033 13.3057L12.6816 15.0273" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14.4033 15.0273L12.6816 13.3057" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13.5417 10.625C15.4975 10.625 17.0833 12.2108 17.0833 14.1667C17.0833 16.1225 15.4975 17.7083 13.5417 17.7083C11.5858 17.7083 10 16.1225 10 14.1667C10 12.2108 11.5858 10.625 13.5417 10.625Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

const IconChat = (props) => {
    return (
        <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M17.5 4.16667V12.5C17.5 12.942 17.3244 13.366 17.0118 13.6785C16.6993 13.9911 16.2754 14.1667 15.8333 14.1667H5.83333L2.5 17.5V4.16667C2.5 3.72464 2.67559 3.30072 2.98816 2.98816C3.30072 2.67559 3.72464 2.5 4.16667 2.5H15.8333C16.2754 2.5 16.6993 2.67559 17.0118 2.98816C17.3244 3.30072 17.5 3.72464 17.5 4.16667Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13.3327 6.66699H6.66602" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6.66602 10H9.99935" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

const IconCheckedOutline = (props) => {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M12 21C7.029 21 3 16.971 3 12C3 7.029 7.029 3 12 3C16.971 3 21 7.029 21 12C21 16.971 16.971 21 12 21Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M16 10L11 15L8 12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

const IconErrorOutline = (props) => {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M12.0005 4.125C16.3496 4.125 19.875 7.65037 19.875 12.0005C19.875 16.3496 16.3496 19.875 12.0005 19.875C7.65136 19.875 4.125 16.3496 4.125 12.0005C4.125 7.65135 7.65037 4.125 12.0005 4.125Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 12.5749V8.12891" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <circle cx="12.0034" cy="15.9096" r="0.897886" fill="currentColor" />
        </svg>
    )
}

const IconBackArrow = (props) => {
    return (
        <svg {...props} width="14" height="12" viewBox="0 0 14 12" fill="none">
            <path d="M13.2422 6.00879L0.758854 6.00879" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5.74414 1.01953L0.76414 6.00036L5.74414 10.9812" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

const IconCaretDown = (props) => {
    return (
        <svg {...props} width="8" height="6" viewBox="0 0 8 6" fill="none">
            <path d="M1.41421 0.5C0.523308 0.5 0.0771418 1.57714 0.707107 2.20711L3.29289 4.79289C3.68342 5.18342 4.31658 5.18342 4.70711 4.79289L7.2929 2.2071C7.92286 1.57714 7.47669 0.5 6.58579 0.5L1.41421 0.5Z" fill="currentColor" />
        </svg>
    )
}

const IconCaretUp = (props) => {
    return (
        <svg  {...props} width="8" height="6" viewBox="0 0 8 6" fill="none">
            <path d="M1.41421 5.5C0.523308 5.5 0.0771418 4.42286 0.707107 3.79289L3.29289 1.20711C3.68342 0.816582 4.31658 0.816583 4.70711 1.20711L7.2929 3.7929C7.92286 4.42286 7.47669 5.5 6.58579 5.5L1.41421 5.5Z" fill="currentColor" />
        </svg>
    )
}

const IconCircleInfo = (props) => {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 17C11.4477 17 11 16.5523 11 16C11 15.4477 11.4477 15 12 15C12.5523 15 13 15.4477 13 16C13 16.5523 12.5523 17 12 17ZM12 7C11.4477 7 11 7.44772 11 8V12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12V8C13 7.44772 12.5523 7 12 7Z" fill="currentColor" />
        </svg>
    )
}

const IconEditPenCheckmark = (props) => {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M8.581 17H5C3.895 17 3 16.105 3 15V5C3 3.895 3.895 3 5 3H15C16.105 3 17 3.895 17 5V8" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M15.622 20.678L21.677 14.623C22.107 14.193 22.107 13.497 21.677 13.068L19.932 11.323C19.502 10.893 18.806 10.893 18.377 11.323L12.322 17.378C12.115 17.585 12 17.864 12 18.155V21H14.845C15.136 21 15.415 20.885 15.622 20.678Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 9.08398L9.5 11.584L8 10.084" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

const IconEditPen = (props) => {
    return (
        <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M5.00003 11.6666H2.58984" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9.16667 7.5H2.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13.3334 3.33337H2.58984" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9.16602 14.0239C9.16601 13.5819 9.34161 13.158 9.65417 12.8454L13.6067 8.89293L13.6777 8.82183C13.8325 8.66697 14.0162 8.54412 14.2184 8.46029C14.4207 8.37646 14.6374 8.33329 14.8563 8.33325C15.0753 8.33321 15.292 8.3763 15.4943 8.46006C15.6966 8.54382 15.8804 8.66661 16.0352 8.82141L17.011 9.79734C17.3236 10.1099 17.4993 10.5339 17.4993 10.976C17.4994 11.4181 17.3239 11.8421 17.0114 12.1548L16.94 12.2263L12.9878 16.1785C12.833 16.3333 12.6493 16.456 12.4471 16.5398C12.2448 16.6235 12.0281 16.6667 11.8092 16.6667H9.99935C9.77834 16.6667 9.56637 16.5789 9.41009 16.4226C9.25381 16.2663 9.16602 16.0543 9.16602 15.8333V14.0239Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

const IconMatrics = (props) => {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M20 16H16.535C16.201 16 15.888 16.167 15.703 16.445L15.297 17.054C15.112 17.332 14.799 17.499 14.465 17.499H9.535C9.201 17.499 8.888 17.332 8.703 17.054L8.297 16.445C8.111 16.167 7.799 16 7.464 16H4C3.448 16 3 16.448 3 17V18C3 19.105 3.895 20 5 20H19C20.105 20 21 19.105 21 18V17C21 16.448 20.552 16 20 16Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9 13V10.667" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 13V9.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M15 13V7.16699" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M20 16V5.571C20 4.703 19.204 4 18.222 4H5.778C4.796 4 4 4.703 4 5.571V16" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

const IconCertificateMedal = (props) => {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M18 18C19.933 18 21.5 16.433 21.5 14.5C21.5 12.567 19.933 11 18 11C16.067 11 14.5 12.567 14.5 14.5C14.5 16.433 16.067 18 18 18Z" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M16 17.3721V22.0001L18 21.0001L20 22.0001V17.3721" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13 21H5C3.895 21 3 20.105 3 19V5C3 3.895 3.895 3 5 3H18C19.105 3 20 3.895 20 5V8" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7 7H16" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7 11H11" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7 15H10" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

const IconCloudSync = (props) => {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M3.88672 14.2809H3.94472C6.77772 14.2809 8.66672 14.1189 8.66672 11.4479C8.66672 8.61488 11.4997 8.61488 11.4997 6.72588C11.4997 4.61388 7.72172 4.83688 7.72172 2.94788V2.87988" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M20.0009 10.5001H16.9329C14.8279 10.5001 13.7109 8.01308 15.1089 6.43908L17.1399 4.15308" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M16 13.999C14.343 13.999 13 15.342 13 16.999C11.895 16.999 11 17.894 11 18.999C11 20.104 11.895 20.999 13 20.999H18.5C19.881 20.999 21 19.879 21 18.499C21 17.234 20.057 16.199 18.838 16.033C18.436 14.851 17.318 13.999 16 13.999V13.999Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M20 10.5C20 5.805 16.195 2 11.5 2C6.805 2 3 5.805 3 10.5C3 13.618 4.683 16.335 7.185 17.815" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

const IconWaterPerformance = (props) => {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M13.5738 3.145C13.0618 3.056 12.5378 3 11.9998 3C11.4618 3 10.9378 3.056 10.4258 3.145" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M20.5195 8.9729C20.8725 9.9419 21.0745 10.9829 21.0745 12.0729" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M18.9482 6.24292C18.2702 5.43592 17.4512 4.75292 16.5332 4.22192" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7.46773 4.22192C6.54973 4.75292 5.73173 5.43592 5.05273 6.24292" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M2.92773 12.0729C2.92773 10.9819 3.12973 9.9409 3.48273 8.9729" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7.4668 19.9241C8.3668 20.4451 9.3638 20.8141 10.4258 21.0001" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M3.48242 15.1731C3.84842 16.1771 4.37842 17.1001 5.05242 17.9031" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M13.5742 21.0001C14.6362 20.8141 15.6322 20.4451 16.5332 19.9241" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M18.9492 17.9031C19.6232 17.1001 20.1532 16.1771 20.5192 15.1731" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.8001 7.40101L15.1961 10.607C17.1671 13.243 15.2881 17 12.0001 17C8.71209 17 6.83309 13.243 8.80309 10.607L11.2001 7.40101C11.6001 6.86601 12.4001 6.86601 12.8001 7.40101Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}

const IconChecker = (props) => {
    return (
        <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M13.3327 4.33325L5.99935 11.6666L2.66602 8.33325" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

const IconLeftArrow = (props) => {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M14 8L10 12L14 16" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

const IconRightArrow = (props) => {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M10 8L14 12L10 16" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

const IconArrowUp = (props) => {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M16 14L12 10L8 14" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

const IconArrowDown = (props) => {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M16 10L12 14L8 10" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

const IconSync = (props) => {
    return (
        <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M18.3397 10.0017L16.6714 11.6684L15.0039 10.0017" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5.28516 5.28659C6.49266 4.07992 8.16016 3.33325 10.0027 3.33325C13.6868 3.33325 16.6735 6.31825 16.6735 10.0016C16.6735 10.5083 16.6118 10.9999 16.5052 11.4741" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M1.66016 9.99821L3.32849 8.33154L4.99599 9.99821" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14.7165 14.7134C13.509 15.92 11.8415 16.6667 9.99896 16.6667C6.31479 16.6667 3.32812 13.6817 3.32812 9.99838C3.32812 9.49171 3.38979 9.00005 3.49646 8.52588" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

const IconHeaderCaret = (props) => {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M9.41421 10C8.52331 10 8.07714 8.92286 8.70711 8.29289L11.2929 5.70711C11.6834 5.31658 12.3166 5.31658 12.7071 5.70711L15.2929 8.2929C15.9229 8.92286 15.4767 10 14.5858 10L9.41421 10Z" fill="currentColor" />
            <path d="M9.41421 14C8.52331 14 8.07714 15.0771 8.70711 15.7071L11.2929 18.2929C11.6834 18.6834 12.3166 18.6834 12.7071 18.2929L15.2929 15.7071C15.9229 15.0771 15.4767 14 14.5858 14L9.41421 14Z" fill="currentColor" />
        </svg>
    )
}

const IconHeaderCaretUp = (props) => {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M9.41421 10C8.52331 10 8.07714 8.92286 8.70711 8.29289L11.2929 5.70711C11.6834 5.31658 12.3166 5.31658 12.7071 5.70711L15.2929 8.2929C15.9229 8.92286 15.4767 10 14.5858 10L9.41421 10Z" fill="currentColor" />
        </svg>
    )
}

const IconHeaderCaretDown = (props) => {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M9.41421 14C8.52331 14 8.07714 15.0771 8.70711 15.7071L11.2929 18.2929C11.6834 18.6834 12.3166 18.6834 12.7071 18.2929L15.2929 15.7071C15.9229 15.0771 15.4767 14 14.5858 14L9.41421 14Z" fill="currentColor" />
        </svg>
    )
}

const IconSearch = (props) => {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M14.5259 13L20.2929 18.7669C20.6834 19.1575 20.6834 19.7906 20.2929 20.1812L20.1812 20.2929C19.7906 20.6834 19.1575 20.6834 18.7669 20.2929L13 14.5259L14.5259 13Z" fill="currentColor" />
            <path fillRule="evenodd" clipRule="evenodd" d="M10 15C12.7614 15 15 12.7614 15 10C15 7.23858 12.7614 5 10 5C7.23858 5 5 7.23858 5 10C5 12.7614 7.23858 15 10 15ZM10 17C13.866 17 17 13.866 17 10C17 6.13401 13.866 3 10 3C6.13401 3 3 6.13401 3 10C3 13.866 6.13401 17 10 17Z" fill="currentColor" />
        </svg>
    )
}

const IconTrash = (props) => {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M3 5.25C2.58579 5.25 2.25 5.58579 2.25 6C2.25 6.41421 2.58579 6.75 3 6.75V5.25ZM21 6.75C21.4142 6.75 21.75 6.41421 21.75 6C21.75 5.58579 21.4142 5.25 21 5.25V6.75ZM14.75 11C14.75 10.5858 14.4142 10.25 14 10.25C13.5858 10.25 13.25 10.5858 13.25 11H14.75ZM13.25 17C13.25 17.4142 13.5858 17.75 14 17.75C14.4142 17.75 14.75 17.4142 14.75 17H13.25ZM10.75 11C10.75 10.5858 10.4142 10.25 10 10.25C9.58579 10.25 9.25 10.5858 9.25 11H10.75ZM9.25 17C9.25 17.4142 9.58579 17.75 10 17.75C10.4142 17.75 10.75 17.4142 10.75 17H9.25ZM19 6.75H21V5.25H19V6.75ZM18.25 6V20H19.75V6H18.25ZM17 21.25H7V22.75H17V21.25ZM3 6.75H5V5.25H3V6.75ZM5.75 20V6H4.25V20H5.75ZM13.25 11V17H14.75V11H13.25ZM9.25 11V17H10.75V11H9.25ZM5 6.75H8V5.25H5V6.75ZM8.75 6V4H7.25V6H8.75ZM10 2.75H14V1.25H10V2.75ZM8 6.75H16V5.25H8V6.75ZM16 6.75H19V5.25H16V6.75ZM15.25 4V6H16.75V4H15.25ZM14 2.75C14.6904 2.75 15.25 3.30964 15.25 4H16.75C16.75 2.48122 15.5188 1.25 14 1.25V2.75ZM8.75 4C8.75 3.30964 9.30964 2.75 10 2.75V1.25C8.48122 1.25 7.25 2.48122 7.25 4H8.75ZM7 21.25C6.30964 21.25 5.75 20.6904 5.75 20H4.25C4.25 21.5188 5.48122 22.75 7 22.75V21.25ZM18.25 20C18.25 20.6904 17.6904 21.25 17 21.25V22.75C18.5188 22.75 19.75 21.5188 19.75 20H18.25Z" fill="currentColor" />
        </svg>
    )
}

const IconAddOutline = (props) => {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M12 8V16" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M16 12H8" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 21C7.029 21 3 16.971 3 12C3 7.029 7.029 3 12 3C16.971 3 21 7.029 21 12C21 16.971 16.971 21 12 21Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

const IconPenEdit = (props) => {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M18 13V19C18 20.105 17.105 21 16 21H5C3.895 21 3 20.105 3 19V8C3 6.895 3.895 6 5 6H9" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.00024 15L11.1502 14.611C11.3712 14.584 11.5772 14.483 11.7352 14.326L19.3662 6.69499C20.2112 5.84999 20.2112 4.47999 19.3662 3.63399C18.5212 2.78899 17.1512 2.78899 16.3052 3.63399L8.74524 11.194C8.59224 11.347 8.49324 11.545 8.46224 11.76L8.00024 15Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

const IconPhoto = (props) => {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M14.8792 10.1678C16.4363 11.7248 16.4363 14.2492 14.8792 15.8062C13.3222 17.3633 10.7978 17.3633 9.24076 15.8062C7.68374 14.2492 7.68374 11.7248 9.24076 10.1678C10.7978 8.61074 13.3222 8.61074 14.8792 10.1678" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M21 9V18C21 19.105 20.105 20 19 20H5C3.895 20 3 19.105 3 18V9C3 7.895 3.895 7 5 7H7L8.462 4.496C8.641 4.189 8.97 4 9.326 4H14.63C14.981 4 15.306 4.184 15.487 4.484L17 7H19C20.105 7 21 7.895 21 9Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

const IconBottomInfo = (props) => {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 17C11.4477 17 11 16.5523 11 16C11 15.4477 11.4477 15 12 15C12.5523 15 13 15.4477 13 16C13 16.5523 12.5523 17 12 17ZM12 7C11.4477 7 11 7.44772 11 8V12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12V8C13 7.44772 12.5523 7 12 7Z" fill="#D0DBFF" />
        </svg>
    )
}

///////////////////////////////////////////////////////////////////////
const IconToolTextColor = (props) => {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M11.999 2C11.999 2 3.99902 9.59998 3.99902 14C3.99902 18.4 7.59902 22 11.999 22C16.399 22 19.999 18.4 19.999 14C19.999 9.59998 11.999 2 11.999 2ZM12.9947 19.9168C12.4502 20.0087 11.999 19.5523 11.999 19C11.999 18.4477 12.4538 18.0127 12.9887 17.8753C14.3973 17.5133 15.5124 16.3983 15.8743 14.9897C16.0118 14.4547 16.4467 14 16.999 14C17.5513 14 18.0078 14.4511 17.9158 14.9957C17.4927 17.5022 15.5012 19.4936 12.9947 19.9168Z" fill="currentColor" />
        </svg>
    )
}

const IconToolTextBold = (props) => {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M12.3571 21H5V2H11.3682C13.7965 2 15.6235 2.45172 16.9029 3.29763C18.14 4.11558 18.7664 5.32177 18.7664 6.99453C18.7664 7.86509 18.5627 8.62054 18.1656 9.27705C17.7817 9.91181 17.1832 10.406 16.3328 10.7476L14.8455 11.3452L16.4087 11.6992C17.4885 11.9438 18.2331 12.4209 18.7052 13.0977C19.2011 13.8094 19.4534 14.6309 19.4533 15.5798C19.4533 17.4067 18.8476 18.7198 17.6861 19.6051C16.4967 20.5117 14.7437 21 12.3571 21ZM11.3683 4.57145H8.65668V10.338L11.4643 10.338L11.473 10.3379C12.5715 10.3188 13.4825 10.1073 14.126 9.62743C14.7951 9.12863 15.1102 8.39783 15.1102 7.51652C15.1102 6.55677 14.8047 5.76202 14.109 5.25184C13.4515 4.77045 12.5092 4.57145 11.3683 4.57145ZM12.3571 18.4287C13.3983 18.4287 14.2713 18.226 14.8918 17.7376C15.5391 17.2281 15.8245 16.4647 15.8245 15.5523C15.8245 14.6069 15.5928 13.8049 15.0464 13.235C14.4855 12.65 13.6336 12.415 12.6184 12.415H8.65668V18.4287H12.3571Z" fill="currentColor" />
        </svg>
    )
}

const IconToolTextItalic = (props) => {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M7.50098 21L11.2985 2H14.8531L11.0555 21H7.50098Z" fill="currentColor" />
        </svg>
    )
}

const IconToolTextUnderline = (props) => {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M12.3136 15.964C10.575 15.964 9.20158 15.5255 8.17841 14.6809C7.16859 13.8475 6.63447 12.6721 6.63447 11.1099V2.00781H9.33536V11.1098C9.33536 12.0545 9.57921 12.823 10.1331 13.3407C10.6749 13.8471 11.4184 14.0768 12.3126 14.0768C13.2013 14.0768 13.9266 13.8483 14.4583 13.344C15.0064 12.8243 15.2176 12.0489 15.2176 11.1099V2.00781H17.9186V11.1099C17.9186 12.6765 17.4052 13.8502 16.4014 14.6812C15.3804 15.5264 14.0305 15.964 12.3136 15.964Z" fill="currentColor" />
            <path d="M21.2637 19.998C21.2637 20.5426 20.8223 20.9841 20.2777 20.9841H4.32591C3.78132 20.9841 3.33984 20.5426 3.33984 19.998C3.33984 19.4534 3.78132 19.0119 4.32591 19.0119H20.2777C20.8223 19.0119 21.2637 19.4534 21.2637 19.998Z" fill="currentColor" />
        </svg>
    )
}

const IconToolAlignLeft = (props) => {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M4 4.5H20" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4 9.5H16" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4 14.5H20" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4 19.5H12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

const IconToolAlignCenter = (props) => {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M4 4.5H20" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6 9.5H18" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4 14.5H20" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8 19.5H16" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

const IconToolAlignRight = (props) => {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M20 4.5H4" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M20 9.5H8" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M20 14.5H4" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M20 19.5H12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

const IconToolAlignJustify = (props) => {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M4 4.5H20" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4 9.5H20" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4 14.5H20" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4 19.5H20" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

const IconToolParaBullet = (props) => {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M9 6.4375H20" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M20 12H9" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9 17.5625H20" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4.5 5.9375C4.40111 5.9375 4.30444 5.96682 4.22221 6.02177C4.13999 6.07671 4.0759 6.1548 4.03806 6.24616C4.00022 6.33752 3.99031 6.43805 4.00961 6.53505C4.0289 6.63204 4.07652 6.72113 4.14645 6.79105C4.21637 6.86098 4.30546 6.9086 4.40246 6.92789C4.49945 6.94719 4.59998 6.93728 4.69134 6.89944C4.78271 6.8616 4.86079 6.79751 4.91574 6.71528C4.97068 6.63306 5 6.53639 5 6.4375C5 6.30489 4.94732 6.17771 4.85355 6.08395C4.75979 5.99018 4.63261 5.9375 4.5 5.9375Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4.5 11.5C4.40111 11.5 4.30444 11.5293 4.22221 11.5843C4.13999 11.6392 4.0759 11.7173 4.03806 11.8087C4.00022 11.9 3.99031 12.0006 4.00961 12.0975C4.0289 12.1945 4.07652 12.2836 4.14645 12.3536C4.21637 12.4235 4.30546 12.4711 4.40246 12.4904C4.49945 12.5097 4.59998 12.4998 4.69134 12.4619C4.78271 12.4241 4.86079 12.36 4.91574 12.2778C4.97068 12.1956 5 12.0989 5 12C5 11.8674 4.94732 11.7402 4.85355 11.6464C4.75979 11.5527 4.63261 11.5 4.5 11.5Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4.5 17.0625C4.40111 17.0625 4.30444 17.0918 4.22221 17.1468C4.13999 17.2017 4.0759 17.2798 4.03806 17.3712C4.00022 17.4625 3.99031 17.5631 4.00961 17.66C4.0289 17.757 4.07652 17.8461 4.14645 17.9161C4.21637 17.986 4.30546 18.0336 4.40246 18.0529C4.49945 18.0722 4.59998 18.0623 4.69134 18.0244C4.78271 17.9866 4.86079 17.9225 4.91574 17.8403C4.97068 17.7581 5 17.6614 5 17.5625C5 17.4299 4.94732 17.3027 4.85355 17.2089C4.75979 17.1152 4.63261 17.0625 4.5 17.0625Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

const IconToolParaNumber = (props) => {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M9 6.4375H20" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M20 12H9" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9 17.5625H20" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6 16H4.5C4.22386 16 4 15.7761 4 15.5C4 15.2239 4.22386 15 4.5 15H7V19H4.5C4.22386 19 4 18.7761 4 18.5C4 18.2239 4.22386 18 4.5 18H6V17.5H5.5C5.22386 17.5 5 17.2761 5 17C5 16.7239 5.22386 16.5 5.5 16.5H6V16Z" fill="currentColor" />
            <path d="M5.8 11H4.5C4.22386 11 4 10.7761 4 10.5C4 10.2239 4.22386 10 4.5 10H7V10.9L5.2 13H6.5C6.77614 13 7 13.2239 7 13.5C7 13.7761 6.77614 14 6.5 14H4V13.1L5.8 11Z" fill="currentColor" />
            <path d="M6 8.5C6 8.77614 5.77614 9 5.5 9C5.22386 9 5 8.77614 5 8.5V6H4.5C4.22386 6 4 5.77614 4 5.5C4 5.22386 4.22386 5 4.5 5H6V8.5Z" fill="currentColor" />
        </svg>
    )
}

const IconToolMiscChat = (props) => {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M9.188 15L11.1 17.55C11.55 18.15 12.45 18.15 12.9 17.55L14.812 15H18C19.657 15 21 13.657 21 12V6C21 4.343 19.657 3 18 3H6C4.343 3 3 4.343 3 6V12C3 13.657 4.343 15 6 15H9.188Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7 21H17" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <circle cx="8.00201" cy="9.49811" r="1.00201" fill="currentColor" />
            <circle cx="11.9981" cy="9.49811" r="1.00201" fill="currentColor" />
            <circle cx="15.9942" cy="9.49811" r="1.00201" fill="currentColor" />
        </svg>
    )
}

const IconToolMiscCat = (props) => {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M3 3V21" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M3 14.333H15V18C15 18.552 14.552 19 14 19H3V14.333Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M3 9.66699H20C20.552 9.66699 21 10.115 21 10.667V13.334C21 13.886 20.552 14.334 20 14.334H3V9.66699Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M3 5H17C17.552 5 18 5.448 18 6V9.667H3V5Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

const IconToolUsers = (props) => {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M3 18C3 15.791 4.791 14 7 14H11C13.209 14 15 15.791 15 18" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M16 4.65234C17.381 4.65234 18.5 5.77134 18.5 7.15234C18.5 8.53334 17.381 9.65234 16 9.65234" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11.4035 4.99613C12.7317 6.3243 12.7317 8.4777 11.4035 9.80587C10.0754 11.134 7.92195 11.134 6.59378 9.80587C5.26561 8.4777 5.26561 6.3243 6.59378 4.99613C7.92195 3.66796 10.0754 3.66796 11.4035 4.99613" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M17 13C19.209 13 21 14.791 21 17" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

const IconGreenInfo = (props) => {
    return (
        <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z" stroke="#00B341" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9.16797 12.9167H11.0924" stroke="#00B341" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.1341 12.9167V9.375H9.17578" stroke="#00B341" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.0846 6.87264C10.0846 6.91384 10.0724 6.95412 10.0495 6.98838C10.0266 7.02264 9.9941 7.04935 9.95603 7.06511C9.91796 7.08088 9.87607 7.08501 9.83566 7.07697C9.79525 7.06893 9.75812 7.04909 9.72899 7.01995C9.69985 6.99082 9.68001 6.9537 9.67197 6.91328C9.66393 6.87287 9.66806 6.83098 9.68383 6.79291C9.6996 6.75485 9.7263 6.72231 9.76056 6.69942C9.79482 6.67653 9.8351 6.66431 9.8763 6.66431" stroke="#00B341" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9.875 6.66431C9.93025 6.66431 9.98324 6.68626 10.0223 6.72533C10.0614 6.7644 10.0833 6.81739 10.0833 6.87264" stroke="#00B341" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

const IconSmallCircle = (props) => {
    return (
        <svg {...props} width="8" height="8" viewBox="0 0 8 8" fill="none">
            <circle cx="4" cy="4" r="4" fill="currentColor" />
        </svg>
    )
}

////////////// Building Icons ////////////////////////////////////////
const IconTrashBin = (props) => {
    return (
        <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M5 7.5V15.8333C5 16.2754 5.17559 16.6993 5.48816 17.0118C5.80072 17.3244 6.22464 17.5 6.66667 17.5H13.3333C13.7754 17.5 14.1993 17.3244 14.5118 17.0118C14.8244 16.6993 15 16.2754 15 15.8333V7.5M11.6667 8.33333V14.1667M8.33333 8.33333V14.1667M3.75 5H16.25M6.66667 5L7.12 3.64C7.23058 3.30799 7.44286 3.01921 7.72675 2.8146C8.01064 2.60998 8.35173 2.49992 8.70167 2.5H11.2983C11.6486 2.49957 11.99 2.60947 12.2742 2.81411C12.5585 3.01875 12.771 3.30772 12.8817 3.64L13.3333 5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

const IconCalendarAddPlus = (props) => {
    return (
        <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M12.5 2.5V4.16667" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9.16602 2.5V4.16667" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5.83398 2.5V4.16667" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M15.8333 10.2167V5.00004C15.8333 4.07921 15.0875 3.33337 14.1667 3.33337H4.16667C3.24583 3.33337 2.5 4.07921 2.5 5.00004V14.1667C2.5 15.0875 3.24583 15.8334 4.16667 15.8334H11.465" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5.83398 7.5H12.5007" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5.83398 10.8334H8.33398" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14.584 17.5C12.5132 17.5 10.834 15.8208 10.834 13.75C10.834 11.7217 12.559 9.99833 14.5873 10C16.6573 10.0017 18.334 11.68 18.334 13.75C18.334 15.8208 16.6557 17.5 14.584 17.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14.584 12.25V15.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M16.084 13.75H13.084" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

const IconBuildingEdit = (props) => {
    return (
        <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M10.489 17.5H8.33398V15.345C8.33403 15.124 8.42186 14.912 8.57815 14.7558L11.9115 11.4225C12.0678 11.2662 12.2797 11.1785 12.5007 11.1785C12.7216 11.1785 12.9335 11.2662 13.0898 11.4225L14.4115 12.7441C14.5677 12.9004 14.6555 13.1123 14.6555 13.3333C14.6555 13.5543 14.5677 13.7662 14.4115 13.9225L11.0782 17.2558C10.9219 17.4121 10.71 17.4999 10.489 17.5Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14.1667 17.5H15C15.663 17.5 16.2989 17.2366 16.7678 16.7677C17.2366 16.2989 17.5 15.663 17.5 15V8.88913C17.4999 8.50859 17.4129 8.13309 17.2457 7.79126C17.0784 7.44943 16.8354 7.15029 16.535 6.91663L11.535 3.02747C11.0961 2.68608 10.556 2.50073 10 2.50073C9.444 2.50073 8.90386 2.68608 8.465 3.02747L3.465 6.91663C3.16463 7.15029 2.92157 7.44943 2.75434 7.79126C2.58711 8.13309 2.50012 8.50859 2.5 8.88913V15C2.5 15.663 2.76339 16.2989 3.23223 16.7677C3.70107 17.2366 4.33696 17.5 5 17.5H5.83333" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

const IconWaterTab = (props) => {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M12.6034 5.56008L15.9049 10.0487C16.516 10.8804 16.8876 11.8684 16.9783 12.9022C17.0689 13.936 16.875 14.9751 16.4183 15.9035C15.9615 16.8319 15.2598 17.6131 14.3915 18.1599C13.5233 18.7066 12.5225 18.9975 11.501 19V19C10.4793 18.9979 9.47829 18.7073 8.60971 18.1607C7.74113 17.614 7.03916 16.8329 6.58217 15.9044C6.12517 14.9759 5.93113 13.9367 6.02171 12.9027C6.11228 11.8687 6.48391 10.8806 7.0951 10.0487L10.3985 5.56008C10.5269 5.38618 10.6933 5.24503 10.8847 5.14782C11.076 5.05061 11.287 5 11.501 5C11.7149 5 11.9259 5.05061 12.1173 5.14782C12.3086 5.24503 12.4751 5.38618 12.6034 5.56008V5.56008Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

const IconEnergyTab = (props) => {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M6.00055 6.37007C5.58492 6.31128 5.16114 6.36148 4.77075 6.51574C4.38035 6.67001 4.03673 6.92305 3.77355 7.25007C2.80755 8.92407 5.70655 12.4081 10.2506 15.0311C10.8376 15.3701 11.4236 15.6771 12.0006 15.9531" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 8.04712C12.577 8.32312 13.163 8.62912 13.75 8.96912C18.294 11.5921 21.194 15.0761 20.227 16.7501C19.9637 17.077 19.6201 17.3299 19.2297 17.4842C18.8393 17.6384 18.4156 17.6887 18 17.6301" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4.12432 14.3811C3.8655 14.7117 3.69706 15.1038 3.63553 15.5192C3.574 15.9345 3.62148 16.3586 3.77332 16.7501C4.73932 18.4241 9.20632 17.6501 13.7503 15.0311C14.3373 14.6921 14.8963 14.3381 15.4233 13.9761" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.57617 10.024C9.10417 9.66204 9.66217 9.30804 10.2492 8.96904C14.7932 6.34604 19.2602 5.57604 20.2262 7.25004C20.3777 7.64152 20.425 8.06564 20.3635 8.48089C20.302 8.89615 20.1337 9.28832 19.8752 9.61904" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.123 20.0109C10.666 20.9489 11.308 21.4999 11.999 21.4999C13.932 21.4999 15.499 17.2469 15.499 11.9999C15.499 11.3219 15.472 10.6619 15.422 10.0239" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.577 13.976C8.527 13.338 8.5 12.676 8.5 12C8.5 6.753 10.067 2.5 12 2.5C12.691 2.5 13.333 3.051 13.876 3.989" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

const IconPin = (props) => {
    return (
        <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M12.8425 3.35295L16.2305 6.74102C16.3863 6.89683 16.5056 7.08527 16.5798 7.29275C16.654 7.50023 16.6812 7.72159 16.6595 7.94087C16.6378 8.16014 16.5677 8.37186 16.4542 8.56077C16.3408 8.74968 16.1869 8.91106 16.0035 9.03329L13.5263 10.6848C13.218 10.8903 12.9974 11.2033 12.9075 11.5628L11.8769 15.6855C11.8449 15.8133 11.7796 15.9304 11.6876 16.0247C11.5956 16.1191 11.4802 16.1873 11.3532 16.2225C11.2262 16.2577 11.0922 16.2585 10.9648 16.2249C10.8373 16.1914 10.7211 16.1246 10.6279 16.0314L3.55208 8.95558C3.4589 8.8624 3.39212 8.74616 3.35855 8.61873C3.32498 8.49131 3.32583 8.35725 3.36101 8.23026C3.39618 8.10327 3.46443 7.98788 3.55877 7.89589C3.65312 7.80389 3.77019 7.73858 3.89803 7.70662L8.02071 6.67594C8.38014 6.58608 8.69316 6.36548 8.89867 6.05722L10.5502 3.57994C10.6724 3.3966 10.8338 3.24266 11.0227 3.12924C11.2116 3.01581 11.4233 2.94571 11.6426 2.924C11.8619 2.90228 12.0832 2.9295 12.2907 3.00367C12.4982 3.07785 12.6867 3.19715 12.8425 3.35295V3.35295Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M3.33398 16.2501L7.09051 12.4935" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

const IconClock = (props) => {
    return (
        <svg {...props} width="25" height="24" viewBox="0 0 25 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M12.49 2C6.97 2 2.5 6.48 2.5 12C2.5 17.52 6.97 22 12.49 22C18.02 22 22.5 17.52 22.5 12C22.5 6.48 18.02 2 12.49 2ZM12.5 20C8.08 20 4.5 16.42 4.5 12C4.5 7.58 8.08 4 12.5 4C16.92 4 20.5 7.58 20.5 12C20.5 16.42 16.92 20 12.5 20ZM12.25 7C12.6642 7 13 7.33579 13 7.75V12.25L16.8746 14.5489C17.2188 14.7531 17.3298 15.1992 17.1214 15.5409C16.9159 15.878 16.4768 15.9861 16.1383 15.783L11.5 13V7.75C11.5 7.33579 11.8358 7 12.25 7Z" fill="currentColor" />
        </svg>
    )
}

const IconFrontLocation = (props) => {
    return (
        <svg {...props} width="82" height="86" viewBox="0 0 82 86" fill="none">
            <g clipPath="url(#clip0_5954_44663)">
                <path d="M76 67.9921H74V23.8072C74.0024 23.2778 73.8695 22.7566 73.614 22.2929C73.3585 21.8292 72.9888 21.4384 72.54 21.1576L42.61 2.46973C42.1288 2.1637 41.5703 2.00116 41 2.00116C40.4297 2.00116 39.8712 2.1637 39.39 2.46973L9.46003 21.1576C9.01122 21.4384 8.64155 21.8292 8.38605 22.2929C8.13056 22.7566 7.99769 23.2778 8.00003 23.8072V67.9921H6.00003V23.8072C5.99701 22.938 6.21583 22.0823 6.6358 21.3212C7.05577 20.5601 7.66299 19.9187 8.40003 19.4578L38.33 0.769924C39.1291 0.265283 40.0549 -0.00256348 41 -0.00256348C41.9452 -0.00256348 42.8709 0.265283 43.67 0.769924L73.6 19.4578C74.3371 19.9187 74.9443 20.5601 75.3643 21.3212C75.7842 22.0823 76.0031 22.938 76 23.8072V67.9921Z" fill="currentColor" />
                <path d="M70 70.9918H68V40.2453C68.0065 39.844 67.9074 39.4479 67.7126 39.097C67.5178 38.746 67.2341 38.4524 66.89 38.2456L42.24 23.3473C41.8658 23.1215 41.4371 23.0022 41 23.0022C40.563 23.0022 40.1342 23.1215 39.76 23.3473L15.11 38.2956C14.7659 38.5024 14.4822 38.796 14.2874 39.147C14.0926 39.4979 13.9935 39.894 14 40.2953V70.9918H12V40.2453C11.999 39.5084 12.1891 38.7838 12.552 38.1424C12.9148 37.501 13.4378 36.9646 14.07 36.5858L38.72 21.5875C39.4076 21.171 40.1961 20.9508 41 20.9508C41.8039 20.9508 42.5925 21.171 43.28 21.5875L67.93 36.5858C68.5622 36.9646 69.0852 37.501 69.448 38.1424C69.8109 38.7838 70.0011 39.5084 70 40.2453V70.9918Z" fill="currentColor" />
                <path d="M67 35.9958H66V28.3467C66.0032 27.9127 65.8933 27.4852 65.6812 27.1066C65.469 26.7279 65.1619 26.4109 64.79 26.187L42.34 12.3786C41.9362 12.1331 41.4726 12.0033 41 12.0033C40.5274 12.0033 40.0639 12.1331 39.66 12.3786L17.21 26.187C16.8381 26.4109 16.531 26.7279 16.3189 27.1066C16.1067 27.4852 15.9968 27.9127 16 28.3467V35.9958H15V28.3467C14.9983 27.7433 15.1531 27.1497 15.4493 26.624C15.7455 26.0982 16.173 25.6583 16.69 25.3471L39.13 11.5287C39.6917 11.1807 40.3393 10.9963 41 10.9963C41.6608 10.9963 42.3084 11.1807 42.87 11.5287L65.31 25.3371C65.827 25.6483 66.2545 26.0882 66.5507 26.614C66.8469 27.1397 67.0017 27.7333 67 28.3367V35.9958Z" fill="currentColor" />
                <path d="M41 85.99C40.0728 85.99 39.1596 85.7635 38.34 85.3301L1.23998 65.7724C0.89783 65.5937 0.606148 65.3318 0.391811 65.0108C0.177475 64.6899 0.0473788 64.3201 0.0135101 63.9356C-0.0203586 63.5512 0.0430894 63.1644 0.198008 62.8109C0.352927 62.4574 0.594333 62.1485 0.899984 61.9128L6.12998 57.5933L7.40998 59.1331L2.14998 63.4826C2.10277 63.5122 2.0641 63.5536 2.03779 63.6027C2.01148 63.6518 1.99845 63.7069 1.99998 63.7626C2.00707 63.8133 2.0262 63.8615 2.05578 63.9033C2.08536 63.945 2.12452 63.9791 2.16998 64.0026L39.27 83.5703C39.8033 83.8514 40.3971 83.9983 41 83.9983C41.6029 83.9983 42.1967 83.8514 42.73 83.5703L79.83 63.9926C79.8755 63.9691 79.9146 63.935 79.9442 63.8933C79.9738 63.8515 79.9929 63.8033 80 63.7526C80.0032 63.7027 79.994 63.6528 79.973 63.6074C79.952 63.562 79.9201 63.5225 79.88 63.4926L74.59 59.1231L75.87 57.5833L81.12 61.9228C81.4227 62.1592 81.6611 62.468 81.8132 62.8207C81.9653 63.1734 82.0262 63.5586 81.9903 63.941C81.9545 64.3234 81.823 64.6907 81.608 65.009C81.393 65.3272 81.1014 65.5863 80.76 65.7624L43.66 85.3401C42.8395 85.77 41.9263 85.9931 41 85.99Z" fill="currentColor" />
                <path d="M29 28.9966H28V18.9978L29 17.9979V28.9966Z" fill="currentColor" />
                <path d="M31 27.9968L30 28.9967V17.9979L31 16.998V27.9968Z" fill="currentColor" />
                <path d="M53 28.9966V17.9979L54 18.9978V29.9965L53 28.9966Z" fill="currentColor" />
                <path d="M52 27.9968H51V16.998L52 17.9979V27.9968Z" fill="currentColor" />
                <path d="M60.5 36.9958H23.5C23.3674 36.9958 23.2402 36.9431 23.1464 36.8494C23.0527 36.7556 23 36.6284 23 36.4958C23 36.3632 23.0527 36.236 23.1464 36.1423C23.2402 36.0485 23.3674 35.9958 23.5 35.9958H60.5C60.6326 35.9958 60.7598 36.0485 60.8536 36.1423C60.9473 36.236 61 36.3632 61 36.4958C61 36.6284 60.9473 36.7556 60.8536 36.8494C60.7598 36.9431 60.6326 36.9958 60.5 36.9958Z" fill="currentColor" />
                <path d="M58.5 38.9956H18.5C18.3674 38.9956 18.2402 38.9429 18.1464 38.8492C18.0527 38.7554 18 38.6282 18 38.4956C18 38.363 18.0527 38.2358 18.1464 38.1421C18.2402 38.0483 18.3674 37.9956 18.5 37.9956H58.5C58.6326 37.9956 58.7598 38.0483 58.8536 38.1421C58.9473 38.2358 59 38.363 59 38.4956C59 38.6282 58.9473 38.7554 58.8536 38.8492C58.7598 38.9429 58.6326 38.9956 58.5 38.9956Z" fill="currentColor" />
                <path d="M32.3199 34.436C32.2347 34.4366 32.1507 34.4159 32.0756 34.3757C32.0005 34.3356 31.9367 34.2772 31.8899 34.206C31.855 34.1503 31.8314 34.0882 31.8206 34.0233C31.8097 33.9584 31.8118 33.892 31.8266 33.8279C31.8415 33.7638 31.8689 33.7032 31.9072 33.6497C31.9455 33.5962 31.994 33.5508 32.0499 33.5161L45.7299 24.7571C45.7853 24.7209 45.8474 24.6962 45.9126 24.6846C45.9778 24.673 46.0446 24.6747 46.1091 24.6897C46.1736 24.7046 46.2344 24.7325 46.2878 24.7716C46.3412 24.8107 46.3861 24.8602 46.4199 24.9171C46.4913 25.0286 46.5156 25.1638 46.4875 25.2931C46.4594 25.4224 46.3811 25.5353 46.2699 25.607L32.5899 34.356C32.5102 34.4097 32.416 34.4376 32.3199 34.436Z" fill="currentColor" />
                <path d="M39 32.747C38.916 32.7488 38.833 32.7285 38.7593 32.6882C38.6856 32.6478 38.6238 32.5888 38.58 32.517C38.5433 32.4623 38.5181 32.4007 38.5057 32.3359C38.4934 32.2711 38.4942 32.2046 38.5083 32.1401C38.5223 32.0757 38.5491 32.0148 38.5872 31.961C38.6253 31.9072 38.6739 31.8616 38.73 31.827L48.37 25.657C48.4815 25.5856 48.6167 25.5613 48.7461 25.5894C48.8754 25.6175 48.9883 25.6958 49.06 25.807C49.132 25.92 49.1566 26.0568 49.1285 26.1878C49.1004 26.3188 49.022 26.4334 48.91 26.507L39.27 32.667C39.1903 32.7207 39.0961 32.7486 39 32.747Z" fill="currentColor" />
                <path d="M25.6 53.9937H20.4C20.0287 53.9937 19.6726 53.8462 19.4101 53.5837C19.1475 53.3212 19 52.9651 19 52.5939V43.395C19 43.2111 19.0362 43.0291 19.1066 42.8593C19.1769 42.6894 19.28 42.5351 19.4101 42.4051C19.5401 42.2751 19.6944 42.172 19.8642 42.1017C20.0341 42.0313 20.2161 41.9951 20.4 41.9951H25.6C25.9713 41.9951 26.3274 42.1426 26.5899 42.4051C26.8525 42.6676 27 43.0237 27 43.395V52.5939C27 52.9651 26.8525 53.3212 26.5899 53.5837C26.3274 53.8462 25.9713 53.9937 25.6 53.9937ZM20.4 42.995C20.3471 42.9936 20.2945 43.003 20.2453 43.0226C20.1962 43.0423 20.1515 43.0717 20.1141 43.1091C20.0767 43.1465 20.0473 43.1911 20.0276 43.2403C20.008 43.2894 19.9986 43.3421 20 43.395V52.5939C20 52.7 20.0421 52.8017 20.1172 52.8767C20.1922 52.9517 20.2939 52.9938 20.4 52.9938H25.6C25.7061 52.9938 25.8078 52.9517 25.8828 52.8767C25.9579 52.8017 26 52.7 26 52.5939V43.395C26.0014 43.3421 25.992 43.2894 25.9724 43.2403C25.9527 43.1911 25.9233 43.1465 25.8859 43.1091C25.8485 43.0717 25.8038 43.0423 25.7547 43.0226C25.7055 43.003 25.6529 42.9936 25.6 42.995H20.4Z" fill="currentColor" />
                <path d="M37.6 53.9937H32.4C32.0287 53.9937 31.6726 53.8462 31.4101 53.5837C31.1475 53.3212 31 52.9651 31 52.5939V43.395C31 43.2111 31.0362 43.0291 31.1066 42.8593C31.1769 42.6894 31.28 42.5351 31.4101 42.4051C31.5401 42.2751 31.6944 42.172 31.8642 42.1017C32.0341 42.0313 32.2162 41.9951 32.4 41.9951H37.6C37.9713 41.9951 38.3274 42.1426 38.59 42.4051C38.8525 42.6676 39 43.0237 39 43.395V52.5939C39 52.9651 38.8525 53.3212 38.59 53.5837C38.3274 53.8462 37.9713 53.9937 37.6 53.9937ZM32.4 42.995C32.3471 42.9936 32.2945 43.003 32.2453 43.0226C32.1962 43.0423 32.1515 43.0717 32.1141 43.1091C32.0767 43.1465 32.0473 43.1911 32.0276 43.2403C32.008 43.2894 31.9986 43.3421 32 43.395V52.5939C32 52.7 32.0421 52.8017 32.1172 52.8767C32.1922 52.9517 32.2939 52.9938 32.4 52.9938H37.6C37.7061 52.9938 37.8078 52.9517 37.8828 52.8767C37.9579 52.8017 38 52.7 38 52.5939V43.395C38.0014 43.3421 37.992 43.2894 37.9724 43.2403C37.9527 43.1911 37.9233 43.1465 37.8859 43.1091C37.8485 43.0717 37.8038 43.0423 37.7547 43.0226C37.7055 43.003 37.6529 42.9936 37.6 42.995H32.4Z" fill="currentColor" />
                <path d="M49.6 53.9937H44.4C44.0287 53.9937 43.6726 53.8462 43.41 53.5837C43.1475 53.3212 43 52.9651 43 52.5939V43.395C43 43.2111 43.0362 43.0291 43.1066 42.8593C43.1769 42.6894 43.28 42.5351 43.41 42.4051C43.5401 42.2751 43.6944 42.172 43.8642 42.1017C44.0341 42.0313 44.2162 41.9951 44.4 41.9951H49.6C49.9713 41.9951 50.3274 42.1426 50.59 42.4051C50.8525 42.6676 51 43.0237 51 43.395V52.5939C51 52.9651 50.8525 53.3212 50.59 53.5837C50.3274 53.8462 49.9713 53.9937 49.6 53.9937ZM44.4 42.995C44.3471 42.9936 44.2945 43.003 44.2453 43.0226C44.1962 43.0423 44.1515 43.0717 44.1141 43.1091C44.0767 43.1465 44.0473 43.1911 44.0276 43.2403C44.008 43.2894 43.9986 43.3421 44 43.395V52.5939C44 52.7 44.0421 52.8017 44.1172 52.8767C44.1922 52.9517 44.2939 52.9938 44.4 52.9938H49.6C49.7061 52.9938 49.8078 52.9517 49.8828 52.8767C49.9579 52.8017 50 52.7 50 52.5939V43.395C50.0014 43.3421 49.992 43.2894 49.9724 43.2403C49.9527 43.1911 49.9233 43.1465 49.8859 43.1091C49.8485 43.0717 49.8038 43.0423 49.7547 43.0226C49.7055 43.003 49.6529 42.9936 49.6 42.995H44.4Z" fill="currentColor" />
                <path d="M61.6 53.9937H56.4C56.0287 53.9937 55.6726 53.8462 55.41 53.5837C55.1475 53.3212 55 52.9651 55 52.5939V43.395C55 43.2111 55.0362 43.0291 55.1066 42.8593C55.1769 42.6894 55.28 42.5351 55.41 42.4051C55.5401 42.2751 55.6944 42.172 55.8642 42.1017C56.0341 42.0313 56.2162 41.9951 56.4 41.9951H61.6C61.9713 41.9951 62.3274 42.1426 62.59 42.4051C62.8525 42.6676 63 43.0237 63 43.395V52.5939C63 52.9651 62.8525 53.3212 62.59 53.5837C62.3274 53.8462 61.9713 53.9937 61.6 53.9937ZM56.4 42.995C56.3471 42.9936 56.2945 43.003 56.2453 43.0226C56.1962 43.0423 56.1515 43.0717 56.1141 43.1091C56.0767 43.1465 56.0473 43.1911 56.0276 43.2403C56.008 43.2894 55.9986 43.3421 56 43.395V52.5939C56 52.7 56.0421 52.8017 56.1172 52.8767C56.1922 52.9517 56.2939 52.9938 56.4 52.9938H61.6C61.7061 52.9938 61.8078 52.9517 61.8828 52.8767C61.9579 52.8017 62 52.7 62 52.5939V43.395C62.0014 43.3421 61.992 43.2894 61.9724 43.2403C61.9527 43.1911 61.9233 43.1465 61.8859 43.1091C61.8485 43.0717 61.8038 43.0423 61.7547 43.0226C61.7055 43.003 61.6529 42.9936 61.6 42.995H56.4Z" fill="currentColor" />
                <path d="M25.6 68.992H20.4C20.0287 68.992 19.6726 68.8445 19.4101 68.582C19.1475 68.3195 19 67.9634 19 67.5921V58.3932C19 58.022 19.1475 57.6659 19.4101 57.4034C19.6726 57.1409 20.0287 56.9934 20.4 56.9934H25.6C25.9713 56.9934 26.3274 57.1409 26.5899 57.4034C26.8525 57.6659 27 58.022 27 58.3932V67.5921C27 67.9634 26.8525 68.3195 26.5899 68.582C26.3274 68.8445 25.9713 68.992 25.6 68.992ZM20.4 57.9933C20.3471 57.9919 20.2945 58.0013 20.2453 58.0209C20.1962 58.0405 20.1515 58.0699 20.1141 58.1073C20.0767 58.1448 20.0473 58.1894 20.0276 58.2385C20.008 58.2877 19.9986 58.3403 20 58.3932V67.5921C20 67.6982 20.0421 67.7999 20.1172 67.875C20.1922 67.95 20.2939 67.9921 20.4 67.9921H25.6C25.7061 67.9921 25.8078 67.95 25.8828 67.875C25.9579 67.7999 26 67.6982 26 67.5921V58.3932C26.0014 58.3403 25.992 58.2877 25.9724 58.2385C25.9527 58.1894 25.9233 58.1448 25.8859 58.1073C25.8485 58.0699 25.8038 58.0405 25.7547 58.0209C25.7055 58.0013 25.6529 57.9919 25.6 57.9933H20.4Z" fill="currentColor" />
                <path d="M37.6 68.992H32.4C32.0287 68.992 31.6726 68.8445 31.4101 68.582C31.1475 68.3195 31 67.9634 31 67.5921V58.3932C31 58.022 31.1475 57.6659 31.4101 57.4034C31.6726 57.1409 32.0287 56.9934 32.4 56.9934H37.6C37.9713 56.9934 38.3274 57.1409 38.59 57.4034C38.8525 57.6659 39 58.022 39 58.3932V67.5921C39 67.9634 38.8525 68.3195 38.59 68.582C38.3274 68.8445 37.9713 68.992 37.6 68.992ZM32.4 57.9933C32.3471 57.9919 32.2945 58.0013 32.2453 58.0209C32.1962 58.0405 32.1515 58.0699 32.1141 58.1073C32.0767 58.1448 32.0473 58.1894 32.0276 58.2385C32.008 58.2877 31.9986 58.3403 32 58.3932V67.5921C32 67.6982 32.0421 67.7999 32.1172 67.875C32.1922 67.95 32.2939 67.9921 32.4 67.9921H37.6C37.7061 67.9921 37.8078 67.95 37.8828 67.875C37.9579 67.7999 38 67.6982 38 67.5921V58.3932C38.0014 58.3403 37.992 58.2877 37.9724 58.2385C37.9527 58.1894 37.9233 58.1448 37.8859 58.1073C37.8485 58.0699 37.8038 58.0405 37.7547 58.0209C37.7055 58.0013 37.6529 57.9919 37.6 57.9933H32.4Z" fill="currentColor" />
                <path d="M49.6 68.992H44.4C44.0287 68.992 43.6726 68.8445 43.41 68.582C43.1475 68.3195 43 67.9634 43 67.5921V58.3932C43 58.022 43.1475 57.6659 43.41 57.4034C43.6726 57.1409 44.0287 56.9934 44.4 56.9934H49.6C49.9713 56.9934 50.3274 57.1409 50.59 57.4034C50.8525 57.6659 51 58.022 51 58.3932V67.5921C51 67.9634 50.8525 68.3195 50.59 68.582C50.3274 68.8445 49.9713 68.992 49.6 68.992ZM44.4 57.9933C44.3471 57.9919 44.2945 58.0013 44.2453 58.0209C44.1962 58.0405 44.1515 58.0699 44.1141 58.1073C44.0767 58.1448 44.0473 58.1894 44.0276 58.2385C44.008 58.2877 43.9986 58.3403 44 58.3932V67.5921C44 67.6982 44.0421 67.7999 44.1172 67.875C44.1922 67.95 44.2939 67.9921 44.4 67.9921H49.6C49.7061 67.9921 49.8078 67.95 49.8828 67.875C49.9579 67.7999 50 67.6982 50 67.5921V58.3932C50.0014 58.3403 49.992 58.2877 49.9724 58.2385C49.9527 58.1894 49.9233 58.1448 49.8859 58.1073C49.8485 58.0699 49.8038 58.0405 49.7547 58.0209C49.7055 58.0013 49.6529 57.9919 49.6 57.9933H44.4Z" fill="currentColor" />
                <path d="M61.6 68.992H56.4C56.0287 68.992 55.6726 68.8445 55.41 68.582C55.1475 68.3195 55 67.9634 55 67.5921V58.3932C55 58.022 55.1475 57.6659 55.41 57.4034C55.6726 57.1409 56.0287 56.9934 56.4 56.9934H61.6C61.9713 56.9934 62.3274 57.1409 62.59 57.4034C62.8525 57.6659 63 58.022 63 58.3932V67.5921C63 67.9634 62.8525 68.3195 62.59 68.582C62.3274 68.8445 61.9713 68.992 61.6 68.992ZM56.4 57.9933C56.3471 57.9919 56.2945 58.0013 56.2453 58.0209C56.1962 58.0405 56.1515 58.0699 56.1141 58.1073C56.0767 58.1448 56.0473 58.1894 56.0276 58.2385C56.008 58.2877 55.9986 58.3403 56 58.3932V67.5921C56 67.6982 56.0421 67.7999 56.1172 67.875C56.1922 67.95 56.2939 67.9921 56.4 67.9921H61.6C61.7061 67.9921 61.8078 67.95 61.8828 67.875C61.9579 67.7999 62 67.6982 62 67.5921V58.3932C62.0014 58.3403 61.992 58.2877 61.9724 58.2385C61.9527 58.1894 61.9233 58.1448 61.8859 58.1073C61.8485 58.0699 61.8038 58.0405 61.7547 58.0209C61.7055 58.0013 61.6529 57.9919 61.6 57.9933H56.4Z" fill="currentColor" />
                <path d="M38.5 84.9901C38.4076 84.988 38.3176 84.9603 38.24 84.9101L31.24 80.6306C31.1663 80.5858 31.1055 80.5226 31.0634 80.4473C31.0214 80.3719 30.9995 80.287 31 80.2007V73.4015C30.9987 73.2168 31.034 73.0337 31.1037 72.8627C31.1735 72.6917 31.2764 72.5362 31.4065 72.4052C31.5367 72.2741 31.6914 72.1701 31.862 72.0991C32.0325 72.0282 32.2153 71.9916 32.4 71.9916H37.6C37.9713 71.9916 38.3274 72.1391 38.59 72.4016C38.8525 72.6642 39 73.0202 39 73.3915V84.4902C38.9996 84.5786 38.9751 84.6653 38.9294 84.741C38.8836 84.8167 38.8182 84.8786 38.74 84.9201C38.6671 84.963 38.5846 84.9871 38.5 84.9901ZM32 79.9307L38 83.6003V73.4015C38.0014 73.3486 37.992 73.296 37.9724 73.2468C37.9528 73.1977 37.9234 73.153 37.8859 73.1156C37.8485 73.0782 37.8039 73.0488 37.7547 73.0292C37.7056 73.0095 37.6529 73.0001 37.6 73.0015H32.4C32.3471 73.0001 32.2945 73.0095 32.2454 73.0292C32.1962 73.0488 32.1516 73.0782 32.1141 73.1156C32.0767 73.153 32.0473 73.1977 32.0277 73.2468C32.0081 73.296 31.9987 73.3486 32 73.4015V79.9307Z" fill="currentColor" />
                <path d="M43.5 84.9901C43.369 84.9852 43.2447 84.9309 43.152 84.8382C43.0593 84.7455 43.005 84.6212 43 84.4902V73.4015C42.9987 73.2168 43.034 73.0337 43.1037 72.8627C43.1735 72.6917 43.2764 72.5362 43.4065 72.4052C43.5367 72.2741 43.6914 72.1701 43.862 72.0991C44.0325 72.0282 44.2153 71.9916 44.4 71.9916H49.6C49.9713 71.9916 50.3274 72.1391 50.59 72.4016C50.8525 72.6642 51 73.0202 51 73.3915V80.8606C51.0016 80.952 50.9771 81.042 50.9292 81.12C50.8814 81.1979 50.8123 81.2606 50.73 81.3005L43.73 84.9301C43.6599 84.9699 43.5806 84.9906 43.5 84.9901ZM44.4 72.9915C44.3471 72.9901 44.2945 72.9996 44.2454 73.0192C44.1962 73.0388 44.1516 73.0682 44.1141 73.1056C44.0767 73.143 44.0473 73.1877 44.0277 73.2368C44.0081 73.286 43.9987 73.3386 44 73.3915V83.6703L50 80.5706V73.4015C50.0014 73.3486 49.992 73.296 49.9724 73.2468C49.9528 73.1977 49.9234 73.153 49.8859 73.1156C49.8485 73.0782 49.8039 73.0488 49.7547 73.0292C49.7056 73.0095 49.6529 73.0001 49.6 73.0015L44.4 72.9915Z" fill="currentColor" />
                <path d="M22.3599 11.6888C22.2795 11.69 22.2001 11.6716 22.1286 11.6349C22.0572 11.5983 21.9958 11.5447 21.9499 11.4788C19.9256 8.55424 17.2222 6.16409 14.0716 4.5132C10.9209 2.8623 7.41693 1.99989 3.85986 1.99988C3.72726 1.99988 3.60008 1.94721 3.50631 1.85345C3.41254 1.7597 3.35986 1.63253 3.35986 1.49994C3.35986 1.36735 3.41254 1.24019 3.50631 1.14643C3.60008 1.05267 3.72726 1 3.85986 1C7.57815 1.00027 11.2409 1.90192 14.5344 3.62768C17.8278 5.35344 20.6537 7.85186 22.7699 10.9088C22.8075 10.9624 22.834 11.0229 22.8479 11.0869C22.8617 11.1508 22.8626 11.2169 22.8505 11.2812C22.8384 11.3455 22.8135 11.4068 22.7773 11.4613C22.7411 11.5158 22.6944 11.5626 22.6399 11.5988C22.5586 11.6579 22.4604 11.6895 22.3599 11.6888Z" fill="currentColor" />
                <path d="M16.9999 11.48C16.9345 11.4796 16.8698 11.4662 16.8097 11.4404C16.7496 11.4146 16.6952 11.3771 16.6499 11.33C14.9785 9.63709 12.9868 8.29392 10.7909 7.37881C8.59495 6.46371 6.23881 5.99499 3.85986 6.00001C3.72726 6.00001 3.60008 5.94733 3.50631 5.85356C3.41254 5.75979 3.35986 5.63261 3.35986 5.50001C3.35986 5.3674 3.41254 5.24022 3.50631 5.14645C3.60008 5.05268 3.72726 5.00001 3.85986 5.00001C6.37145 4.99331 8.85914 5.48781 11.1772 6.45454C13.4953 7.42127 15.5972 8.8408 17.3599 10.63C17.429 10.7005 17.4757 10.7899 17.494 10.8869C17.5124 10.9839 17.5017 11.0841 17.4632 11.175C17.4247 11.2659 17.3602 11.3434 17.2777 11.3977C17.1953 11.4519 17.0986 11.4806 16.9999 11.48Z" fill="currentColor" />
                <path d="M12.3899 11.56C12.2912 11.558 12.1947 11.5305 12.1099 11.48C9.66378 9.85959 6.79398 8.99691 3.85986 9C3.72726 9 3.60008 8.94732 3.50631 8.85355C3.41254 8.75979 3.35986 8.63261 3.35986 8.5C3.35986 8.36739 3.41254 8.24022 3.50631 8.14645C3.60008 8.05268 3.72726 8 3.85986 8C6.99161 7.99883 10.0549 8.91676 12.6699 10.64C12.7795 10.7133 12.8557 10.827 12.8819 10.9562C12.9081 11.0854 12.8822 11.2198 12.8099 11.33C12.764 11.3998 12.7018 11.4573 12.6285 11.4974C12.5553 11.5375 12.4733 11.559 12.3899 11.56Z" fill="currentColor" />
                <path d="M13.3999 18.0686C13.3173 18.0687 13.2359 18.0487 13.1627 18.0104C13.0896 17.9721 13.0268 17.9166 12.9799 17.8486C11.9729 16.3554 10.6147 15.1322 9.02455 14.2865C7.43439 13.4409 5.6609 12.9986 3.85986 12.9986C3.72726 12.9986 3.60008 12.9459 3.50631 12.8522C3.41254 12.7584 3.35986 12.6312 3.35986 12.4986C3.35986 12.366 3.41254 12.2388 3.50631 12.1451C3.60008 12.0513 3.72726 11.9986 3.85986 11.9986C5.83316 11.9902 7.77808 12.4685 9.52237 13.3912C11.2667 14.314 12.7565 15.6526 13.8599 17.2886C13.9328 17.4004 13.9589 17.5363 13.9328 17.6672C13.9066 17.7981 13.8301 17.9135 13.7199 17.9886C13.6244 18.0486 13.5123 18.0767 13.3999 18.0686Z" fill="currentColor" />
            </g>
            <defs>
                <clipPath id="clip0_5954_44663">
                    <rect width="82" height="86" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

const IconMall = (props) => {
    return (
        <svg {...props} width="82" height="86" viewBox="0 0 82 86" fill="none" >
            <g clipPath="url(#clip0_5954_44695)">
                <path d="M76 67.9921H74V23.8072C74.0024 23.2778 73.8695 22.7566 73.614 22.2929C73.3585 21.8292 72.9888 21.4384 72.54 21.1576L42.61 2.46973C42.1288 2.1637 41.5703 2.00116 41 2.00116C40.4297 2.00116 39.8712 2.1637 39.39 2.46973L9.46003 21.1576C9.01122 21.4384 8.64155 21.8292 8.38605 22.2929C8.13056 22.7566 7.99769 23.2778 8.00003 23.8072V67.9921H6.00003V23.8072C5.99701 22.938 6.21583 22.0823 6.6358 21.3212C7.05577 20.5601 7.66299 19.9187 8.40003 19.4578L38.33 0.769924C39.1291 0.265283 40.0549 -0.00256348 41 -0.00256348C41.9452 -0.00256348 42.8709 0.265283 43.67 0.769924L73.6 19.4578C74.3371 19.9187 74.9443 20.5601 75.3643 21.3212C75.7842 22.0823 76.0031 22.938 76 23.8072V67.9921Z" fill="currentColor" />
                <path d="M70 70.9918H68V40.2453C68.0065 39.844 67.9074 39.4479 67.7126 39.097C67.5178 38.746 67.2341 38.4524 66.89 38.2456L42.24 23.3473C41.8658 23.1215 41.4371 23.0022 41 23.0022C40.563 23.0022 40.1342 23.1215 39.76 23.3473L15.11 38.2956C14.7659 38.5024 14.4822 38.796 14.2874 39.147C14.0926 39.4979 13.9935 39.894 14 40.2953V70.9918H12V40.2453C11.999 39.5084 12.1891 38.7838 12.552 38.1424C12.9148 37.501 13.4378 36.9646 14.07 36.5858L38.72 21.5875C39.4076 21.171 40.1961 20.9508 41 20.9508C41.8039 20.9508 42.5925 21.171 43.28 21.5875L67.93 36.5858C68.5622 36.9646 69.0852 37.501 69.448 38.1424C69.8109 38.7838 70.0011 39.5084 70 40.2453V70.9918Z" fill="currentColor" />
                <path d="M67 35.9958H66V28.3467C66.0032 27.9127 65.8933 27.4852 65.6812 27.1066C65.469 26.7279 65.1619 26.4109 64.79 26.187L42.34 12.3786C41.9362 12.1331 41.4726 12.0033 41 12.0033C40.5274 12.0033 40.0639 12.1331 39.66 12.3786L17.21 26.187C16.8381 26.4109 16.531 26.7279 16.3189 27.1066C16.1067 27.4852 15.9968 27.9127 16 28.3467V35.9958H15V28.3467C14.9983 27.7433 15.1531 27.1497 15.4493 26.624C15.7455 26.0982 16.173 25.6583 16.69 25.3471L39.13 11.5287C39.6917 11.1807 40.3393 10.9963 41 10.9963C41.6608 10.9963 42.3084 11.1807 42.87 11.5287L65.31 25.3371C65.827 25.6483 66.2545 26.0882 66.5507 26.614C66.8469 27.1397 67.0017 27.7333 67 28.3367V35.9958Z" fill="currentColor" />
                <path d="M41 85.99C40.0728 85.99 39.1596 85.7635 38.34 85.3301L1.23998 65.7724C0.89783 65.5937 0.606148 65.3318 0.391811 65.0108C0.177475 64.6899 0.0473788 64.3201 0.0135101 63.9356C-0.0203586 63.5512 0.0430894 63.1644 0.198008 62.8109C0.352927 62.4574 0.594333 62.1485 0.899984 61.9128L6.12998 57.5933L7.40998 59.1331L2.14998 63.4826C2.10277 63.5122 2.0641 63.5536 2.03779 63.6027C2.01148 63.6518 1.99845 63.7069 1.99998 63.7626C2.00707 63.8133 2.0262 63.8615 2.05578 63.9033C2.08536 63.945 2.12452 63.9791 2.16998 64.0026L39.27 83.5703C39.8033 83.8514 40.3971 83.9983 41 83.9983C41.6029 83.9983 42.1967 83.8514 42.73 83.5703L79.83 63.9926C79.8755 63.9691 79.9146 63.935 79.9442 63.8933C79.9738 63.8515 79.9929 63.8033 80 63.7526C80.0032 63.7027 79.994 63.6528 79.973 63.6074C79.952 63.562 79.9201 63.5225 79.88 63.4926L74.59 59.1231L75.87 57.5833L81.12 61.9228C81.4227 62.1592 81.6611 62.468 81.8132 62.8207C81.9653 63.1734 82.0262 63.5586 81.9903 63.941C81.9545 64.3234 81.823 64.6907 81.608 65.009C81.393 65.3272 81.1014 65.5863 80.76 65.7624L43.66 85.3401C42.8395 85.77 41.9263 85.9931 41 85.99Z" fill="currentColor" />
                <path d="M29 28.9966H28V18.9978L29 17.9979V28.9966Z" fill="currentColor" />
                <path d="M31 27.9968L30 28.9967V17.9979L31 16.998V27.9968Z" fill="currentColor" />
                <path d="M53 28.9966V17.9979L54 18.9978V29.9965L53 28.9966Z" fill="currentColor" />
                <path d="M52 27.9968H51V16.998L52 17.9979V27.9968Z" fill="currentColor" />
                <path d="M59.5 69.9919H22.5C22.3674 69.9919 22.2402 69.9392 22.1464 69.8454C22.0527 69.7517 22 69.6245 22 69.4919V52.4939C22 52.3613 22.0527 52.2342 22.1464 52.1404C22.2402 52.0466 22.3674 51.994 22.5 51.994C22.6326 51.994 22.7598 52.0466 22.8536 52.1404C22.9473 52.2342 23 52.3613 23 52.4939V68.992H59V42.5051H60V69.5019C59.9974 69.6328 59.9436 69.7574 59.8501 69.849C59.7566 69.9406 59.6309 69.9919 59.5 69.9919Z" fill="currentColor" />
                <path d="M18.6201 53.7438C18.5102 53.7449 18.4031 53.709 18.316 53.6419C18.229 53.5748 18.167 53.4804 18.1401 53.3738L17.3801 50.4742C17.3506 50.3456 17.3684 50.2108 17.4301 50.0942C17.463 50.0365 17.5076 49.9863 17.5609 49.9467C17.6143 49.9071 17.6753 49.879 17.7401 49.8642L62.2501 38.2656C62.3138 38.249 62.3801 38.2453 62.4453 38.2544C62.5104 38.2636 62.5732 38.2855 62.6298 38.3189C62.6865 38.3524 62.736 38.3967 62.7755 38.4493C62.815 38.5019 62.8438 38.5618 62.8601 38.6255L63.6201 41.5252C63.6652 41.6478 63.6652 41.7825 63.6201 41.9052C63.5871 41.9629 63.5426 42.0131 63.4892 42.0527C63.4359 42.0923 63.3749 42.1203 63.3101 42.1351L18.7501 53.7338L18.6201 53.7438ZM18.4701 50.7441L19.0001 52.6339L62.5301 41.2952L62.0001 39.3654L18.4701 50.7441Z" fill="currentColor" />
                <path d="M40.5 69.9919C40.3674 69.9919 40.2402 69.9392 40.1464 69.8454C40.0527 69.7517 40 69.6245 40 69.4919V56.9934H22.5C22.3674 56.9934 22.2402 56.9407 22.1464 56.847C22.0527 56.7532 22 56.626 22 56.4934C22 56.3608 22.0527 56.2337 22.1464 56.1399C22.2402 56.0462 22.3674 55.9935 22.5 55.9935H40.5C40.6326 55.9935 40.7598 56.0462 40.8536 56.1399C40.9473 56.2337 41 56.3608 41 56.4934V69.4919C41 69.6245 40.9473 69.7517 40.8536 69.8454C40.7598 69.9392 40.6326 69.9919 40.5 69.9919Z" fill="currentColor" />
                <path d="M28.5 69.9919C28.3674 69.9919 28.2402 69.9392 28.1464 69.8454C28.0527 69.7517 28 69.6245 28 69.4919V56.4934C28 56.3608 28.0527 56.2337 28.1464 56.1399C28.2402 56.0462 28.3674 55.9935 28.5 55.9935C28.6326 55.9935 28.7598 56.0462 28.8536 56.1399C28.9473 56.2337 29 56.3608 29 56.4934V69.4919C29 69.6245 28.9473 69.7517 28.8536 69.8454C28.7598 69.9392 28.6326 69.9919 28.5 69.9919Z" fill="currentColor" />
                <path d="M34.5 69.9919C34.3674 69.9919 34.2402 69.9392 34.1464 69.8454C34.0527 69.7517 34 69.6245 34 69.4919V56.4934C34 56.3608 34.0527 56.2337 34.1464 56.1399C34.2402 56.0462 34.3674 55.9935 34.5 55.9935C34.6326 55.9935 34.7598 56.0462 34.8536 56.1399C34.9473 56.2337 35 56.3608 35 56.4934V69.4919C35 69.6245 34.9473 69.7517 34.8536 69.8454C34.7598 69.9392 34.6326 69.9919 34.5 69.9919Z" fill="currentColor" />
                <path d="M47 46.0047H46V70.0019H47V46.0047Z" fill="currentColor" />
                <path d="M59.5 54.9945H49.5C49.3674 54.9945 49.2402 54.9419 49.1464 54.8481C49.0527 54.7543 49 54.6271 49 54.4945V47.4945C49 47.3619 49.0527 47.2348 49.1464 47.141C49.2402 47.0472 49.3674 46.9945 49.5 46.9945H59.5C59.6326 46.9945 59.7598 47.0472 59.8536 47.141C59.9473 47.2348 60 47.3619 60 47.4945V54.4945C60 54.6271 59.9473 54.7543 59.8536 54.8481C59.7598 54.9419 59.6326 54.9945 59.5 54.9945ZM50 53.9945H59V47.9945H50V53.9945Z" fill="currentColor" />
                <path d="M26 48.0054H25V40.0054L26 41.0054V48.0054Z" fill="currentColor" />
                <path d="M21 49.9947H20V46.4947C20 46.3621 20.0527 46.2349 20.1464 46.1411C20.2402 46.0473 20.3674 45.9947 20.5 45.9947H26V46.9947H21V49.9947Z" fill="currentColor" />
                <path d="M22.5 67.9922H17.5C17.3674 67.9922 17.2402 67.9395 17.1464 67.8458C17.0527 67.752 17 67.6248 17 67.4922C17 67.3596 17.0527 67.2324 17.1464 67.1387C17.2402 67.0449 17.3674 66.9922 17.5 66.9922H22.5C22.6326 66.9922 22.7598 67.0449 22.8536 67.1387C22.9473 67.2324 23 67.3596 23 67.4922C23 67.6248 22.9473 67.752 22.8536 67.8458C22.7598 67.9395 22.6326 67.9922 22.5 67.9922Z" fill="currentColor" />
                <path d="M64.5 67.9922H59.5C59.3674 67.9922 59.2402 67.9395 59.1464 67.8458C59.0527 67.752 59 67.6248 59 67.4922C59 67.3596 59.0527 67.2324 59.1464 67.1387C59.2402 67.0449 59.3674 66.9922 59.5 66.9922H64.5C64.6326 66.9922 64.7598 67.0449 64.8536 67.1387C64.9473 67.2324 65 67.3596 65 67.4922C65 67.6248 64.9473 67.752 64.8536 67.8458C64.7598 67.9395 64.6326 67.9922 64.5 67.9922Z" fill="currentColor" />
                <path d="M54.5 72.9916H36.5C36.3674 72.9916 36.2402 72.939 36.1464 72.8452C36.0527 72.7514 36 72.6242 36 72.4916C36 72.359 36.0527 72.2319 36.1464 72.1381C36.2402 72.0443 36.3674 71.9916 36.5 71.9916H54.5C54.6326 71.9916 54.7598 72.0443 54.8536 72.1381C54.9473 72.2319 55 72.359 55 72.4916C55 72.6242 54.9473 72.7514 54.8536 72.8452C54.7598 72.939 54.6326 72.9916 54.5 72.9916Z" fill="currentColor" />
                <path d="M78.54 15.9986H65.54C65.4074 15.9986 65.2802 15.946 65.1865 15.8522C65.0927 15.7584 65.04 15.6313 65.04 15.4987C65.04 15.3661 65.0927 15.2389 65.1865 15.1452C65.2802 15.0514 65.4074 14.9987 65.54 14.9987H78.07V14.8988C78.0976 14.5119 78.0372 14.1239 77.8935 13.7637C77.7497 13.4035 77.5263 13.0806 77.24 12.819C76.266 12.22 75.1035 12.006 73.98 12.2191C73.8624 12.243 73.7401 12.2239 73.6355 12.1651C73.5308 12.1064 73.4508 12.012 73.41 11.8991C73.1057 11.1339 72.5727 10.4813 71.8836 10.0303C71.1946 9.57933 70.3831 9.35202 69.56 9.37939C68.5128 9.276 67.4603 9.51455 66.56 10.0593C66.4909 10.115 66.4089 10.1524 66.3215 10.1681C66.2341 10.1838 66.1442 10.1774 66.06 10.1493C65.9768 10.1199 65.9027 10.0695 65.8449 10.0029C65.787 9.93627 65.7475 9.85581 65.73 9.76935C65.73 9.57937 64.9 5.10989 60.41 5.10989C59.1557 5.10145 57.9274 5.46697 56.8819 6.15976C55.8363 6.85255 55.021 7.84119 54.54 8.99944C54.4781 9.11789 54.3722 9.20731 54.245 9.24845C54.1178 9.28959 53.9796 9.27916 53.86 9.21941C53.744 9.15774 53.6569 9.05288 53.6176 8.92751C53.5783 8.80214 53.59 8.66635 53.65 8.54949C55.14 5.61983 57.4 4.13 60.38 4.13C61.7927 4.12981 63.1647 4.60365 64.2761 5.47565C65.3875 6.34765 66.1742 7.56736 66.51 8.93945C67.4614 8.55211 68.4833 8.36819 69.51 8.39951C70.4657 8.38326 71.4066 8.63748 72.224 9.13284C73.0414 9.6282 73.7022 10.3445 74.13 11.1992C75.4288 11.0159 76.749 11.3263 77.83 12.0691C78.2347 12.4245 78.5525 12.868 78.7588 13.3655C78.9651 13.863 79.0545 14.4013 79.02 14.9387V15.5387C79.0103 15.6608 78.956 15.7752 78.8675 15.86C78.779 15.9448 78.6625 15.9941 78.54 15.9986Z" fill="currentColor" />
                <path d="M43.1099 44.2158L22.3699 40.5058C22.3044 40.496 22.2414 40.4732 22.1848 40.4389C22.1281 40.4046 22.0789 40.3593 22.0399 40.3058C21.9687 40.1927 21.9404 40.0579 21.9599 39.9258L22.4899 36.9258C22.5006 36.8609 22.5244 36.7989 22.5596 36.7434C22.5949 36.6879 22.6409 36.6401 22.695 36.6028C22.7492 36.5654 22.8102 36.5394 22.8746 36.5262C22.939 36.5129 23.0054 36.5128 23.0699 36.5258L50.6299 41.4958L49.0499 42.2258L23.3899 37.6458L23.0299 39.6458L43.2799 43.2558L43.1099 44.2158Z" fill="currentColor" />
            </g>
            <defs>
                <clipPath id="clip0_5954_44695">
                    <rect width="82" height="86" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

const IconFingerPrint = (props) => {
    return (
        <svg {...props} width="25" height="24" viewBox="0 0 25 24" fill="none">
            <path d="M8.01095 19.812C7.28493 18.51 6.90096 16.914 6.90096 15.186C6.90096 12.282 9.41494 9.91802 12.499 9.91802C15.5889 9.91802 18.097 12.282 18.097 15.186C18.097 15.516 18.367 15.786 18.697 15.786C19.027 15.786 19.297 15.516 19.297 15.186C19.297 11.616 16.249 8.71798 12.499 8.71798C8.74906 8.71798 5.70093 11.622 5.70093 15.186C5.70093 17.118 6.13895 18.924 6.96695 20.4C7.77695 21.846 8.34096 22.512 9.37894 23.562C9.49893 23.6819 9.64894 23.742 9.80492 23.742C9.95494 23.742 10.1109 23.688 10.2189 23.562C10.4589 23.334 10.4589 22.95 10.2249 22.716C9.30093 21.786 8.78494 21.192 8.01095 19.812Z" fill="currentColor" />
            <path d="M5.82692 2.89198C7.88494 1.76996 10.1289 1.19998 12.5109 1.19998C14.8989 1.19998 16.8969 1.71 19.195 2.898C19.285 2.94002 19.375 2.96398 19.471 2.96398C19.687 2.96398 19.897 2.844 20.005 2.63998C20.155 2.34596 20.041 1.98 19.7469 1.82998C17.2989 0.564018 15.0669 0 12.5109 0C9.93092 0 7.48894 0.618018 5.25092 1.836C4.95691 1.998 4.85493 2.358 5.01091 2.65202C5.17291 2.94598 5.53291 3.04802 5.82692 2.89198Z" fill="currentColor" />
            <path d="M12.457 5.79596C8.587 5.79596 5.04703 7.96197 3.44503 11.31C2.89901 12.444 2.62305 13.7519 2.62305 15.1919C2.62305 16.8119 2.91105 18.3599 3.49903 19.9379C3.61901 20.2499 3.96101 20.4059 4.27303 20.2919C4.58505 20.1719 4.74103 19.8299 4.62701 19.5179C3.91297 17.6039 3.82899 16.1279 3.82899 15.1919C3.82899 13.9319 4.06299 12.7979 4.53099 11.8259C5.93499 8.88593 9.04899 6.98998 12.463 6.98998C17.257 6.98998 21.157 10.668 21.157 15.1919C21.157 16.4819 20.0349 17.5259 18.661 17.5259C17.287 17.5259 16.165 16.4759 16.165 15.1919C16.165 13.2419 14.509 11.6579 12.469 11.6579C10.429 11.6579 8.77297 13.2419 8.77297 15.1919C8.77297 17.5619 9.69698 19.7879 11.371 21.4559C12.667 22.7459 13.915 23.4539 15.829 23.9759C15.8769 23.9939 15.9309 23.9999 15.9849 23.9999C16.2489 23.9999 16.4889 23.826 16.5609 23.5619C16.6509 23.2439 16.459 22.9139 16.1409 22.8239C14.4489 22.3619 13.3449 21.7379 12.2109 20.6099C10.7649 19.1699 9.96693 17.2439 9.96693 15.198C9.96693 13.908 11.0889 12.864 12.4629 12.864C13.8369 12.864 14.9589 13.914 14.9589 15.198C14.9589 17.148 16.6209 18.732 18.6549 18.732C20.6889 18.732 22.3509 17.148 22.3509 15.198C22.351 10.014 17.911 5.79596 12.457 5.79596Z" fill="currentColor" />
            <path d="M23.1909 8.27994C21.9969 6.61196 20.4849 5.30392 18.6909 4.37996C14.9109 2.43596 10.0869 2.44198 6.31291 4.39194C4.51291 5.32192 2.99489 6.64194 1.80689 8.32196C1.61491 8.59196 1.68089 8.96394 1.95089 9.15597C2.05889 9.22797 2.17887 9.26397 2.29891 9.26397C2.48493 9.26397 2.67089 9.17999 2.79093 9.01799C3.86491 7.49401 5.23892 6.29999 6.86494 5.46001C10.3029 3.68403 14.701 3.67801 18.1449 5.44803C19.7649 6.27603 21.1329 7.46403 22.2129 8.97603C22.4049 9.24603 22.7829 9.30605 23.0529 9.11401C23.3229 8.92192 23.3829 8.54994 23.1909 8.27994Z" fill="currentColor" />
            <path d="M19.6568 20.208C19.1768 20.292 18.6908 20.322 18.4028 20.322C17.2028 20.322 16.2068 20.04 15.3608 19.464C13.9028 18.474 13.0328 16.8781 13.0328 15.192C13.0328 14.862 12.7628 14.592 12.4328 14.592C12.1028 14.592 11.8328 14.862 11.8328 15.192C11.8328 17.28 12.9008 19.248 14.6828 20.46C15.7208 21.162 16.9748 21.522 18.4028 21.522C18.5708 21.522 19.1648 21.516 19.8668 21.39C20.1908 21.33 20.4128 21.018 20.3528 20.694C20.2928 20.37 19.9808 20.148 19.6568 20.208Z" fill="currentColor" />
        </svg>
    )
}

const IconCalendar = (props) => {
    return (
        <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M7 3V6" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13 3V6" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M3 8H17" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14.6667 4H5.33333C4.04467 4 3 5.05824 3 6.36364V14.6364C3 15.9418 4.04467 17 5.33333 17H14.6667C15.9553 17 17 15.9418 17 14.6364V6.36364C17 5.05824 15.9553 4 14.6667 4Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

const IconMultiplePlus = (props) => {
    return (
        <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M4.16667 13.3333H3.70333C3.03917 13.3333 2.5 12.7942 2.5 12.13V3.70333C2.5 3.03917 3.03917 2.5 3.70333 2.5H12.1292C12.7942 2.5 13.3333 3.03917 13.3333 3.70333V4.16667" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M16.2967 17.4998H7.87008C7.20591 17.4998 6.66675 16.9607 6.66675 16.2965V7.86984C6.66675 7.20567 7.20591 6.6665 7.87008 6.6665H16.2959C16.9609 6.6665 17.5001 7.20567 17.5001 7.86984V16.2957C17.5001 16.9607 16.9609 17.4998 16.2967 17.4998Z" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" />
            <path d="M12.0833 10V14.1667" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14.1667 12.0835H10" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export {
    IconDownArrow,
    IconUpArrow,
    IconPencil,
    IconRectangle,
    IconZone,
    IconDotRectangle,
    IconPlug,
    IconLayer,
    IconCircleChecked,
    IconUndo,
    IconRedo,
    IconThreeDots,
    IconPlus,
    IconMinus,
    IconPlantType,
    IconHVACSystem,
    IconStar,
    IconPointerArrow,
    IconReportDetail,
    IconReview,
    IconChat,
    IconCheckedOutline,
    IconErrorOutline,
    IconBackArrow,
    IconCaretDown,
    IconCaretUp,
    IconCircleInfo,
    IconEditPenCheckmark,
    IconEditPen,
    IconMatrics,
    IconCertificateMedal,
    IconCloudSync,
    IconWaterPerformance,
    IconChecker,
    IconLeftArrow,
    IconRightArrow,
    IconArrowUp,
    IconArrowDown,
    IconSync,
    IconHeaderCaret,
    IconHeaderCaretUp,
    IconHeaderCaretDown,
    IconSearch,
    IconTrash,
    IconAddOutline,
    IconPenEdit,
    IconPhoto,
    IconBottomInfo,
    IconGreenInfo,
    IconSmallCircle,

    IconToolTextColor,
    IconToolTextBold,
    IconToolTextItalic,
    IconToolTextUnderline,
    IconToolAlignLeft,
    IconToolAlignCenter,
    IconToolAlignRight,
    IconToolAlignJustify,
    IconToolParaBullet,
    IconToolParaNumber,
    IconToolMiscChat,
    IconToolMiscCat,
    IconToolUsers,

    IconTrashBin,
    IconCalendarAddPlus,
    IconBuildingEdit,

    IconWaterTab,
    IconEnergyTab,

    IconPin,
    IconClock,
    IconFrontLocation,
    IconMall,
    IconFingerPrint,
    IconCalendar,
    IconMultiplePlus
}

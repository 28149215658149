import React, { useState, useEffect, useContext } from 'react'
import { useHistory, useParams, Link } from 'react-router-dom'
import {
    Typography,
    makeStyles,
} from '@material-ui/core'
import { ReactGrid } from "@silevis/reactgrid";
import "@silevis/reactgrid/styles.css";
import "./assets/styles.css";
import Constants from '../../../config/Constants'
import { getColumns } from "./Columns";
import {
    getRows,
} from "./Rows";

import { AlertUtil } from '@src/utils'
import TooltipCellTemplate from './TooltipCellTemplate'
import SummaryAPI from '../SummaryAPI'
const summaryAPI = new SummaryAPI()


const useStyles = makeStyles((theme) => ({
    container: {
        display: 'block',
        width: '100%',
        position: 'relative',
        padding: '0',
        margin: 'auto',
        overflow: 'auto',
        '& > .reactgrid': {
            margin: 'auto',
        }
    },
    selector: {
        height: '40px',
        marginRight: '12px',
        '& > div': {
            padding: '5px'
        }
    },
    deleteButton: {
        color: `rgb(220, 0, 78)`,
        border: `1px solid`,
        '&:hover': {
            color: `rgb(220, 0, 78)`,
            border: `1px solid`,
        }
    },
    buttonProgress: {
        // style for button loading
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    wrapperButton: {
        position: 'relative'
    },
    attachments: {
        borderCollapse: 'collapse',
        "& tr": {
            "& td": {
                padding: 5,
            },
            "& th": {
                fontWeight: 500,
                padding: 5
            },

            "& td.currentCol": {
                border: `1px solid #000`,
            }
        }
    },
    uploadInput: {
        display: 'none',
    },
}))

export default ({ storeNotification, onLoading }) => {
    const classes = useStyles()

    const [summaryLines, setSummaryLines] = useState(() => []);
    const [accessToken, setAccessToken] = useState(() => '');

    const columns = getColumns()
    const rows = getRows(summaryLines);

    useEffect(() => {
        loadData()

        if (window.localStorage.getItem('currentPtUser') !== null) {
            const u = JSON.parse(window.localStorage.getItem('currentPtUser'))
            const token = u.access_token
            if (token) {
                setAccessToken(token)
            }
          }
  
    }, [])

    const loadData = async () => {
        onLoading(true)
        try {
            const { data: summaryData } = await summaryAPI.getSummaryData()
            setSummaryLines(summaryData)
        } catch (e) {
            const m = e.message ? e.message : e.toString()
            AlertUtil.showErrorAlert(storeNotification, m)
        }
        onLoading(false)
    }

    return (
        <div className={`${classes.container} tracker-wrapper`}>
            <div className="pb-20" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Typography variant="h4" className="text-center">Summary</Typography>
                <span style={{paddingLeft: 20}}>(<a href={`${Constants.API_URL}/track/summary-export?access_token=${accessToken}`} download="summary.xlsx">Download</a>)</span>
            </div>
            <div className={`${classes.container}`}>
                <ReactGrid
                    rows={rows}
                    columns={columns}
                    stickyTopRows={1}
                    // props below are availble for PRO version
                    enableFillHandle
                    enableRangeSelection
                    customCellTemplates={{ tooltip: new TooltipCellTemplate() }}
                />
            </div>
        </div>
    )
}

import { makeStyles } from '@material-ui/core/styles'
import { green } from '@material-ui/core/colors'

const useStyles = makeStyles((theme) => ({
  main: {
    maxWidth: '432px',
    paddingLeft: 0,
    paddingRight: 0
  },
  paper: {
    margin: theme.spacing(8, 0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    minHeight: '5.6rem',
    fontWeight: 400
  },
  socialGroupBtn: {
    margin: theme.spacing(2, 0)
  },
  via: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    fontWeight: '400',
    fontSize: '2rem'
  },
  or: {
    width: '100%',
    textAlign: 'center',
    fontSize: '1.8rem',
    fontWeight: 'bold',
    borderBottom: '1px solid #000',
    lineHeight: '0.1em',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1.5),
    '& span': {
      background: theme.palette.background.default,
      padding: '0 10px'
    }
  },
  buttonProgress: {
    // style for button loading
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -7,
    marginLeft: -12
  },
  wrapperButton: {
    position: 'relative'
  },
  secondLink: {
    margin: theme.spacing(0.5, 0.75),
    fontWeight: 400,
    color: '#7092FE',
    textDecoration: 'none'
  },
  checkBox: {
    marginTop: '16px',
    fontSize: '1.4rem',
    color: '#868E92'
  },
  poppoverLink: {
    cursor: 'pointer',
    textDecoration: 'none',

    color: '#2D292A'
  },
  poppoverContainer: {
    minWidth: '432px',
    padding: theme.spacing(1),
    '&:hover': {
      backgroundColor: '#ecf1f5'
    }
  },
  poppoverContent: {
    // padding: theme.spacing(2),
    fontSize: '1.4rem'
  },
  poppoverIcon: {
    padding: theme.spacing(2)
  },
  poppoverHeader: {
    fontWeight: '500'
  }
}))

export default useStyles

import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import { Typography, Container, Box, Grid } from '@material-ui/core'

import TitleGroupAuth from '@src/components/common/TitleGroupAuth'
import UseStyles from './ForgotEmailStyle'

const useStyles = UseStyles

function SignInSide(props) {
  const storeUser = props.storeUser
  const classes = useStyles()
  return (
    <>
      <Container component='main' maxWidth='xs' className={classes.main}>
        <div className={classes.paper}>
          <TitleGroupAuth
            title={'Forgot e-mail?'}
            description={`If the email you connected to Akeptus is not accessible anymore, you can log in via.`}
          />

          <div className={classes.boxLink}>
            <Typography variant='caption'>
              <Box mt={2.5} mb={0} fontSize='1.4rem' align='center'>
                In case none of these work, contact our
                <Link className={classes.secondLink} to='#' variant='body2'>
                  Technical Support.
                </Link>
              </Box>
            </Typography>

            <Link
              className={classes.secondLink}
              to='/auth/forgot-password'
              variant='body2'
            >
              Forgot password
            </Link>

            <Link
              className={classes.secondLink}
              to='/auth/signin'
              variant='button'
              color='textPrimary'
            >
              Return
            </Link>
          </div>
        </div>
      </Container>
    </>
  )
}

export default SignInSide

import ENUMS from '../config/Enums'

class AlertUtil {
  static showAlert(storeNotification, type, message) {
    storeNotification.setContent(type, message)
    storeNotification.setIsOpen(true)
  }
  static showErrorAlert (storeNotification, message) {
    const type = ENUMS.NotificationType.ERROR
    message = message || 'Have something wrong, please try again!'
    storeNotification.setContent(type, message)
    storeNotification.setIsOpen(true)
  }

  static showSuccessAlert (storeNotification, message) {
    const type = ENUMS.NotificationType.SUCCESS
    storeNotification.setContent(type, message)
    storeNotification.setIsOpen(true)
  }
}

export default AlertUtil

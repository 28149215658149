import React from 'react'

import { makeStyles, withStyles } from '@material-ui/core'
import { Pagination, PaginationItem } from '@material-ui/lab'

import Constants from '@src/config/Constants'

const colorS = Constants.COLORS

const PTPaginationItem = withStyles(() => ({
    root: {
        margin: 0,
        borderRadius: 0,
        borderColor: '#D6D6D6',
        borderRight: 'none',
        height: 40,
        width: 40,
        color: colorS.sGrey
    },
    selected: {
        color: colorS.sBlack
    },
    page: {
        '&$selected': {
            background: 'rgba(112,146,254,.1)',
            border: '1px solid ' + colorS.sPurple,
            '&:hover': {
                background: 'rgba(112,146,254,.1)'
            }
        }
    },
    ellipsis: {
        height: 40,
        width: 40,
        lineHeight: '40px',
        border: `1px solid #D6D6D6`,
        borderRightColor: 'transparent',
        borderRadius: 0,
    }
}))(PaginationItem);

const useStyles = makeStyles((theme) => ({
    first: {
        borderRadius: '6px 0 0 6px'
    },
    last: {
        borderRight: '1px solid ' + '#D6D6D6',
        borderRadius: '0 6px 6px 0'
    }
}))

function Paginator({ onChange, pageCount }) {
    const classes = useStyles()
    return (
        <Pagination
            count={pageCount}
            showFirstButton
            showLastButton
            variant="outlined"
            shape="rounded"
            size="large"
            onChange={(event, page) => { onChange(event, page) }}
            renderItem={(item) => {
                return (
                    <PTPaginationItem {...item}
                        className={
                            (item.type == 'last') ? classes.last : ((item.type == 'first') ? classes.first : '')
                        } />)
            }}
        />
    )
}

export default Paginator

import React, { useState, useEffect } from 'react'
import {
    withStyles,
    makeStyles,
    LinearProgress,
    Typography
} from '@material-ui/core'

import Constants from '@src/config/Constants'
import { green } from '@material-ui/core/colors'
const colorS = Constants.COLORS

const PLinearProgress = withStyles(() => ({
    root: {
        height: 4,
        borderRadius: 4,
    },
    colorPrimary: {
        backgroundColor: '#F7F7FA',
    },
    bar: {
        borderRadius: 4,
        backgroundColor: colorS.sPurple,
    },
}))(LinearProgress)

const PRedLinearProgress = withStyles(() => ({
    root: {
        height: 4,
        borderRadius: 4,
    },
    colorPrimary: {
        backgroundColor: '#F7F7FA',
    },
    bar: {
        borderRadius: 4,
        backgroundColor: colorS.sRedAccent,
    },
}))(LinearProgress)

const progressStyels = makeStyles((theme) => ({
    container: {
        position: 'relative',
        width: '100%',
        textAlign: 'right'
    },
}))

const PLinearProgressWithLabel = ({ alert, percent, children }) => {
    const classes = progressStyels()
    return (
        <div className={classes.container}>
            {children}
            {!alert &&
                <PLinearProgress
                    variant="determinate"
                    value={percent}
                />
            }
            {alert &&
                <PRedLinearProgress
                    variant="determinate"
                    value={percent}
                />
            }
        </div>
    )
}

const cellProgressStyels = makeStyles((theme) => ({
    progress: {
        '&$none': {
            background: '#00B34144',
            '& > div': {
                background: '#00B341',
            }
        },
        '&$low': {
            background: '#FFA82544',
            '& > div': {
                background: '#FFA825',
            }
        },
        '&$medium': {
            background: '#FF6B4E44',
            '& > div': {
                background: '#FF6B4E',
            }
        },
        '&$high': {
            background: '#EC484744',
            '& > div': {
                background: '#EC4847',
            }
        },
    },
    none: {},
    low: {},
    medium: {},
    high: {},
}))

const TableCellProgress = withStyles(() => ({
    root: {
        height: 2,
        borderRadius: 2,
    },
    colorPrimary: {
        backgroundColor: '#F7F7FA',
    },
    bar: {
        borderRadius: 2,
        backgroundColor: colorS.sRedAccent,
    },
}))(LinearProgress)

const PTableCellProgress = ({ className, value, statusFormula }) => {
    const classes = cellProgressStyels()
    const [themeColor, setThemeColor] = useState('none')

    useEffect(() => {
        if (statusFormula) {
            setThemeColor(statusFormula(value))
        }
    }, [])

    return (
        <TableCellProgress
            className={`${className} ${classes.progress} ${classes[themeColor]}`}
            variant="determinate"
            value={value}
        />
    )
}

export { PLinearProgress, PLinearProgressWithLabel, PRedLinearProgress, PTableCellProgress }
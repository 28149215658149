
export function calculateOutputVariables(inputVariables) {
  const { trackLines, quarters } = inputVariables;

  const taxTotal = {
    credits_from_study: trackLines.reduce((t, c) => t + (c.credits_from_study || 0), 0),
    income_tax_allocation: trackLines.reduce((t, c) => t + (c.income_tax_allocation || 0), 0),
    payroll_allocation: trackLines.reduce((t, c) => t + (c.credits_from_study - c.income_tax_allocation || 0), 0),
  }

  const quarterTotal = quarters.map((item, index) => trackLines.reduce((t, c) => t + c.tax_values[index], 0))

  return {
    taxTotal,
    quarterTotal
  };
}

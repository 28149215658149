import React, { useEffect, useState } from 'react'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import NumberFormat from 'react-number-format'

import {
  Button,
  Grid,
  Card,
  CardMedia,
  CardContent,
  MenuItem,
  Typography,
  Box
} from '@material-ui/core'

import InputBankCardNumber from './InputBankCardNumber'
import InputTextTransparent from './InputTextTransparent'
import InputBankCardExpiry from './InputBankCardExpiry'
import InputBankCardCVC from './InputBankCardCVC'

import PictureBankCardBg from '@src/assets/img/p-bank-card-background.svg'

// import CompanyAPI from '../../../components/Company/CompanyAPI'
// import AlertUtil from '../../../utils/AlertUtil'

const useStyles = makeStyles((theme) => ({
  bankCard: {
    display: 'flex',
    marginTop: theme.spacing(3),
    border: `1px solid ${theme.palette.common.sGrey20}`,
    borderRadius: '16px'
  },
  bankCardBefore: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column-reverse',
    width: '408px',
    height: '240px',
    padding: '36px 12px',
    background: 'linear-gradient(59.53deg, #7092FE 0%, #A2B9FF 100%)',
    borderRadius: '16px',
    '&:$before': {
      content: '""',
      opacity: '0.1',
      position: 'absolute',
      left: 0,
      top: 0,
      zIndex: 0,
      width: '100%',
      height: '100%',
      backgroundImage: `url(${PictureBankCardBg})`,
      borderRadius: '16px'
    }
  },
  bankCardAfter: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column-reverse',
    maxWidth: '135px',
    flexGrow: 1,
    padding: '36px 18px',
    '&:$before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 48,
      zIndex: 1,
      width: '100%',
      height: '56px',
      backgroundColor: theme.palette.common.sGrey20
    }
  },
  bankCardNumberBox: {
    marginBottom: theme.spacing(3)
  }
}))

export default (props) => {
  const classes = useStyles()
  const theme = useTheme()

  const [cardNumberInitValue, setCardNumberInitValue] = useState('')
  const [cardNumber, setCardNumber] = useState('')

  const [cvcInitValue, setCvcInitValue] = useState('')
  const [cvc, setCvc] = useState('')

  useEffect(() => {
    // if (location.search) {
    //   const query = new URLSearchParams(location.search)
    //   if (query.get('location') !== null && query.get('category') !== null) {
    //     console.log(query.get('location'))
    //     console.log(query.get('category'))
    //   }
    // }
  }, [])

  return (
    <div className={classes.bankCard}>
      <div className={classes.bankCardBefore}>
        <Grid container>
          <Grid item xs={7}>
            <InputTextTransparent
              variant='filled'
              placeholder='Jason Statham'
              label='Card holder'
              type='text'
              fullWidth
              labelShrink
              // helperText={props.touched.password && props.errors.password}
              // error={props.touched.password && !!props.errors.password}
              name='inputName'
              value=''
            />
          </Grid>
          <Grid item xs={5}>
            <InputBankCardExpiry
              variant='filled'
              fullWidth
              // value={cardNumberInitValue}
              // onChange={(e) => setCardNumber(e)}
            />
          </Grid>
        </Grid>
        <div className={classes.bankCardNumberBox}>
          <InputBankCardNumber
            variant='filled'
            fullWidth
            value={cardNumberInitValue}
            onChange={(e) => setCardNumber(e)}
          />
        </div>
      </div>
      <div className={classes.bankCardAfter}>
        <InputBankCardCVC
          variant='filled'
          value={cvcInitValue}
          onChange={(e) => setCvc(e)}
          // value={cardNumberInitValue}
          // onChange={(e) => setCardNumber(e)}
        />
      </div>
    </div>
  )
}

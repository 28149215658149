import React, { useContext, useEffect, useState } from 'react'
import { Route, withRouter, useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { makeStyles } from '@material-ui/core/styles'
import { LinearProgress, Grid } from '@material-ui/core'

import CurrentPtUserStorage from '@src/components/CurentUser/CurrentPtUserStorage'
import Notification from '@src/components/Notification/Notification'
import NotificationStore from '@src/components/Notification/NotificationStore'
import { TrackerRoutes } from './constant'
import HeaderPrimary from '@src/components/Header/HeaderPrimary'

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    margin: '0 auto',
    width: '100%',
    padding: '84px 20px 40px'
  },
  root: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column'
  },
  progress: {
    width: '100%',
    marginTop: '-40px',
    marginBottom: '40px',
  }
}))

function TrackerLayout(props) {
  const storeUser = useContext(CurrentPtUserStorage)
  const storeNotification = useContext(NotificationStore)
  const classes = useStyles()

  const [loading, setLoading] = useState(false);

  useEffect(() => {
  }, [])

  return (
    <>
      <Notification />
      <Grid container component='main' className={classes.root}>
        <HeaderPrimary storeUser={storeUser} />
        <div className={classes.container}>
          {loading && <LinearProgress className={classes.progress} />}          
          {TrackerRoutes.map(({ path, exact = true, component: Comp }) => (
            <Route
              key={path}
              path={path}
              exact={exact}
              render={(props) => (
                <Comp
                  {...props}
                  storeUser={storeUser}
                  storeNotification={storeNotification}
                  onLoading={(e) => setLoading(e)}
                />
              )}
            />
          ))}
        </div>
      </Grid>
    </>
  )
}

export default observer(withRouter(TrackerLayout))

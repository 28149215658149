import React from 'react'
import { Modal, IconButton, Button, Box } from '@material-ui/core'

import { makeStyles, useTheme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  btn: {
    border: 'none',
    '&:hover': {
      border: 'none'
    }
  }
}))

export default (props) => {
  const classes = useStyles()
  const theme = useTheme()

  return (
    <Button
      {...props}
      variant='outlined'
      color='primary'
      className={`${classes.btn} ${props.className}`}
    >
      {props.children}
    </Button>
  )
}

import CompanyHome from "./Home"

const prefixCompanyRoute = '/company'

const CompanyRoutes = [
    {
        path: `${prefixCompanyRoute}`,
        component: CompanyHome,
    },
]

export { CompanyRoutes }
import React from 'react'
import { createMuiTheme } from '@material-ui/core/styles'

import SwitchActiveIcon from './assets/img/i-done-circle.svg'
import SwitchInActiveIcon from './assets/img/i-inactive-circle.svg'

import Constants from './config/Constants'

const colorS = Constants.COLORS

const constantS = {
  radiusValue: '8px',
  radiusSmallValue: '6px',
  bodyText: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '1.4rem',
    lineHeight: '20px'
  }
}

const globalTheme = createMuiTheme({
  palette: {
    common: {
      ...colorS,
      ...constantS
    },
    activeButtonGroup: {
      background: '#D2DFE8'
    }
  }
})

const theme = createMuiTheme({
  palette: {
    common: {
      ...colorS,
      ...constantS
    },
    background: {
      default: '#F8FBFF'
    }
  },
  typography: {
    // Tell Material-UI what's the font-size on the html element is.
    fontFamily: '"Ubuntu", sans-serif',
    htmlFontSize: 10
  },
  // override component style
  overrides: {
    MuiCssBaseline: {
      '@global': {
        // '*::-webkit-scrollbar': {
        //   width: '0.4em'
        // },
        // '*::-webkit-scrollbar-track': {
        //   '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
        // },
        // '*::-webkit-scrollbar-thumb': {
        //   backgroundColor: 'rgba(0,0,0,.1)',
        //   outline: '1px solid slategrey'
        // }

        /* Small Text */
        '.small-text': {
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '1.2rem',
          lineHeight: '18px'
        }
      }
    },
    // style typography
    MuiTypography: {
      /* H2 Title Regular */
      h2: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '3rem',
        lineHeight: '36px'
      },

      /* H3 Title Regular */
      h3: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '2.4rem',
        lineHeight: '30px'
      },

      /* H4 Title Regular */
      h4: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '2rem',
        lineHeight: '26px'
      },

      /* H5 Title Regular */
      h5: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '1.6rem',
        lineHeight: '22px'
      },

      /* Body Text */
      body1: {
        ...constantS.bodyText
      },

      /* Ultra Small */
      body2: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '1.1rem',
        lineHeight: '18px'
      },

      /* Caption */
      caption: {
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '1.4rem',
        lineHeight: '20px'
      }

      // TODO: style typography element
      // | 'h1'
      // | 'h2'
      // | 'h3' -> done
      // | 'h4' -> done
      // | 'h5' -> done
      // | 'h6'
      // | 'subtitle1'
      // | 'subtitle2'
      // | 'body1' -> done: Body Text
      // | 'body2' -> done: Ultra Small
      // | 'caption' -> done
      // | 'small-text' -> done: style in the global
      // | 'button'
      // | 'overline'
      // | 'srOnly'
      // | 'inherit'
    },
    // Style sheet name
    MuiToggleButtonGroup: {
      // Name of the rule
      grouped: {
        background: 'white'
        // border: '1px solid ' + colorS.sPurple
      }
    },
    // override toggle button style
    MuiToggleButton: {
      // Name of the rule
      label: {},
      root: {
        flexGrow: '1',
        height: '40px',
        paddingTop: 0,
        paddingBottom: 0,
        color: colorS.sGrey,
        border: '1px solid ' + colorS.sGrey20,
        '&$selected': {
          color: colorS.sWhite,
          backgroundColor: colorS.sPurple,
          border: '1px solid ' + colorS.sPurple,
          '&:hover': {
            backgroundColor: colorS.sPurple
          }
        }
      }
    },
    // override button style
    MuiButton: {
      root: {
        textTransform: 'none',
        fontSize: '1.4rem',
        fontWeight: 'normal',
        lineHeight: '20px'
      },
      contained: {
        color: colorS.sWhite,
        textTransform: 'none',
        fontSize: '1.4rem',
        fontWeight: 'normal',
        lineHeight: '20px',
        backgroundColor: colorS.sPurple,
        borderRadius: '6px',
        boxShadow: 'none',
        '&:hover': {
          backgroundColor: colorS.sPurpleHover,
          boxShadow: 'none'
        },
        '&$disabled': {
          color: colorS.sWhite,
          backgroundColor: colorS.sPurpleDisabled
        }
      },
      containedSecondary: {
        color: colorS.sBlack,
        backgroundColor: colorS.sGrey20,
        '&:hover': {
          backgroundColor: colorS.sGrey20,
          boxShadow: 'none',
          opacity: '0.5'
        },
        '&$disabled': {
          color: colorS.sBlack,
          backgroundColor: colorS.sGrey20,
          opacity: '0.5'
        }
      },
      outlined: {
        color: colorS.sBlack,
        textTransform: 'none',
        fontSize: '1.4rem',
        backgroundColor: colorS.sWhite,
        borderRadius: '6px',
        boxShadow: 'none',
        '&:hover': {
          color: colorS.sBlack,
          backgroundColor: colorS.sWhite
        }
        // '&$disabled': {
        //   color: colorS.sBlack,
        //   backgroundColor: colorS.sWhite
        // }
      },
      text: {
        color: colorS.sBlack,
        textTransform: 'none',
        fontSize: '1.4rem',
        backgroundColor: colorS.sWhite,
        borderRadius: '7px',
        boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.1)',
        '&:hover': {
          color: colorS.sBlack,
          backgroundColor: colorS.sWhite
        }
        // '&$disabled': {
        //   color: colorS.sBlack,
        //   backgroundColor: colorS.sWhite
        // }
      }
    },
    // override input label style
    MuiInputLabel: {
      filled: {
        fontSize: '1.4rem'
      }
    },
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: colorS.sGrey
        }
      }
    },
    // _TODO: fix here.
    MuiInputBase: {
      root: {
        fontSize: '1.4rem',
        height: '56px',
        paddingLeft: 12,
        paddingRight: 12,
        background: '#fff'
      },
      multiline: {
        height: 'auto',
        padding: '12px 0 12px 12px'
      }
    },
    MuiInput: {
      root: {
        height: '56px',
        backgroundColor: colorS.sGrey20,
        borderRadius: '6px',
        '&:hover': {
          border: '1px solid ' + colorS.sGrey20,
          backgroundColor: colorS.sWhite
        },
        '&$focused': {
          border: '1px solid ' + colorS.sPurple,
          backgroundColor: colorS.sWhite
        },
        // disabled style
        '&$disabled': {
          backgroundColor: colorS.sWhite,
          border: '1px solid ' + colorS.sGrey20
        }
      },
      // '&$underline': {
      underline: {
        height: '56px',
        '&:$before': {
          border: 'none',
          transition: 'none'
        },
        '&:$after': {
          border: 'none',
          transition: 'none'
        },
        '&:hover': {
          // style fake .foo class to overridee this rule
          '&:not(.foo):before': {
            border: 'none'
          },
          '&:before': {
            border: 'none',
            transition: 'none'
          }
        },
        '&$focused': {
          '&:after': {
            border: 'none',
            transition: 'none'
          }
        },
        '&$disabled': {
          '&:before': {
            border: 'none',
            transition: 'none'
          }
          // '&:hover': {
          //   '&:before': {
          //     border: 'none'
          //   }
          // },
        }
      }
    },
    // override textfield style
    MuiOutlinedInput: {
      root: {
        '&$focused': {
          '& + $notchedOutline': {
            border: '1px solid ' + colorS.sPurple
          }
        }
      }
    },
    // override textfield style
    MuiFilledInput: {
      root: {
        // backgroundColor: colorS.sGrey20,
        backgroundColor: colorS.sBlueAccent3,
        borderRadius: '6px',
        borderTopRightRadius: '6px',
        borderTopLeftRadius: '6px',
        borderBottomLeftRadius: '6px',
        borderBottomRightRadius: '6px',
        border: '1px solid ' + colorS.sBlueAccent3,
        '&:hover': {
          border: '1px solid ' + colorS.sGrey20,
          backgroundColor: colorS.sWhite
        },
        '&$focused': {
          // border: '1px solid ' + colorS.sGrey20,
          border: '1px solid ' + colorS.sPurple,
          backgroundColor: colorS.sWhite
        },
        // disabled style
        '&$disabled': {
          backgroundColor: colorS.sWhite,
          border: '1px solid ' + colorS.sGrey20
        }
      },

      input: {
        paddingLeft: 0,
        paddingRight: 0
      },

      // '&$underline': {
      underline: {
        height: '56px',
        // border: '2px solid ' + colorS.sPurple,
        '&:$before': {
          border: 'none',
          transition: 'none'
        },
        '&:$after': {
          border: 'none',
          transition: 'none'
        },
        '&:hover': {
          '&:before': {
            border: 'none',
            transition: 'none'
          }
        },
        '&$focused': {
          '&:after': {
            border: 'none',
            transition: 'none'
          }
        },
        '&$disabled': {
          '&:before': {
            border: 'none',
            transition: 'none'
          }
          // '&:hover': {
          //   '&:before': {
          //     border: 'none'
          //   }
          // },
        }
      }
    },
    // override input select style
    MuiSelect: {
      select: {
        borderRadius: '6px',
        // border: '2px solid ' + colorS.sWhite
        // '&:focus': {
        //   borderRadius: '6px',
        //   border: '2px solid ' + colorS.sPurple,
        //   backgroundColor: colorS.sWhite
        // }
        '&:focus': {
          background: 'none!important'
        }
      }
    },
    // override tooltip
    MuiTooltip: {
      // tooltip: {
      //   backgroundColor: 'red'
      // },
      tooltipArrow: {
        padding: '16px',
        fontSize: '1.2rem',
        color: colorS.sBlack,
        backgroundColor: colorS.sWhite,
        boxShadow:
          '0px 9px 46px rgba(0, 0, 0, 0.02), 0px 24px 38px rgba(0, 0, 0, 0.04), 0px 11px 15px rgba(0, 0, 0, 0.08)',
        borderRadius: '6px'
      },
      arrow: {
        '&:before': {
          color: colorS.sWhite
        }
        // backgroundColor: 'blue'
      }
    },
    // override backdrop
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.7)'
      }
    },
    // override dialog
    MuiDialog: {
      paper: {
        overflowY: 'unset'
        // borderRadius: '6px',
        // boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.07), 0px 0px 12px rgba(0, 0, 0, 0.04)'
      }
    },
    MuiDialogTitle: {
      root: {
        padding: '24px'
      }
    },
    MuiDialogContent: {
      root: {
        padding: '0 24px 24px 24px'
      }
    },
    MuiPaper: {
      rounded: {
        borderRadius: '6px'
      },
      elevation8: {
        boxShadow:
          '0px 9px 46px rgba(0, 0, 0, 0.02), 0px 24px 38px rgba(0, 0, 0, 0.04), 0px 11px 15px rgba(0, 0, 0, 0.08)'
      }
    },
    MuiFab: {
      primary: {
        backgroundColor: colorS.sPurple,
        '&:hover': {
          backgroundColor: colorS.sPurple
        }
      }
    },
    // override tab
    MuiTabs: {
      indicator: {
        backgroundColor: colorS.sPurple
      }
    },
    MuiTab: {
      root: {
        textTransform: 'none'
      },
      textColorInherit: {
        color: colorS.sBlack,
        opacity: 1,
        '&$selected': {
          color: colorS.sBlack
        }
      },
      textColorPrimary: {
        color: '#6E6D7A',
        opacity: 1,
        '&$selected': {
          color: colorS.sPurple
        }
      },
      wrapper: {
        ...constantS.bodyText
      }
    },
    // override divider
    MuiDivider: {
      root: {
        backgroundColor: colorS.sGrey20
      }
    },
    // override avatar
    MuiAvatar: {
      rounded: {
        borderRadius: '8px'
      }
    }
  }
})

export default {
  GlobalTheme: globalTheme,
  Theme: theme
}

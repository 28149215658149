import React, { useState, useEffect, useContext } from 'react'
import { withRouter, Link as RouterLink, useHistory } from 'react-router-dom'

import {
  makeStyles,
  useTheme,
  Typography,
  Popover,
  Link,
  Card,
  CardMedia,
  CardContent,
  Box
} from '@material-ui/core'
import { Avatar } from '@src/components/CustomTheme'

import ENUMS from '../../config/Enums'
import ImgUser from '@src/assets/img/icon-user.svg'
import IconAdd from '@src/assets/img/i-add-circle-grey.svg'
import IconLogout from '@src/assets/img/i-log-out-black.svg'

const useStyles = makeStyles((theme) => ({
  nav: {
    cursor: 'pointer',
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: '24px',
    maxHeight: '40px',
    // lineHeight: '1.4rem',
    textAlign: 'right',
    borderRadius: '50%'
  },
  avatar: {
    cursor: 'pointer',
    margin: 0,
    padding: '5px',
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    overflow: 'hidden',
    border: '1px solid #f0f0f0'
  },
  popoverRoot: {},
  popoverPaper: {
    boxShadow: '0 0 12px rgb(0 0 0 / 4%), 0 4px 6px rgb(0 0 0 / 7%)'
  },
  boxInfo: {
    padding: theme.spacing(2, 2, 0, 2)
  },
  card: {
    display: 'flex',
    alignItems: 'center',
    boxShadow: 'none'
  },
  cardDetails: {
    flex: 1
  },
  cardMedia: {
    // borderRadius: '50%'
  },
  img: {
    width: theme.spacing(5),
    height: theme.spacing(5)
  },
  cardContent: {
    padding: theme.spacing(0, 0, 0, 1.5),
    paddingBottom: '0!important'
  },
  boxMenu: {
    margin: theme.spacing(1.5, 2)
    // borderTop: `1px solid ${theme.palette.common.sGrey20}`
  },
  boxAction: {
    padding: theme.spacing(1.5, 3),
    background: theme.palette.common.sGrey20
  },
  actionItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center'
  }
}))

export default (props) => {
  const theme = useTheme()
  const history = useHistory()

  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)

  const [avatar, setAvatar] = useState('')
  const [openPopup, setOpenPopup] = useState(false)

  const handleClickDocument = () => {
    // if (props.storeUser.user.homeownerId) {
    // }
    // if (props.storeUser.user.tradesmanId) {
    // }
  }

  const handleClickSettings = () => {
    if (props.storeUser.user.homeownerId) {
      // go to setting page
      history.push('/settings/personal-data')
    }
    if (props.storeUser.user.tradesmanId) {
      // go to company page
      history.push('/settings/company')
    }
  }

  const handleClickOpen = (e) => {
    setAnchorEl(e.currentTarget)
    setOpenPopup(true)
  }

  const handleLogout = (e) => {
    // // disconnect to socket

    // // remove data questionnaire
    // window.localStorage.removeItem('questionnaire')

    props.storeUser.removeUser()
    window.localStorage.clear()
    history.push('/auth/signin')
  }

  const handleClose = () => {
    setAnchorEl(null)
    setOpenPopup(false)
  }
  const redirectToSetting = () => {
    history.push('/setting')
  }
  useEffect(() => {
    if (props.avatar) {
      setAvatar(props.avatar)
    }
  }, [props.avatar])

  return (
    <>
      <nav className={classes.nav} onClick={handleClickOpen}>
        <img src={ImgUser} alt={props.name} className={classes.avatar} />
      </nav>

      <Popover
        open={openPopup}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        classes={{ root: classes.popoverRoot, paper: classes.popoverPaper }}
      >
        <div className={classes.boxMenu}>
          <Typography
            onClick={redirectToSetting}
            component='div'
            variant='body1'
            className='cursor-pointer'
          >
            <Box
              component='span'
              mt={1}
              mb={1}
              ml={'46px'}
              color={theme.palette.common.sBlack}
            >
              Setting
            </Box>
          </Typography>
        </div>
        <div className={classes.boxMenu}>
          <Typography
            onClick={handleLogout}
            component='div'
            variant='h5'
            className={`${classes.actionItem} mt-16`}
          >
            <img src={IconLogout} alt='' />
            <Box
              component='span'
              ml={2.5}
              fontSize='1.4rem'
              color={theme.palette.common.sBlack}
            >
              &nbsp;Log out
            </Box>
          </Typography>
        </div>
      </Popover>
    </>
  )
}
